<ng-container>
    <mat-toolbar class="header mat-elevation-z8">

        <div class="header-logo">
            <img class="logo" [src]="logo_src" />
        </div>

        <div class="header-buttons" *ngIf="(isSessionLoaded$ | async) && isAccountActive">

            <app-header-button *ngIf="isMsp" icon="dashboard_customize" text="Tenants"
                [active]="currentModule?.startsWith('msp-')" [link]="msp_dashboard_link">
            </app-header-button>

            <div *ngIf="!!clientName" class="header-title">
                <p class="head">Current Tenant:</p>
                <p class="name">{{ clientName }}</p>
            </div>

            <ng-container *ngIf="!!tenant_id">
                <app-header-button icon="dashboard" text="Dashboard" [active]="currentModule === 'dashboard'"
                    [link]="dashboard_link">
                </app-header-button>

                <!-- <app-header-button icon="warning" text="Recover" [active]="currentModule === 'recover'"
                    [link]="recover_link">
                </app-header-button> -->

                <!-- <app-header-button icon="watch_later" text="Detect" [active]="currentModule === 'monitoring'"
                    [link]="monitoring_link">
                </app-header-button> -->
            </ng-container>

        </div>

        <div class="header-alt">
            <button (click)="openSupportModal()" class="support-btn"  mat-raised-button>
                Need Support ?
            </button>
        </div>

        <div class="header-alt">
            <app-profile-button color="primary" *ngIf="(isSessionLoaded$ | async)" [name]="username">
            </app-profile-button>
            <a href="https://www.octiga.io/roadmap_features" target="_blank">
                <button id="roadmap_features">
                    <mat-icon>location_on</mat-icon>
                    <span>Road Map</span>
                </button>
            </a>
        </div>

    </mat-toolbar>

    <ng-container>
        <div *ngIf="!isDesktop && isSmallScreen; then block else show"></div>
        <ng-template #block>
            <div class="block-container">
                <h1 style="text-align: center;">Window Too Small</h1>
                <p style="text-align: center;">
                    Small window sizes are not supported right now. Please expand your browser window.
                </p>
                <p style="text-align: center;">
                    If you are viewing on a mobile device please open the app on your desktop.
                </p>
            </div>
        </ng-template>
        <ng-template #show>
            <mat-sidenav-container class="sidenav-container" (click)="closeSideNav($event)">

                <mat-sidenav *ngIf="currentModule === 'monitoring'" #sidenav [opened]="isDesktop" mode="side"
                    [fixedInViewport]="false" [fixedTopGap]="0" [fixedBottomGap]="0">
                    <app-monitoring-nav [tenant_id]="tenant_id" (click)="!isDesktop ? sidenav.toggle(): null">
                    </app-monitoring-nav>
                </mat-sidenav>

                <mat-sidenav-content *ngIf="(isSessionLoaded$ | async)">
                    <router-outlet></router-outlet>
                </mat-sidenav-content>

            </mat-sidenav-container>
        </ng-template>


    </ng-container>

</ng-container>

<app-snack-bar></app-snack-bar>

<app-spinner *ngIf="(spinnerStatus$ | async) || !(isSessionLoaded$ | async)"></app-spinner>

<ng-template #supportRef>
    <div class="mat-typology">
        <h2>
            Octiga Support
        </h2>

        <p>
            Do you need some quick help from the Octiga Team?
        </p>

        <p>
            Please email

            <a href= "mailto: support@octiga.com">
                support@octiga.com
            </a>
        </p>

        <p>
            If you require a video support call, please let the team know and we will set it up accordingly.
        </p>

        <button mat-dialog-close mat-raised-button color="primary">
            Close
        </button>
    </div>
</ng-template>
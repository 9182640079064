import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of, take } from 'rxjs';
import { catchError, distinct, filter, map, mergeMap, sample } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { client } from '../..';
import * as actions from './actions';
import { Baseline } from './model';
import { BASELINES_CRUD } from 'src/app/routes/tenant-routes';

@Injectable()
export class SwayBaselineEffects {


    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadBaselines),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map(data => {
                        return actions.loadBaselinesSuccess({ _tenant, data });
                    }),
                    catchError(error => of(actions.loadBaselinesFailure({ _tenant, error })))
                )
            )
        )
    );

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createBaseline),
            mergeMap(({ _tenant, data }) => this.post(_tenant, data)
                .pipe(
                    map(data => actions.createBaselineSuccess({ _tenant, data })),
                    catchError((error: any) => of(actions.createBaselineFailure({ _tenant, error })))
                )
            )
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateBaseline),
            mergeMap(({ _tenant, data, update_auto_remediation }) => this.patch(_tenant, data, update_auto_remediation)
                .pipe(
                    map(data => actions.updateBaselineSuccess({ _tenant, data })),
                    catchError((error: any) => of(actions.updateBaselineFailure({ _tenant, error })))
                )
            )
        )
    );



    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteBaseline),
            mergeMap(({ _tenant, baselineId }) => this.delete(_tenant, baselineId)
                .pipe(
                    map(() => actions.deleteBaselineSuccess({ _tenant, baselineId })),
                    catchError((error: any) => of(actions.deleteBaselineFailure({ _tenant, error })))
                )
            )
        )
    );


    deleteTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteBaselineTemplate),
            mergeMap(({ _tenant, template_id }) => this.deleteTemplate(_tenant, template_id)
                .pipe(
                    map((baselines) => actions.deleteBaselineTemplateSuccess({ _tenant, baselines, })),
                    catchError((error: any) => of(actions.deleteBaselineFailure({ _tenant, error })))
                )
            )
        )
    );

    detachTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.detachBaselineTemplate),
            mergeMap(({ _tenant, template_id }) => this.detachTemplate(_tenant, template_id)
                .pipe(
                    map((baselines) => actions.detachBaselineTemplateSuccess({ _tenant, baselines })),
                    catchError((error: any) => of(actions.detachBaselineTemplateFailure({ _tenant, error })))
                )
            )
        )
    );

    deleteTemplate(_tenant: string, template_id: string) {
        const ready$ = this.store.select(client(_tenant).sway.baselines.status).pipe(filter(res => res.loaded));
        const baselines$ = this.store.select(client(_tenant).sway.baselines.byTemplateId(template_id)).pipe(map(res => res.map(res => res.id)));

        return baselines$.pipe(sample(ready$), take(1));
    }

    detachTemplate(_tenant: string, template_id: string) {
        const ready$ = this.store.select(client(_tenant).sway.baselines.status).pipe(filter(res => res.loaded));
        const baselines$ = this.store.select(client(_tenant).sway.baselines.byTemplateId(template_id)).pipe(map(baselines => baselines.map(baseline => ({
            ...baseline,
            template_id: null,
            template_item_id: null
        }))));

        return baselines$.pipe(sample(ready$), take(1));
    }

    /**
    * @description
    * get all baselines from a specific tenant (SWAY database)
    * @param {string} tenant - tenant ID (string)
    */
    private get(tenant: string) {
        return this.ajax.get(tenant, BASELINES_CRUD(tenant));
    }

    /**
    * @description
    * delete a baseline from a specific tenant (SWAY database)
    * @param {string} tenant - tenant ID (string)
    * @param {Baseline} baseline - baseline
    */
    private delete(tenant: string, baselineId: string) {
        return this.ajax.delete(tenant, `/api/sway/tenant/${tenant}/baseline/${baselineId}`);
    }


    /**
     * @description
     * add a new configured baseline to a specific tenant (SWAY database)
     * @param {string} tenant - tenant ID (string)
     * @param {Baseline} baseline - baseline
    */
    private post(tenant: string, baseline: Partial<Baseline>): Observable<Baseline> {
        return this.ajax.post(tenant, `/api/sway/tenant/${tenant}/baseline`, baseline);
    }

    /**
     * @description
     * edit existing  baseline's schema (SWAY database)
     * @param {string} tenant - tenant ID (string)
     * @param {Baseline} baseline - baseline
    */
    private patch(tenant: string, baseline: Partial<Baseline>, update_auto_remediation: boolean): Observable<Baseline> {
        if(update_auto_remediation){
            return this.ajax.patch(tenant, `/api/sway/tenant/${tenant}/baseline/${baseline.id}?update_auto_remediation=${update_auto_remediation}`, { schema: baseline.schema,key: baseline.key || undefined, template_item_id: baseline.template_item_id || undefined , auto_remediation: baseline.auto_remediation || false});
        }else{
            return this.ajax.patch(tenant, `/api/sway/tenant/${tenant}/baseline/${baseline.id}`, { schema: baseline.schema,key: baseline.key || undefined, template_item_id: baseline.template_item_id || undefined });
        }
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>
    ) { }

}

import { IdentitySecurityDefaultsEnforcementPolicy } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadSecurityDefaultsPolicy = createAction(
    '[Graph/Security Defaults] Load Security Defaults Policy ',
    props<{ _tenant: string }>()
);

export const loadSecurityDefaultsPolicySuccess = createAction(
    '[Graph/Domain] Load Security Defaults Polciy Success',
    props<{
        _tenant: string;
        policy: IdentitySecurityDefaultsEnforcementPolicy;
    }>()
);

export const loadSecurityDefaultsFailure = createAction(
    '[Graph/Domain] Load Security Defaults Policy Failure',
    props<{ _tenant: string; error: any }>()
);

export const updateSecurityDefaults = createAction(
    '[Graph/Security Defaults] Update Security Defaults Policy ',
    props<{ _tenant: string; isEnabled: boolean }>()
);

export const updateSecurityDefaultsSuccess = createAction(
    '[Graph/Domain] Create Security Defaults Polciy Success',
    props<{ _tenant: string; isEnabled: boolean }>()
);

export const updateSecurityDefaultsFailure = createAction(
    '[Graph/Domain] Create Security Defaults Policy Failure',
    props<{ _tenant: string; error: any }>()
);

import { createAction, props } from '@ngrx/store';
import { Session } from 'src/app/stores/root/octiga/session/model';

export const changeClient = createAction(
    '[Root/Session] Change Client',
    props<{ clientId: string }>()
);

export const loadSession = createAction(
    '[Root/Session] Load Session'
);

export const loadSessionSuccess = createAction(
    '[Root/Session] Load Session Success',
    props<{ session: Session }>()
);

export const loadSessionFailure = createAction(
    '[Root/Session] Load Session Failure',
    props<{ error: any }>()
);

export const deleteSession = createAction(
    '[Root/Session] Delete Session',
    props<{ microsoftSignout: boolean }>()
);

export const deleteSessionSuccess = createAction(
    '[Root/Session] Delete Session Success'
);

export const deleteSessionFailure = createAction(
    '[Root/Session] Delete Session Failure',
    props<{ error: any }>()
);

<form [formGroup]="form" class="mat-typography" *ngIf="defaultPolicy && form">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                Identity
            </td>

            <td>
                {{defaultPolicy?.Identity}}
            </td>

            <td>
                {{defaultPolicy?.Identity}}
            </td>

            <td>
                <sway-baseline-status></sway-baseline-status>
            </td>
        </tr>

        <tr>
            <td>
                <p class="title">
                    First Contact Safety Tips

                    <mat-icon [matTooltip]="description.EnableFirstContactSafetyTips">info</mat-icon>

                </p>
            </td>

            <td>
                {{defaultPolicy?.EnableFirstContactSafetyTips ? 'Enabled' : 'Disabled'}}
            </td>

            <td>
                <div class="form">
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="EnableFirstContactSafetyTips">
                                <mat-option [value]="true">Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </td>

            <td>
                <sway-baseline-status
                    [hasError]="defaultPolicy.EnableFirstContactSafetyTips !== formValue.EnableFirstContactSafetyTips">
                </sway-baseline-status>
            </td>
        </tr>

        <tr>
            <td>
                <p class="title">

                    Authentication Fail Action
                    <mat-icon [matTooltip]="description.AuthenticationFailAction">info</mat-icon>
                </p>
            </td>

            <td>
                {{defaultPolicy?.AuthenticationFailAction}}

            </td>

            <td>
                <div class="form">
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="AuthenticationFailAction">
                                <mat-option [value]="'MoveToJmf'">Move To Junk Mail Folder</mat-option>
                                <mat-option [value]="'Quarantine'">Quarantine</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </td>

            <td>
                <sway-baseline-status
                    [hasError]="defaultPolicy.AuthenticationFailAction !== formValue.AuthenticationFailAction">
                </sway-baseline-status>
            </td>
        </tr>

        <tr>
            <td>
                <p class="title">

                    Spoof Quarantine Tag
                    <mat-icon [matTooltip]="description.SpoofQuarantineTag">info</mat-icon>
                </p>

            </td>

            <td>
                {{defaultPolicy?.SpoofQuarantineTag}}
            </td>

            <td>
                <div class="form">
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="SpoofQuarantineTag">
                                <mat-option *ngFor="let policy of quarantinePolicylookup$ | async"
                                    [value]="policy.Name">{{policy.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </td>

            <td>
                <sway-baseline-status [hasError]="defaultPolicy.SpoofQuarantineTag !== formValue.SpoofQuarantineTag">
                </sway-baseline-status>
            </td>
        </tr>


        <tr>
            <td>
                <p class="title">

                    Spoof Intelligence

                    <mat-icon [matTooltip]="description.EnableSpoofIntelligence">info</mat-icon>
                </p>

            </td>

            <td>
                {{defaultPolicy?.EnableSpoofIntelligence ? 'Enabled': 'Disabled'}}
            </td>

            <td>
                <div class="form">
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="EnableSpoofIntelligence">
                                <mat-option [value]="true">Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </td>
            <td>
                <sway-baseline-status
                    [hasError]="defaultPolicy.EnableSpoofIntelligence !== formValue.EnableSpoofIntelligence">
                </sway-baseline-status>
            </td>
        </tr>

        <tr>
            <td>
                <p class="title">
                    Enable Via Tag
                    <mat-icon [matTooltip]="description.EnableViaTag">info</mat-icon>
                </p>
            </td>

            <td>
                {{defaultPolicy?.EnableViaTag ? 'Enabled': 'Disabled'}}
            </td>

            <td>
                <div class="form">
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="EnableViaTag">
                                <mat-option [value]="true">Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </td>

            <td>
                <sway-baseline-status [hasError]="defaultPolicy.EnableViaTag !== formValue.EnableViaTag">
                </sway-baseline-status>
            </td>
        </tr>

        <tr>
            <td>
                <p class="title">

                    Unauthenticated Sender
                    <mat-icon [matTooltip]="description.EnableUnauthenticatedSender">info</mat-icon>
                </p>
            </td>

            <td>
                {{defaultPolicy?.EnableUnauthenticatedSender ? 'Enabled': 'Disabled'}}
            </td>

            <td>
                <div class="form">
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="EnableUnauthenticatedSender">
                                <mat-option [value]="true">Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </td>
            <td>
                <sway-baseline-status
                    [hasError]="defaultPolicy.EnableUnauthenticatedSender !== formValue.EnableUnauthenticatedSender">
                </sway-baseline-status>
            </td>
        </tr>
    </table>
</form>

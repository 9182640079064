import { AppRole } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadServicePrincipalAppRoles = createAction(
    '[Graph/ServicePrincipalAppRole] Load',
    props<{ _tenant: string, id: string }>()
);

export const loadServicePrincipalAppRolesSuccess = createAction(
    '[Graph/ServicePrincipalAppRole] Load Success',
    props<{ _tenant: string, id: string, data: AppRole[] }>()
);

export const loadServicePrincipalAppRolesFailure = createAction(
    '[Graph/ServicePrincipalAppRole] Load Failure',
    props<{ _tenant: string, error: any }>()
);

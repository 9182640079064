import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { PersistentPolicySchema } from './model';
import { BehaviorSubject, filter, skip } from 'rxjs';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { FormBuilder } from '@angular/forms';
import { InitCapBaselineFormItem } from '../shared/cap-form';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss']
})

export class BaselineComponent extends BaseComponent implements OnInit, OnDestroy {
    schemaFunc = PersistentPolicySchema;
    schema$: BehaviorSubject<any>;
    policy$: BehaviorSubject<ConditionalAccessPolicy> = new BehaviorSubject(null);

    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.form = this.initForm(this.baseline.schema);
        
        this.schema$ = new BehaviorSubject(this.baseline.schema);
        this.schema$.pipe(filter(res => !!res), skip(1)).subscribe(schema => {// skip first emit
            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema))
            });
        });

    }

    initForm(schema) {
        return InitCapBaselineFormItem(schema, this.fb);
    }

    onBaselineErrorChange(e) {
        this.baselineErrorChange.next(e);
    }
}

import { Domain } from '@microsoft/microsoft-graph-types-beta';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export const loadDomains = createAction(
    '[Graph/Domain] Load Domains',
    props<{ _tenant: string }>()
);

export const loadDomainsSuccess = createAction(
    '[Graph/Domain] Load Domains Success',
    props<{ _tenant: string, domains: Domain[] }>()
);

export const loadDomainsFailure = createAction(
    '[Graph/Domain] Load Domains Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateDomain = createAction(
    '[Graph/Domain] Update Domain',
    props<{ _tenant: string, domain: Partial<Domain> & { id: string } }>()
);

export const updateDomainSuccess = createAction(
    '[Graph/Domain] Update Domain Success',
    props<{ _tenant: string, domain: Update<Domain> }>()
);

export const updateDomainFailure = createAction(
    '[Graph/Domain] Update Domain Failure',
    props<{ _tenant: string, error: any }>()
);

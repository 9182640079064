import { DirectoryRole, User } from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable, combineLatest, filter, map, sample, skipUntil } from 'rxjs';
import { client } from 'src/app/stores/client';
import { Status } from 'src/app/stores/status.interface';
import { UserExclusion } from '../../../group-spec-registry.service';
import { updateGraphUser } from 'src/app/stores/client/graph/user/user.actions';

export { FormComponent } from './form.component';
export { ValidatorComponent } from './validator.component';

export const schemaFieldName = 'lastLoginMonthValue';
export const currentValueFieldName = 'lastSignInDateTime';
const OneMonthInMilliseconds = 2629800000;


export interface UserWithLastLogin extends User {
    lastLoginMonthValue: number,
    lastSignInDateTime: string,
    roles: DirectoryRole[],
    RecipientTypeDetails: string,
}

export function fetch_data(store: Store<any>, tenant_id): Observable<UserWithLastLogin[]> {
    // users
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(
        filter(res => res.loaded)
    );

    const users$ = store.select(client(tenant_id).graph.users.all).pipe(sample(usersReady$));

    // roles
    const directoryRolesReady$ = store.select(client(tenant_id).graph.directoryRole.status).pipe(
        filter(res => res.loaded)
    );
    const directoryRoles$ = store.select(client(tenant_id).graph.directoryRole.all).pipe(
        sample(directoryRolesReady$)
    );

    // role members
    const directoryRoleMembersReady$ = store.select(client(tenant_id).graph.directoryRole.members.status).pipe(
        filter(res => res.loaded)
    );

    const directoryRoleMembers$ = store.select(client(tenant_id).graph.directoryRole.members.all).pipe(
        skipUntil(directoryRoleMembersReady$)
    );


    
    // mailboxes
    const mailboxesReady$ = store.select(client(tenant_id).powershell.exoMailbox.status).pipe(
        filter(res => res.loaded)
    );
    const mailboxes$ = store.select(client(tenant_id).powershell.exoMailbox.all).pipe(sample(mailboxesReady$));


    return combineLatest([users$, directoryRoles$, directoryRoleMembers$, mailboxes$])
        .pipe(map(([users, allRules, members, mailboxes]) => users.map(user => {
            // filter roles where user is member
            const user_roles = allRules.filter(role => members.find(drm => drm.roleTemplateId === role.roleTemplateId)?.members.some(m => m.id === user.id));


            const last_signIn_date = new Date(
                user.signInActivity?.lastSignInDateTime || user.lastPasswordChangeDateTime
            ).getTime();


            const today = new Date().getTime();
            const diff = (today - last_signIn_date);

            let lastLoginMonthValue;
            let lastSignInDateTime = 'Never logged in';

            lastLoginMonthValue = diff / OneMonthInMilliseconds;

            const date = DateTime.fromISO(user.signInActivity?.lastSignInDateTime || user.lastPasswordChangeDateTime);
            lastSignInDateTime = date.toFormat('d-M-yyyy');


            return {
                ...user,
                lastLoginMonthValue,
                lastSignInDateTime,
                roles: user_roles,
                RecipientTypeDetails: mailboxes.find(m => m.UserPrincipalName === user.userPrincipalName)?.RecipientTypeDetails
            };
        })));
}

export function select_status(store: Store<any>, tenant_id): Observable<Status> {
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(
        filter(res => res.loaded)
    );

    return usersReady$;
}

export function excludeFnForAdmins(user: UserWithLastLogin): UserExclusion {
   
    if(['EquipmentMailbox', 'RoomMailbox', 'SharedMailbox'].includes(user.RecipientTypeDetails)){
        return { type: 'other', icon: 'not_interested', class: 'na-icon', desc: `Excluded because this user is a ${user.RecipientTypeDetails}.` };

    }

    if (!user.accountEnabled) {
        return { type: 'sign-in_blocked', icon: 'not_interested', class: 'na-icon', desc: 'Excluded because this user is blocked from sign-in.' };
    }

    if(!user.signInActivity){
        return { type: 'sign-in_blocked', icon: 'close', class: 'na-icon', desc: 'This baseline requires one or more of the following service plans: 41781fb2-bc02-4b7c-bd55-b576c07bb09d, eec0eb4f-6444-4f95-aba0-50c24d67f998.' };
    }


    if (user.userType === 'Guest') {
        return { type: 'guest_user', icon: 'not_interested', class: 'na-icon', desc: 'Excluded because this user is a guest user.' };
    }


    if (!user.roles?.length) {
        return { type: 'other', icon: 'not_interested', class: 'na-icon', desc: 'Excluded because this user isn\'t an admin user.' };
    }

    return;
}

export function excludeFnForRegularUsers(user: UserWithLastLogin): UserExclusion {
    // console.log(user.RecipientTypeDetails,'koko')
   
    if(['EquipmentMailbox', 'RoomMailbox', 'SharedMailbox'].includes(user.RecipientTypeDetails)){
        return { type: 'other', icon: 'not_interested', class: 'na-icon', desc: `Excluded because this user is a ${user.RecipientTypeDetails}.` };

    }
   
    if (!user.accountEnabled) {
        return { type: 'sign-in_blocked', icon: 'not_interested', class: 'na-icon', desc: 'Excluded because this user is blocked from sign-in.' };
    }


    if (user.userType === 'Guest') {
        return { type: 'guest_user', icon: 'not_interested', class: 'na-icon', desc: 'Excluded because this user is a guest user.' };
    }

    
    // if (user.userType === 'Guest') {
    //     return { type: 'guest_user', icon: 'not_interested', class: 'na-icon', desc: 'Excluded because this user is a guest user.' };
    // }

    if(!user.signInActivity && !user.lastPasswordChangeDateTime ){
        return { type: 'sign-in_blocked', icon: 'close', class: 'na-icon', desc: 'This baseline requires one or more of the following service plans: 41781fb2-bc02-4b7c-bd55-b576c07bb09d, eec0eb4f-6444-4f95-aba0-50c24d67f998.' };
    }

    if (user.roles?.length > 0) {
        return { type: 'other', icon: 'not_interested', class: 'na-icon', desc: 'Excluded because this user is an admin user.' };
    }
    return;
}

export function Remediate(_tenant: string, current: User, schema: any) { // we block access;
    const data: Partial<User> = {
        id: current.id,
        accountEnabled: false,
    };

    return updateGraphUser({ _tenant, userId: current.id, data });
}

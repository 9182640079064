import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as MspUserActions from './actions';
import { MSPUser } from './model';
import { EffectsBase } from 'src/app/modules/shared/libs/state/effects-base';
import { LoaderState } from 'src/app/modules/shared/libs/loader/store/loader.reducer';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class MspUserEffects extends EffectsBase {

    loadMspUsers$ = this.createEffect(
        MspUserActions.loadMspUsers,
        mergeMap((action) => this.ajax.get('/api/octiga/v2/msp/user')
            .pipe(
                map((data: MSPUser[]) => MspUserActions.loadMspUsersSuccess({ msp_users: data })),
                catchError((error) => of(MspUserActions.loadMspUsersFailure({ error }))
                )
            )
        )
    );


    upsertMspUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MspUserActions.upsertMspUser),
            mergeMap((action) => this.ajax.post('/api/octiga/v2/msp/user', action.msp_user)
                .pipe(
                    map((data: MSPUser) => MspUserActions.upsertMspUserSuccess({ msp_user: data })),
                    catchError((error) => of(MspUserActions.upsertMspUserFailure({ error })))
                ))
        )
    );

    updateMspUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MspUserActions.updateMspUser),
            mergeMap((action) => this.ajax.put<MSPUser>(`/api/octiga/v2/msp/user/${action.data.id}`, action.data)
                .pipe(
                    map((data) => MspUserActions.updateMspUserSuccess({ data })),
                    catchError((error) => of(MspUserActions.updateMspUserFailure({ error })))
                )
            )
        )
    );

    deleteMspUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MspUserActions.deleteMspUser),
            mergeMap((action) => this.ajax.delete(`/api/octiga/v2/msp/user/${action.id}?tenant_id=${action.msp_id}`)
                .pipe(
                    map(() => { return MspUserActions.deleteMspUserSuccess({ id: action.id }); }),
                    catchError((error) => of(MspUserActions.deleteMspUserFailure({ error })))
                )
            )
        )
    );

    constructor(
      private ajax: MspAjaxService,
        protected actions$: Actions,
        protected loaderStore: Store<LoaderState>,
        protected snackbar: MatSnackBar,

    ) {
        super(actions$, loaderStore, snackbar);
    }

}

import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';

export { BaselineComponent } from './baseline.component';
export { Remediate } from './remediate';
export const href = 'https://learn.microsoft.com/en-us/azure/active-directory/conditional-access/howto-conditional-access-policy-risk';


function grantControls(grant) {
    return {
        'type': 'object',
        'properties': {
            'builtInControls': {
                'type': 'array',
                'items': {
                    'type': 'string',
                    'const': grant
                }
            }
        }
    };
}



const signInRiskLevels = {
    'type': 'array',
    'items': {
        'enum': [
            'high',
            'medium'
        ]
    },
    'uniqueItems': true,
    'maxItems': 2,
    'minItems': 2

};


function policy(form): ConditionalAccessPolicySchema {
    return {
        grantControls: grantControls(form.grant || 'block'),
        conditions: {
            signInRiskLevels
        }
    };
}
export const mfaCAPRiskySigninBaselineSchema =

    (
        form
    ) => {
        return createCapPolicySchema(form, policy(form));
    };
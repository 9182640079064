import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { TargetTenant } from './model';

export const featureKey = 'TargetTenant';

export interface State extends EntityState<TargetTenant>, Status { }

export const adapter: EntityAdapter<TargetTenant> = createEntityAdapter<TargetTenant>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadTargetTenant, (state) => ({ ...state, loading: true })),
    on(actions.loadTargetTenantSuccess, (state, action) => adapter.setAll(action.tenants, { ...state, loading: false, loaded: true })),
    on(actions.loadTargetTenantFailure, (state, { error }) => ({ ...state, error })),

    on(actions.createTargetTenant, (state) => ({ ...state, creating: true })),
    on(actions.createTargetTenantSuccess, (state, action) => adapter.addOne(action.tenant, { ...state, creating: false })),
    on(actions.createTargetTenantFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.deleteTargetTenant, (state) => ({ ...state, deleting: true })),
    on(actions.deleteTargetTenantSuccess, (state, action) => adapter.removeOne(action.id, { ...state, deleting: false })),
    on(actions.deleteTargetTenantFailure, (state, { error }) => ({ ...state, error, deleting: false }))
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export const selectEntity = (id: string) => createSelector(
    selectEntities,
    entities => entities[id]
);

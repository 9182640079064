import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './modules/dashboard/components/container/container.component';
import { ClientGuard } from './services/guards/client.guard';
import { SynchronousGuard } from './services/guards/synchronous.guard';
import { WildcardGuard } from './services/guards/wildcard.guard';
import { AllPermissions } from './utils/configured-azure-permissions';
import { MspGuard } from './services/guards/msp.guard';
import { RolePermissionsGuard } from './services/guards/role-permessions-guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    },


    {
        path: 'client/:tenant',
        canActivate: [SynchronousGuard],
        data: {
            guards: [ClientGuard],
            scopes: AllPermissions
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'monitoring',
                loadChildren: () => import('src/app/modules/monitoring/monitoring.module').then(m => m.MonitoringModule)
            },
            {
                path: 'account',
                loadChildren: () => import('src/app/modules/billing/billing.module').then(m => m.BillingModule)
            },
            {
                path: 'hardening',
                loadChildren: () => import('src/app/modules/hardening/hardening.module').then(m => m.HardeningModule)
            },
            {
                path: 'sway',
                loadChildren: () => import('src/app/modules/sway/sway.module').then(m => m.SwayModule),
            },
            {
                path: 'report',
                loadChildren: () => import('src/app/modules/report/report.module').then(m => m.ReportModule)
            },

            // redirects
            {
                path: 'dashboard/user-list',
                redirectTo: 'monitoring/events-user',
                pathMatch: 'full'
            },
            {
                path: '**',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            }
        ]
    },

    {
        path: 'msp',
        canActivate: [MspGuard],
        loadChildren: () => import('src/app/modules/msp/msp.module').then(m => m.MspModule)
    },

    {
        path: 'templates',
        canActivate: [MspGuard, RolePermissionsGuard],
        data: {
            feature: 'msp-baseline-templates',
            action: 'create',
            scope: 'msp'
        },
        loadChildren: () => import('src/app/modules/sway/templates.module').then(m => m.TemplatesModule)
    },
    {
        path: '**',
        canActivate: [WildcardGuard],
        component: ContainerComponent, // hack - WildcardGuard means we will never reach this component
    }
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // enableTracing: true
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';
import { Dismiss } from './model';

@Injectable()
export class DismissEffects {

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadDismissItems),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map(data => actions.loadDismissItemsSuccess({ _tenant, data })),
                    catchError(error => of(actions.loadDismissItemsFailure({ _tenant, error })))
                )
            )
        )
    );

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createDismissItem),
            mergeMap(({ _tenant, data }) => this.post(_tenant, data)
                .pipe(
                    map(data => actions.createDismissItemSuccess({ _tenant, data })),
                    catchError((error: any) => of(actions.createDismissItemFailure({ _tenant, error })))
                )
            )
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteDismissItem),
            mergeMap(({ _tenant, id }) => this.delete(_tenant, id)
                .pipe(
                    map(() => { return actions.deleteDismissItemSuccess({ _tenant, id }); }),
                    catchError((error: any) => of(actions.deleteDismissItemFailure({ _tenant, error })))
                )
            )
        )
    );

    /**
    * @description
    * get all dismissed items from DynamoDB
    */
    private get(tenant: string): Observable<Dismiss[]> {
        return this.ajax.get(tenant, '/api/octiga/v2/tenant/dismiss');
    }


    /**
    * @description
    * remove a dismissed item from DynamoDB
    * @param {string} id - Hash ID of the dismissed item
    */
    private delete(tenant: string, id: string): Observable<Dismiss> {
        return this.ajax.delete(tenant, `/api/octiga/v2/tenant/dismiss/${id}`).pipe(map(res => res['Attributes']));
    }


    /**
     * @description
     * add a new dismiss item to DynamoDB
     * @param {Dismiss} dismiss - dismiss item
    */
    private post(tenant: string, dismiss: Dismiss) {
        return this.ajax.post(tenant, '/api/octiga/v2/tenant/dismiss', dismiss);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}

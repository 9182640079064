
export function checkIsBlockingEveryone (form: any): boolean{
    const userIncluded = form.includeUsersConfig;

    if(userIncluded === 'All'){
        const excludeUsers = form.excludeUsers?.length > 0 && form.excludeUserSettings?.excludeUsers;
        const excludeGroups = form.excludeGroups?.length > 0 && form.excludeUserSettings?.excludeGroups;
        const excludeRoles = form.excludeRoles?.length > 0 && form.excludeUserSettings?.excludeRoles;
        const excludeGuests = form.excludeGuestsOrExternalUsers.length > 0 && form.excludeUserSettings?.excludeGuestsOrExternalUsers;
        
        if(excludeUsers || excludeGroups || excludeGuests || excludeRoles ){
            return false;
        }else{
            return true;
        }

    }else{
        return false;
    }
}



export function getUserItemsFromSchema (schema: any, item: string){
    return schema?.contains?.properties?.conditions?.properties?.users?.properties?.[item]?.items?.enum || [];
}

export function getIncludeGuestsOrExternalUsersValue(schema: any){
    return (schema?.contains?.properties?.conditions?.properties?.users?.properties?.includeGuestsOrExternalUsers?.properties?.guestOrExternalUserTypes?.pattern?.split(')').join('')?.replace('(?=.*', '').split('(?=.*')) || [];
}


export function getbuiltInControlsFromSchema(schema: any){
    return schema?.contains?.properties?.grantControls?.properties?.builtInControls?.items?.enum?.[0];    
}

export function getExcludeGuestsOrExternalUsersValue(schema: any){
    return (schema?.contains?.properties?.conditions?.properties?.users?.properties?.excludeGuestsOrExternalUsers?.properties?.guestOrExternalUserTypes?.pattern?.split(')').join('')?.replace('(?=.*', '').split('(?=.*')) || [];
}

export function getIncludeUsersConfig (schema: any): 'All' | 'None' | 'GuestsOrExternalUsers'{
    const includeUsers = schema?.contains?.properties?.conditions?.properties?.users?.properties?.includeUsers?.items?.enum || [];
    
    const value = includeUsers[0]?.toLowerCase();

    return value === 'all' ? 'All' : value === 'none' ? 'None' : 'GuestsOrExternalUsers';

}

export function getIncludeUsersItemsFromSchema(schema: any){
    const includeUsers = 
    schema?.contains?.properties?.conditions?.properties?.users?.properties?.includeUsers?.items?.enum || [];
    return includeUsers.filter(res=> res !== 'None' && res !== 'All' );

}


export function getExcludeUsersItemsFromSchema(schema: any){
    return schema?.contains?.properties?.conditions?.properties?.users?.properties?.excludeUsers?.items?.enum || [];
}


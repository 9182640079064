import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';

import * as actions from './actions';
import { OrganizationConfig } from './model';
import { retry } from 'src/app/pipes/retry.pipe';

@Injectable()
export class ExoOrganizationConfigEffects {

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchExoOrganizationConfig),
            distinct(action => action._tenant),
            mergeMap((action) => this.get(action._tenant)
                .pipe(
                    retry(3000, 3, 'anti phishing  timeout'),

                    map((data: OrganizationConfig[]) => actions.fetchExoOrganizationConfigSuccess({ _tenant: action._tenant, data: data[0] })),
                    catchError((error) => of(actions.fetchExoOrganizationConfigFailure({ _tenant: action._tenant, error })))
                )
            )
        )
    );

    private get(tenant: string): Observable<OrganizationConfig[]> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/org-config');
    }

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateExoOrganizationConfig),
            mergeMap((action) => this.put(action._tenant, action.data)
                .pipe(
                    map((_) => actions.updateExoOrganizationConfigSuccess({ _tenant: action._tenant, data: action.data })),
                    catchError((error) => of(actions.updateExoOrganizationConfigFailure({ _tenant: action._tenant, error: {message : error.message, trace: error.trace} })))
                ))
        )
    );

    private put(tenant: string, body: Partial<OrganizationConfig>): Observable<OrganizationConfig[]> {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/org-config', body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }
}


import {TargetGroups} from './';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OperatingSystemVersionRange } from '@microsoft/microsoft-graph-types-beta';
import { Windows10CompliancePolicySchema } from './model';
export const windows10CompliancePolicyDisplayName = 'Octiga Windows 10 Or Later - Compliance Policy';

export function initForm(schema: Windows10CompliancePolicySchema, fb: FormBuilder): FormGroup {
    const osRanges = schema.contains.properties.policy.properties.validOperatingSystemBuildRanges?.items?.oneOf;

    const validOperatingSystemBuildRangesController = osRanges?.length > 0 ? fb.array(
        osRanges?.
            map(res => (addOperatingSystemBuildRangesGroup(fb, { description: res.properties.description.const, lowestVersion: res.properties.lowestVersion.const, highestVersion: res.properties.highestVersion.const }))) || []
    ) : fb.array([]);

    let form = new FormGroup({});

    form = fb.group({
        displayName: [windows10CompliancePolicyDisplayName, Validators.required],
        description: [schema.contains.properties.policy.properties.description?.const],

        bitLockerEnabled: [schema.contains.properties.policy.properties.bitLockerEnabled?.const], // boolean
        secureBootEnabled: [schema.contains.properties.policy.properties.secureBootEnabled?.const], // boolean
        codeIntegrityEnabled: [schema.contains.properties.policy.properties.codeIntegrityEnabled?.const], // boolean


        // device properties
        osMinimumVersion: [schema.contains.properties.policy.properties.osMinimumVersion?.const, [Validators.pattern(/^\d{1,5}(?:\.\d{1,5}){0,3}$/), minmaxValidator(form.get('osMinimumVersion'), 'osMaximumVersion', 'max')]], //string
        osMaximumVersion: [schema.contains.properties.policy.properties.osMaximumVersion?.const, [Validators.pattern(/^\d{1,5}(?:\.\d+){0,3}$/), minmaxValidator(form.get('osMaximumVersion'), 'osMinimumVersion', 'min')]], //string  //TODO check validity
        mobileOsMinimumVersion: [schema.contains.properties.policy.properties.mobileOsMinimumVersion?.const, [Validators.pattern(/^\d{1,6}(?:\.\d{1,6}){0,5}$/), minmaxValidator(form.get('mobileOsMinimumVersion'), 'mobileOsMaximumVersion', 'max')]], //string
        mobileOsMaximumVersion: [schema.contains.properties.policy.properties.mobileOsMaximumVersion?.const, [Validators.pattern(/^\d{1,6}(?:\.\d{1,6}){0,5}$/), minmaxValidator(form.get('mobileOsMaximumVersion'), 'mobileOsMinimumVersion', 'min')]], //string

        validOperatingSystemBuildRanges: validOperatingSystemBuildRangesController,

        // system security
        configurationManagerComplianceRequired: [schema.contains.properties.policy.properties.configurationManagerComplianceRequired?.const], //boolean
        passwordRequired: [schema.contains.properties.policy.properties.passwordRequired?.const], // boolean // check
        passwordBlockSimple: [schema.contains.properties.policy.properties.passwordBlockSimple?.const], // boolean
        passwordRequiredType: [schema.contains.properties.policy.properties.passwordRequiredType?.const],// string
        passwordMinimumCharacterSetCount: [schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount?.const],// TODO
        passwordMinimumLength: [schema.contains.properties.policy.properties.passwordMinimumLength?.const], // number
        passwordMinutesOfInactivityBeforeLock: [schema.contains.properties.policy.properties.passwordMinutesOfInactivityBeforeLock?.const || 0], // number
        passwordExpirationDays: [schema.contains.properties.policy.properties.passwordExpirationDays?.const], // [Validators.min(1), Validators.max(225)]
        passwordPreviousPasswordBlockCount: [schema.contains.properties.policy.properties.passwordPreviousPasswordBlockCount?.const], // number
        passwordRequiredToUnlockFromIdle: [schema.contains.properties.policy.properties.passwordRequiredToUnlockFromIdle?.const], //boolean
        storageRequireEncryption: [schema.contains.properties.policy.properties.storageRequireEncryption?.const], // boolean
        activeFirewallRequired: [schema.contains.properties.policy.properties.activeFirewallRequired?.const], //boolean
        antivirusRequired: [schema.contains.properties.policy.properties.antivirusRequired?.const], //boolean
        antiSpywareRequired: [schema.contains.properties.policy.properties.antiSpywareRequired?.const], //boolean,
        tpmRequired: [schema.contains.properties.policy.properties.tpmRequired?.const],

        // defender
        defenderEnabled: [schema.contains.properties.policy.properties.defenderEnabled?.const], // boolean
        defenderVersion: [schema.contains.properties.policy.properties.defenderVersion?.const, Validators.pattern(/^\d{1,5}(?:\.\d{1,5}){0,3}$/)], //string
        rtpEnabled: [schema.contains.properties.policy.properties.rtpEnabled?.const],
        signatureOutOfDate: [schema.contains.properties.policy.properties.signatureOutOfDate?.const],

        // defender for endpoint
        deviceThreatProtectionRequiredSecurityLevel: [schema.contains.properties.policy.properties.deviceThreatProtectionRequiredSecurityLevel?.const],



        assignments: fb.group({
            allUsers: [schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.some(res => res?.properties?.['@odata.type']?.const === TargetGroups.allUsers)],
            allDevices: [schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.some(res => res?.properties?.['@odata.type']?.const === TargetGroups.allDevices)],
            includedGroups: [getTargetGroupsIds(schema, TargetGroups.groupInclusion)],
            groupInclusion: [schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.some(res => res?.properties?.['@odata.type']?.const === TargetGroups.groupInclusion)]
        }),

        scheduledActionsForRule: fb.group({ // TODO
            DeviceNonCompliance: fb.group({
                gracePeriodHours: []
            })
        })
    });


    return form;
}



function addOperatingSystemBuildRangesGroup(fb: FormBuilder, item?: OperatingSystemVersionRange): FormGroup {
    return fb.group({
        description: [item?.description],
        lowestVersion: [item?.lowestVersion, [Validators.required, Validators.pattern(/^\d+(?:\.\d+){3}$/)]],
        highestVersion: [item?.highestVersion, [Validators.required, Validators.pattern(/^\d+(?:\.\d+){3}$/)]]
    });
}

export function getTargetGroupsIds(schema, type): string[] {
    const items = schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.filter(res => res?.properties?.['@odata.type']?.const === type) || [];
    const result = items?.map(res => res.properties.groupId.const);

    return result;
}




export function minmaxValidator(control: AbstractControl, otherControlName: string, type: 'min' | 'max'): any {
    const validate = (control: AbstractControl) => {

        if (!control?.parent || !control) {
            return null;
        }

        const otherControlValue = control.parent.get(otherControlName).value;
        if (!control.value || !otherControlValue) {
            return null;
        }

        if (type === 'min') {
            if (getComparableNumber(control.value) < getComparableNumber(otherControlValue)) {
                return { 'min': { requiredMin: otherControlValue } };
            }
        } else {
            if (getComparableNumber(control.value) > getComparableNumber(otherControlValue)) {
                return { 'max': { requiredMin: otherControlValue } };
            }
        }

        return null;
    };

    return validate;
}

function getComparableNumber(value: string) {
    return +value.replaceAll('.', '');
}
import { createAction, props } from '@ngrx/store';
import { PerUserMfaModel, PerUserMfaState } from './model';



export const fetchGraphPerUserMfaState = createAction(
    '[Graph/AuthMethods] Fetch Graph Per User Mfa State',
    props<{ _tenant: string, ids: string[] }>()
);

export const fetchGraphPerUserMfaStateSuccess = createAction(
    '[Graph/AuthMethods] Fetch Graph Per User Mfa State Success',
    props<{ _tenant: string, data: PerUserMfaModel[] }>()
);

export const fetchGraphPerUserMfaStateFailure = createAction(
    '[Graph/AuthMethods] Fetch Graph Per User Mfa State Failure',
    props<{ _tenant: string, error: any }>()
);




export const updatePerUserMfaState = createAction(
    '[Graph/AuthMethods] Update Per User Mfa State',
    props<{ _tenant: string, userId: string, perUserMfaState: PerUserMfaState}>()
);

export const updatePerUserMfaStateSuccess = createAction(
    '[Graph/AuthMethods] Update Per User Mfa State Success',
    props<{ _tenant: string, userId: string, perUserMfaState: PerUserMfaState}>()
);

export const updatePerUserMfaStateFailure = createAction(
    '[Graph/AuthMethods] Update Per User Mfa State Failure',
    props<{ _tenant: string, error: any }>()
);

import { Injectable } from '@angular/core';
import { MailboxSettings } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, filter, of, skipUntil } from 'rxjs';
import { catchError, distinct, map, mergeMap, switchMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { client } from '../..';
import * as actions from './actions';

@Injectable()
export class UserMailboxSettingsEffects {

    fetchMailboxSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchMailboxSettings),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.fetchMailBoxSettings(_tenant)
                .pipe(
                    map((data: any[]) => {
                        if(data){ 
                            return actions.fetchMailboxSettingsSuccess({ _tenant, data: data.filter(res=> !!res) }); // we have some null values 
                        }
                    }),

                    catchError((error) => of(actions.fetchMailboxSettingsSuccessFailure({ _tenant, error })))
                ))
        )
    );

    fetchMailBoxSettings(_tenant: string) {
        const ready$ = this.store.select(client(_tenant).powershell.exoMailbox.status).pipe(
            filter(res => res.loaded)
        );
        return this.store.select(client(_tenant).powershell.exoMailbox.all)
            .pipe(
                skipUntil(ready$),
                switchMap((mailboxes) => combineLatest(
                    mailboxes.map(mailbox => this.get(_tenant, mailbox.UserPrincipalName)))
                )
            );
    }

    private get(_tenant: string, identity: string): Observable<any> {
        return this.ajax.get(_tenant, `/api/microsoft/graph/users/${identity}/mailboxSettings`)
            .pipe(
                map(mailboxSettings => ({ id: identity, mailboxSettings })),
                catchError((error) => of(null)) // when you have error merge mail cancel all other requested calls, I convert error to null success, to avoid cancellation of all other active requests
            );
    }





    // fetchUserMailboxSettings$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(actions.fetchUserMailboxSettings),
    //         distinct(action => action._tenant),
    //         mergeMap(({ _tenant, user_id }) => this.fetchUserMailBoxSettings(_tenant, user_id)
    //             .pipe(
    //                 map((data: any[]) => actions.fetchUserMailboxSettingsSuccess({ _tenant, data })),

    //                 catchError((error) => of(actions.fetchUserMailboxSettingsSuccessFailure({ _tenant, error })))
    //             ))
    //     )
    // )


    private fetchUserMailBoxSettings(_tenant: string, user_id: string): Observable<any> {
        return this.ajax.get(_tenant, `/api/microsoft/graph/users/${user_id}/mailboxSettings`);
    }



    updateMailboxSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMailboxSettings),
            mergeMap(({ _tenant, user_id, MailboxSettings }) => this.updateMailboxSetting(_tenant, user_id, MailboxSettings)
                .pipe(
                    map((mailboxSettings: MailboxSettings) => actions.updateMailboxSettingsSuccess({ _tenant, data : {id: user_id, mailboxSettings} })),

                    catchError((error) => of(actions.updateMailboxSettingsFailure({ _tenant, error })))
                ))
        )
    );

    private updateMailboxSetting(_tenant: string, user_id: string, MailboxSettings: MailboxSettings): Observable<MailboxSettings> {
        return this.ajax.patch(_tenant, `/api/microsoft/graph/users/${user_id}/mailboxSettings`, MailboxSettings);

    }


    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>
    ) { }

}

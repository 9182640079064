import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient } from '@angular/common/http';
import { Observable, from, throwError, BehaviorSubject } from 'rxjs';
import { switchMap, catchError, filter, take, finalize } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private token: string | null = null;
    private exp: number | null = null;

    private refreshingToken = false;
    public refreshTokenSubject = new BehaviorSubject<string | null>(null);

    // Array of URL patterns that should bypass authentication
    private exemptUrls: string[] = [
        '/api/microsoft/oauth/session',
        '/api/microsoft/oauth/token',
    ];

    constructor(private httpClient: HttpClient) {
    }

    private isUrlExempt(url: string): boolean {
        return this.exemptUrls.some(exemptUrl => url.includes(exemptUrl));
    }

    private isTokenExpired(): boolean {
        const currentTime = Math.floor(Date.now() / 1000);
        return !this.exp || this.exp < currentTime;
    }

    private handleRefreshToken(): Observable<string> {
        if (!this.refreshingToken) {
            this.refreshingToken = true;
            this.refreshTokenSubject.next(null);

            return from(this.httpClient.get<{ jwt: string, exp: number }>('/api/microsoft/oauth/token')).pipe(
                switchMap(({ jwt, exp }) => {
                    this.refreshingToken = false;
                    this.token = jwt;
                    this.exp = exp;
                    this.refreshTokenSubject.next(jwt);
                    return [jwt];
                }),
                catchError((error) => {
                    this.refreshingToken = false;
                    this.token = null;
                    this.exp = null;
                    this.refreshTokenSubject.next(null);
                    return throwError(() => error);
                }),
                finalize(() => {
                    this.refreshingToken = false;
                })
            );
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1)
        );
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        // Check if URL is exempt from authentication
        if (this.isUrlExempt(request.url)) {
            return next.handle(request);
        }

        // Check if token is expired
        if (!this.token || this.isTokenExpired()) {
            return this.handleRefreshToken().pipe(
                switchMap(newToken => {
                    const clonedRequest = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${newToken}`
                        }
                    });
                    return next.handle(clonedRequest);
                }),
                catchError(error => {
                    // Handle refresh failure (e.g., redirect to login)
                    return throwError(() => error);
                })
            );
        }

        // Proceed with existing valid token
        const clonedRequest = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.token}`
            }
        });
        return next.handle(clonedRequest);
    }
}
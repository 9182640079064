import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';

export { BaselineComponent } from './baseline.component';
export { Remediate } from './remediate';
export const href = 'https://learn.microsoft.com/en-us/azure/active-directory/conditional-access/howto-policy-approved-app-or-app-protection';

const grantControls = {
    'type': 'object',
    'properties': {
        'operator': {
            'type': 'string',
            'const': 'OR'
        },
        'builtInControls': {
            'type': 'array',
            'items': {
                'type': 'string',
                'enum': ['approvedApplication', 'compliantApplication']
            },
            'maxItems': 2
        }
    }
};


const platforms = {
    'type': 'object',
    'properties': {
        'includePlatforms': {
            'type': 'array',
            'items': {
                'enum': ['android', 'iOS'],
                'type': 'string'
            },
            'minItems': 2,
            'maxItems': 2,
            'uniqueItems': true
        }

    }
};


const applications = {
    'type': 'object',
    'properties': {
        'includeApplications': { //   Target resources > Cloud apps > Include,
            'type': 'array',
            'items': {
                'enum': ['all', 'All'],
                'type': 'string'
            },
            'minItems': 1,
            'maxItems': 1,
            'uniqueItems': true
        }

    },
    'required': ['includeApplications']
};


const clientAppTypes = {
    'type': 'array',
    'items': {
        'enum': [
            'all',
            'All'
        ],
        'minItems': 1,
        'maxItems': 1,
        'uniqueItems': true
    }
};


const policy: ConditionalAccessPolicySchema = {
    grantControls,

    conditions: {
        platforms,
        applications,
        clientAppTypes
    }
};

export const CAPAppProtectionBaselineSchema =
    (
        form
    ) => {
        return createCapPolicySchema(form, policy);
    };


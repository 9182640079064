import { AccountRisksComponent } from './account-risks/account-risks.component';
import { AppReportComponent } from './app-report/app-report.component';
import { BaselineCategoriesComponent } from './baseline-categories/baseline-categories.component';
import { ChangesHistoryComponent } from './changes-history/changes-history.component';
import { MailFlowComponent } from './mail-flow/mail-flow.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { PwnedCheckComponent } from './pwned-check/pwned-check.component';
import { RemoteAccessComponent } from './remote-access/remote-access.component';
import { SwayComponent } from './sway/sway.component';

export const dashboardCards = {
    monitoring: MonitoringComponent,
    remote_access: RemoteAccessComponent,
    risks: AccountRisksComponent,
    mail_flow: MailFlowComponent,
    pwned_check: PwnedCheckComponent,
    changes_history: ChangesHistoryComponent,
    sway: SwayComponent,
    baselineCategories: BaselineCategoriesComponent,
    app_report: AppReportComponent,
};

import { createAction, props } from '@ngrx/store';

export const loadReportDeviations = createAction(
    '[Report/Baseline Deviation] Load',
    props<{ _tenant: string }>()
);

export const loadReportDeviationsSuccess = createAction(
    '[Report/Baseline Deviation] Load Success',
    props<{ _tenant: string, data: any[] }>()
);

export const loadReportDeviationsFailure = createAction(
    '[Report/Baseline Deviation] Load Failure',
    props<{ _tenant: string, error: any }>()
);

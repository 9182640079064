import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';


const grantControls = {
    'type': 'object',
    'properties': {
        'builtInControls': {
            'type': 'array',
            'items': {
                'type': 'string',
                'const': 'mfa'
            },
            'maxItems': 1
        }
    }
};


const policy : ConditionalAccessPolicySchema = {
    grantControls,
};
export const mfaBaselineSchema = 

(
    form
) => {
    return createCapPolicySchema(form, policy);
};
<div class="wrapper" 
    (click)="navigate()">

    <div class="session">
        <div class="image">
            <img class="icon" src="./assets/img/dashboard/remote-access.svg" />
    
        </div>
    </div>
</div>





<!-- <div class="wrapper" [class.disabled]="!(notLoading$ | async)"
    (click)="navigate()">
    <div class="spinner">
        <mat-spinner diameter="20" *ngIf="!(notLoading$ | async)"></mat-spinner>
    </div>
    <div class="session">
        <div class="image">
            <img class="icon" src="./assets/img/dashboard/remote-access.svg" />
            <div class="middle">
                <div class="text">{{!(notLoading$| async)? 'Please Wait...': 'Click to Open'}}</div>
            </div>
            <app-severity-badges [severity]="severity$ | async"></app-severity-badges>
        </div>
    </div>
</div> -->
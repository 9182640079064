import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ComponentType } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { merge, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { selectMspData } from 'src/app/modules/msp/store';
import { TrialExpireDialogComponent } from 'src/app/modules/shared/components/trial-expire-modal/trial-expire-modal.component';
import { client } from 'src/app/stores/client';

import * as fromRoot from 'src/app/stores/root.store';
import { SpinnerService } from './../../services/guards/spinner.service';
import { Actions, ofType } from '@ngrx/effects';
import { loadSessionFailure, loadSessionSuccess } from 'src/app/stores/root/octiga/session/actions';
// import { MspStatus } from 'src/app/modules/msp/store/msp/model';

// const ACTIVE_STATUSES = ['REGISTERING', 'TRIAL', 'SUBSCRIPTION'];
// const isActiveStatus = (status: MspStatus): boolean => Boolean(status) && ACTIVE_STATUSES.some(activeStatus => status === activeStatus);
// const isInactiveStatus = (status: MspStatus): boolean => Boolean(status) && !isActiveStatus(status);

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, OnDestroy {
    @ViewChild('sidenav') sidenav: MatSidenav;
    @ViewChild('supportRef') supportRef: ComponentType<any>;

    subscriptions: Subscription[] = [];
    spinnerStatus$ = this.spinnerService.getSpinnerStatus();
    public currentModule = 'dashboard';

    public isDesktop: boolean = true;
    public isSmallScreen: boolean = false;
    public isMsp: boolean = false;
    public isAccountActive: boolean = false;
    public username: string = '';
    public tenant_id: string;
    public clientName: string | null = null;
    public logo_src = 'assets/img/logo-white.svg';

    public msp_dashboard_link: string;
    public dashboard_link: string;
    public recover_link: string;
    public monitoring_link: string;


    public isAuthenticated$ = this.store.pipe(
        select(fromRoot.selectSession),
        filter(session => session.isAuthenticated)
    );

    public isSessionLoaded$ = merge(this.actions.pipe(ofType(loadSessionSuccess)), this.actions.pipe(ofType(loadSessionFailure)))

    private $tenant_id = this.store.pipe(
        select(fromRoot.selectSession),
        filter(state => state.isAuthenticated && !!state.session.clientId),
        distinctUntilChanged((a, b) => a.session.clientId === b.session.clientId),
        map(state => state.session.clientId)
    );

    private $msp = this.store.pipe(
        select(fromRoot.selectSession),
        filter(state => state.isAuthenticated && !!state.session.msp_id),
        switchMap(_ => this.store.select(selectMspData)),
        filter(msp => !!msp)
    );

    public $currentClientName: ReplaySubject<string> = new ReplaySubject();

    private mobile_sizes = ['xs', 'sm'];

    constructor(
        public router: Router,
        private store: Store<fromRoot.State>,
        private mediaObserver: MediaObserver,
        private breakpointObserver: BreakpointObserver,
        private dialog: MatDialog,
        private spinnerService: SpinnerService,
        private actions:Actions
    ) { }

    ngOnInit(): void {
        const sub1 = this.getModule().subscribe(m => {
            this.currentModule = m;
        });

        const sub2 = this.isAuthenticated$.subscribe(state => {
            this.isMsp = !!state.session.msp_id;
            this.username = state.session.upn;
            this.tenant_id = state.session.clientId;
            this.msp_dashboard_link = 'msp/dashboard';
            this.dashboard_link = `client/${this.tenant_id}/dashboard`;
            this.recover_link = `client/${this.tenant_id}/recover`;
            this.monitoring_link = `client/${this.tenant_id}/monitoring/events`;
        });

        const sub3 = this.checkTrialExpired();

        const sub4 = this.checkIsDesktop();

        const sub5 = this.checkSmallScreen();

        const sub6 = this.getCurrentClientName();

        const sub7 = this.checkHasWhiteLabel();

        this.subscriptions = [sub1, sub2, sub3, sub4, sub5, sub6, sub7];

    }


    ngOnDestroy(): void {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    // Use for showing which header button is actively selected only, can simplify it later.
    private getModule(): Observable<string> {
        return this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            filter((e: NavigationEnd) => !e.url.includes('templates')),
            map((e: NavigationEnd) => {
                if (e.url.includes('msp')) {
                    return `msp-${this.router.parseUrl(e.url).root.children.primary.segments[1].path}`;
                }
                else if (e.url === '/auth/microsoft' || e.url.includes('/auth/microsoft')) {
                    return 'auth';
                }
                else if (Object.entries(this.router.parseUrl(e.url).root.children).length > 0) {
                    return this.router.parseUrl(e.url).root.children.primary.segments[2].path; // dashboard, recover, monitoring
                }
                return null;
            })
        );
    }

    private checkIsDesktop(): Subscription {
        return this.mediaObserver.media$.subscribe(m => {
            if (this.mobile_sizes.find(s => s === m.mqAlias)) {
                this.isDesktop = false;
            } else {
                this.isDesktop = true;
            }
        });
    }

    private checkSmallScreen(): Subscription {
        return this.breakpointObserver
            .observe([Breakpoints.XSmall, Breakpoints.Small])
            .subscribe((state: BreakpointState) => {
                if (state.breakpoints[Breakpoints.XSmall] === true || state.breakpoints[Breakpoints.Small] === true) {
                    this.isSmallScreen = true;
                }
                else {
                    this.isSmallScreen = false;
                }
            });
    }

    private checkTrialExpired(): Subscription {
        return this.$msp
            .subscribe(msp => {
                this.isAccountActive = (msp.status === 'REGISTERING' || msp.status === 'TRIAL' || msp.status === 'SUBSCRIPTION');
                if (!this.isAccountActive) {
                    this.dialog.open(TrialExpireDialogComponent, { width: '40rem', disableClose: true });
                }
            });
    }

    private getCurrentClientName(): Subscription {
        return this.$tenant_id
            .pipe(
                switchMap(_tenant => this.store.select(client(_tenant).octiga.tenant.data)),
                filter(t => !!t),
                map(t => t.name)
            )
            .subscribe(name => {
                this.clientName = name;
            });
    }

    private checkHasWhiteLabel(): Subscription {
        return this.$msp
            .subscribe(m => {
                if (!!m && !!m.whiteLabel && !!m.whiteLabel.logo) {
                    this.logo_src = m.whiteLabel.logo;
                }
            });
    }

    public closeSideNav(e): void {
        if (!this.isDesktop && !!this.sidenav) {
            this.sidenav.close();
        }
    }

    public openSupportModal(): void {
        this.dialog.open(this.supportRef);
    }

}

import { switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { client } from 'src/app/stores/client';
import { Store, select } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaselineFormComponent } from '../../abstract/baseline-form.component';
import { selectSwaySpecAll, selectSwaySpecByTag } from 'src/app/stores/root.store';
const TenantBaselineTag = 'OrgSMTP';
@Component({
    selector: 'sway-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaselineFormComponent implements OnInit {
    constructor(private store: Store<any>, private route: ActivatedRoute){
        super();
    }
    
    tenant_id = this.route.snapshot.paramMap.get('tenant');
  
    tenant_baseline$ = 
        this.store.pipe(
            select(selectSwaySpecByTag(TenantBaselineTag)),
            switchMap(spec=> this.store.pipe(select(client(this.tenant_id).sway.baselines.bySpecId(spec.id))))
        ); // to link group smtp to tenant smtp 

    field: string;
    public control = new FormControl();

    ngOnInit(): void { 
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));

        if (this.readonly) {
            this.control.disable();
        }

        const value = schema.properties[this.field]?.const;

        if(value===null){
            this.control.setValue('null'); // form value doesn't accept null so I set it to null string

        }else{
            this.control.setValue(value);

        }


        this.control.valueChanges.subscribe(value => {
            
            if(value === 'null'){
                schema.properties[this.field]['const'] = null ;
                schema.properties[this.field]['type'] = 'null' ;

            }else{
                schema.properties[this.field]['const'] = value;
                schema.properties[this.field]['type'] = 'boolean' ;


            }
            schema.required = [this.field];
            this.baselineChange.next({
                baseline: {
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema))
                }
            });
        });
    }

}

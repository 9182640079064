<sway-cap-baseline *ngIf="baseline && schema$" [readOnly]="readOnly"  [tenant_id]="tenant_id"
    [form]="form" [baseline]="baseline" [baselineTemplateItem]="baselineTemplateItem" [readOnly]="readOnly" [schema$]="schema$" [schemaFunc]="schemaFunc" [policy$]="policy$"
    (baselineErrorChange)="onBaselineErrorChange($event)">

    <ng-container *ngTemplateOutlet="content"></ng-container>
</sway-cap-baseline>



<ng-template #content>
    <ng-container *ngIf="policy$ | async as policy">
        <ng-container *ngIf="policy?.id">
            <tr>
                <td>
                    Grant
                </td>

                <td>

                    <ng-container *ngFor="let item of policy?.grantControls?.builtInControls">
                        {{ item }}
                    </ng-container>
                </td>



                <td>
                    MFA
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.grantControls?.builtInControls[0] === 'mfa' && policy?.grantControls?.builtInControls?.length == 1)">
                    </sway-baseline-status>
                </td>
            </tr>
        </ng-container>
    </ng-container>
</ng-template>
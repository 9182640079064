<button  mat-mini-fab class="button" color="primary" (click)="openDismissDialog()">
    <mat-icon matTooltip="Dismiss Alert"  matTooltipPosition="right">visibility_off</mat-icon>
</button>

<div class="wrapper" (click)="navigate()">
    <!-- <div class="spinner">
        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div> -->
    <div class="session">
        <div class="image">
            <img class="icon" src="./assets/img/dashboard/app.svg" />
            <div class="middle">
                <div class="text">Click to View</div>
            </div>
            <app-severity-badges [severity]="severity$ | async"></app-severity-badges>
        </div>
    </div>
</div>
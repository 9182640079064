<p *ngIf="policyLength > 0 && !data?.isEnabled && control.value">
    It looks like you have a custom Conditional Access policy enabled. Enabling a Conditional Access policy prevents you from enabling security defaults. You can use Conditional Access to configure custom policies that enable the same behavior provided by security defaults.
    <a *ngIf="!readMore" (click)="readMore = true" class="more"> Read More…</a>

</p>


<div *ngIf="readMore">
    The following Conditional Access Policies were found on the tenant. Please note Some of these may be controlled by Octigas baselines however others may have been created outside of Octiga and need to be managed in the Azure Portal.
    <a  (click)="readMore = false" class="more"> Less.</a>

    <ul>
        <li *ngFor="let policy of conditionalAccessPolicies">
            {{policy.displayName}}
        </li>    
    </ul>
</div>

<div class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                Security Defaults Policy
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>

            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>


<ng-template #validatorRef>
    <div class="validator">
        {{ data?.isEnabled ? "Enabled" : "Disabled" }}
    </div>
</ng-template>

<ng-template #formRef>
    <div class="field boolean">
        <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select [formControl]="control">
                <mat-option [value]="true">Enable</mat-option>
                <mat-option [value]="false">Disable</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';

import * as userActions from './actions';
import { User } from './model';

const filterDiscoverySearchMailbox = map((mboxes: Array<User>) => mboxes.filter(m => !m.Name.startsWith('DiscoverySearchMailbox')));
const filterServiceUsers = map((mboxes: Array<User>) => mboxes.filter(m => !m.Name.startsWith('OctigaServiceUser')));

@Injectable()
export class ExoUserEffects {

    fetchUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.fetchExoUsers),
            distinct(action => action._tenant),
            mergeMap((action) => this.getUsers(action._tenant)
                .pipe(
                    filterDiscoverySearchMailbox,
                    filterServiceUsers,
                    map((data: User[]) => userActions.fetchExoUsersSuccess({ _tenant: action._tenant, data })),
                    catchError((error) => of(userActions.fetchExoUsersFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    private getUsers(tenant: string): Observable<User[]> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/user');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }
}

import { createReducer, createSelector, on } from '@ngrx/store';
import { Recipient } from 'src/app/interfaces/powershell/exo/recipient.interface';
import * as ExoActions from './actions';

export const exoFeatureKey = 'recipient';

export interface State {
    data: Recipient[]
    loaded: boolean
    error: boolean
}

export const initialState: State = {
    data: [],
    loaded: false,
    error: false
};


export const reducer = createReducer(
    initialState,
    on(ExoActions.loadRecipientsSuccess, (state, { data }) => ({ ...state, data, loaded: true })),
    on(ExoActions.loadRecipientsFailure, (state, { error }) => ({ ...state, error, loaded: false }))
);

export const selectRecipients = (state: State) => state.data;

export const getRecipients = createSelector(selectRecipients, (data) => data);


import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { TargetGroup } from './model';

export const featureKey = 'TargetGroup';

export interface State extends EntityState<TargetGroup>, Status { }

export const adapter: EntityAdapter<TargetGroup> = createEntityAdapter<TargetGroup>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadTargetGroup, (state) => ({ ...state, loading: true })),
    on(actions.loadTargetGroupSuccess, (state, action) => adapter.setAll(action.groups, { ...state, loading: false, loaded: true })),
    on(actions.loadTargetGroupFailure, (state, { error }) => ({ ...state, error })),

    on(actions.createTargetGroup, (state) => ({ ...state, creating: true })),
    on(actions.createTargetGroupSuccess, (state, action) => adapter.addOne(action.group, { ...state, creating: false })),
    on(actions.createTargetGroupFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.deleteTargetGroup, (state) => ({ ...state, deleting: true })),
    on(actions.deleteTargetGroupSuccess, (state, action) => adapter.removeOne(action.id, { ...state, deleting: false })),
    on(actions.deleteTargetGroupFailure, (state, { error }) => ({ ...state, error, deleting: false }))
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export const selectEntity = (id: string) => createSelector(
    selectEntities,
    entities => entities[id]
);

import { createAction, props } from '@ngrx/store';

export const loadGeoJson = createAction(
    '[Google Map] Load GeoJson',
    props<{ msp_id: string }>()
);

export const loadGeoJsonSuccess = createAction(
    '[Google Map] Load GeoJson Success',
    props<{ data: any }>()
);

export const loadGeoJsonFailure = createAction(
    '[Google Map] Load GeoJson Failure',
    props<{ error: any }>()
);

import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ChangeType } from 'src/app/interfaces/changes.interface';
import { Mailbox } from './model';

export const fetchMailboxes = createAction(
    '[Pwsh/Exo] Fetch Mailboxes',
    props<{ _tenant: string }>()
);

export const loadMailboxesSuccess = createAction(
    '[Pwsh/Exo] Load Mailboxes Success',
    props<{ _tenant: string, mailboxes: Mailbox[] }>()
);

export const loadMailboxsFailure = createAction(
    '[Pwsh/Exo] Load Mailboxes Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateMailbox = createAction(
    '[Pwsh/Exo] Update Mailbox',
    props<{ _tenant: string, mailbox: Update<Mailbox> }>()
);

export const updateMailboxes = createAction(
    '[Pwsh/Exo] Update Mailboxes',
    props<{ _tenant: string, mailbox: Update<Mailbox>[] }>()
);

export const deleteExoMailbox = createAction(
    '[Root/Pwsh/Exo] Delete Exo Mailbox',
    props<{ _tenant: string, id: string }>()
);

export const updateMailboxFields = createAction(
    '[Pwsh/Exo] Update Mailbox Field(s)',
    props<{ _tenant: string, Identity: string, data: { [key: string]: any } }>()
);

export const updateMailboxFieldsSuccess = createAction(
    '[Pwsh/Exo] Update Mailbox Field(s) Success',
    props<{ _tenant: string, Identity: string, data: { [key: string]: any } }>()
);

export const updateMailboxFieldsFailure = createAction(
    '[Pwsh/Exo] Update Mailbox Field(s) Failure',
    props<{ _tenant: string, Identity: string, data: { [key: string]: any }, error: any }>()
);



export const updateMailboxLitigationHold = createAction(
    '[Pwsh/Exo] Update Mailbox updateMailboxLitigationHold',
    props<{ _tenant: string, Identity: string, data: { [key: string]: any } }>()
);

export const updateMailboxLitigationHoldSuccess = createAction(
    '[Pwsh/Exo] Update Mailbox updateMailboxLitigationHold Success',
    props<{ _tenant: string, Identity: string, data: { [key: string]: any } }>()
);

export const updateMailboxLitigationHoldFailure = createAction(
    '[Pwsh/Exo] Update Mailbox updateMailboxLitigationHold Failure',
    props<{ _tenant: string, Identity: string, data: { [key: string]: any }, error: any }>()
);

// microsoft calls
export const deleteOWARule = createAction(
    '[Pwsh/Exo] Delete OWA Forwarding Rule',
    (item: { _tenant: string, identity: string, fields: { [key: string]: string } }) => {
        const changeType: ChangeType = 'owa-forwarding';
        return {
            ...item, changeType
        };
    }
);

export const deleteOWARuleSuccess = createAction(
    '[Pwsh/Exo] Delete OWA Forwarding Rule Success',
    props<{ _tenant: string, identity: string }>()
);

export const deleteOWARuleFailure = createAction(
    '[Pwsh/Exo] Delete OWA Forwarding Rule Failure',
    props<{ _tenant: string, error: any }>()
);

export const disableMailbox = createAction(
    '[Pwsh/Exo] Disable Mailbox',
    props<{ _tenant: string, upn: string }>()
);

export const disableMailboxSuccess = createAction(
    '[Pwsh/Exo] Disable Mailbox Success',
    props<{ _tenant: string, upn: string }>()
);

export const disableMailboxFailure = createAction(
    '[Pwsh/Exo] Disable Mailbox Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateMailboxSharingPolicy = createAction(
    '[Pwsh/Exo] Update Mailbox SharingPolicy',
    props<{ _tenant: string, Identity: string, SharingPolicy: any }>()
);

export const updateMailboxSharingPolicySuccess = createAction(
    '[Pwsh/Exo] Update Mailbox SharingPolicy Success',
    props<{ _tenant: string, Identity: string, SharingPolicy: any }>()
);

export const updateMailboxSharingPolicyFailure = createAction(
    '[Pwsh/Exo] Update Mailbox SharingPolicy',
    props<{ _tenant: string, error: any }>()
);

import { createAction, props } from '@ngrx/store';
import { SwaySpec } from './model';

export const loadSpecs = createAction(
    '[Sway/Spec] Load Specs'
);

export const loadSpecsSuccess = createAction(
    '[Sway/Spec] Load Specs Success',
    props<{ data: SwaySpec[] }>()
);

export const loadSpecsFailure = createAction(
    '[Sway/Spec] Load Specs Failure',
    props<{ error: any }>()
);


import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { CAPAppProtectionBaselineSchema } from './';
import { BehaviorSubject, filter, skip } from 'rxjs';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { InitCapBaselineFormItem } from '../shared/cap-form';
import { FormBuilder } from '@angular/forms';



@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss']
})

export class BaselineComponent extends BaseComponent implements OnInit {
    schemaFunc = CAPAppProtectionBaselineSchema;
    schema$ : BehaviorSubject<any>; 
    policy$: BehaviorSubject<ConditionalAccessPolicy> = new BehaviorSubject(null);

    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.form = this.initForm(this.baseline.schema);
        this.schema$ = new BehaviorSubject(this.baseline.schema);
        this.schema$.pipe(filter(res=> !!res), skip(1)).subscribe(schema=>{// skip first emit
            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema))
            });
        });
    }
    
    onBaselineErrorChange(e){
        this.baselineErrorChange.next(e);
    }

    initForm(schema) {
        return InitCapBaselineFormItem(schema, this.fb);
    }

    mapper(str){
        const mapper_obj = {
            'approvedApplication' : 'Require approved client app',
            'compliantApplication' : 'Require app protection policy'
        };

        return mapper_obj[str] || str;
    }
}

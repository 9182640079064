import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';

import * as actions from './actions';
import { TransportConfig } from './model';
import { retry } from 'src/app/pipes/retry.pipe';

@Injectable()
export class ExoTransportConfigEffects {

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchExoTransportConfig),
            distinct(action => action._tenant),
            mergeMap((action) => this.get(action._tenant)
                .pipe(
                    retry(3000, 3, 'Exo transport Config timeout'),
                    map((data: TransportConfig[]) => actions.fetchExoTransportConfigSuccess({ _tenant: action._tenant, data: data[0] })),
                    catchError((error) => of(actions.fetchExoTransportConfigFailure({ _tenant: action._tenant, error: {message : error.message, trace: error.trace} })))
                ))
        )
    );

    private get(tenant: string): Observable<TransportConfig[]> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/transport-config');
    }

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateExoTransportConfig),
            mergeMap((action) => this.put(action._tenant, action.data)
                .pipe(
                    map((_) => actions.updateExoTransportConfigSuccess({ _tenant: action._tenant, data: action.data })),
                    catchError((error) => of(actions.updateExoTransportConfigFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    private put(tenant: string, body: Partial<TransportConfig>): Observable<void> {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/transport-config', body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }
}

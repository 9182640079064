<div class="severity-count">
    <div class="critical">
        <!-- <mat-icon svgIcon="critical"></mat-icon> -->
        <mat-icon class="critical">local_fire_department</mat-icon>
        <div class="severity">Critical</div>
        <span>{{severity.critical}}</span>
    </div>
    <div class="danger">
        <mat-icon class="danger">warning</mat-icon>
        <div class="severity">Danger</div>
        <span>{{severity.danger}}</span>
    </div>
    <div class="warning">
        <mat-icon class="warning">warning_outlined</mat-icon>
        <div class="severity">Warning</div>
        <span>{{severity.warning}}</span>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, firstValueFrom, from, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { MailboxDelegate } from 'src/app/interfaces/powershell/exo/mailbox-delegate.interface';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as ExoActions from './actions';
import { UpdateDelegateProps } from './model';

@Injectable()
export class MailboxDelegateEffects {

    getMailboxDelegates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.getMailboxDelegates),
            mergeMap((action) => this.getMailboxDelegates(action._tenant, action.Identity)
                .pipe(
                    retry(3000, 3, 'mobile delegated timeout'),
                    map((data: MailboxDelegate) => {
                        // TODO: Don't overwrite the original entities, should push to the current state
                        return ExoActions.getMailboxDelegatesSuccess({ _tenant: action._tenant, exoMailboxDelegates: { ...data, Identity: action.Identity } });
                    }),
                    catchError((error) => of(ExoActions.getMailboxDelegatesFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    getMailboxDelegates(_tenant: string, Identity: string) {
        const getMailboxPermissions$ = this.ajax.get(_tenant, `/api/microsoft/powershell/exo/mailbox-permission/${Identity}`).pipe(
            map(res => res.filter(re => re.User !== 'NT AUTHORITY\\SELF'))
        );
        const getRecipientPermissions$ = this.ajax.get(_tenant, `/api/microsoft/powershell/exo/recipient-permission/${Identity}`).pipe(
            map(res => res.filter(re => re.Trustee !== 'NT AUTHORITY\\SELF'))
        );
        return combineLatest([getMailboxPermissions$, getRecipientPermissions$]).pipe(
            map(([MailboxPermissions, RecipientPermissions]) => ({
                MailboxPermissions,
                RecipientPermissions
            })));
    }

    updateMailboxDelegates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.updateMailboxDelegates),
            mergeMap((action) => from(this.updateMailboxDelegates(action))
                .pipe(
                    concatMap(() => {
                        return [
                            ExoActions.getMailboxDelegates({ _tenant: action._tenant, Identity: action.Identity }),
                            ExoActions.updateMailboxDelegatesSuccess(action)
                        ];
                    }),
                    catchError((error) => of(ExoActions.updateMailboxDelegatesFailure({ _tenant: action._tenant, error: { message: error.message, trace: error.trace } })))
                ))
        )
    );

    async updateMailboxDelegates(props: UpdateDelegateProps): Promise<void> {

        const { Identity, delegates } = props;

        for (const id of delegates.AddRecipientPermissionIdentities) {
            await firstValueFrom(this.ajax.post(props._tenant, `/api/microsoft/powershell/exo/recipient-permission/${Identity}/${id}`));
        }

        for (const id of delegates.RemoveRecipientPermissionIdentities) {
            await firstValueFrom(this.ajax.delete(props._tenant, `/api/microsoft/powershell/exo/recipient-permission/${Identity}/${id}`));
        }

        for (const id of delegates.AddMailboxPermissionIdentities) {
            await firstValueFrom(this.ajax.post(props._tenant, `/api/microsoft/powershell/exo/mailbox-permission/${Identity}/${id}`));
        }

        for (const id of delegates.RemoveMailboxPermissionIdentities) {
            await firstValueFrom(this.ajax.delete(props._tenant, `/api/microsoft/powershell/exo/mailbox-permission/${Identity}/${id}`));
        }
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }
}
import { MalwareFilterPolicy } from 'src/app/interfaces/powershell/exo/malware-filter-policy.interface';
import { disableExoEOPProtectionPolicy, enableExoEOPProtectionPolicy } from 'src/app/stores/client/powershell/exo/eop-protection-policy-rule/actions';
export const IdentityName = 'Standard Preset Security Policy';

export function Remediate(_tenant: string, data: MalwareFilterPolicy[], schema: any) {

    const state = schema.contains.properties.State['const'];

    if (state ==='Enabled') {
        return enableExoEOPProtectionPolicy( // not finished
            {
                _tenant,
                data: {
                    Identity: IdentityName
                }
            }
        );
    } else {
        return disableExoEOPProtectionPolicy( // not finished
            {
                _tenant,
                data: {
                    Identity: IdentityName
                }
            }
        );
    }
}

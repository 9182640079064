<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{title}}
            </mat-panel-title>

            <mat-panel-description>

            
                <ng-container *ngFor="let error of errors; index as i">
                    <ng-container *ngIf="i < 1">

                        <mat-error *ngIf="error.type == 'excludeRoles'">
                            Exclude roles doesn't match
                        </mat-error>

                        <mat-error *ngIf="error.type == 'excludeUsers'">
                            Exclude users doesn't match
                        </mat-error>


                        <mat-error *ngIf="error.type == 'excludeGroups'">
                            Exclude groups doesn't match
                        </mat-error>


                        <mat-error *ngIf="error.type == 'excludeGuestsOrExternalUsers'">
                            Exclude guests or external users doesn't match
                        </mat-error>

                    </ng-container>
                </ng-container>

                <ng-container *ngIf="errors?.length === 0">
                    <mat-icon class="success">check_circle_outline</mat-icon>

                </ng-container>

            </mat-panel-description>
        </mat-expansion-panel-header>


        <div class="wrapper">
            <ng-container *ngTemplateOutlet="contentRef">

            </ng-container>
        </div>
    </mat-expansion-panel>

</mat-accordion>


<ng-template #contentRef>

    <table>
        <thead>
            <th class="item-header">
                Current Value
            </th>

            <th class="item-header">
                Desired Value
            </th>
        </thead>

        <tbody>
            <tr>

                <ng-container *ngTemplateOutlet="policyValueRef">

                </ng-container>

                <ng-container *ngTemplateOutlet="baselineValueRef">

                </ng-container>
            </tr>
        </tbody>
    </table>


</ng-template>


<ng-template #policyValueRef>
    <td class="item-data">
        <ul>
            <li>
                <mat-checkbox [disabled]="true"
                    [checked]="policy?.conditions?.users?.['excludeGuestsOrExternalUsers']?.['guestOrExternalUserTypes']?.split(',')?.length >  0 ">
                    Guest or external users
                </mat-checkbox>
    
                <ul *ngIf="policy?.conditions?.users?.['excludeGuestsOrExternalUsers']?.['guestOrExternalUserTypes'].split(',') as items"
                    class="number">
                    <li *ngFor="let item of items">
                        {{item}}
                    </li>
                </ul>
            </li>
    
            <li>
                <mat-checkbox [disabled]="true" [checked]="policy?.conditions?.users?.excludeRoles?.length > 0">
                    Directory roles
                    <span class="number">({{policy?.conditions?.users?.['excludeRoles']?.length ||
                        0}})</span>
                </mat-checkbox>
            </li>
    
            <li>
                <mat-checkbox [disabled]="true" [checked]="policy?.conditions?.users?.excludeUsers?.length > 0">
                    Users
    
                    <span class="number">({{policy?.conditions?.users?.excludeUsers?.length || 0}})</span>
    
                </mat-checkbox>
            </li>
    
            <li>
                <mat-checkbox [disabled]="true" [checked]="policy?.conditions?.users?.excludeGroups?.length > 0">
                    Groups
    
                    <span class="number">({{policy?.conditions?.users?.excludeGroups?.length || 0}})</span>
                </mat-checkbox>
            </li>
        </ul>
    </td>
</ng-template>



<ng-template #baselineValueRef>
    <td class="item-data">



        <form [formGroup]="excludeUserSettingsController" class="radio-wrapper">

            <ul>
                <li>
                    <mat-checkbox [disabled]="!override?.includes('excludeGuestsOrExternalUsers')  && readOnly" formControlName="excludeGuestsOrExternalUsers">
                        Guest or external users
                    </mat-checkbox>

                    <ul *ngIf="excludeUserSettings.excludeGuestsOrExternalUsers">
                        <li>
                            <ng-select [searchable]="false" [multiple]="true" [appendTo]="'body'"
                                [formControl]="excludeGuestsOrExternalUsersController"
                                placeholder="Guest or external users">
                                <ng-option value="b2bCollaborationGuest">B2B collaboration guest
                                    users</ng-option>
                                <ng-option value="b2bCollaborationMember">B2B collaboration member
                                    users</ng-option>
                                <ng-option value="b2bDirectConnectUser">B2B direct connect users</ng-option>
                                <ng-option value="internalGuest">Local guest users</ng-option>
                                <ng-option value="serviceProvider">Service provider users</ng-option>
                                <ng-option value="otherExternalUser">Other external users</ng-option>
                            </ng-select>
                        </li>
                    </ul>
                </li>

                <li>
                    <mat-checkbox [disabled]="!override?.includes('excludeRoles')  && readOnly"  formControlName="excludeRoles">
                        Directory roles
                        <span class="number">({{form.get('excludeRoles')?.value.length}})</span>
                    </mat-checkbox>

                    <ul *ngIf=" excludeUserSettings.excludeRoles && (override?.includes('excludeRoles') || !readOnly)">
                        <li>
                            <div class="btn-wrapper">
                                <button (click)="manageItems('excludeRoles')" mat-button color="primary">Manage</button>
                            </div>
                        </li>
                    </ul>

                    <ul
                    *ngIf="readOnly && !override?.includes('excludeRoles') && form.get('excludeRoles')?.value?.length > 0">
                    <li>
                        <div class="btn-wrapper">
                            <button (click)="viewExcludedRoles()" mat-button color="primary">View Roles</button>
                        </div>
                    </li>
                </ul>
                
                </li>

                <li>
                    <mat-checkbox [disabled]="!override?.includes('excludeUsers') && readOnly"  formControlName="excludeUsers">
                        Users

                        <span class="number">({{form.get('excludeUsers')?.value.length}})</span>

                    </mat-checkbox>

                    <ul *ngIf="excludeUserSettings.excludeUsers">
                        <li>

                            <div class="btn-wrapper">
                                <button (click)="manageItems('excludeUsers')" mat-button color="primary">Manage</button>
                            </div>
                        </li>
                    </ul>
                </li>

                <li>
                    <mat-checkbox [disabled]="!override?.includes('excludeGroups')  && readOnly"  formControlName="excludeGroups">
                        Groups

                        <span class="number">({{form.get('excludeGroups')?.value.length}})</span>

                    </mat-checkbox>

                    <ul *ngIf="excludeUserSettings.excludeGroups">
                        <li>

                            <div class="btn-wrapper">
                                <button (click)="manageItems('excludeGroups')" mat-button
                                    color="primary">Manage</button>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </form>
    </td>
</ng-template>



<ng-template #excludedRolesRef>

    <div class="mat-typography">
        <div>
            <h3 style="font-weight: 600;">
                The Following roles are excluded from the baseline
            </h3>

        

            <li *ngFor="let role of excludedRoles">
                {{role.displayName}}
            </li>

            <div>
                <button mat-raised-button color="primary" mat-dialog-close>Close</button>
            </div>
        </div>
    </div>
</ng-template>
import { createAction, props } from '@ngrx/store';
import { TemplateItem } from './model';


export const loadTemplateItems = createAction(
    '[Templates/API] Load Templates Items',
    props<{ msp_id: string }>()
);

export const loadTemplateItemsSuccess = createAction(
    '[Templates/API] Load Templates Items Success',
    props<{ items: TemplateItem[] }>()
);

export const loadTemplateItemsFailure = createAction(
    '[Templates/API] Load Templates Items Failure',
    props<{ error: any }>()
);



export const createTemplateItem = createAction(
    '[Templates/API] Create Template Item',
    props<{ msp_id: string, template_id: string, item: TemplateItem, _type: 'tenant' | 'group', required_plans?: any }>()
);

export const createTemplateItemSuccess = createAction(
    '[Templates/API] Create Template Item Success',
    props<{ item: TemplateItem }>()
);

export const createTemplateItemFailure = createAction(
    '[Templates/API] Create Template Item Failure',
    props<{ error: any }>()
);



export const updateTemplateItem = createAction(
    '[Templates/API] update Template Item',
    props<{
        msp_id: string,
        template_item: Partial<TemplateItem>,
        _type: 'tenant' | 'group'
        update_auto_remediation?:boolean,
    }>()
);

export const updateTemplateItemSuccess = createAction(
    '[Templates/API] update Template Item Success',
    props<{ item: TemplateItem }>()
);

export const updateTemplateItemFailure = createAction(
    '[Templates/API] update Template Item Failure',
    props<{ error: any }>()
);



export const deleteTemplateItem = createAction(
    '[Templates/API] Delete Template Item',
    props<{ msp_id: string, template_id: string, id: string, deleteBaselines: boolean }>()
);

export const deleteTemplateItemSuccess = createAction(
    '[Templates/API] Delete Template Item Success',
    props<{ id: string }>()
);

export const deleteTemplateItemFailure = createAction(
    '[Templates/API] Delete Template Item Failure',
    props<{ error: any }>()
);

import { createAction, props } from '@ngrx/store';
import { ReportSchedule } from './model';

export const loadSchedules = createAction(
    '[Report Schedule] Load',
    props<{ _tenant: string }>()
);

export const loadSchedulesSuccess = createAction(
    '[Report Schedule] Load Success',
    props<{ _tenant: string, data: ReportSchedule[] }>()
);

export const loadSchedulesFailure = createAction(
    '[Report Schedule] Load Failure',
    props<{ _tenant: string, error: any }>()
);

export const createSchedule = createAction(
    '[Report Schedule] Create',
    props<{ _tenant: string, data: Partial<ReportSchedule> }>()
);

export const createScheduleSuccess = createAction(
    '[Report Schedule] Create Success',
    props<{ _tenant: string, data: ReportSchedule }>()
);

export const createScheduleFailure = createAction(
    '[Report Schedule] Create Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateSchedule = createAction(
    '[Report Schedule] Update',
    props<{ _tenant: string, data: Partial<ReportSchedule> }>()
);

export const updateScheduleSuccess = createAction(
    '[Report Schedule] Update Success',
    props<{ _tenant: string, data: ReportSchedule }>()
);

export const updateScheduleFailure = createAction(
    '[Report Schedule] Update Failure',
    props<{ _tenant: string, error: any }>()
);

export const deleteSchedule = createAction(
    '[Report Schedule] Delete',
    props<{ _tenant: string, ScheduleId: string }>()
);

export const deleteScheduleSuccess = createAction(
    '[Report Schedule] Delete Success',
    props<{ _tenant: string, ScheduleId: string }>()
);

export const deleteScheduleFailure = createAction(
    '[Report Schedule] Delete Failure',
    props<{ _tenant: string, error: any }>()
);

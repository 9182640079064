import { Injectable } from '@angular/core';
import { SubscribedSku } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';

@Injectable()
export class GraphSubscribedSkusEffects {
    loadGraphSubscribedSkus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadSubscribedSkus),
            // distinct(action => action._tenant), // could reload it for the same tenant after assign any license
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map((data) => actions.loadSubscribedSkusSuccess({ _tenant, skus: data.value })),
                    catchError((error) => of(actions.loadSubscribedSkusFailure({ _tenant, error }))),
                )
            )
        )
    );

    private get(tenant: string) {
        return this.ajax.get<{ value: SubscribedSku[] }>(tenant, '/api/microsoft/graph/subscribedSkus');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}

import { Injectable } from '@angular/core';
import { User } from '@microsoft/microsoft-graph-types-beta';
import { select, Store } from '@ngrx/store';
import { combineLatest, debounceTime, filter, iif, map, Observable, of, ReplaySubject, skipUntil, switchMap } from 'rxjs';
import { client } from 'src/app/stores/client';
import { selectSwaySpecAll, selectSwaySpecStatus } from 'src/app/stores/root.store';
import { UserRiskExclusion } from './../components/risks/model';
import {  Group } from '@microsoft/microsoft-graph-types-beta';

type TenantId = string;
type UserId = string;


@Injectable({
    providedIn: 'root'
})
export class UserRisksService {
    private tenants: Map<TenantId, ReplaySubject<Map<UserId, UserRiskExclusion[]>>> = new Map();

    constructor(private store: Store) { }

    public getRiskMap(tenant_id: TenantId): Observable<Map<string, UserRiskExclusion[]>> {
        return this.getSubject(tenant_id);
    }

    public getAllRisks(tenant_id: TenantId): Observable<UserRiskExclusion[]> {
        return this.getSubject(tenant_id).pipe(
            map(risks => [...risks.values()].flat(1))
        );
    }

    public getUserRisks(tenant_id: TenantId, user: User): Observable<UserRiskExclusion[]> {
        return this.getSubject(tenant_id).pipe(
            map(risks => risks.get(user.id))
        );
    }

    private getSubject(tenant_id: TenantId) {
        if (this.tenants.has(tenant_id)) {
            return this.tenants.get(tenant_id);
        } else {
            const subject = new ReplaySubject<Map<UserId, UserRiskExclusion[]>>(1);
            this.tenants.set(tenant_id, subject);
            this.subscribeToTenant(tenant_id);
            return subject;
        }
    }


    private subscribeToTenant(tenant_id: TenantId) {

        const worker = new Worker(new URL('./risks.worker', import.meta.url));

        worker.onmessage = ({ data }) => {
            this.tenants.get(tenant_id).next(data);
        };

        // users
        const usersReady$ = this.store.select(client(tenant_id).graph.users.status).pipe(
            map(res => !!res.loaded)
        );
        const users$ = this.store.select(client(tenant_id).graph.users.all).pipe(
            skipUntil(usersReady$)
        );

        // mailboxes
        const mailboxesReady$ = this.store.select(client(tenant_id).powershell.exoMailbox.status).pipe(
            map(res => !!res.loaded)
        );
        const mailboxes$ = this.store.select(client(tenant_id).powershell.exoMailbox.all).pipe(
            skipUntil(mailboxesReady$)
        );


        // role members
        const directoryRoleMembersReady$ = this.store.select(client(tenant_id).graph.directoryRole.members.status).pipe(
            map(res => !!res.loaded)
        );
        const directoryRoleMembers$ = this.store.select(client(tenant_id).graph.directoryRole.members.all).pipe(
            skipUntil(directoryRoleMembersReady$)
        );

        // auth methods
        const authMethodsReady$ = this.store.select(client(tenant_id).graph.authenticationMethods.status).pipe(
            map(res => !!res.loaded)
        );
        const authMethods$ = this.store.select(client(tenant_id).graph.authenticationMethods.all).pipe(
            skipUntil(authMethodsReady$)
        );



        // per User Mfa
        const perUserMfaStatus$ = this.store.select(client(tenant_id).graph.perUserMfaState.status).pipe(
            map(res => !!res.loaded)
        );
        const perUserMfa$ = this.store.select(client(tenant_id).graph.perUserMfaState.all).pipe(
            skipUntil(perUserMfaStatus$)
        );

        const specsReady$ = this.store.select(selectSwaySpecStatus).pipe(
            map(res => !!res.loaded)
        );

        const specs$ = this.store.select(selectSwaySpecAll).pipe(
            skipUntil(specsReady$),
        );

        const baselinesReady$ = this.store.select(client(tenant_id).sway.baselines.status).pipe(
            map(res => !!res.loaded)
        );


        const baselines$ = this.store.select(client(tenant_id).sway.baselines.all).pipe(
            skipUntil(baselinesReady$),
        );



        const swayGroupReady$ = this.store.select(client(tenant_id).sway.groups.status).pipe(
            map(res => !!res.loaded)
        );

        const swayGroups$ = this.store.pipe(select(client(tenant_id).sway.groups.all), skipUntil(swayGroupReady$));
       
        const filteredGraphGroups$ = swayGroups$.pipe(
            switchMap(groups => iif(() => groups.length > 0, combineLatest(groups.map(group => this.group_members(tenant_id, group.id))), of([])))
        );


        const graphGroupsMembersReady$ = this.store.select(client(tenant_id).graph.groups.members.status).pipe(
            map(res => !!res.loaded)
        );
        const graphGroups$ = this.store.select(client(tenant_id).graph.groups.all).pipe(
            skipUntil(graphGroupsMembersReady$)
        );


        const CAPoliciesReady$ = this.store.select(client(tenant_id).graph.conditionalAccessPolicy.status).pipe(
            map(res => !!res.loaded)
        );

        const CAPolicies$ = this.store.select(client(tenant_id).graph.conditionalAccessPolicy.all).pipe(

            map(policies => policies.filter(policy => policy.grantControls?.builtInControls?.includes('mfa') && policy.state === 'enabled')),

            skipUntil(CAPoliciesReady$)
        );



        const securityDefaultsReady$ = this.store.select(client(tenant_id).graph.securityDefaults.status).pipe(
            filter(res => !!res.loaded),

        );

        const securityDefaults$ = this.store.select(client(tenant_id).graph.securityDefaults.item).pipe(
            skipUntil(securityDefaultsReady$),
        );

        
        const deviationsReady$ = this.store.select(client(tenant_id).sway.deviations.status).pipe(
            filter(res => !!res.loaded),

        );

        const deviations$ = this.store.select(client(tenant_id).sway.deviations.allActive).pipe(
            skipUntil(deviationsReady$),
        );


        combineLatest([
            users$,
            mailboxes$,
            directoryRoleMembers$,
            authMethods$,
            perUserMfa$,
            filteredGraphGroups$,
            baselines$,
            specs$,
            graphGroups$,
            CAPolicies$,
            securityDefaults$,
            deviations$,
        ]).pipe(debounceTime(200))
            .subscribe(([users, mailboxes, members, authMethods, perUserMfa, filteredGraphGroups, baselines, specs, graphGroups, CAPolicies, securityDefaults, deviations]) => {
                worker.postMessage([
                    users,
                    mailboxes,
                    members,
                    authMethods,
                    perUserMfa,
                    filteredGraphGroups,
                    baselines,
                    specs,
                    graphGroups,
                    tenant_id,
                    CAPolicies,
                    securityDefaults,
                    deviations
                ]);
            });
    }

    private group_members(tenant_id, gid: string) : Observable<Group> {
        if (tenant_id === gid) { // default group
            return this.store.pipe(select(client(tenant_id).graph.users.all)).pipe(filter(res => res.length > 0), map(res => ({ id: gid, members: res, displayName: 'Default' })));

        } else {
            return this.store.pipe(
                select(client(tenant_id).graph.groups.group(gid)),
                filter(group => !!group),
                // map(group => group.members)
            );
        }
    }
}

import { createAction, props } from '@ngrx/store';
import { RemoteDomain } from './model';

export const fetchExoRemoteDomain = createAction(
    '[Client/Pwsh/Exo] Fetch RemoteDomain',
    props<{ _tenant: string }>()
);

export const fetchExoRemoteDomainSuccess = createAction(
    '[Client/Pwsh/Exo] Fetch RemoteDomain Success',
    props<{ _tenant: string, data: RemoteDomain }>()
);

export const fetchExoRemoteDomainFailure = createAction(
    '[Client/Pwsh/Exo] Fetch RemoteDomain Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateExoRemoteDomain = createAction(
    '[Client/Pwsh/Exo] Update RemoteDomain',
    props<{ _tenant: string, data: Partial<RemoteDomain> }>()
);

export const updateExoRemoteDomainSuccess = createAction(
    '[Client/Pwsh/Exo] Update RemoteDomain Success',
    props<{ _tenant: string, data: Partial<RemoteDomain> }>()
);

export const updateExoRemoteDomainFailure = createAction(
    '[Client/Pwsh/Exo] Update RemoteDomain Failure',
    props<{ _tenant: string, error: any }>()
);

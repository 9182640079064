import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';

export const featureKey = 'roles';

export interface State extends Status {
    data: string[]
}

export const initialState: State = {
    data: [],
    ...initialStatus
};

export const reducer = createReducer(
    initialState,
    on(actions.reloadGraphRoles, (state) => ({ ...state, loaded: false })),
    on(actions.loadGraphRolesSuccess, (state, { data }) => ({ ...state, data, loaded: true, error: false })),
    on(actions.loadGraphRolesFailure, (state, { error }) => ({ ...state, error, loaded: true }))
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';
import { CsOAuthConfiguration } from './model';

@Injectable()
export class CsOAuthConfigurationEffects {

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchCsOAuthConfiguration),
            mergeMap((action) => this.get(action._tenant)
                .pipe(
                    tap(data => console.log(data)),
                    map((data: CsOAuthConfiguration[]) => actions.fetchCsOAuthConfigurationSuccess({ _tenant: action._tenant, data: data[0] })),
                    catchError((error) => of(actions.fetchCsOAuthConfigurationFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    private get(tenant: string): Observable<CsOAuthConfiguration[]> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/teams/cs-oauthconfiguration');
    }

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateCsOAuthConfiguration),
            mergeMap((action) => this.put(action._tenant, action.data)
                .pipe(
                    map((_) => actions.updateCsOAuthConfigurationSuccess({ _tenant: action._tenant, data: action.data })),
                    catchError((error) => of(actions.updateCsOAuthConfigurationFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    private put(tenant: string, body: Partial<CsOAuthConfiguration>): Observable<void> {
        return this.ajax.put(tenant, '/api/microsoft/powershell/teams/cs-oauthconfiguration', body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }
}

<sway-cap-baseline 
    *ngIf="baseline && schema$"
    [readOnly]="readOnly"
    [tenant_id]="tenant_id"
    [form]="form"
    [baseline]="baseline"
    [schema$]="schema$"
    [schemaFunc]="schemaFunc"
    [policy$]="policy$"
    [baselineTemplateItem]="baselineTemplateItem"
    (baselineErrorChange)="onBaselineErrorChange($event)"
    >


    <ng-container *ngTemplateOutlet="content"></ng-container>
     
</sway-cap-baseline>


<ng-template #content>
    <ng-container *ngIf="policy$ | async as policy">
        <ng-container *ngIf="policy?.id">
            <tr >
                <td>
                    Grant
                </td>
        
                <td>
                    
                    <ng-container *ngFor ="let item of policy?.grantControls?.builtInControls">
                        {{ mapper(item)  }}
                        <br>
                    </ng-container>
                </td>
        
        
        
                <td>
                    Require approved client app
                    <br>
                    
                    Require app protection policy
                </td>
        
                <td>
                    <sway-baseline-status [hasError]="!(policy?.grantControls?.builtInControls.includes('approvedApplication') && policy?.grantControls?.builtInControls.includes('compliantApplication') && policy?.grantControls?.builtInControls?.length == 2)" > </sway-baseline-status>
                </td>
            </tr>


            <tr >
                <td>
                    For multiple controls
                </td>
        
                <td>
                    
                    <ng-container *ngIf ="policy?.grantControls?.operator !== 'OR'">
                        Require all the selected controls
                    </ng-container>

                    
                    <ng-container *ngIf ="policy?.grantControls?.operator == 'OR'">
                        Require one of the selected controls
                    </ng-container>
                </td>
        
        
        
                <td>
                    Require one of the selected controls                 
                </td>
        
                <td>
                    <sway-baseline-status [hasError]="policy?.grantControls?.operator !== 'OR'" > </sway-baseline-status>
                </td>
            </tr>
        
        
            <tr >
                <td>
                    Include platforms
                </td>
        
                <td>
                    <div *ngFor ="let item of policy?.conditions?.platforms?.includePlatforms">
                        {{ item }}
                    </div>
                </td>
        
        
        
                <td>
                   android, iOS
                </td>
        
                <td>
                    <sway-baseline-status [hasError]="!(policy?.conditions?.platforms?.includePlatforms?.includes('iOS') && policy?.conditions?.platforms?.includePlatforms?.includes('android') &&  policy?.conditions?.platforms?.includePlatforms?.length == 2)" > </sway-baseline-status>
                </td>
            </tr>


            <tr >
                <td>
                    Include Applications
                </td>
        
                <td >
                    <div *ngFor=" let item of policy.conditions?.applications?.includeApplications">
                        {{item}}
                    </div>
                </td>
        
        
        
                <td>
                    All
                </td>
        
                <td>
                    <sway-baseline-status [hasError]="!(policy?.conditions?.applications?.includeApplications?.[0] === 'All' && policy?.conditions?.applications?.includeApplications?.length === 1)" > </sway-baseline-status>
                </td>
            </tr>

            
            <tr>
                <td>
                    Client App Types
                </td>

                <td>
                    <div *ngFor="let item of policy?.conditions?.clientAppTypes">
                        {{item}}
                    </div>
                </td>



                <td>
                    All.
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.conditions?.clientAppTypes?.includes('all') &&  policy?.conditions?.clientAppTypes?.length == 1)">
                    </sway-baseline-status>

                </td>
            </tr>
        </ng-container>
    </ng-container>
</ng-template>
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SpoTenant } from 'src/app/stores/client/powershell/spo/tenant/model';
import { BaseComponent } from '../../base/base.component';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss']
})

export class BaselineComponent extends BaseComponent implements OnInit {
    @Input() data: SpoTenant;

    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));

        const value = schema.properties?.PreventExternalUsersFromResharing?.const;

        if (value !== undefined) {
            this.control.setValue(value);
        } else {
            this.control.setValue('any');
        }

        this.control.valueChanges.subscribe(value => {
            if (value === 'any') {
                delete schema.properties?.PreventExternalUsersFromResharing?.const;
                schema.required = [];
            } else {
                schema.properties.PreventExternalUsersFromResharing['const'] = value;
                schema.required = ['PreventExternalUsersFromResharing'];
            }

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema))
            });
        });
    }
}

import { createAction, props } from '@ngrx/store';
import { Subscription } from './model';

export const loadMgmtSubscriptions = createAction(
    '[Tenant Status] Load Mgmt Subscriptions',
    props<{ _tenant: string }>()
);

export const loadMgmtSubscriptionsSuccess = createAction(
    '[Tenant Status] Load Mgmt Subscriptions Success',
    props<{ _tenant: string, data: Subscription[] | string }>()
);

export const loadMgmtSubscriptionsFailure = createAction(
    '[Tenant Status] Load Mgmt Subscriptions Failure',
    props<{ _tenant: string, error: any }>()
);

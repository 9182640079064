<div *ngIf="ignore; else elseBlock">
    <mat-icon  matTooltip="This value is ignored" inline="true" >remove</mat-icon>
    
</div>

<ng-template #elseBlock>

    <p *ngIf="errors?.length > 0 || hasError; else valid">
        <mat-icon class="warn-icon" matTooltip="Current value must be equal to Desired Value" inline="true" color="warn">warning</mat-icon>
        
    </p>
    
    
    
    <ng-template #valid>
        <mat-icon class="success">check_circle_outline</mat-icon>
    </ng-template>
    
</ng-template>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as actions from './actions';
import { IP } from './model';

@Injectable()
export class MspIpEffects {

    loadIps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadIps),
            distinct((action) => action.type),
            mergeMap(_ => this.get()
                .pipe(
                    map(({ records }) => actions.loadIpsSuccess({ ips: records })),
                    catchError((error) => of(actions.loadIpsFailure({ error })))
                )
            )
        )
    );

    createIp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createIp),
            mergeMap(({ ip }) =>
                this.post(ip).pipe(
                    map(ip => actions.createIpSuccess({ ip })),
                    catchError((error) =>
                        of(actions.createIpFailure({ error }))
                    )
                )
            )
        )
    );

    deleteIp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteIp),
            mergeMap(({ id }) =>
                this.delete(id).pipe(
                    map((_) => actions.deleteIpSuccess({ id })),
                    catchError((error) => of(actions.deleteIpFailure({ id, error })))
                )
            )
        )
    );

    get() {
        return this.ajax.get<{ records: IP[] }>('/api/octiga/v2/msp/ip');
    }

    post(body: Partial<IP>) {
        return this.ajax.post<IP>('/api/octiga/v2/msp/ip', body);
    }

    put(id: string, body: Partial<IP>) {
        return this.ajax.put<IP>(`/api/octiga/v2/msp/ip/${id}`, body);
    }

    delete(id: string) {
        return this.ajax.delete<IP>(`/api/octiga/v2/msp/ip/${id}`);
    }

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService
    ) { }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, skipWhile } from 'rxjs/operators';
import { selectSession } from 'src/app/stores/root.store';

@Injectable({
    providedIn: 'root'
})
export class WildcardGuard implements CanActivate {

    constructor(private store: Store<any>, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.pipe(
            select(selectSession),
            skipWhile(data => data.loading),
            map(data => {
                if (!data.session) {
                    return this.router.parseUrl('/auth/microsoft');
                }
                if (data.session.msp_id) {
                    return this.router.parseUrl('/msp/dashboard');
                }
                else if (data.session.clientId) {
                    return this.router.parseUrl(`/client/${data.session.clientId}/dashboard`);
                }
                else if (!data.session.msp_id && !!data.session.tenantAccess && Object.keys(data.session.tenantAccess).length === 1) { // single tenant mode
                    return this.router.parseUrl(`/client/${Object.keys(data.session.tenantAccess)[0]}/dashboard`);
                }
                else {
                    return this.router.parseUrl('/auth/microsoft');
                }
            })

        );
    }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';
import { DKIMSigningConfig } from './model';

////////   APPLIES TO ON-PREM ONLY   ////////
const endPoint = '/api/microsoft/powershell/exo/dkim-signing-config';

@Injectable()
export class DKIMSigningConfigEffects {
    fetchDKIMSigningConfigEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchDkimSigningConfig),
            distinct(action => action._tenant),
            mergeMap((action) => this.get(action._tenant)
                .pipe(
                    map((data: DKIMSigningConfig[]) => actions.fetchDKIMSigningConfigSuccess({ _tenant: action._tenant, data })),
                    catchError((error) => of(actions.fetchDKIMSigningConfigFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    private get(tenant: string): Observable<DKIMSigningConfig[]> {
        return this.ajax.get(tenant, endPoint);
    }

    updateDKIMSigningConfigEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateDKIMSigningConfig),
            mergeMap((action) => this.put(action._tenant, action.data)
                .pipe(
                    map((data: DKIMSigningConfig[]) => ({ id: data[0].Id, changes: { ...data[0] } })),
                    map((data) => actions.updateDKIMSigningConfigSuccess({ _tenant: action._tenant, data })),
                    catchError((error) => of(actions.updateDKIMSigningConfigFailure({ _tenant: action._tenant, error: {message : error.message, trace: error.trace} })))
                ))
        )
    );

    private put(tenant: string, body: Partial<DKIMSigningConfig>): Observable<DKIMSigningConfig[]> {
        return this.ajax.put(tenant, endPoint, body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }
}

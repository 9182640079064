import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-severity-badges',
    templateUrl: './severity-badges.component.html',
    styleUrls: ['./severity-badges.component.scss']
})
export class SeverityBadgesComponent implements OnInit {
   @Input() isLoading: boolean = false;
   @Input() small: boolean = false;

   @Input() severity: {
        critical?: number,
        danger?: number,
        warning?: number,
        info?: number
    };
   constructor() { }

   ngOnInit(): void {
   }

}

import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { StripeService } from 'src/app/modules/billing/services/stripe.service';

@Component({
    selector: 'billing-card-form',
    templateUrl: './card-form.component.html',
    styleUrls: ['./card-form.component.scss']
})
export class CardFormComponent implements AfterViewInit {

    @Output() card = new EventEmitter<stripe.elements.Element | false>();

    cardNumber_complete = false;
    cardExpiry_complete = false;
    cardCvc_complete = false;

    constructor(private stripeService: StripeService) { }

    ngAfterViewInit() {
        this.setupStripeForm(this.stripeService.stripe);
    }

    setupStripeForm(stripe: stripe.Stripe) {
        const elements = stripe.elements();

        const options = {
            classes: {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid'
            },
            style: {
                base: {
                    fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    lineHeight: 'normal'
                }
            }
        };

        const cardNumber = elements.create('cardNumber', options);
        cardNumber.mount('#card-element-card-number');
        cardNumber.addEventListener('change', e => {
            this.cardNumber_complete = e.complete;
            this.emit(cardNumber);
        });

        const cardExpiry = elements.create('cardExpiry', options);
        cardExpiry.mount('#card-element-card-expiry');
        cardExpiry.addEventListener('change', e => {
            this.cardExpiry_complete = e.complete;
            this.emit(cardExpiry);
        });

        const cardCvc = elements.create('cardCvc', options);
        cardCvc.mount('#card-element-card-cvc');
        cardCvc.addEventListener('change', e => {
            this.cardCvc_complete = e.complete;
            this.emit(cardCvc);
        });
    }

    emit(element: stripe.elements.Element) {
        const required = [
            this.cardNumber_complete,
            this.cardExpiry_complete,
            this.cardCvc_complete
        ];
        if (required.every(i => i)) {
            this.card.emit(element);
        } else {
            this.card.emit(false);
        }
    }
}

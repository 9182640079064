import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AlertItem } from './model';

export const fetchAlerts = createAction(
    '[Root/Alert] Fetch Alerts',
    props<{ _tenant: string, start: string, end: string }>()
);

export const fetchAlertsSuccess = createAction(
    '[Root/Alert] Fetch Alerts Success',
    props<{ _tenant: string, start: string, end: string, alerts: AlertItem[] }>()
);

export const fetchAlertsFailure = createAction(
    '[Root/Alert] Fetch Alerts Failure',
    props<{ _tenant: string, error: any }>()
);

export const dismissAlert = createAction(
    '[Root/Alert] Dismiss Alert',
    props<{ _tenant: string, alert: AlertItem }>()
);

export const dismissAlertFailure = createAction(
    '[Root/Alert] Dismiss Alert Failure',
    props<{ _tenant: string, alert: AlertItem, error: any }>()
);

export const updateAlertsCursor = createAction(
    '[Root/Alert] Update Alerts Cursor',
    props<{ _tenant: string, date: string }>()
);

export const addAlert = createAction(
    '[Root/Alert] Add Alert',
    props<{ _tenant: string, alert: AlertItem }>()
);

export const upsertAlert = createAction(
    '[Root/Alert] Upsert Alert',
    props<{ _tenant: string, alert: AlertItem }>()
);

export const addAlerts = createAction(
    '[Root/Alert] Add Alerts',
    props<{ _tenant: string, alerts: AlertItem[] }>()
);

export const upsertAlerts = createAction(
    '[Root/Alert] Upsert Alerts',
    props<{ _tenant: string, alerts: AlertItem[] }>()
);

export const updateAlert = createAction(
    '[Root/Alert] Update Alert',
    props<{ _tenant: string, alert: Update<AlertItem> }>()
);

export const updateAlerts = createAction(
    '[Root/Alert] Update Alerts',
    props<{ _tenant: string, alerts: Update<AlertItem>[] }>()
);

export const deleteAlert = createAction(
    '[Root/Alert] Delete Alert',
    props<{ _tenant: string, alert: AlertItem }>()
);

export const deleteAlerts = createAction(
    '[Root/Alert] Delete Alerts',
    props<{ _tenant: string, ids: string[] }>()
);

export const clearAlerts = createAction(
    '[Root/Alert] Clear Alerts',
    props<{ _tenant: string }>()
);



import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, distinct, last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { ChangesService } from 'src/app/services/changes.service';

import * as ChangesActions from 'src/app/stores/client/octiga/changes/actions';
import * as ExoActions from './actions';
import { ExoTransportRule } from './model';
import { Store } from '@ngrx/store';

@Injectable()
export class TransportRuleEffects {

    loadTransportRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.loadExoTransportRules),
            mergeMap(({ _tenant }) => this.ajax.get(_tenant, '/api/microsoft/powershell/exo/transport-rule')
                .pipe(
                    retry(3000, 3, 'transport rules timeout'),
                    last(),
                    map((data: ExoTransportRule[]) => ExoActions.loadExoTransportRulesSuccess({ _tenant, data })),
                    catchError((error) => of(ExoActions.loadExoTransportRulesFailure({ _tenant, error: { message: error.message, trace: error.trace } })))
                ))
        )
    );

    deleteTransportRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.deleteExoTransportRule),
            mergeMap(action => this.deleteTransportRule(action._tenant, action.Identity)
                .pipe(
                    concatMap(() => {
                        const params = { user: 'company', fields: { operation: 'delete', name: action.Identity } };
                        const item = this.changesService.formatChangesObjectToDB(params, 'transport-rule');
                        return [
                            ChangesActions.storeChangesToDB({ _tenant: action._tenant, item }),
                            ExoActions.loadExoTransportRules({ _tenant: action._tenant }),
                            ExoActions.deleteExoTransportRuleSuccess({ _tenant: action._tenant, identity: action.Identity }),
                        ];
                    }),
                    catchError((error) => of(ExoActions.deleteExoTransportRuleFailure({ _tenant: action._tenant, error })))
                ))
        )
    );


    createTransportRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.createExoTransportRule),
            mergeMap((action) => this.createTransportRule(action._tenant, action.exoTransportRule)
                .pipe(
                    // tap((load)=> this.store.dispatch(ExoActions.loadExoTransportRules({_tenant: action._tenant})) ), // any crate could change all priority 
                   
                    map((exoTransportRule) => ExoActions.loadExoTransportRules({ _tenant: action._tenant })),
                    catchError((error) => of(ExoActions.createExoTransportRuleFailure({ _tenant: action._tenant, error })))
                ))
        )
    );


    updateTransportRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.updateExoTransportRule),
            mergeMap((action) => this.updateTransportRule(action._tenant, action.exoTransportRule)
                .pipe(
                    // tap((load)=> this.store.dispatch(ExoActions.loadExoTransportRules({_tenant: action._tenant})) ), // any update could change all priority 
                    // map((exoTransportRule) => ExoActions.updateExoTransportRuleSuccess({ _tenant: action._tenant, exoTransportRule })),
                    map((exoTransportRule) => ExoActions.loadExoTransportRules({ _tenant: action._tenant })),

                    catchError((error) => of(ExoActions.updateExoTransportRuleFailure({ _tenant: action._tenant, error })))
                ))
        )
    );


    enableTransportRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.enableExoTransportRule),
            mergeMap((action) => this.enableTransportRule(action._tenant, action.Identity)
                .pipe(
                    map((exoTransportRule) => ExoActions.enableExoTransportRuleSuccess({ _tenant: action._tenant,exoTransportRule})),
                    catchError((error) => of(ExoActions.enableExoTransportRuleFailure({ _tenant: action._tenant, error })))
                ))
        )
    );


    disableTransportRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.disableExoTransportRule),
            mergeMap((action) => this.disableTransportRule(action._tenant, action.Identity)
                .pipe(
                    map((exoTransportRule) => ExoActions.disableExoTransportRuleSuccess({ _tenant: action._tenant, exoTransportRule })),
                    catchError((error) => of(ExoActions.disableExoTransportRuleFailure({ _tenant: action._tenant, error })))
                ))
        )
    );


    deleteTransportRule(tenant: string, identity: string) {
        const url = `/api/microsoft/powershell/exo/transport-rule/${identity}`;
        return this.ajax.delete(tenant, url);
    }

    createTransportRule(tenant: string, options: Partial<ExoTransportRule>) {
        return this.ajax.post(tenant, '/api/microsoft/powershell/exo/transport-rule', options).pipe(map(res => res[0]));
    }


    updateTransportRule(tenant: string, rule: Partial<ExoTransportRule>) {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/transport-rule', rule).pipe(map(res => res[0]));
    }

    enableTransportRule(tenant: string, Identity: string) {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/enable-transport-rule', {Identity}).pipe(map(res => res[0]));
    }

    disableTransportRule(tenant: string, Identity: string) {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/disable-transport-rule', {Identity}).pipe(map(res => res[0]));
    }


    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private changesService: ChangesService,
        private store: Store<any>
    ) { }
}

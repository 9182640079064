import { createAction, props } from '@ngrx/store';
import { RipeASN } from './model';

export const loadASNList = createAction(
    '[ASN List] Load'
);

export const loadASNListSuccess = createAction(
    '[ASN List] Load Success',
    props<{ data: RipeASN[] }>()
);

export const loadASNListFailure = createAction(
    '[ASN List] Load Failure',
    props<{ error: any }>()
);

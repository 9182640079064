import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DismissItem } from '../../shared/dismiss-panel-dialog/dismiss-items-table/dismiss-items-table.component';
import { DismissPanelDialogComponent } from '../../shared/dismiss-panel-dialog/dismiss-panel-dialog.component';
import { CardComponent } from '../card.component';

@Component({
    selector: 'app-app-report',
    templateUrl: './app-report.component.html',
    styleUrls: ['./app-report.component.scss']
})
export class AppReportComponent extends CardComponent implements OnInit, OnDestroy {

    public tenant_id = this.route.snapshot.paramMap.get('tenant');
    public severity$: BehaviorSubject<{ critical: number, danger: number, warning: number }> = new BehaviorSubject({ critical: -1, danger: -1, warning: -1 });
    // public isLoading = true;

    // private tenant$ = this.store.select(client(this.tenant_id).octiga.tenant.data)
    //     .pipe(
    //         first(data => !!data),
    //         map(data => data.id)
    //     )

    // private dismiss$ = this.store.select(client(this.tenant_id).octiga.dismiss.all)
    //     .pipe(
    //         withLatestFrom(this.tenant$),
    //         map(([res, tenant]) => {
    //             if (!!res && res.length > 0) {
    //                 return res.filter(r => (r.type === 'consented_app') && r.tenant === tenant);
    //             } else {
    //                 return []
    //             }
    //         })
    //     )

    private subscriptions: Subscription[] = [];
    private items: DismissItem[] = [];

    constructor(
        public matDialog: MatDialog,
        public router: Router,
        private route: ActivatedRoute,
    ) {
        super();
    }

    ngOnInit(): void {
        this.loadSeverity();
    }

    loadSeverity() {
        this.items = [];
        const severity = { critical: 0, danger: 0, warning: 0 };

        this.severity$.next(severity);
        // this.isLoading = false;

        // const sps_loaded$ = this.store.select(client(this.tenant_id).graph.servicePrincipal.status).pipe(map(status => status.loaded));
        // const sps_error$ = this.store.select(client(this.tenant_id).graph.servicePrincipal.status)
        //     .pipe(
        //         filter(status => status.error !== false),
        //         map(status => status.error)
        //     );

        // let sub_1 = combineLatest([
        //     this.dismiss$,
        //     sps_loaded$
        // ])
        //     .subscribe(([dismiss, loaded]) => {
        //         if (loaded) {
        //             this.items = [];
        //             let severity = { critical: 0, danger: 0, warning: 0 };

        //             this.severity$.next(severity);
        //             this.isLoading = false;
        //         }
        //     })

        // this.subscriptions.push(sub_1);

        // let sub_2 = sps_error$
        //     .subscribe(error => {
        //         this.isLoading = false;
        //     })
        // this.subscriptions.push(sub_2);
    }

    openDismissDialog() {
        const data = { tenant_id: this.tenant_id, title: 'Consented Applications', type: 'consented_app', items: this.items };
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height = '50vh';
        dialogConfig.width = '60vw';
        dialogConfig.data = data;
        const dialogRef = this.matDialog.open(DismissPanelDialogComponent, dialogConfig);
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    navigate() {
        this.router.navigate([`client/${this.tenant_id}/dashboard/app-report`]);
    }
}

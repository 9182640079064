import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { UpdateDateList } from './model';

export const loadUpdateDateLists = createAction(
    '[Update Summary Date List] Load UpdateDateLists',
    props<{ _tenant: string, updateDateLists: UpdateDateList[] }>()
);

export const addUpdateDateList = createAction(
    '[Update Summary Date List] Add UpdateDateList',
    props<{ _tenant: string, updateDateList: UpdateDateList }>()
);

export const upsertUpdateDateList = createAction(
    '[Update Summary Date List] Upsert UpdateDateList',
    props<{ _tenant: string, updateDateList: UpdateDateList }>()
);

export const addUpdateDateLists = createAction(
    '[Update Summary Date List] Add UpdateDateLists',
    props<{ _tenant: string, updateDateLists: UpdateDateList[] }>()
);

export const upsertUpdateDateLists = createAction(
    '[Update Summary Date List] Upsert UpdateDateLists',
    props<{ _tenant: string, updateDateLists: UpdateDateList[] }>()
);

export const updateUpdateDateList = createAction(
    '[Update Summary Date List] Update UpdateDateList',
    props<{ _tenant: string, updateDateList: Update<UpdateDateList> }>()
);

export const updateUpdateDateLists = createAction(
    '[Update Summary Date List] Update UpdateDateLists',
    props<{ _tenant: string, updateDateLists: Update<UpdateDateList>[] }>()
);

export const deleteUpdateDateList = createAction(
    '[Update Summary Date List] Delete UpdateDateList',
    props<{ _tenant: string, id: string }>()
);

export const deleteUpdateDateLists = createAction(
    '[Update Summary Date List] Delete UpdateDateLists',
    props<{ _tenant: string, ids: string[] }>()
);

export const clearUpdateDateLists = createAction(
    '[Update Summary Date List] Clear UpdateDateLists',
    props<{ _tenant: string }>()
);

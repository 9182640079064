import { DirectoryRole } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadDirectoryRoleTemplates = createAction(
    '[Graph/Directory Role Templates] Load',
    props<{ msp: string }>()
);

export const loadDirectoryRoleTemplatesSuccess = createAction(
    '[Graph/Directory Role Templates] Load Success',
    props<{ msp: string, roles: DirectoryRole[] }>()
);

export const loadDirectoryRoleTemplatesFailure = createAction(
    '[Graph/Directory Role Templates] Load Failure',
    props<{ msp: string, error: any }>()
);

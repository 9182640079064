import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';

const applications = {
    'type': 'object',
    'properties': {
        'includeApplications': { //   Target resources > Cloud apps > Include,
            'type': 'array',
            'items': {
                'enum': ['all', 'All'],
                'type': 'string'
            },
            'minItems': 1,
            'maxItems': 1,
            'uniqueItems': true
        }

    },
    'required': ['includeApplications']
};


const clientAppTypes = {
    'type': 'array',
    'items': {
        'enum': [
            'exchangeActiveSync',
            'other'
        ]
    }
};

const grantControls = {
    'type': 'object',
    'properties': {
        'operator': {
            'type': 'string',
            'const': 'OR'
        },
        'builtInControls': {
            'type': 'array',
            'items': {
                'type': 'string',
                'const': 'block'
            },
            'maxItems': 1
        }
    }
};


const policy: ConditionalAccessPolicySchema = {
    grantControls,

    conditions: {
        applications,
        clientAppTypes
    }
};

export const legacyAuthBaselineSchema =

    (
        form
    ) => {
        return createCapPolicySchema(form, policy);
    };


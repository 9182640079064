import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as SpecActions from './actions';
import { SwaySpec } from './model';

export const featureKey = 'swaySpec';

export interface State extends EntityState<SwaySpec>, Status { }

export const adapter: EntityAdapter<SwaySpec> = createEntityAdapter<SwaySpec>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(SpecActions.loadSpecs, (state) => ({ ...state, loading: !state.loaded })),
    on(SpecActions.loadSpecsSuccess, (state, action) => adapter.setAll(action.data, { ...state, loaded: true, loading: false })),
    on(SpecActions.loadSpecsFailure, (state, { error }) => ({ ...state, loading: false, error }))
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

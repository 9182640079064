import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appDashboardOutlet]'
})
export class DashboardOutletDirective implements OnInit {

    @Input() item;

    constructor(public viewContainerRef: ViewContainerRef) { }

    ngOnInit(): void {
        console.log(this.item);
    }

}

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { NgChartsModule } from 'ng2-charts';
import { ShortNumberPipe } from 'src/app/pipes/short-number.pipe';
import { ClassificationService } from 'src/app/services/classification.service';
import { MaterialModule } from '../material.module';
import { AlertFilterFormComponent } from './components/alerts/alert-filter-form/alert-filter-form.component';
import { AlertTableComponent } from './components/alerts/alert-table/alert-table.component';
import { DoesNotExistDialogComponent } from './components/alerts/does-not-exist-dialog/does-not-exist-dialog.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ChangeLogComponent } from './components/change-log/change-log.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { DotsLoaderComponent } from './components/dots-loader/dots-loader.component';
import { DualListMultiselectComponent } from './components/dual-list-multiselect/dual-list-multiselect.component';
import { EventListFilterComponent } from './components/event-list-filter/event-list-filter.component';
import { EventDetailContentComponent } from './components/event-list/event-detail-dialog/event-detail-content/event-detail-content.component';
import { EventDetailDialogComponent } from './components/event-list/event-detail-dialog/event-detail-dialog.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { EventSummaryTableByCountriesComponent } from './components/event-summary-table-by-countries/event-summary-table-by-countries.component';
import { EventSummaryTableByRadiusComponent } from './components/event-summary-table-by-radius/event-summary-table-by-radius.component';
import { EventSummaryTableComponent } from './components/event-summary-table/event-summary-table.component';
import { EventsDialogComponent } from './components/events-dialog/events-dialog.component';
import { GeolocationEditNameDialogComponent } from './components/geolocation-tool/geolocation-edit-name-dialog/geolocation-edit-name-dialog.component';
import { GeolocationSelectionListComponent } from './components/geolocation-tool/geolocation-selection-list/geolocation-selection-list.component';
import { GeolocationToolComponent } from './components/geolocation-tool/geolocation-tool.component';
import { CasDetailsTableComponent } from './components/mailboxes/mailbox-details-dialog/cas-details-table/cas-details-table.component';
import { InboxRulesDescriptionComponent } from './components/mailboxes/mailbox-details-dialog/inbox-rules-detail-table/inbox-rules-description/inbox-rules-description.component';
import { InboxRulesDetailTableComponent } from './components/mailboxes/mailbox-details-dialog/inbox-rules-detail-table/inbox-rules-detail-table.component';
import { MailboxDetailsDialogComponent } from './components/mailboxes/mailbox-details-dialog/mailbox-details-dialog.component';
import { OwaRuleDeleteDialogComponent } from './components/mailboxes/mailbox-details-dialog/owa-rules-detail-table/owa-rule-delete-dialog/owa-rule-delete-dialog.component';
import { OwaRulesDetailTableComponent } from './components/mailboxes/mailbox-details-dialog/owa-rules-detail-table/owa-rules-detail-table.component';
import { ResetPasswordPanelComponent } from './components/mailboxes/mailbox-details-dialog/reset-password-panel/reset-password-panel.component';
import { MailboxesTableComponent } from './components/mailboxes/mailboxes-table/mailboxes-table.component';
import { MonitoringSummaryChartComponent } from './components/monitoring-week-summary-chart/monitoring-week-summary-chart.component';
import { MultipleSelectionListComponent } from './components/multiple-selection-list/multiple-selection-list.component';
import { OkDialogComponent } from './components/ok-dialog/ok-dialog.component';
import { ConfirmChangesDialogComponent } from './components/reset-password/confirm-changes-dialog/confirm-changes-dialog.component';
import { ConfirmChangesListComponent } from './components/reset-password/confirm-changes-list/confirm-changes-list.component';
import { ConfirmChangesProceedComponent } from './components/reset-password/confirm-changes-proceed/confirm-changes-proceed.component';
import { SideMenuSelectionComponent } from './components/side-menu-selection/side-menu-selection.component';
import { SummariesChartComponent } from './components/summaries-chart/summaries-chart.component';
import { TabsRoutedComponent } from './components/tabs-routed/tabs-routed.component';
import { TrialExpireDialogComponent } from './components/trial-expire-modal/trial-expire-modal.component';
import { CustomizePipe } from './customize.pipe';
import { TableCellFilterComponent } from './libs/table/table-cell-filter/table-cell-filter.component';
import { RoleAuthDirective } from 'src/app/services/guards/roles/role-auth.directive';
import { PermissionCheckDirective } from 'src/app/services/guards/permission-check.directive';

@NgModule({
    declarations: [
        AlertFilterFormComponent,
        AlertTableComponent,
        CasDetailsTableComponent,
        ChangeLogComponent,
        ConfirmChangesDialogComponent,
        ConfirmChangesListComponent,
        ConfirmChangesProceedComponent,
        CustomizePipe,
        DotsLoaderComponent,
        DualListMultiselectComponent,
        EventDetailContentComponent,
        EventDetailDialogComponent,
        EventsDialogComponent,
        EventListComponent,
        EventListFilterComponent,
        EventSummaryTableComponent,
        EventSummaryTableByCountriesComponent,
        EventSummaryTableByRadiusComponent,
        GeolocationToolComponent,
        GeolocationSelectionListComponent,
        GeolocationEditNameDialogComponent,
        InboxRulesDescriptionComponent,
        InboxRulesDetailTableComponent,
        MailboxDetailsDialogComponent,
        MailboxesTableComponent,
        MonitoringSummaryChartComponent,
        MultipleSelectionListComponent,
        OkDialogComponent,
        OwaRuleDeleteDialogComponent,
        OwaRulesDetailTableComponent,
        ResetPasswordPanelComponent,
        TrialExpireDialogComponent,
        ShortNumberPipe,
        SideMenuSelectionComponent,
        SummariesChartComponent,
        DoesNotExistDialogComponent,
        DeleteDialogComponent,
        BreadcrumbComponent,
        TabsRoutedComponent,
        DualListMultiselectComponent,
        ConfirmDialogComponent,
        TableCellFilterComponent,
        RoleAuthDirective,
        PermissionCheckDirective
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        GoogleMapsModule,
        ScrollingModule,
        NgChartsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatGridListModule,
        RouterModule,
    ],
    exports: [
        RoleAuthDirective,
        PermissionCheckDirective,
        AlertFilterFormComponent,
        AlertTableComponent,
        BreadcrumbComponent,
        CasDetailsTableComponent,
        ChangeLogComponent,
        NgChartsModule,
        CommonModule,
        ConfirmChangesDialogComponent,
        ConfirmChangesListComponent,
        ConfirmChangesProceedComponent,
        CustomizePipe,
        DotsLoaderComponent,
        DeleteDialogComponent,
        DualListMultiselectComponent,
        GeolocationToolComponent,
        GeolocationSelectionListComponent,
        GeolocationEditNameDialogComponent,
        EventDetailContentComponent,
        EventDetailDialogComponent,
        EventsDialogComponent,
        EventListComponent,
        EventListFilterComponent,
        EventSummaryTableComponent,
        EventSummaryTableByCountriesComponent,
        EventSummaryTableByRadiusComponent,
        InboxRulesDescriptionComponent,
        InboxRulesDetailTableComponent,
        MailboxDetailsDialogComponent,
        MailboxesTableComponent,
        MatGridListModule,
        MonitoringSummaryChartComponent,
        MultipleSelectionListComponent,
        OkDialogComponent,
        OwaRuleDeleteDialogComponent,
        OwaRulesDetailTableComponent,
        TrialExpireDialogComponent,
        TableCellFilterComponent,
        ShortNumberPipe,
        SideMenuSelectionComponent,
        SummariesChartComponent,
        TabsRoutedComponent,
    ],
    providers: [MatDatepickerModule, ClassificationService],
})
export class SharedModule {
    constructor() {
        Chart.register(...registerables);
        Chart.register(annotationPlugin);
    }
}

<sway-cap-baseline *ngIf="baseline && schema$" [readOnly]="readOnly" [baselineTemplateItem]="baselineTemplateItem"
    [tenant_id]="tenant_id" [form]="form" [baseline]="baseline" [schema$]="schema$"
    [schemaFunc]="schemaFunc" [policy$]="policy$" (baselineErrorChange)="onBaselineErrorChange($event)">
    <ng-container *ngTemplateOutlet="content"></ng-container>

</sway-cap-baseline>

<ng-template #content>
    <ng-container *ngIf="policy$ | async as policy">
        <ng-container *ngIf="policy?.id">
            <tr>
                <td>
                    Persistent Browser
                </td>

                <td>

                    <ng-container *ngIf="policy?.sessionControls?.persistentBrowser?.isEnabled  ">
                        {{ policy?.sessionControls?.persistentBrowser?.mode }}
                    </ng-container>

                </td>



                <td>
                    Never
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.sessionControls?.persistentBrowser?.isEnabled && policy?.sessionControls?.persistentBrowser?.mode === 'never')">
                    </sway-baseline-status>
                </td>
            </tr>
        </ng-container>
    </ng-container>
</ng-template>
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { loadTenant } from 'src/app/stores/client/octiga/tenant/actions';
import * as TasksActions from './actions';

function generateQuery(action: { scanType: string, start?: string, end?: string, force?: boolean }) {
    let url = `/api/microsoft/monitoring/scan?type=${action.scanType}`;
    if (action.start && action.end) {
        url += `&start=${action.start}&end=${action.end}`;
    }
    if (action.force) {
        url += '&force=true';
    }
    return url;
}

@Injectable()
export class TasksEffects {

    generateTasks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TasksActions.generateTasks),
            concatMap((action) => this.ajax.post(action._tenant, generateQuery(action))
                .pipe(
                    concatMap(data => [
                        loadTenant({ _tenant: action._tenant }),
                        TasksActions.generateTasksSuccess({ _tenant: action._tenant, data })
                    ]),
                    catchError((error) => of(TasksActions.generateTasksFailure({ _tenant: action._tenant, error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}

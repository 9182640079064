import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
export const featureKey = 'mspCwmStatuses';

interface StatusesByBoard {
    data: any
    board_id: number
}
export interface State extends EntityState<StatusesByBoard>, Status {
}

export const adapter: EntityAdapter<StatusesByBoard> = createEntityAdapter<StatusesByBoard>({
    selectId: (item) => item.board_id
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.loadMspCwmStatusesSuccess,
        (state, { data, board_id }) => {
            state = { ...state, loaded: true }; return adapter.upsertOne({ data, board_id }, state);
        }),
    on(actions.loadMspCwmStatusesFailure, (state, { error }) => ({ ...state, error })),

);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();




import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { loaderFeatureKey, loaderReducer } from './store/loader.reducer';
import { ContentLoaderComponent } from './content-loader/content-loader.component';
import { CommonModule } from '@angular/common';
import { ReactiveComponentModule } from '@ngrx/component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderDirective } from './loader.directive';

@NgModule({
    imports: [
        CommonModule,
        ReactiveComponentModule,
        StoreModule.forFeature( loaderFeatureKey, loaderReducer ),
        MatProgressSpinnerModule
    ],
    declarations: [
        ContentLoaderComponent,
        LoaderDirective,
    ],
    exports: [
        ContentLoaderComponent,
        LoaderDirective,
    ]
})
export class LoaderModule {}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ExoTransportRuleActions from './actions';
import { ExoTransportRule } from './model';
import { EntityStatus, initialStatus } from '../../../../status.interface';

export const exoFeatureKey = 'exoTransportRules';

export interface State extends EntityState< ExoTransportRule>, EntityStatus {}

export const adapter: EntityAdapter<ExoTransportRule> = createEntityAdapter<ExoTransportRule>({
    selectId: (rule) => rule.Identity
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});


export const reducer = createReducer(
    initialState,
  
    on(ExoTransportRuleActions.loadExoTransportRulesSuccess,
        (state, action) => adapter.setAll(action.data, { ...state, loaded: true , updating: false, creating : false, error: false} ) 
    ),

    on(ExoTransportRuleActions.loadExoTransportRulesFailure,
        (state, action) => ({ ...state, error: action.error, loaded: false })
    ),
    on(ExoTransportRuleActions.deleteExoTransportRuleSuccess,
        (state) => (state)
    ),
    on(ExoTransportRuleActions.deleteExoTransportRuleFailure,
        (state, action) => ({ ...state, error: action.error })
    ),


    on(ExoTransportRuleActions.createExoTransportRuleFailure,
        (state, action) => ({ ...state, error: action.error })
    ),

    on(ExoTransportRuleActions.updateExoTransportRule,
        (state, action) => ({ ...state, error: null, updating: true })
    ),

    
    on(ExoTransportRuleActions.createExoTransportRule,
        (state, action) => ({ ...state, error: null, updating: true }) 
    ),
    
    on(ExoTransportRuleActions.deleteExoTransportRule,
        (state, action) => ({ ...state, error: null, updating: true })
    ),
  
    on(ExoTransportRuleActions.updateExoTransportRuleFailure,
        (state, action) => ({ ...state, error: action.error, updating: false })
    ),
  

    on(ExoTransportRuleActions.enableExoTransportRule,
        (state, action) => ({ ...state, error: null, updating: true })
    ),
    on(ExoTransportRuleActions.enableExoTransportRuleSuccess,
        (state, action) => adapter.upsertOne(action.exoTransportRule, {...state, updating: false})
    ),
    on(ExoTransportRuleActions.enableExoTransportRuleFailure,
        (state, action) => ({ ...state, error: action.error, updating: false })
    ),
  

    
    on(ExoTransportRuleActions.disableExoTransportRule,
        (state, action) => ({ ...state, error: null, updating: true })
    ),
    on(ExoTransportRuleActions.disableExoTransportRuleSuccess,
        (state, action) => adapter.upsertOne(action.exoTransportRule, {...state, updating: false})
    ),
    on(ExoTransportRuleActions.disableExoTransportRuleFailure,
        (state, action) => ({ ...state, error: action.error, updating: false })
    ),
  


);


export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

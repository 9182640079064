import { AppRoleAssignment } from '@microsoft/microsoft-graph-types-beta';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';

export const featureKey = 'graphAppRoleAssignments';

export interface State extends EntityState<AppRoleAssignment>, Status { }

export const adapter: EntityAdapter<AppRoleAssignment> = createEntityAdapter<AppRoleAssignment>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.loadAppRoleAssignmentsSuccess,
        (state, action) => adapter.setAll(action.roles, { ...state, loaded: true })
    ),
    on(actions.loadAppRoleAssignmentsFailure,
        (state, { error }) => ({ ...state, error, loaded: false })
    )
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

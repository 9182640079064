import { OAuth2PermissionGrant } from '@microsoft/microsoft-graph-types-beta';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
export const featureKey = 'graphServicePrincipalOAuth2PermissionGrants';

export interface State extends Status {
    data: {
        [key: string]: OAuth2PermissionGrant[]
    }
}

export const initialState: State = {
    data: {},
    ...initialStatus
};

function upsertOne(id: string, data: OAuth2PermissionGrant[], state: State) {
    const updated = Object.assign({}, state);
    updated.data[id] = data;
    return updated;
}

export const reducer = createReducer(
    initialState,

    on(actions.loadServicePrincipalOAuth2PermissionGrantsSuccess,
        (state, { id, data }) => upsertOne(id, data, { ...state, loaded: true })
    ),
    on(actions.loadServicePrincipalOAuth2PermissionGrantsFailure,
        (state, { error }) => ({ ...state, error, loaded: true })
    )
);

export const selectByPrincipalId = (id: string) => (state: State) => state.data[id];

import { switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Component, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Domain } from '@microsoft/microsoft-graph-types-beta';
import { BaseComponent } from '../../base/base.component';
import { filter, first, map, Observable, Subscription } from 'rxjs';
import { client } from 'src/app/stores/client';
import { selectSession } from 'src/app/stores/root.store';
interface ExtendedDomain extends Domain {
    isDeviated?: boolean;
}

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})

export class BaselineComponent extends BaseComponent implements OnInit, OnDestroy {

    verified_domains: ExtendedDomain[] = [];

    unVerified_domains$: Observable<Domain[]> = this.store.select(selectSession)
        .pipe(
            first(sess => !!sess?.session?.clientId),
            map(sess => sess.session.clientId),
            switchMap(tenant => {

                return this.store.pipe(
                    select(client(tenant).graph.domains.all),
                    filter(i => !!i),
                    map(domains => domains.filter(domain => !domain.isVerified))
                );
            })

        );


    nonRoot_domains$: Observable<Domain[]> = this.store.select(selectSession)
        .pipe(
            first(sess => !!sess?.session?.clientId),
            map(sess => sess.session.clientId),
            switchMap(tenant => {

                return this.store.pipe(
                    select(client(tenant).graph.domains.all),
                    filter(i => !!i),
                    map(domains => domains.filter(domain => !domain.isRoot))
                );
            })

        );



    MAX_INT = 2147483647;
    sub: Subscription;


    constructor(private readonly fb: FormBuilder, private store: Store<any>) {
        super();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }


    ngOnInit(): void {
        this.form = this.initForm(this.baseline?.schema);

        this.sub = this.store.select(client(this.tenant_id).sway.baselines.byId(this.baseline?.id)).subscribe(baseline => {
            if (baseline) {
                const schema = JSON.parse(JSON.stringify(this.baseline.schema));
                this.form = this.initForm(schema);
                this.observeFormChanges(schema);

                if (this.readOnly) {
                    this.form.disable();
                    this.control.disable();
                }

            }
        });

    }

    observeFormChanges(schema) {
        this.form.valueChanges.subscribe(form => {
            if (!schema) return;

            if (isNaN(+form.passwordNotification)) {
                this.form.get('passwordNotification').setValue(0);
            }


            if (isNaN(+form.validityPeriod)) {
                this.form.get('validityPeriod')?.setValue(0);
            }

            schema.items.properties.passwordNotificationWindowInDays['const'] = form.passwordNotification === null ? 14 : +form.passwordNotification;
            schema.items.properties.passwordValidityPeriodInDays['const'] = form.validityPeriod === null ? 90 : +form.validityPeriod;

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema))
            });
        });
    }


    initForm(schema) {
        return this.fb.group({
            passwordNotification: [schema?.items?.properties?.passwordNotificationWindowInDays?.const, [Validators.max(this.MAX_INT)]],
            validityPeriod: [schema?.items?.properties?.passwordValidityPeriodInDays?.const, [Validators.max(this.MAX_INT)]]
        });
    }


    ngDoCheck(): void {
        // this.validate()
        this.setDomainsUIDeviations();
    }

    setDomainsUIDeviations() {
        this.verified_domains = JSON.parse(JSON.stringify(this.data)) as ExtendedDomain[];
        this.errors.forEach(err => {
            const index = err.instancePath.split('/')[1]; // get the index of domain which have deviation
            this.verified_domains[+index]['isDeviated'] = true;
        });
    }

    passwordValidityToggle(e) {
        this.form.get('validityPeriod').setValue(e.checked ? this.MAX_INT : '365');
    }
}

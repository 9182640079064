<ng-container *ngIf="!isLoading" class="">
    <div [class.small]="small">

        <div *ngIf="severity.critical > 0">
            <span class="critical" matBadge="{{severity.critical}}" matBadgeColor="warn" matBadgeSize="large"
                matBadgeOverlap="true"></span>
        </div>
        <div *ngIf="severity.danger > 0">
            <span class="danger" matBadge="{{severity.danger}}" matBadgeColor="warn" matBadgeSize="large"
                matBadgeOverlap="true"></span>
        </div>
        <div *ngIf="severity.warning > 0">
            <span class="warning" matBadge="{{severity.warning}}" matBadgeColor="accent" matBadgeSize="large"
                matBadgeOverlap="true"></span>
        </div>
        <div *ngIf="severity.info > 0">
            <span class="info" matBadge="{{severity.info}}" matBadgeSize="large" matBadgeOverlap="true"></span>
        </div>
        <div
            *ngIf="severity.critical === 0 && severity.danger === 0 && severity.warning === 0 && (!severity.info || severity.info === 0)">
            <span class="safe" matBadge="done" matBadgeSize="large" matBadgeOverlap="false"></span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isLoading">
    <div class="spinner">
        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div>
</ng-container>
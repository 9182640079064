<mat-card class="mat-elevation-z8">


    <div class="card-section card-left">

        <mat-card-header>
            <div mat-card-avatar class="header-image-container mat-elevation-z4" [style.background-color]="plan.color">
                <div class="header-image"></div>
            </div>
            <mat-card-title>{{plan.title}}</mat-card-title>
            <mat-card-subtitle>{{plan.description}}</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <mat-list>
                <mat-list-item *ngFor="let item of plan.features">
                    <mat-icon mat-list-icon>check</mat-icon>
                    <div>{{item}}</div>
                </mat-list-item>
            </mat-list>
            <!-- <mat-card-subtitle class="price">{{plan.price}}</mat-card-subtitle> -->
            <div class="total">
                <div class="row">14 Mailboxes x €3</div>
                <div class="row">=</div>
                <div class="row">€42 / Month</div>
            </div>
        </mat-card-content>

    </div>

    <div class="vert-divider"></div>


    <div class="card-section card-right">

        <mat-card-header>
            <mat-card-title>Payment</mat-card-title>
            <mat-card-subtitle>Activate your subscription</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <billing-info-form (info)="infoChanges($event)"></billing-info-form>
            <billing-card-form (card)="cardChanges($event)"></billing-card-form>
        </mat-card-content>

        <div class="button-wrapper">
            <button mat-raised-button color="primary" (click)="submit()" [disabled]="!info || !card">Subscribe</button>
        </div>

    </div>

</mat-card>

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../../base/base.component';
import { IdentityName } from './remediate';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],

})

export class BaselineComponent extends BaseComponent implements OnInit {
    strictPolicy: any;

    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        const value = schema?.contains?.properties?.State?.const;
        this.strictPolicy = this.data.find(res => res.Identity === IdentityName);
        if(!this.strictPolicy){
            this.baselineErrorChange.next({
                remediate: true,
                save: false
            });
        }else{
            this.baselineErrorChange.next({
                remediate: false,
                save: false
            });
        }


        this.control.setValue(value);

        this.control.valueChanges.subscribe(value => {
            schema.contains.properties.State['const'] = value;

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema))
            });
        });
    }


    ngDoCheck() {
        this.strictPolicy = this.data.find(res => res.Identity === IdentityName);
 
        if(!this.strictPolicy){
            this.baselineErrorChange.next({
                remediate: true,
                save: false
            });
        }else{
            this.baselineErrorChange.next({
                remediate: false,
                save: false
            });
        }

    }

}


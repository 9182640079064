export const AdminRoles = [
    'f023fd81-a637-4b56-95fd-791ac0226033', // service support adminstrator
    '892c5842-a9a6-463a-8041-72aa08ca3cf6', // Cloud App Security administrator
    '62e90394-69f5-4237-9190-012177145e10', // Global administrator
    '9b895d92-2cd3-44c7-9d02-a6ac2d5ea5c3', // Application administrator
    'c4e39bd9-1100-46d3-8c65-fb160da0071f', // Authentication Administrator
    'b0f54661-2d74-4c50-afa3-1ec803f12efe', // Billing administrator
    '158c047a-c907-4556-b7ef-446551a6b5f7', // Cloud application administrator
    'b1be1c3e-b65d-4f19-8427-f6fa0d97feb9', // Conditional Access administrator
    '29232cdf-9323-42fd-ade2-1d097af3e4de', // Exchange administrator
    '729827e3-9c14-49f7-bb1b-9608f156bbb8', // Helpdesk administrator
    '966707d0-3269-4727-9be2-8c3a10f19b9d', // Password administrator
    '7be44c8a-adaf-4e2a-84d6-ab2649e08a13', // Privileged authentication administrator
    'e8611ab8-c189-46e8-94e1-60213ab1f814', // Privileged Role Administrator
    '194ae4cb-b126-40b2-bd5b-6091b380977d', // Security administrator
    'f28a1f50-f6e7-4571-818b-6a12f2af6b6c', // SharePoint administrator
    '32696413-001a-46ae-978c-ce0f6b3620d2', // Windows Update Deployment Administrator
    '11451d60-acb2-45eb-a7d6-43d0f0125c13', // Windows 365 adminstrator
    '3f1acade-1e04-4fbc-9b69-f0302cd84aef', // Edge Adminstrator
    'fe930be7-5e62-47db-91af-98c3a49a38b1', // User Admistrator
    '75941009-915a-4869-abe7-691bff18279e', // Skype for Business Administrator
    '9f06204d-73c1-4d4c-880a-6edb90606fd8', // Azure AD Joined Device Local Administrator
    '17315797-102d-40b4-93e0-432062caca18', // Compliance Administrator
    '3a2c62db-5318-420d-8d74-23affee5d9d5', // Intune Administrator
    '44367163-eba1-44c3-98af-f5787879f96a', // Dynamics 365 Administrator
    'a9ea8996-122f-4c74-9520-8edcd192826c', // Power BI Administrator
    '7495fdc4-34c4-4d15-a289-98788ce399fd', // Azure Information Protection Administrator
    '38a96431-2bdf-4b4c-8b6e-5d3d8abac1a4', // Desktop Analytics Administrator
    '4d6ac14f-3453-41d0-bef9-a3e0c569773a', // 4d6ac14f-3453-41d0-bef9-a3e0c569773a
    '7698a772-787b-4ac8-901f-60d6b08affd2', // Cloud Device Administrator
    'baf37b3a-610e-45da-9e62-d9d1e5e8914b', // Teams Communications Administrator
    '69091246-20e8-4a56-aa4d-066075b2a7a8', // Teams Administrator
    'eb1f4a8d-243a-41f0-9fbd-c7cdf6c5ef7c', // Insights Administrator
    '6e591065-9bad-43ed-90f3-e9424366d2f0', // External ID User Flow Administrator
    '0f971eea-41eb-4569-a71e-57bb8a3eff1e', // External ID User Flow Attribute Administrator
    'aaf43236-0c0d-4d5f-883a-6955382ac081', // B2C IEF Keyset Administrator
    '3edaf663-341e-4475-9f94-5c398ef6c070', // B2C IEF Policy Administrator
    'be2f45a1-457d-42af-a067-6ec1fa63bc45', // External Identity Provider Administrator
    'e6d1a23a-da11-4be4-9570-befc86d067a7', // Compliance Data Administrator
    '74ef975b-6605-40af-a5d2-b9539d836353', // Kaizala Administrator
    '0964bb5e-9bdb-4d7b-ac29-58e794862a40', // Search Administrator
    '644ef478-e28f-4e28-b9dc-3fdde9aa0b1f', // Printer Adminsitrator
    '0526716b-113d-4c15-b2c8-68e3c22b9f80', // Authentication Policy Administrator
    'fdd7a751-b60b-444a-984c-02652fe8fa1c', // Groups Administrator
    '11648597-926c-4cf3-9c36-bcebb0ba8dcc', // Power Platform Administrator
    'e3973bdf-4987-49ae-837a-ba8e231c7286', // Azure DevOps Administrator
    '8ac3fc64-6eca-42ea-9e69-59f4c7b60eb2', // Hybrid Identity Administrator
    '2b745bdf-0803-4d80-aa65-822c4493daac', // Office Apps Administrator
    'd37c8bed-0711-4417-ba38-b4abe66ce4c2', // Network Administrator
    '3d762c5a-1b6c-493f-843e-55a3b42923d4', // Teams Devices Administrator
    'c430b396-e693-46cc-96f3-db01bf8bb62a', // Attack Simulation Administrator
    '9c6df0f2-1e7c-4dc3-b195-66dfbd24aa8f', // Attack Payload Author
    'b5a8dcf3-09d5-43a9-a639-8e29ef291470', // Knowledge Administrator
    '8329153b-31d0-4727-b945-745eb3bc5f31', // Domain Name Administrator
    '8424c6f0-a189-499e-bbd0-26c1753c96d4', // Attribute Definition Administrator
    '58a13ea3-c632-46ae-9ee0-9c0d43cd7f3d', // Attribute Assignment Administrator
    '31392ffb-586c-42d1-9346-e59415a2cc4e', // Exchange Recipient Administrator
    '45d8d3c5-c802-45c6-b32a-1d70b5e1e86e' // Identity Governance Administrator
];
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CryptoService } from 'src/app/modules/shared/services/crypto.service';
import { CasMailbox } from 'src/app/stores/client/powershell/exo/cas-mailbox/model';
import { CardComponent } from '../card.component';

const casMailboxFilterPredicate = (mailbox: CasMailbox) => mailbox.Name.startsWith('DiscoverySearchMailbox') ? false : true;

function setCAS(casMailbox: CasMailbox, dismiss: { [key: string]: boolean }) {
    const ActiveSync = casMailbox.ActiveSyncEnabled ? { 'ActiveSync': 'warning' } : casMailbox.ActiveSyncEnabled === null ? null : { 'ActiveSync': 'info' };
    const IMAP = casMailbox.ImapEnabled ? { 'IMAP': 'critical' } : casMailbox.ImapEnabled === null ? null : { 'IMAP': 'info' };
    const OWA = casMailbox.OWAEnabled ? { 'OWA': 'warning' } : casMailbox.OWAEnabled === null ? null : { 'OWA': 'info' };
    const POP = casMailbox.PopEnabled ? { 'POP': 'critical' } : casMailbox.PopEnabled === null ? null : { 'POP': 'info' };
    const EWS = casMailbox.EwsEnabled ? { 'EWS': 'warning' } : casMailbox.EwsEnabled === null ? null : { 'EWS': 'info' };
    const Mobile = casMailbox.OutlookMobileEnabled ? { 'Mobile': 'warning' } : casMailbox.OutlookMobileEnabled === null ? null : { 'Mobile': 'info' };
    const MAPI = casMailbox.MAPIEnabled === null ? null : { 'MAPI': 'info' };
    const SMTP = casMailbox.SmtpClientAuthenticationDisabled ? { 'SMTP': 'info' } : casMailbox.SmtpClientAuthenticationDisabled === null ? null : { 'SMTP': 'critical' };

    const severity = [ActiveSync, IMAP, SMTP, POP, EWS, OWA, Mobile, MAPI];
    return {
        ...casMailbox,
        Severity: severity.filter(s => s !== null),
        Dismiss: dismiss
    };
}

@Component({
    selector: 'app-remote-access',
    templateUrl: './remote-access.component.html',
    styleUrls: ['./remote-access.component.scss']
})
export class RemoteAccessComponent extends CardComponent implements OnInit, OnDestroy {

    private tenant_id = this.route.snapshot.paramMap.get('tenant');
    // private tenant$ = this.store.select(client(this.tenant_id).octiga.tenant.data).pipe(first(data => !!data), map(data => data.id))
    // private dismiss$ = this.store.select(client(this.tenant_id).octiga.dismiss.all).pipe(
    //     withLatestFrom(this.tenant$),
    //     map(([res, tenant]) => {
    //         if (!!res && res.length > 0) {
    //             return res.filter(r => r.type === 'cas' && r.tenant === tenant);
    //         } else {
    //             return []
    //         }
    //     })
    // )

    // private dismissStatus$ = this.store.select(client(this.tenant_id).octiga.dismiss.status).pipe(filter(s => !s.creating && !s.updating && s.loaded));
    // private casStatus$ = this.store.select(client(this.tenant_id).powershell.exoCasMailbox.status).pipe(filter(s => !s.creating && !s.updating && s.loaded));
    // private subscriptions: Subscription[] = [];

    // public notLoading$ = combineLatest([this.dismissStatus$, this.casStatus$]).pipe(map(items => items.every(i => !i.updating && !i.creating && i.loaded)))
    // public severity$: BehaviorSubject<{ critical: number, danger: number, warning: number }> = new BehaviorSubject({ critical: -1, danger: -1, warning: -1 });

    constructor(
        private route: ActivatedRoute,
        public router: Router,
        private store: Store<any>,
        private crypto: CryptoService

    ) {
        super();
    }

    ngOnInit(): void {
        // this.dispatcher()
        // this.subscriptions.push(
        //     combineLatest([
        //         this.store.pipe(select(client(this.tenant_id).powershell.exoCasMailbox.all)),
        //         this.dismiss$,
        //         this.notLoading$])
        //         .subscribe(([casMailboxes, dismiss, notLoading]) => {
        //             if (notLoading === true) {
        //                 let dismiss_ids = dismiss.map(d => d.id);
        //                 let set_cas_severity: CasMailbox[] = [];
        //                 casMailboxes = casMailboxes.filter(cas => casMailboxFilterPredicate(cas));

        //                 for (let i = 0; i < casMailboxes.length; i++) {
        //                     let element: CasMailbox;
        //                     let cas_dismiss: { [key: string]: boolean } = {
        //                         'ActiveSync': dismiss_ids.includes(this.crypto.hash(casMailboxes[i].ExternalDirectoryObjectId + '_ActiveSync')),
        //                         'IMAP': dismiss_ids.includes(this.crypto.hash(casMailboxes[i].ExternalDirectoryObjectId + '_IMAP')),
        //                         'POP': dismiss_ids.includes(this.crypto.hash(casMailboxes[i].ExternalDirectoryObjectId + '_POP')),
        //                         'EWS': dismiss_ids.includes(this.crypto.hash(casMailboxes[i].ExternalDirectoryObjectId + '_EWS')),
        //                         'OWA': dismiss_ids.includes(this.crypto.hash(casMailboxes[i].ExternalDirectoryObjectId + '_OWA')),
        //                         'Mobile': dismiss_ids.includes(this.crypto.hash(casMailboxes[i].ExternalDirectoryObjectId + '_Mobile')),
        //                         'MAPI': dismiss_ids.includes(this.crypto.hash(casMailboxes[i].ExternalDirectoryObjectId + '_MAPI')),
        //                         'SMTP': dismiss_ids.includes(this.crypto.hash(casMailboxes[i].ExternalDirectoryObjectId + '_SMTP'))
        //                     };
        //                     element = setCAS(casMailboxes[i], cas_dismiss);
        //                     set_cas_severity.push(element);
        //                 }

        //                 let cas_severities = { critical: 0, danger: 0, warning: 0 };

        //                 for (let s = 0; s < set_cas_severity.length; s++) {
        //                     let cas_severity = set_cas_severity[s].Severity;
        //                     let cas_severity_arr = Object.values(cas_severity);
        //                     let cas_dismiss = set_cas_severity[s].Dismiss;

        //                     for (let j = 0; j < cas_severity_arr.length; j++) {
        //                         if (cas_dismiss[Object.keys(cas_severity_arr[j])[0]] === false) {
        //                             switch (Object.values(cas_severity_arr[j])[0]) {
        //                                 case 'critical': cas_severities.critical++; break;
        //                                 case 'danger': cas_severities.danger++; break;
        //                                 case 'warning': cas_severities.warning++; break;
        //                                 default: break;
        //                             }
        //                         }
        //                     }

        //                     this.severity$.next(cas_severities);
        //                 }
        //             }
        //         })
        // )

    }

    dispatcher() {
        // this.store.dispatch(fetchCasMailboxes({ _tenant: this.tenant_id }))
        // this.store.dispatch(loadDismissItems({ _tenant: this.tenant_id }))
    }

    ngOnDestroy() {
        // for (const s of this.subscriptions) {
        //     s.unsubscribe();
        // }
    }

    navigate() {
        this.router.navigate([`client/${this.tenant_id}/dashboard/remote-access`]);
        // this.notLoading$.pipe(first()).subscribe(notLoading => { if (notLoading) this.router.navigate([`client/${this.tenant_id}/dashboard/remote-access`]) });
    }

}

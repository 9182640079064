import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';
import { Subscription } from './model';

@Injectable()
export class SubscriptionsStatusEffects {

    loadMgmtSubscriptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMgmtSubscriptions),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map(subs => {
                        return actions.loadMgmtSubscriptionsSuccess({ _tenant, data: subs });
                    }),
                    catchError((error) => of(actions.loadMgmtSubscriptionsFailure({ _tenant, error })))
                )
            )
        )
    );

    private get(tenant: string): Observable<Subscription[] | string> {
        return this.ajax.get(tenant, '/api/fabric/status/client/mgmt-subscriptions');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}

<div class="card-element" id="payment-form" #stripe_el>

    <form>

        <div class="row">
            <div class="field custom">
                <div id="card-element-card-number" class="input empty"></div>
                <label for="card-element-card-number" data-tid="elements_examples.form.card_number_label">Card Number</label>
                <div class="baseline"></div>
            </div>
        </div>

        <div class="row">
            <div class="field half-width">
                <div id="card-element-card-expiry" class="input empty"></div>
                <label for="card-element-card-expiry" data-tid="elements_examples.form.card_expiry_label">Expiration</label>
                <div class="baseline"></div>
            </div>
            <div class="field half-width">
                <div id="card-element-card-cvc" class="input empty"></div>
                <label for="card-element-card-cvc" data-tid="elements_examples.form.card_cvc_label">CVC</label>
                <div class="baseline"></div>
            </div>
        </div>

    </form>

</div>

import { createAction, props } from '@ngrx/store';

export const loadMspCwmStatuses = createAction(
    '[Msp/CWM] Load Msp Cwm Statuses',
    props<{ board_id: number }>()
);

export const loadMspCwmStatusesSuccess = createAction(
    '[Msp/CWM] Load Msp Cwm Statuses Success',
    props<{ data: any, board_id: number }>()
);

export const loadMspCwmStatusesFailure = createAction(
    '[Msp/CWM] Load Msp Cwm Statuses Failure',
    props<{ error: any }>()
);

import { createAction, props } from '@ngrx/store';

export const loadMspCWMConnection = createAction(
    '[Msp/CWM] Load Msp CWM Connection',
);

export const loadMspCWMConnectionSuccess = createAction(
    '[Msp/CWM] Load Msp CWM Connection Success',
    props<{ data: any }>()
);

export const loadMspCWMConnectionFailure = createAction(
    '[Msp/CWM] Load Msp CWM Connection Failure',
    props<{ error: any }>()
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, expand, map, mergeMap, reduce } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import { updateMsp } from '../../../msp/actions';
import * as actions from './actions';

@Injectable()
export class CwmSeveritiesEffects {
    loadMspCWMConfigSeverities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMspCwmSeverities),
            mergeMap(() => this.getSeverities()
                .pipe(
                    map(data => actions.loadMspCwmSeveritiesSuccess({ data })),
                    catchError((error) => {
                        this.store.dispatch(updateMsp({ data: { cwm: false } }));
                        return of(actions.loadMspCwmSeveritiesFailure({ error }));
                    })
                )
            )
        )
    );

    getSeverities() {
        return this.mspAjax.get('/api/octiga/v2/cwm/service/severities')
            .pipe(
                expand((res) => {
                    if (res.nextPageUrl) {
                        return this.mspAjax.get('/api/octiga/v2/cwm' + res.nextPageUrl.split('/v4_6_release/apis/3.0')[1]);
                    }
                    return EMPTY;
                }),
                reduce((acc, res: any) => acc.concat(res.data), [])
            );
    }

    constructor(
        private actions$: Actions,
        private mspAjax: MspAjaxService,
        private store: Store<any>
    ) { }

}

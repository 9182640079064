import { FormControl } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SharePointDetails as SharePointSiteDetails } from 'src/app/stores/client/graph/spo/sharepoint-site/model';
import { BaseComponent } from '../../base/base.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';


interface ExtendedSharePointSiteDetails extends SharePointSiteDetails {
    isIgnored: boolean
    control: FormControl
    hasThreshold: boolean
}

let schema;


@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss']
})

export class BaselineComponent extends BaseComponent implements OnInit, AfterViewInit {
    @Input() data: Array<SharePointSiteDetails>;
    @ViewChild(MatSort) sort: MatSort;

    public dataSource: MatTableDataSource<ExtendedSharePointSiteDetails> = new MatTableDataSource();
    public columns: string[] = ['siteUrl', 'storageAllocatedInMGBytes', 'storageUsedInMGBytes', 'storageUsedPercentage', 'threshold', 'status'];


    ngOnInit(): void {
        schema = JSON.parse(JSON.stringify(this.baseline.schema));

        this.setControllers();
        const value = this.getGeneralThresholdValue();

        this.control.setValue(value || 0);

        this.control.valueChanges.subscribe(value => {
            if(value < 0){
                this.control.setValue(0);
            }else if (value > 100){
                this.control.setValue(100);
            }

            this.setGeneralChecker(value);

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema))
            });
        });


    }

    ngAfterViewInit(): void {
        if(this.readOnly){
            this.form.disable();
            this.control.disable();
        }

        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item, prop) => {
            switch (prop) {
            case 'threshold': return item.control?.value;
            case 'status': return item.hasThreshold ? item.control.value < item.storageUsedPercentage : this.control.value < item.storageUsedPercentage;
           
            default: return item[prop];
            }
        };

        this.dataSource.sort.direction = 'desc';
    }

    private getGeneralChecker() {
        const allCheckers = schema.items.allOf;
        return allCheckers.find(res => !res?.if?.properties?.siteUrl?.const);
    }

    private getGeneralThresholdValue() {
        return this.getGeneralChecker().then?.properties?.storageUsedPercentage.maximum;
    }

    private setGeneralChecker(value) {
        this.getGeneralChecker().then.properties.storageUsedPercentage.maximum = value;
    }

    private getSpecificThresholdValue(siteUrl: string){
        const checker = this.getSpecificChecker(siteUrl);

        if(checker){
            return checker.then.properties.storageUsedPercentage.maximum;
        }else{
            return; 
        }
    }

    private getSpecificChecker(siteUrl: string) {
        const allCheckers = schema.items.allOf;
        return allCheckers.find(res => res.if.properties.siteUrl.const === siteUrl);
    }

    removeSpecificChecker(siteUrl){
        const allCheckers : Array <any> = schema.items.allOf;
        const index = allCheckers.findIndex(res => res.if?.properties?.siteUrl?.const === siteUrl);
        
        if(index >= 0){
            allCheckers.splice(index, 1);
        }
    }


    private setSpecificChecker(value: number, siteUrl: string) {
        const specificChecker = this.getSpecificChecker(siteUrl);
        
        if (specificChecker) {
            specificChecker.then.properties.storageUsedPercentage.maximum = value;
        } else {
            schema.items.allOf.push(
                {
                    'type': 'object',
                    'if': { 'properties': { 'siteUrl': { 'const': siteUrl } } },
                    'then': {
                        'properties': {
                            'storageUsedPercentage': {
                                'type': 'number',
                                'maximum': value
                            }
                        }
                    }
                },
            );
        }
        
        this.baselineChange.next({
            ...this.baseline,
            schema: JSON.parse(JSON.stringify(schema))
        });
    }


    setIgnoredSites() {
        const ignoredSites = this.dataSource.data.filter(res => res.isIgnored || res.hasThreshold).map(res => res.siteUrl);

        if (ignoredSites.length > 0) {
            this.getGeneralChecker().if.properties.siteUrl.not.enum = ignoredSites;

        } else {
            this.getGeneralChecker().if.properties.siteUrl.not.enum = ['---'];
        }


        this.baselineChange.next({
            ...this.baseline,
            schema: JSON.parse(JSON.stringify(schema))
        });

    }

    setControllers() {
        const excludedItemUrls: string[] = this.getGeneralChecker().if.properties?.siteUrl?.not?.enum || [];

        this.dataSource.data = this.data.map(site => {
            const hasThreshold = !!this.getSpecificChecker(site.siteUrl);
            const isIgnored = hasThreshold ? false : excludedItemUrls.some(url => site.siteUrl === url);
            
            const control = !hasThreshold ?
                new FormControl(this.getGeneralThresholdValue()) : 
                new FormControl(this.getSpecificThresholdValue(site.siteUrl));                
                
            if(this.readOnly){
                control.disable();
            }


            return {
                ...site,
                isIgnored,
                control,
                hasThreshold
            };
        });

        this.dataSource.data.forEach(site => {
            site.control.valueChanges.subscribe(value => {
                if(value < 0){
                    site.control.setValue(0);
                }else if (value > 100){
                    site.control.setValue(100);
                }
                
                if(site.hasThreshold){
                    this.setSpecificChecker(value, site.siteUrl);
                    this.setIgnoredSites();
                }else{
                    this.removeSpecificChecker(site.siteUrl);

                    this.setIgnoredSites();
                }
            });
        });
    }

    changeThresholdType(site: ExtendedSharePointSiteDetails, value){
        site.control.setValue(this.control.value);
    }

    filter(text){
        this.dataSource.filter = text.trim().toLowerCase();
    }
}

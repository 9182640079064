import { updateSpoTenant } from 'src/app/stores/client/powershell/spo/tenant/actions';
import { SpoTenant } from 'src/app/stores/client/powershell/spo/tenant/model';

export function Remediate(_tenant: string, data: Partial<SpoTenant>, schema: any) {

    const RequireAnonymousLinksExpireInDays = schema.properties.RequireAnonymousLinksExpireInDays.const || 0;

    return updateSpoTenant({ _tenant, data: { RequireAnonymousLinksExpireInDays } });
    
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, map, distinct } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as SpecActions from './actions';
import { SwaySpec } from './model';

@Injectable()
export class SpecEffects {

    loadSpec$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SpecActions.loadSpecs),
            distinct((action) => action.type),
            mergeMap(() => this.getSpec()
                .pipe(
                    map((data: SwaySpec[]) => SpecActions.loadSpecsSuccess({ data })),
                    catchError((error) => of(SpecActions.loadSpecsFailure({ error })))
                )
            )
        )
    );

    getSpec(): Observable<SwaySpec[]> {
        return this.ajax.get('/api/sway/spec?include=categories');
    }

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService
    ) { }
}

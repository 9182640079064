<ng-template #snackBar>
    <div [class.success]="success"  class="container">
        <div class="message">

            <p [class.error-title]="error"> 
                 <mat-icon *ngIf="success">sentiment_very_satisfied</mat-icon>
                 <mat-icon *ngIf="error">mood_bad</mat-icon>
          
                {{ msg }}
            </p>

            <p [class.error]="error">{{ msg2 }}</p>
        </div>

        <button mat-button type="button" (click)="dismiss()">	<mat-icon>cancel</mat-icon></button>
    </div>
</ng-template>

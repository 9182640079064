import { reloadGraphUsers } from './../../../stores/client/graph/user/user.actions';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import { reloadGraphRoles } from 'src/app/stores/client/octiga/status/roles/actions';
import { DeviationService } from '../../sway/services/deviation.service';
import { selectMspData } from '../store';
import { loadMspSuccess } from '../store/msp/actions';
import { Msp } from '../store/msp/model';
import { reloadSwayGroups } from 'src/app/stores/client/sway/group/actions';
import { loadSession } from 'src/app/stores/root/octiga/session/actions';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {

    private close$ = new Subject();

    constructor(
        private store: Store<any>,
        private ajax: MspAjaxService,
        private deviationService: DeviationService
    ) { }

    public init(userId:string) {
        this.store.pipe(
            select(selectMspData),
            filter(msp => !!msp && !!msp.id),
            filter(msp => msp.tenants.filter(t => t.status == 'REGISTERING').length > 0),
            switchMap(old_msp => timer(15000, 45000)
                .pipe(
                    takeUntil(this.close$),
                    switchMap(_ => this.ajax.get<Msp>(`/api/octiga/v2/msp?userId=${userId}`).pipe(
                        filter(new_msp => {
                            const new_count = new_msp.tenants.filter(t => t.status == 'REGISTERING').length;
                            const old_count = old_msp.tenants.filter(t => t.status == 'REGISTERING').length;
                            return old_count !== new_count;
                        }),
                        map(new_msp => ({ new_msp, old_msp, tenants: old_msp.tenants.filter(t => t.status == 'REGISTERING').map(t => t.id) }))
                    ))
                )
            )
        )
            .subscribe(({ new_msp, old_msp, tenants }) => {
                for (const _tenant of tenants) {
                    this.store.dispatch(reloadGraphRoles({ _tenant }));
                    this.store.dispatch(reloadGraphUsers({ _tenant }));
                    this.store.dispatch(reloadSwayGroups({ _tenant }));
                    this.deviationService.init(_tenant);
                }

                this.close$.next(null);
                this.store.dispatch(loadMspSuccess({ data: new_msp }));

                this.store.dispatch(loadSession());
            });
    }
}

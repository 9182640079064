import { Injectable } from '@angular/core';
import { DirectoryObject, User } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, combineLatest, concatMap, delay, distinct, filter, map, mergeMap, Observable, of, switchMap, take, zip } from 'rxjs';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { client } from '../../..';
import { loadDirectoryRolesSuccess } from '../actions';
import * as actions from './actions';
import { DirectoryRoleMembersModel } from './model';

interface Response {
    id: string,
    status: number,
    headers: {
        [key: string]: string
    },
    body: {
        value: (DirectoryObject | User) []
     }
}

interface BatchResponse {
    responses: Response[]
}

@Injectable()
export class DirectoryRoleMemberEffects {

    private postBatchRequest(tenant: string, body: any): Observable<BatchResponse> {
        return this.ajax.post<BatchResponse>(tenant, '/api/microsoft/graph/$batch', body);
    }

    private loadMembersForRoles(tenant: string, ids: string[]): Observable<DirectoryRoleMembersModel[]> {
        const requests = ids.map(id => ({
            method: 'GET',
            id,
            url: `directoryRoles/roleTemplateId=${id}/members`
        }));

        const request_batches = []; // batching batches! $batch is limited to 20 requests per batch

        let count = 0;
        do {
            const start = count * 20;
            const end = start + 20;
            request_batches.push(requests.slice(start, end));
        } while ((++count * 20) < requests.length);

        return combineLatest(request_batches.map(requests => this.postBatchRequest(tenant, { requests })))
            .pipe(
                take(1),
                map(items => items.flat(1)),
                switchMap(batches => {

                    const results: DirectoryRoleMembersModel[] = [];
                    const retry_ids: string[] = [];
                    const retry_delays: number[] = [];

                    for (const batch of batches) {

                        for (const response of batch.responses) {

                            if (response.status === 200) {
                                if (response.body['@odata.nextLink']) {
                                    console.warn('unhandled @odata.nextLink:', response.body['@odata.nextLink']);
                                }
                                results.push({
                                    roleTemplateId: response.id,
                                    members: response.body.value || [] // should be an array, probs not necessary
                                });
                                continue;
                            }

                            if (response.status === 429) { // too many requests
                                retry_ids.push(response.id);
                                retry_delays.push(parseInt(response.headers['Retry-After']) || 0);
                                continue;
                            }

                            console.error(response);
                        }
                    }

                    if (retry_ids.length === 0) {
                        // no throttled requests
                        return of(results);
                    } else {
                        // recur this function to retry throttled requests
                        const delay_ms = Math.max(...retry_delays) * 1000;
                        return of(null)
                            .pipe(
                                delay(delay_ms),
                                switchMap(() => zip( // zip results from this invocation and next
                                    of(results),
                                    this.loadMembersForRoles(tenant, retry_ids)
                                )
                                    .pipe(
                                        map(([a, b]) => a.concat(b))
                                    )
                                )
                            );

                    }

                })
            );
    }

    loadDirectoryRoleMembers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDirectoryRolesSuccess),
            distinct(action => action._tenant),
            switchMap(action => this.store.pipe(
                select(client(action._tenant).graph.directoryRole.members.status),
                filter(status => !status.loaded),
                map(() => action),
                take(1)
            )),
            mergeMap(({ _tenant, roles }) => this.loadMembersForRoles(_tenant, roles.map(role => role.roleTemplateId))
                .pipe(
                    mergeMap(results => results.map(item => actions.loadDirectoryRoleMembersSuccess({ _tenant, item }))),
                    catchError((error: any) => of(actions.loadDirectoryRoleMembersFailure({ _tenant, error })))
                )
            )
        )
    );

    assignRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.assignRoles),
            mergeMap(({ _tenant, user, addRoles, removeRoles }) => this.assignRoles(_tenant, user, addRoles, removeRoles)
                .pipe(
                    map((user) => actions.assignRolesSuccess({ _tenant, addRoles, removeRoles, user: user })),
                    catchError((error) => of(actions.assignRolesFailure({ _tenant, error }))),
                )
            )
        )
    );

    assignRoles(_tenant: string, user: User, addRoles: string[], removeRoles: string[]) : Observable<any> {
        return combineLatest( [...addRoles.map(role=> this.assignRole(_tenant, role, user.id)), ...removeRoles.map(role=> this.removeMember(_tenant, role, user.id))] )
            .pipe(switchMap(()=> this.ajax.get(_tenant, `/api/microsoft/graph/users/${user.id}`) )); 

    }

    assignRole(_tenant: string, roleTemplateId: string, userId: string) {
        const directoryObject = {
            '@odata.id': `https://graph.microsoft.com/beta/users/${userId}`
        };

        return this.ajax.post(_tenant, `/api/microsoft/graph/directoryRoles/roleTemplateId=${roleTemplateId}/members/$ref`, directoryObject);
    }


    removeDirectoryRoleMembers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.removeDirectoryRoleMember),
            concatMap(({ _tenant, roleTemplateId, memberId }) => this.removeMember(_tenant, roleTemplateId, memberId)
                .pipe(
                    map(() => actions.removeDirectoryRoleMemberSuccess({ _tenant, roleTemplateId, memberId })),
                    catchError((error: any) => of(actions.removeDirectoryRoleMemberFailure({ _tenant, error })))
                )
            )
        )
    );

    private removeMember(_tenant: string, roleTemplateId: string, memberId: string) {
        const url = `/api/microsoft/graph/directoryRoles/roleTemplateId=${roleTemplateId}/members/${memberId}/$ref`;
        return this.ajax.delete(_tenant, url);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store
    ) { }
}

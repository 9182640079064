import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of, catchError, concatMap, last, map, mergeMap, switchMap, filter, distinct, take } from 'rxjs';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { ChangesService } from 'src/app/services/changes.service';
import { client } from '../../..';
import { storeChangesToDB } from '../../../octiga/changes/actions';
import * as MailboxActions from './actions';
import { Mailbox } from './model';

const filterDiscoverySearchMailbox = map((mboxes: Array<Mailbox>) => mboxes.filter(m => !m.Name.startsWith('DiscoverySearchMailbox')));

@Injectable()
export class MailboxEffects {

    loadMailboxes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.fetchMailboxes),
            distinct(action => action._tenant),
            switchMap(action => this.store.pipe(
                select(client(action._tenant).powershell.exoMailbox.status),
                filter(status => !status.loaded),
                map(() => action),
                take(1)
            )),
            mergeMap((action) => this.getPSMailboxes(action._tenant)
                .pipe(
                    retry(3000, 3, 'exo timeout'),
                    last(),
                    map((mailboxes: Mailbox[]) => {
                        /* trim stmp:*/
                        mailboxes = mailboxes.map(m => {
                            return { ...m, ForwardingSmtpAddress: m.ForwardingSmtpAddress !== null ? m.ForwardingSmtpAddress.substring(5) : null };
                        });
                        return MailboxActions.loadMailboxesSuccess({ _tenant: action._tenant, mailboxes });
                    }),
                    catchError((error) => of(MailboxActions.loadMailboxsFailure(error)))
                )
            )
        )
    );











    updateMailboxFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.updateMailboxFields),
            mergeMap((action) => {
                const fields = Object.entries(action.data);
                const options = {};
                for (let i = 0; i < fields.length; i++) {
                    options[fields[i][0]] = fields[i][1];
                }
                return this.putPSMailbox(action._tenant, { Identity: action.Identity, options })
                    .pipe(
                        map((_) => MailboxActions.updateMailboxFieldsSuccess({
                            _tenant: action._tenant,
                            Identity: action.Identity,
                            data: action.data
                        })),
                        catchError((error) => of(MailboxActions.updateMailboxFieldsFailure({ ...action, error: { message: error.message, trace: error.trace } })))
                    );
            })
        )
    );



    updateLitigationHold$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.updateMailboxLitigationHold),
            mergeMap((action) => {
                const fields = Object.entries(action.data);
                const options = {};
                for (let i = 0; i < fields.length; i++) {
                    options[fields[i][0]] = fields[i][1];
                }
                return this.putPSMailbox(action._tenant, { Identity: action.Identity, options })
                    .pipe(
                        map((_) => MailboxActions.updateMailboxLitigationHoldSuccess({
                            _tenant: action._tenant,
                            Identity: action.Identity,
                            data: action.data
                        })),
                        catchError((error) => of(MailboxActions.updateMailboxLitigationHoldFailure({ ...action, error: { message: error.message, trace: error.trace } })))
                    );
            })
        )
    );

    deleteOWARule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.deleteOWARule),
            mergeMap(action => this.ajax.delete(action._tenant,
                `/api/microsoft/powershell/exo/mailbox/${action.identity}/owaforward`)
                .pipe(
                    concatMap(() => {
                        const params = { user: action.identity, fields: action.fields };
                        const item = this.changesService.formatChangesObjectToDB(params, action.changeType);
                        return [storeChangesToDB({ _tenant: action._tenant, item }),
                            MailboxActions.updateMailbox({
                                _tenant: action._tenant,
                                mailbox: {
                                    id: action.identity,
                                    changes: {
                                        DeliverToMailboxAndForward: false,
                                        ForwardingAddress: null,
                                        ForwardingSmtpAddress: null
                                    }
                                }
                            }),
                            MailboxActions.deleteOWARuleSuccess(action)
                        ];
                    }),
                    catchError((error) => of(MailboxActions.deleteOWARuleFailure({ _tenant: action._tenant, error })))
                )
            ))
    );

    /* Removes the mailbox's Exchange attributes from Active Directory.
     The mailbox isn't deleted and can be reconnected to its user at a later date by using the Connect-Mailbox */
    disableMailbox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.disableMailbox),
            mergeMap((action) => this.disableMailbox(action._tenant, action.upn)
                .pipe(
                    concatMap(() => {
                        const params = { user: action.upn, fields: { operation: 'disable' } };
                        const item = this.changesService.formatChangesObjectToDB(params, 'mailbox');
                        return [
                            storeChangesToDB({ _tenant: action._tenant, item }),
                            MailboxActions.disableMailboxSuccess({ _tenant: action._tenant, upn: action.upn })
                        ];
                    }),
                    catchError((error) => {
                        return of(MailboxActions.disableMailboxFailure({ _tenant: action._tenant, error }));
                    }

                    )
                )
            ))
    );

    updateMailboxSharingPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.updateMailboxSharingPolicy),
            mergeMap((action) => this.updateMailboxSharingPolicy(action._tenant, { Identity: action.Identity, SharingPolicy: action.SharingPolicy })
                .pipe(
                    map(() => MailboxActions.updateMailboxSharingPolicySuccess({ _tenant: action._tenant, Identity: action.Identity, SharingPolicy: action.SharingPolicy })),
                    catchError((error) => of(MailboxActions.updateMailboxSharingPolicyFailure({ _tenant: action._tenant, error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private changesService: ChangesService,
        private store: Store<any>
    ) { }

    getPSMailboxes(tenant: string): Observable<Array<Mailbox>> {
        let url = '/api/microsoft/powershell/exo/mailbox';
        url += '?SelectObject=Name,Identity,UserPrincipalName,WindowsEmailAddress,SharingPolicy,DeliverToMailboxAndForward,ForwardingSmtpAddress,ForwardingAddress,RecipientTypeDetails,GrantSendOnBehalfTo,LitigationHoldEnabled,LitigationHoldDuration';
        return this.ajax.get(tenant, url).pipe(
            filterDiscoverySearchMailbox
        );
    }

    putPSMailbox(tenant: string, options: { [key: string]: any }) {
        const body = { ...options.options, Identity: options.Identity, };
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/mailbox', body);
    }

    disableMailbox(tenant: string, upn: string) {
        const url = `/api/microsoft/powershell/exo/disable-mailbox/${upn}`;
        return this.ajax.put(tenant, url);
    }

    updateMailboxSharingPolicy(tenant: string, options) {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/mailbox', options);
    }
}

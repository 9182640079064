<div *ngIf="isLoaded" class="mat-typography">
    <form [formGroup]="form">

        <table>
            <tr>
                <th>
                    Field
                </th>

                <th>
                    Current Value
                </th>

                <th>
                    Desired Value
                </th>

                <th>
                    Status
                </th>
            </tr>

            <tr>
                <td>
                    Identity
                </td>

                <td colspan="3">
                    <!-- {{ policy?.Name}} -->

                    Automatic Forwarding - Block Test
                </td>

            </tr>



            <tr>
                <td class="flex-data">
                    <div>
                        State
                    </div>
                </td>

                <td>
                    <ng-container *ngIf="policy">
                        {{policy?.State }}
                    </ng-container>

                    <ng-container *ngIf="!policy">
                        No Policy
                    </ng-container>
                    

                </td>

                <td>

                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label>State</mat-label>
                            <mat-select formControlName="State">
                                <mat-option [value]="'Enabled'">Enable</mat-option>
                                <!-- <mat-option [value]="'Disabled'">Disable</mat-option>  -->
                                <mat-option value="delete">No Policy (Delete)</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>


                <td>

                    <sway-baseline-status *ngIf="formValue.State !== 'delete' " [hasError]="formValue?.State !== policy?.State"></sway-baseline-status>
                    <sway-baseline-status *ngIf="formValue.State == 'delete' " [hasError]="!!policy"></sway-baseline-status>
                </td>
            </tr>


            
            <tr *ngIf="formValue.State !== 'delete' ">
                <td class="flex-data">
                    <div>
                        Message Type Matches
                        <mat-icon matTooltip="Specifies a condition that looks for messages of the specified type."
                            class="suffix-icon" matSuffix>info</mat-icon>
                    </div>
                </td>

                <td>
                    {{ policy?.MessageTypeMatches }}
                </td>

                <td>
                    AutoForward
                </td>

                <td>
                    <sway-baseline-status [hasError]="'AutoForward' !== policy?.MessageTypeMatches">
                    </sway-baseline-status>
                </td>
            </tr>





            <ng-container *ngIf="formValue.State !== 'delete' ">
                <tr>
                    <td class="flex-data">
                        <div>
                            From Scope
                            <mat-icon matTooltip="Specifies a condition that looks for the location of message senders"
                                class="suffix-icon" matSuffix>info</mat-icon>
                        </div>
                    </td>

                    <td>
                        {{policy?.FromScope }}

                    </td>

                    <td>
                        <ng-select  [clearable]="false" [appendTo]="'body'" formControlName="FromScope" placeholder="From Scope:">
                            <ng-option *ngFor="let scope of scopes"  [value]="scope.value">{{scope.text}}</ng-option>
                        </ng-select>
                    </td>

                    <td>
                        <sway-baseline-status [hasError]="formValue.FromScope !== policy?.FromScope">
                        </sway-baseline-status>
                    </td>
                </tr>

                <tr>
                    <td class="flex-data">
                        <div>
                            Sent To Scope
                            <mat-icon matTooltip="Specifies a condition that looks for the location of recipients. "
                                class="suffix-icon" matSuffix>info</mat-icon>
                        </div>
                    </td>

                    <td>
                        {{policy?.SentToScope }}

                    </td>

                    <td>
                        <ng-select [appendTo]="'body'" [clearable]="false" formControlName="SentToScope" placeholder="Sent To Scope:">
                            <ng-option *ngFor="let scope of scopes" [value]="scope.value">{{scope.text}}</ng-option>
                        </ng-select>
                    </td>

                    <td>
                        <sway-baseline-status [hasError]="formValue.SentToScope !== policy?.SentToScope">
                        </sway-baseline-status>
                    </td>
                </tr>


                <tr>
                    <td class="flex-data">
                        <div>

                            Reject Message Enhanced Status Code
                            <mat-icon
                                matTooltip="Specifies the enhanced status code that's used when the rule rejects messages. Valid values are 5.7.1 or between 5.7.900 and 5.7.999."
                                class="suffix-icon" matSuffix>info</mat-icon>
                        </div>
                    </td>

                    <td>
                        {{policy?.RejectMessageEnhancedStatusCode }}
                    </td>

                    <td>
                        <mat-label class="status-label">Reject Message Enhanced Status Code</mat-label>

                        <div class="status-form-wrapper field boolean">

                            <span class="status-number">5</span>
                            <span class="status-code">.</span>
                            <span class="status-number">7</span>
                            <span class="status-code">. </span>

                            <mat-form-field class="status-form-item" appearance="fill">
                                <input min="1" max="999" formControlName="RejectMessageEnhancedStatusCode" type="number"
                                    matInput>
                            </mat-form-field>

                        </div>

                        <mat-error *ngIf="form.get('RejectMessageEnhancedStatusCode').invalid">
                            Valid values are 5.7.1 or between 5.7.900 and 5.7.999.
                        </mat-error>
                    </td>

                    <td>
                        <sway-baseline-status
                            [hasError]="RejectMessageEnhancedStatusCodeMismatch">
                        </sway-baseline-status>
                    </td>
                </tr>

                <tr>
                    <td class="flex-data">
                        <div>
                            Reject Message Reason Text
                            <mat-icon
                                matTooltip="Specifies the explanation text that's used when the rule rejects messages. The maximum number of characters is 1024."
                                class="suffix-icon" matSuffix>info</mat-icon>
                        </div>

                    </td>

                    <td>
                        {{policy?.RejectMessageReasonText }}
                    </td>

                    <td>
                        <div class=" field boolean">
                            <mat-form-field appearance="fill">
                                <mat-label>Reject Message Reason Text</mat-label>

                                <textarea formControlName="RejectMessageReasonText" matInput></textarea>
                            </mat-form-field>

                            <mat-error *ngIf="form.get('RejectMessageReasonText').errors?.required">
                                Reject Message Reason Text is Required.
                            </mat-error>
                        </div>
                    </td>

                    <td>

                        <sway-baseline-status
                            [hasError]="formValue.RejectMessageReasonText !== policy?.RejectMessageReasonText">
                        </sway-baseline-status>
                    </td>
                </tr>





                <tr>
                    <td class="flex-data">
                        <div>
                            Except If Sent To
                            <mat-icon matTooltip="Specifies an exception that looks for recipients in messages."
                                class="suffix-icon" matSuffix>info</mat-icon>
                        </div>

                    </td>

                    <td>
                        <div *ngIf="policy?.ExceptIfSentTo?.length > 0" class="">
                            <div *ngFor="let item of policy.ExceptIfSentTo" class="">
                                {{item}}
                            </div>
                        </div>
                    </td>

                    <td>

                        <div>

                            <sway-filtered-list *ngIf="users$ | async as users" [options]="users"
                                [items]="formValue.ExceptIfSentTo" (update)="updateField( 'ExceptIfSentTo', $event)"
                                [placeholder]="'Except If Send To'">
                            </sway-filtered-list>

                            <mat-error *ngIf="ExceptIfSentToError?.length > 0">
                                <ul>
                                    Invalid Input, you have to set a valid emails for the following users:
                                    <li *ngFor="let item of ExceptIfSentToError">
                                        {{item}}
                                    </li>
                                </ul>
                            </mat-error>
                        </div>
                    </td>

                    <td>

                        <sway-baseline-status [hasError]="ExceptIfSentToMismatch">
                        </sway-baseline-status>
                    </td>
                </tr>

                <tr>
                    <td class="flex-data">
                        <div>

                            Except If Recipient Domain Is
                            <mat-icon
                                matTooltip="Specifies an exception that looks for recipients with email addresses in the specified domains. You can specify multiple domains separated by commas."
                                class="suffix-icon" matSuffix>info</mat-icon>
                        </div>
                    </td>

       
                    <td>
                        <div *ngIf="policy?.ExceptIfRecipientDomainIs?.length > 0" class="">
                            <div *ngFor="let item of policy.ExceptIfRecipientDomainIs" class="">
                                {{item}}
                            </div>
                        </div>
                    </td>


                    <td>


                        <sway-filtered-list [addTagText]="'Add Domain'"  [options]="[]"
                        [items]="formValue.ExceptIfRecipientDomainIs" (update)="updateField( 'ExceptIfRecipientDomainIs', $event)"
                        [placeholder]="'Except If Recipient Domain Is'">
                    </sway-filtered-list>
                    </td>

                    <td>
                        <sway-baseline-status
                            [hasError]="ExceptIfRecipientDomainIsMismatch">
                        </sway-baseline-status>
                    </td>
                </tr>

                <tr>
                    <td class="flex-data">
                        <div>
                            Generate Incident Report
                            <mat-icon
                                matTooltip="Specifies where to send the incident report that's defined by the IncidentReportContent parameter."
                                class="suffix-icon" matSuffix>info</mat-icon>
                        </div>

                    </td>

                    <td>
                        {{policy?.GenerateIncidentReport }}
                    </td>

                    <td>
                        <div class="field boolean">
                            <mat-form-field appearance="fill">
                                <mat-label>Generate Incident Report</mat-label>
                                <input formControlName="GenerateIncidentReport" matInput />
                            </mat-form-field>

                            <mat-error *ngIf="form.get('GenerateIncidentReport').errors?.email">
                                Invalid Email Address.
                            </mat-error>
                        </div>

                    </td>

                    <td>

                        <sway-baseline-status
                            [hasError]="formValue.GenerateIncidentReport !== policy?.GenerateIncidentReport">
                        </sway-baseline-status>
                    </td>
                </tr>

                <tr>
                    <td class="flex-data">
                        <div>
                            Priority
                            <mat-icon
                                matTooltip="Specifies a priority value for the rule that determines the order of rule processing. A lower integer value indicates a higher priority, the value 0 is the highest priority, and rules can't have the same priority value."
                                class="suffix-icon" matSuffix>info</mat-icon>



                        </div>
                    </td>

                    <td>
                        {{policy?.Priority }}
                    </td>

                    <td>
                        <div class="field boolean">
                            <mat-form-field appearance="fill">
                                <mat-label>Priority</mat-label>
                                <input formControlName="Priority" matInput type="number" />

                                <mat-error *ngIf="form.get('Priority').errors?.max">
                                    Valid priority values for the existing {{data?.length}} rules are from 0 through
                                    {{data?.length - 1}}.


                                    Valid priority values for the existing {{data?.length}} rules are from 0 through
                                    {{data?.length - 1}}.

                                    <!-- Valid priority values for a new rule (the 9th rule) are from 0 through 8.
                                The default value for a new rule (the 9th rule) is 8. -->
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </td>

                    <td>
                        <sway-baseline-status [hasError]="formValue.Priority != policy?.Priority">
                        </sway-baseline-status>
                    </td>
                </tr>
            </ng-container>

        </table>
    </form>
</div>
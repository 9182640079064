import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
export const featureKey = 'mspCwmTypes';

interface TypesByBoard {
    data: any
    board_id: number
}
export interface State extends EntityState<TypesByBoard>, Status {
}

export const adapter: EntityAdapter<TypesByBoard> = createEntityAdapter<TypesByBoard>({
    selectId: (item) => item.board_id
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.loadMspCwmTypesSuccess, (state, { data, board_id }) => {
        state = { ...state, loaded: true };
        return adapter.upsertOne({ data, board_id }, state);
    }),
    on(actions.loadMspCwmTypesFailure, (state, { error }) => ({ ...state, error })),

);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

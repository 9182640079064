import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DashboardOutletDirective } from '../../directives/dashboard-outlet.directive';
import { CardComponent } from '../cards/card.component';
import { dashboardCards } from '../cards/cards';

export interface DashItem {
    title: string
    id: string
    component: CardComponent
    cols: number
    rows: number
}

interface CardsLayout {
    columns: number
    items: DashItem[]
}

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
    @ViewChildren(DashboardOutletDirective) dashboardOutlet: QueryList<DashboardOutletDirective>;

    public cardsLayout$: BehaviorSubject<CardsLayout> = new BehaviorSubject({ columns: 6, items: default_dashboard });
    public showOverlay$: BehaviorSubject<boolean> = new BehaviorSubject(false); // overlay wrapper

    constructor(
        private breakpointObserver: BreakpointObserver,
        private cd: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        // this.cd.detectChanges();
        // this.loadContents();
        this.breakpointSubscription();
    }

    breakpointSubscription() {
        /** Based on the screen size, switch from standard to one column per row */
        this.breakpointObserver
            .observe([
                Breakpoints.XSmall,
                Breakpoints.Small,
                Breakpoints.HandsetPortrait,
                Breakpoints.Medium,
                Breakpoints.Large,
                Breakpoints.XLarge
            ])
            .subscribe((state: BreakpointState) => {

                if (state.breakpoints[Breakpoints.Small] === true || state.breakpoints[Breakpoints.HandsetPortrait] === true) {
                    this.cardsLayout$.next({
                        items: small_dashboard,
                        columns: 2
                    });
                }

                else if (state.breakpoints[Breakpoints.Medium] === true || state.breakpoints[Breakpoints.Large] === true) {
                    this.cardsLayout$.next({
                        items: medium_dashboard,
                        columns: 3
                    });
                }
                else {
                    this.cardsLayout$.next({
                        items: default_dashboard,
                        columns: 6
                    });
                }

                this.cd.detectChanges();
            });
    }

}

const default_dashboard: DashItem[] = [
    { title: 'Monitoring', id: '00', component: dashboardCards.monitoring, cols: 6, rows: 1 },
    { title: 'Baselines', id: '02', component: dashboardCards.sway, cols: 2, rows: 1 },
    { title: 'Baseline Categories', id: '10', component: dashboardCards.baselineCategories, cols: 4, rows: 1 },
    { title: 'Mail Flow', id: '03', component: dashboardCards.mail_flow, cols: 2, rows: 1 },
    { title: 'Accounts Risks / Management', id: '04', component: dashboardCards.risks, cols: 4, rows: 1 },
    { title: 'Remote Access', id: '05', component: dashboardCards.remote_access, cols: 1, rows: 1 },
    { title: 'App Report', id: '06', component: dashboardCards.app_report, cols: 1, rows: 1 },
    { title: 'Pwned Check', id: '08', component: dashboardCards.pwned_check, cols: 1, rows: 1 },
    { title: 'Change History', id: '09', component: dashboardCards.changes_history, cols: 1, rows: 1 }
];

const small_dashboard: DashItem[] = [
    { title: 'Monitoring', id: '00', component: dashboardCards.monitoring, cols: 2, rows: 1 },
    { title: 'Baselines', id: '02', component: dashboardCards.sway, cols: 3, rows: 3 },
    { title: 'Baseline Categories', id: '10', component: dashboardCards.baselineCategories, cols: 4, rows: 1 },
    { title: 'Accounts Risks / Management', id: '04', component: dashboardCards.risks, cols: 2, rows: 1 },
    { title: 'Remote Access', id: '03', component: dashboardCards.remote_access, cols: 1, rows: 1 },
    { title: 'Mail Flow', id: '06', component: dashboardCards.mail_flow, cols: 1, rows: 1 },
    { title: 'Pwned Check', id: '08', component: dashboardCards.pwned_check, cols: 1, rows: 1 },
    { title: 'Change History', id: '09', component: dashboardCards.changes_history, cols: 1, rows: 1 },
    { title: 'App Report', id: '05', component: dashboardCards.app_report, cols: 1, rows: 1 }
];

const medium_dashboard: DashItem[] = [
    { title: 'Monitoring', id: '00', component: dashboardCards.monitoring, cols: 3, rows: 1 },
    { title: 'Baselines', id: '02', component: dashboardCards.sway, cols: 3, rows: 1 },
    { title: 'Baseline Categories', id: '10', component: dashboardCards.baselineCategories, cols: 4, rows: 1 },
    { title: 'Accounts Risks / Management', id: '04', component: dashboardCards.risks, cols: 3, rows: 1 },
    { title: 'Remote Access', id: '03', component: dashboardCards.remote_access, cols: 1, rows: 1 },
    { title: 'Mail Flow', id: '06', component: dashboardCards.mail_flow, cols: 2, rows: 1 },
    { title: 'Pwned Check', id: '08', component: dashboardCards.pwned_check, cols: 1, rows: 1 },
    { title: 'Change History', id: '09', component: dashboardCards.changes_history, cols: 1, rows: 1 },
    { title: 'App Report', id: '05', component: dashboardCards.app_report, cols: 1, rows: 1 }
];

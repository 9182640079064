import { createAction, props } from '@ngrx/store';
import { MspAccessGroupsDTO_Fetch, MspAccessGroupsDTO_Get, MspAccessGroupsDTO_Post, MspAccessGroupsDTO_Put } from '../../models/msp-access-group';
import { MspAccessRolesDTO_Fetch } from '../../models/msp-access-roles';
import { baseProps } from 'src/app/modules/shared/libs/state/actions-base';

export const fetchMspAccessGroups = createAction(
    '[oct/msp-access-groups] fetchMspAccessGroups'
);

export const fetchMspAccessGroups_success = createAction(
    '[oct/msp-access-groups] fetchMspAccessGroups_success',
    props<{ accessGroups: MspAccessGroupsDTO_Fetch }>()
);

export const getMspAccessGroup = createAction(
    '[oct/msp-access-groups] getMspAccessGroup',
    props<{ groupId: string; isNewGroup?: boolean }>()
);

export const getMspAccessGroup_success = createAction(
    '[oct/msp-access-groups] getMspAccessGroup_success',
    props<{ selectedAccessGroup: MspAccessGroupsDTO_Get }>()
);

export const createMspAccessGroup = createAction(
    '[oct/msp-access-groups] createMspAccessGroup',
    baseProps<{ newGroup: MspAccessGroupsDTO_Post }>()
);

export const createMspAccessGroup_success = createAction(
    '[oct/msp-access-groups] createMspAccessGroup_success',
);

export const updateMspAccessGroup = createAction(
    '[oct/msp-access-groups] updateMspAccessGroup',
    baseProps<{ groupId: string; updatedGroup: MspAccessGroupsDTO_Put }>()
);

export const updateMspAccessGroup_success = createAction(
    '[oct/msp-access-groups] updateMspAccessGroup_success',
);

export const deleteMspAccessGroup = createAction(
    '[oct/msp-access-groups] deleteMspAccessGroup',
    baseProps<{groupId: string }>()
);

export const fetchMspAccessRoles = createAction(
    '[oct/msp-access-groups] fetchMspAccessRoles',
);

export const fetchMspAccessRoles_success = createAction(
    '[oct/msp-access-groups] fetchMspAccessRoles_success',
    props<{ accessRoles: MspAccessRolesDTO_Fetch }>()
);

import { createAction, props } from '@ngrx/store';

export const loadMspCwmBoards = createAction(
    '[Msp/CWM] Load Msp Cwm Boards',
);

export const loadMspCwmBoardsSuccess = createAction(
    '[Msp/CWM] Load Msp Cwm Boards Success',
    props<{ data: any }>()
);

export const loadMspCwmBoardsFailure = createAction(
    '[Msp/CWM] Load Msp Cwm Boards Failure',
    props<{ error: any }>()
);
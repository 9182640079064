import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import Ajv, { ErrorObject } from 'ajv';
import { BehaviorSubject, Observable, Subscription, combineLatest, first, map, sample, skipUntil, switchMap, take } from 'rxjs';
import { TemplateItem } from 'src/app/modules/sway/store/templates/items/model';
import { client } from 'src/app/stores/client';
import { selectGraphDirectoryRoleTemplates, selectGraphDirectoryRoleTemplatesStatus, selectSession } from 'src/app/stores/root.store';




@Component({
    selector: 'sway-baseline-cap-config',
    templateUrl: './cap-baseline-config.component.html',
    styleUrls: ['./cap-baseline-config.component.scss']
})
export class CapBaselineConfigComponent implements OnInit, OnDestroy {
    private ajv = new Ajv({
        allErrors: true,
        verbose: true,
        strict: false
    });

    public errors$: BehaviorSubject< ErrorObject<string, Record<string, any>, unknown>[] > = new BehaviorSubject([]);


  @Input() form: FormGroup;
  @Input() readOnly: boolean;
  @Input() policy$: Observable<ConditionalAccessPolicy>;
  @Input() tenant_id: string;
  @Input() schema$: BehaviorSubject<any>;
  @Input() baselineTemplateItem: TemplateItem;

  directorRoleTemplatesLoaded$ = this.store.select(selectGraphDirectoryRoleTemplatesStatus).pipe(map(status => status.loaded));
  directorRoleTemplates$ = this.store.select(selectGraphDirectoryRoleTemplates).pipe(sample(this.directorRoleTemplatesLoaded$)).pipe(take(1));

  users$ = this.store.select(selectSession).pipe(
      map(sess => sess.session.clientId),
      switchMap(tid => combineLatest([
          this.store.select(client(tid).graph.users.all),
          this.store.select(client(tid).graph.users.status)
      ])),
      first(([data, status]) => status.loaded),
      map(([data]) => data),
  );


  groups$ = this.store.select(selectSession).pipe(
      map(sess => sess.session.clientId),
      switchMap(tid =>
          this.store.select(client(tid).graph.groups.all)
              .pipe(skipUntil(
                  this.store.select(client(tid).graph.groups.status).pipe(map(res => res.loaded))
              ))
      )
  );
  policy: ConditionalAccessPolicy;
  sub: Subscription;


  constructor(private store: Store<any>) { }

  ngOnDestroy(): void {
      this.sub.unsubscribe();
  }

  ngOnInit(): void {
      this.sub = combineLatest([this.policy$, this.schema$]).subscribe(([policy, schema]) => {
          this.policy = policy;

          const validate = this.ajv.compile(schema);
          const errors = validate([policy]) ? [] : validate.errors!;
     
          this.errors$.next(errors);
      });
  }
}

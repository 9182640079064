import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import {
    MspAccessGroupsDTO_Fetch,
    MspAccessGroupsDTO_Get,
    MspAccessGroupsDTO_Post,
    MspAccessGroupsDTO_Put
} from '../models/msp-access-group';

@Injectable({ providedIn: 'root' })
export class MspAccessGroupsService {
    readonly BASE_URL = '/api/octiga/v2/msp/access/groups';

    readonly FETCH_ACCESS_GROUPS = `${this.BASE_URL}`;
    readonly GET_ACCESS_GROUP = (groupId: string): string => `${this.BASE_URL}/${groupId}`;
    readonly CREATE_ACCESS_GROUP = `${this.BASE_URL}/`;
    readonly UPDATE_ACCESS_GROUP = (groupId: string): string => `${this.BASE_URL}/${groupId}`;
    readonly DELETE_ACCESS_GROUP = (groupId: string): string => `${this.BASE_URL}/${groupId}`;

    constructor(private http: MspAjaxService) {}

    fetchAccessGroups(): Observable<MspAccessGroupsDTO_Fetch> {
        return this.http.get<MspAccessGroupsDTO_Fetch>(this.FETCH_ACCESS_GROUPS);
    }

    getAccessGroup(groupId: string): Observable<MspAccessGroupsDTO_Get> {
        return this.http.get<MspAccessGroupsDTO_Get>(this.GET_ACCESS_GROUP(groupId));
    }

    createAccessGroup(newGroup: MspAccessGroupsDTO_Post): Observable<MspAccessGroupsDTO_Post> {
        return this.http.post<MspAccessGroupsDTO_Post, unknown>(this.CREATE_ACCESS_GROUP, newGroup);
    }

    updateAccessGroup(groupId: string, editedGroup: MspAccessGroupsDTO_Put): Observable<unknown> {
        return this.http.put<MspAccessGroupsDTO_Put, unknown>(this.UPDATE_ACCESS_GROUP(groupId), editedGroup);
    }

    deleteAccessGroup(groupId: string): Observable<unknown> {
        return this.http.delete<MspAccessGroupsDTO_Put>(this.DELETE_ACCESS_GROUP(groupId));
    }
}

import { Domain } from '@microsoft/microsoft-graph-types-beta';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as DomainActions from './actions';

export const featureKey = 'graphDomains';

export interface State extends EntityState<Domain>, Status { }

export const adapter: EntityAdapter<Domain> = createEntityAdapter<Domain>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(DomainActions.updateDomain,
        (state, action) => ({ ...state, updating: true, error: null }),
    ),
    on(DomainActions.updateDomainSuccess,
        (state, action) => adapter.updateOne(action.domain, ({ ...state, updating: false, error: null }))
    ),
    
  
    on(DomainActions.loadDomainsSuccess,
        (state, action) => adapter.setAll(action.domains, { ...state, loaded: true, error: null })
    ),
    on(DomainActions.loadDomainsFailure,
        (state, { error }) => ({ ...state, error, loaded: false })),
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { InputGeo } from './model';

export const featureKey = 'InputGeo';

export interface State extends EntityState<InputGeo>, Status { }

export const adapter: EntityAdapter<InputGeo> = createEntityAdapter<InputGeo>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadInputGeo, (state) => ({ ...state, loading: true })),
    on(actions.loadInputGeoSuccess, (state, action) => adapter.setAll(action.data, { ...state, loading: false, loaded: true })),
    on(actions.loadInputGeoFailure, (state, { error }) => ({ ...state, error })),

    on(actions.createInputGeo, (state) => ({ ...state, creating: true })),
    on(actions.createInputGeoSuccess, (state, action) => adapter.addOne(action.data, { ...state, creating: false })),
    on(actions.createInputGeoFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.deleteInputGeo, (state) => ({ ...state, deleting: true })),
    on(actions.deleteInputGeoSuccess, (state, action) => adapter.removeOne(action.id, { ...state, deleting: false })),
    on(actions.deleteInputGeoFailure, (state, { error }) => ({ ...state, error, deleting: false }))
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export const selectEntity = (id: string) => createSelector(
    selectEntities,
    entities => entities[id]
);

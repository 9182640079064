import { Comparer, createEntityAdapter, EntityState, IdSelector } from '@ngrx/entity';

export interface Status {
    loading: boolean
    loaded: boolean // to be set only once, after initial load
    error: boolean | string // false or error message
    creating?: boolean // during POST
    updating: boolean // during PUT/PATCH
    deleting?: boolean // during DELETE
    count?: number,
    updateCount?:number
}

const loading = false;
const loaded = false;
const error = false;
const creating = false;
const updating = false;

export const initialStatus: Status = {
    loading,
    loaded,
    error,
    creating,
    updating
};


export interface EntityStatus {
    loaded: boolean // to be set only once, after initial load
    error: boolean | string // false or error message
    itemStatus?: {
        [key: string]: EntityItemStatus
    }
}

export const initialEntityStatus: EntityStatus = {
    loaded: false,
    error: false,
    itemStatus: {}
};

export interface EntityItemStatus {
    updating: boolean
    deleting: boolean
    error: boolean | string
}

export const initialEntityItemStatus: EntityItemStatus = {
    updating: false,
    deleting: false,
    error: false
};

export const selectStatus = (state: any): Status => {
    if(!state ) return initialStatus;
    const { loading, loaded, error, creating, updating, count, updateCount } = state;
    return { loading, loaded, error, creating, updating, count, updateCount };
};


export function createStatusEntityAdapter<T>(
    options: {
        selectId?: IdSelector<T>;
        sortComparer?: false | Comparer<T>;
    } = {}
) {

    const selectId = options.selectId || ((instance: any) => instance.id);

    const adapter = createEntityAdapter<T>(options);

    function getInitialState(additionalState: any = {}): EntityState<T> & EntityStatus {
        return Object.assign({
            entities: {},
            ids: [],
            ...initialEntityStatus
        }, additionalState);
    }

    const _setItem = (
        entity: T,
        state: EntityState<T> & EntityStatus,
        change: Partial<EntityItemStatus>,
        field?: string
    ): EntityState<T> & EntityStatus => {
        const id = field ? `${selectId(entity)}-${field}` : selectId(entity);
        const itemStatus = { ...state.itemStatus };
        const modified = { ...(itemStatus[id] || initialEntityItemStatus), ...change };
        itemStatus[id] = modified;
        return { ...state, itemStatus };
    };

    const setEntityStatus = (
        entity: T,
        state: EntityState<T> & EntityStatus,
        status: Partial<EntityItemStatus>,
        field?: string // track status of updates to part of Entity
    ): EntityState<T> & EntityStatus => {
        return _setItem(entity, state, status, field);
    };

    const setEntityError = (
        entity: T,
        state: EntityState<T> & EntityStatus,
        error: any,
        field?: string
    ): EntityState<T> & EntityStatus => {
        return _setItem(entity, state, { error }, field);
    };

    const selectEntityStatus = (
        entity: T,
        state: any,
        field?: string
    ): EntityItemStatus => {
        const id = field ? `${selectId(entity)}-${field}` : selectId(entity);
        return state.itemStatus[id] || { ...initialEntityItemStatus };
    };

    function getSelectors() {
        return {
            ...adapter.getSelectors(),
            selectEntityStatus
        };
    }

    return {
        ...adapter,
        getInitialState,
        getSelectors,
        setEntityStatus,
        setEntityError
    };

}

export interface CapItem {
    displayName: string,
    id: string,
    inBaselineSchema?: boolean,
    inPolicy?: boolean
}

export enum RowType {
    includeUsers = 'includeUsers',
    excludeUsers = 'excludeUsers',
    includeRoles = 'includeRoles',
    excludeRoles = 'excludeRoles',
    includeGroups = 'includeGroups',
    excludeGroups = 'excludeGroups'
}

export interface CapUserSettings {
    includeRoles: boolean,
    includeUsers: boolean,
    includeGroups: boolean,
    includeGuestsOrExternalUsers: boolean,

    excludeRoles: boolean,
    excludeUsers: boolean,
    excludeGroups: boolean,
    excludeGuestsOrExternalUsers: boolean,
}

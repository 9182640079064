import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base/base.component';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss']
})
export class BaselineComponent extends BaseComponent implements OnInit {
    
    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        const value = schema.properties?.SmtpClientAuthenticationDisabled?.const;

        if (value !== undefined) {
            this.control.setValue(value);
        } else {
            this.control.setValue('any');
        }

        this.control.valueChanges.subscribe(value => {

            if (value === 'any') {
                delete schema.properties?.SmtpClientAuthenticationDisabled?.const;
                schema.required = [];

            } else {
                schema.properties.SmtpClientAuthenticationDisabled['const'] = value;
                schema.required = ['SmtpClientAuthenticationDisabled'];
            }

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema))
            });
        });
    }
}

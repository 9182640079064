import { Injectable } from '@angular/core';
import { Domain } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, distinct, filter, last, map, mergeMap, Observable, of, switchMap, take } from 'rxjs';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { client } from '../..';
import * as actions from './actions';

@Injectable()
export class GraphDomainsEffects {

    loadGraphDomains$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadDomains),
            distinct(action => action._tenant),
            switchMap(action => this.store.pipe(
                select(client(action._tenant).graph.domains.status),
                filter(status => !status.loaded),
                map(() => action),
                take(1)
            )),
            mergeMap(({ _tenant }) => this.ajax.get(_tenant, '/api/microsoft/graph/domains')
                .pipe(
                    retry(3000, 3, 'graph timeout'),
                    last(),
                    map((data: any) => {
                        return actions.loadDomainsSuccess({ _tenant, domains: data.value as Domain[] });
                    }),
                    catchError((error) => of(actions.loadDomainsFailure({ _tenant, error }))),
                )
            )
        )
    );

    updateGraphDomain$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateDomain),
            mergeMap(({ _tenant, domain }) => this.patchDomain(_tenant, domain)
                .pipe(
                    map(() => ({ id: domain.id, changes: { ...domain } })),
                    map((domain) => actions.updateDomainSuccess({ _tenant, domain })),
                    catchError((error) => of(actions.loadDomainsFailure({ _tenant, error })))
                )
            )
        )
    );

    private patchDomain(tenant: string, domain: Partial<Domain>): Observable<Domain> {
        return this.ajax.patch(tenant, `/api/microsoft/graph/domains/${domain.id}`, domain);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store
    ) { }

}

<sway-cap-baseline *ngIf="baseline && schema$" [readOnly]="readOnly"  [tenant_id]="tenant_id"
    [form]="form" [baseline]="baseline" [baselineTemplateItem]="baselineTemplateItem" [readOnly]="readOnly"
    [schema$]="schema$" [schemaFunc]="schemaFunc" [policy$]="policy$"
    (baselineErrorChange)="onBaselineErrorChange($event)">

    <ng-container *ngTemplateOutlet="content"></ng-container>
</sway-cap-baseline>



<ng-template #content>
    <ng-container *ngIf="policy$ | async as policy">
        <tr *ngIf="policy.id || form.get('state').value !=='delete'">
            <td>
                Grant
            </td>

            <td>

                <ng-container *ngFor="let item of policy?.grantControls?.builtInControls">
                    {{ item }}
                </ng-container>
            </td>




            <td>
                <mat-form-field appearance="fill">
                    <mat-label>Grant</mat-label>
                    <mat-select [disabled]="readOnly" [formControl]="grantController">

                        <mat-option [value]="'block'">Block</mat-option>
                        <mat-option [value]="'mfa'">Required MFA</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>

            <td>
                <sway-baseline-status
                    [hasError]="!(policy?.grantControls?.builtInControls[0] === grantController?.value && policy?.grantControls?.builtInControls?.length == 1)">
                </sway-baseline-status>
            </td>
        </tr>

        <ng-container *ngIf="policy?.id">
            <tr>
                <td>
                    Include Applications
                </td>

                <td>
                    <div *ngFor="let item of policy?.conditions?.applications?.includeApplications">
                        {{ item }}
                    </div>
                </td>



                <td>
                    All
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.conditions?.applications?.includeApplications?.includes('All') &&  policy?.conditions?.applications?.includeApplications?.length == 1)">
                    </sway-baseline-status>
                </td>
            </tr>


            <tr>
                <td>
                    Client App Types
                </td>

                <td>
                    <div *ngFor="let item of policy?.conditions?.clientAppTypes">
                        {{item}}
                    </div>
                </td>



                <td>
                    All.
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.conditions?.clientAppTypes?.includes('all') &&  policy?.conditions?.clientAppTypes?.length == 1)">
                    </sway-baseline-status>

                </td>
            </tr>

            <tr>
                <td>
                    SignIn Risk Levels
                </td>

                <td>

                    <ng-container *ngFor="let item of policy?.conditions?.signInRiskLevels">
                        {{ item }}
                    </ng-container>
                </td>



                <td>
                    High
                    <br>
                    Medium
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.conditions?.signInRiskLevels.includes('high') && policy?.conditions?.signInRiskLevels.includes('medium') && policy?.conditions?.signInRiskLevels?.length === 2 )">
                    </sway-baseline-status>
                </td>
            </tr>
        </ng-container>
    </ng-container>
</ng-template>
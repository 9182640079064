import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as actions from './actions';
import { OrganizationConfig } from './model';

export const featureKey = 'exoOrganizationConfig';

export interface State extends Status {
    data: OrganizationConfig
}

export const initialState: State = {
    data: undefined,
    ...initialStatus
};

export const reducer = createReducer(
    initialState,
  
    on(actions.fetchExoOrganizationConfigSuccess,
        (state, { data }) => ({ ...state, loaded: true, error: null, data })
    ),
    on(actions.fetchExoOrganizationConfigFailure,
        (state, { error }) => ({ ...state, error })
    ),
    on(actions.updateExoOrganizationConfig,
        (state) => ({ ...state, updating: true , error: null})
    ),
    on(actions.updateExoOrganizationConfigSuccess,
        (state, { data }) => ({ ...state, updating: false, error: null, data: { ...state.data, ...data } })
    ),
    on(actions.updateExoOrganizationConfigFailure,
        (state, { error }) => ({ ...state, updating: false, error })
    )
);

export const selectItem = (state: State) => state.data;

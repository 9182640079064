import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LoaderState } from 'src/app/modules/shared/libs/loader/store/loader.reducer';
import { EffectsBase } from 'src/app/modules/shared/libs/state/effects-base';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as MspUserActions from './actions';
import { User } from '@microsoft/microsoft-graph-types-beta';

@Injectable()
export class MspGraphUserEffects extends EffectsBase { 
    loadMspGraphUsers$ = this.createEffect(
        MspUserActions.loadMspGraphUsers,
        mergeMap((action) => this.ajax.get('/api/octiga/v2/msp/graph-user')
            .pipe(
                map((data: User[]) => MspUserActions.loadMspGraphUsersSuccess({ msp_users: data })),
                catchError((error) => of(MspUserActions.loadMspGraphUsersFailure({ error }))
                )
            )
        )
    );

    constructor(
      private ajax: MspAjaxService,
        protected actions$: Actions,
        protected loaderStore: Store<LoaderState>,
        protected snackbar: MatSnackBar,

    ) {
        super(actions$, loaderStore, snackbar);
    }

}

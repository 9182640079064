import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as actions from './actions';
import { Msp } from './model';

@Injectable()
export class SwayMspConfigEffects {
    loadTemplateOnboardingId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadSwayMspConfig),
            mergeMap(({ msp_id }) => this.ajax.get<Msp[]>(`/api/sway/msp/${msp_id}`).pipe(map((res: any) => res.records))
                .pipe(
                    map(data => actions.loadSwayMspConfigSuccess({ data })),
                    catchError((error) => of(actions.loadSwayMspConfigFailure({ error })))
                ))
        )
    );

    setOnboardProp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.setOnboardTemplate),

            mergeMap(({ mspId, template_id, template_type }) => this.setOnboardProp(mspId, template_id).pipe(map(res => ({ msp_id: mspId, template_id, template_type })))
                .pipe(
                    map(data => actions.setOnboardTemplateSuccess(data)),
                    catchError((error) => of(actions.setOnboardTemplateFailure({ error })))
                ))
        )
    );


    deleteOnboardProp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteOnboardTemplate),

            mergeMap(({ mspId, template_id }) => this.deleteOnboardingProps(mspId, template_id).pipe(map(res => ({ msp_id: mspId, template_id })))
                .pipe(
                    map(data => actions.deleteOnboardTemplateSuccess(data)),
                    catchError((error) => of(actions.deleteOnboardTemplateFailure({ error })))
                ))
        )
    );




    setOnboardProp(msp_id: string, template_id: string) {
        return this.ajax.put(`/api/sway/msp/${msp_id}/template/${template_id}/onboard`, {});
    }

    deleteOnboardingProps(msp_id: string, template_id: string) {
        return this.ajax.delete(`/api/sway/msp/${msp_id}/template/${template_id}/onboard`);

    }

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private ajax: MspAjaxService
    ) { }
}

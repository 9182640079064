import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, tap } from 'rxjs';
import { catchError, concatMap, distinct, map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { loadSwayTenant } from '../tenant/actions';
import { SwayTenant } from '../tenant/model';
import * as GroupActions from './actions';
import { SwayGroup } from './model';

@Injectable()
export class GroupEffects {

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GroupActions.loadSwayGroups),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map(data => GroupActions.loadSwayGroupsSuccess({ _tenant, data })),
                    catchError((error: any) => of(GroupActions.loadSwayGroupsFailure({ _tenant, error })))
                )
            )
        )
    );

    reload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GroupActions.reloadSwayGroups),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map(data => GroupActions.loadSwayGroupsSuccess({ _tenant, data })),
                    catchError((error: any) => of(GroupActions.loadSwayGroupsFailure({ _tenant, error })))
                )
            )
        )
    );


    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GroupActions.createGroup),
            mergeMap(({ _tenant, data }) => this.post(_tenant, data)
                .pipe(
                    concatMap((res: SwayGroup) => [
                        GroupActions.createGroupSuccess({ _tenant, data: res }),
                        loadSwayTenant({ _tenant })
                    ]),
                    catchError((error: any) => of(GroupActions.createGroupFailure({ _tenant, group_id: data.id, error })))
                ))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GroupActions.updateSwayGroup),
            mergeMap(({ _tenant, id, data }) => this.put(_tenant, id, data)
                .pipe(
                    concatMap((res: SwayGroup) => [
                        GroupActions.updateSwayGroupSuccess({ _tenant, data: { id, template_id: data.template_id } }),
                        loadSwayTenant({ _tenant })
                    ]),
                    catchError((error: any) => of(GroupActions.updateGroupFailure({ _tenant, group_id: data.id, error })))
                ))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GroupActions.deleteGroup),
            mergeMap(({ _tenant, group_id }) => this.delete(_tenant, group_id)
                .pipe(
                    map(() =>
                        GroupActions.deleteGroupSuccess({ _tenant, group_id })
                    ),
                    catchError((error: any) => of(GroupActions.deleteGroupFailure({ _tenant, group_id, error })))
                ))
        )
    );

    updatePriority$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GroupActions.updateGroupOrder),
            mergeMap(({ _tenant, group_order }) => this.patch(_tenant, group_order)
                .pipe(
                    map(data => GroupActions.updateGroupOrderSuccess({ _tenant, data })),
                    catchError((error: any) => of(GroupActions.updateGroupOrderFailure({ _tenant, error })))
                ))
        )
    );

    /**
     * @description
     * Get all the Group ID(s) from the specific tenant (SWAY database)
     *
     * @param tenant tenant ID (string)
     * @returns An Observable contains all the group IDs under its tenant
     **/
    private get(tenant: string): Observable<SwayGroup[]> {
        return this.ajax.get(tenant, `/api/sway/tenant/${tenant}/group`); // ,users,baselines
    }

    /**
     * @description
     * Add an existing Microsoft Group to a specific tenant (SWAY database)
     * @param {Object} data - SwayGroup  (tenant_id, id, name, position)
     * @param {string} data.tenant_id - tenant ID (string)
     * @param {string} data.id - group ID that you want to add (string)
     * @param {string} data.name - group name (string)
     * @param {number} data.position - priority number
     **/
    private post(tenant: string, data: SwayGroup): Observable<SwayGroup> {
        return this.ajax.post(tenant, `/api/sway/tenant/${tenant}/group/${data.id}`, { name: data.name });
    }

    private put(tenant: string, id: string, data: Partial<SwayGroup>): Observable<SwayGroup> {
        return this.ajax.patch(tenant, `/api/sway/tenant/${tenant}/group/${id}`, { id, template_id: data.template_id });
    }


    /**
    * @description
    * Delete group by group ID from a specific tenant (SWAY database)
    * @param {Object} tenant - tenant ID (string)
    * @param {string} group_id: group ID (string)
    **/
    private delete(tenant: string, group_id: string) {
        return this.ajax.delete(tenant, `/api/sway/tenant/${tenant}/group/${group_id}`);
    }

    /**
     * @description
     * update groups order array to a specific tenant (SWAY database)
     * @param {string} tenant - tenant ID (string)
     * @param {string[]} group_order - array of group IDs in order
     * @returns {Observable<SwayTenant>} Observable of SwayTenant (id, name and group_order)
     **/
    private patch(tenant: string, group_order: string[]): Observable<SwayTenant> {
        return this.ajax.patch(tenant, `/api/sway/tenant/${tenant}`, { group_order }).pipe(map(res => {
            return { ...res, group_order: JSON.parse(res.group_order) };
        }));
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}

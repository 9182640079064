import { createAction, props } from '@ngrx/store';
import { SwayCategory } from './model';

export const loadSwayCategories = createAction(
    '[Sway Categories] Load'
);

export const loadSwayCategoriesSuccess = createAction(
    '[Sway Categories] Load Success',
    props<{ data: SwayCategory[] }>()
);

export const loadSwayCategoriesFailure = createAction(
    '[Sway Categories] Load Failure',
    props<{ error: any }>()
);

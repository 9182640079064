import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as MailboxActions from './actions';
import { Mailbox } from './model';

export const featureKey = 'exoMailbox';

export interface State extends EntityState<Mailbox>, Status { }

export const adapter: EntityAdapter<Mailbox> = createEntityAdapter<Mailbox>({
    selectId: (mailbox) => mailbox.Identity
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,

    on(MailboxActions.loadMailboxesSuccess,
        (state, action) => adapter.setAll(action.mailboxes, { ...state, loaded: true })),

    on(MailboxActions.loadMailboxsFailure,
        (state, { error }) => ({ ...state, error })),


    on(MailboxActions.updateMailboxFields,
        (state, action) => ({ ...state, updating: true })),


    on(MailboxActions.updateMailboxFieldsSuccess,
        (state, action) => {
            const RecipientTypeDetails = action.data.type === 'Shared' ? 'SharedMailbox' : 'UserMailbox';
            return adapter.updateOne({ id: action.Identity, changes: { updating: false, RecipientTypeDetails } }, state);
        }),

    on(MailboxActions.updateMailboxFieldsFailure,
        (state, action) => ({ ...state, updating: false, error: action.error })),



    on(MailboxActions.updateMailboxLitigationHold,
        (state, action) => ({ ...state, updating: true })),


    on(MailboxActions.updateMailboxLitigationHoldSuccess,
        (state, action) => {
            const LitigationHoldEnabled = action.data.LitigationHoldEnabled; 
            const LitigationHoldDuration = action.data.LitigationHoldDuration + '.00.00'; 
            return adapter.updateOne({ id: action.Identity, changes: { updating: false, LitigationHoldDuration, LitigationHoldEnabled } }, state);
        }),

    on(MailboxActions.updateMailboxFieldsFailure,
        (state, action) => ({ ...state, updating: false, error: action.error })),



    on(MailboxActions.updateMailbox,
        (state, action) => adapter.updateOne(action.mailbox, state)
    )
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

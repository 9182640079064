<div class="wrapper">
    <button class="button close" (click)="modal.close()">
        <mat-icon>close</mat-icon>
    </button>
    <h1 class="mat-h1">Dismiss Items - {{ data.title }}
    </h1>
    <div class="content">
        <app-dismiss-items-table [tenant_id]="data.tenant_id" [type]="data.type" [items]="data.items">
        </app-dismiss-items-table>
    </div>
</div>
import { OAuth2PermissionGrant } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadServicePrincipalOAuth2PermissionGrants = createAction(
    '[Graph/ServicePrincipalOAuth2PermissionGrant] Load',
    props<{ _tenant: string, id: string }>()
);

export const loadServicePrincipalOAuth2PermissionGrantsSuccess = createAction(
    '[Graph/ServicePrincipalOAuth2PermissionGrant] Load Success',
    props<{ _tenant: string, id: string, data: OAuth2PermissionGrant[] }>()
);

export const loadServicePrincipalOAuth2PermissionGrantsFailure = createAction(
    '[Graph/ServicePrincipalOAuth2PermissionGrant] Load Failure',
    props<{ _tenant: string, error: any }>()
);

<sway-cap-baseline 
    *ngIf="baseline && schema$"
    [readOnly]="readOnly"
    [tenant_id]="tenant_id"
    [form]="form"
    [baseline]="baseline"
    [schema$]="schema$"
    [schemaFunc]="schemaFunc"
    [policy$]="policy$"
    [baselineTemplateItem]="baselineTemplateItem"
    (baselineErrorChange)="onBaselineErrorChange($event)"
    >


    <ng-container *ngTemplateOutlet="content"></ng-container>
     
</sway-cap-baseline>


<ng-template #content>
    <ng-container *ngIf="policy$ | async as policy">
        <ng-container *ngIf="policy?.id">
            
            
        
            <tr >
                <td>
                    Include Applications
                </td>
        
                <td>
                    <div *ngFor ="let item of policy?.conditions?.applications?.includeApplications">
                        {{ item }}
                    </div>
                </td>
        
        
        
                <td>
                   Office 365
                </td>
        
                <td>
                    <sway-baseline-status [hasError]="!(policy?.conditions?.applications?.includeApplications?.includes('Office365') &&  policy?.conditions?.applications?.includeApplications?.length == 1)" > </sway-baseline-status>
                </td>
            </tr>


            
            <tr >
                <td>
                    Session Control
                </td>
        
                <td>
                    <div *ngIf ="policy?.sessionControls?.applicationEnforcedRestrictions?.isEnabled">
                        Use app enforced restrictions
                    </div>
                </td>
        
        
        
                <td>
                    Use app enforced restrictions.
                </td>
        
                <td>
                    <sway-baseline-status [hasError]="!policy?.sessionControls?.applicationEnforcedRestrictions?.isEnabled" > </sway-baseline-status>
                </td>
            </tr>
        </ng-container>
    </ng-container>
</ng-template>
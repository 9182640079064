import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as actions from './actions';
import { QuarantinePolicy } from './model';

export const featureKey = 'quarantinePolicy';

export interface State extends EntityState<QuarantinePolicy>, Status { }

export const adapter: EntityAdapter<QuarantinePolicy> = createEntityAdapter<QuarantinePolicy>({selectId: (entity)=>entity.Id});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.fetchExoQuarantinePolicySuccess,
        (state, action) => adapter.setAll(action.data, { ...state, loaded: true })
    ),
    on(actions.fetchExoQuarantinePolicyFailure,
        (state, { error }) => ({ ...state, error })
    )
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

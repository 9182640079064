import { createAction, props } from '@ngrx/store';
import { SharingPolicy } from 'src/app/interfaces/powershell/exo/sharing-policy.interface';

export const loadSharingPolicies = createAction(
    '[Pwsh/Exoo] Load Sharing Policies',
    props<{ _tenant: string }>()
);

export const loadSharingPoliciesSuccess = createAction(
    '[Pwsh/Exoo] Load Sharing Policies Success',
    props<{ _tenant: string, data: SharingPolicy[] }>()
);

export const loadSharingPoliciesFailure = createAction(
    '[Pwsh/Exoo] Load Sharing Policies Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateSharingPolicy = createAction(
    '[Pwsh/Exo] Update SharingPolicy',
    props<{ _tenant: string, Identity: string, Domains: string[] }>()
);

export const updateSharingPolicySuccess = createAction(
    '[Pwsh/Exo] Update SharingPolicy Success',
    props<{ _tenant: string, Identity: string, Domains: string[] }>()
);

export const updateSharingPolicyFailure = createAction(
    '[Pwsh/Exo] Update SharingPolicy Failure',
    props<{ _tenant: string, error: any }>()
);
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EntityStatus, initialStatus } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { ReportSchedule } from './model';

export const featureKey = 'reportSchedule';

export interface State extends EntityState<ReportSchedule>, EntityStatus {

}

export const adapter = createEntityAdapter<ReportSchedule>({

});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.loadSchedulesSuccess,
        (state, { data }) => adapter.upsertMany(data, { ...state, loaded: true })
    ),
    on(actions.createSchedule, (state, { data }) => ({ ...state, creating: true })),
    on(actions.createScheduleSuccess,
        (state, { data }) => adapter.upsertOne(data, { ...state, creating: false }),
    ),
    on(actions.createScheduleFailure,
        (state, { error }) => ({ ...state, error, creating: false })
    ),
    on(actions.updateSchedule, (state, { data }) => ({ ...state, updating: true })),
    on(actions.updateScheduleSuccess, (state, { data }) => adapter.upsertOne(data, { ...state, updating: false })),
    on(actions.updateScheduleFailure,
        (state, { error }) => ({ ...state, error, updating: false })
    ),
    on(actions.deleteSchedule, (state, { ScheduleId }) => ({ ...state, deleting: true })),
    on(actions.deleteScheduleSuccess,
        (state, { ScheduleId }) => adapter.removeOne(ScheduleId, { ...state, deleting: false })),
    on(actions.deleteScheduleFailure,
        (state, { error }) => ({ ...state, error, deleting: false })
    ),
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

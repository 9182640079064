import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { deleteSession } from 'src/app/stores/root/octiga/session/actions';
import * as fromRoot from 'src/app/stores/root.store';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-profile-button',
    templateUrl: './profile-button.component.html',
    styleUrls: ['./profile-button.component.scss']
})
export class ProfileButtonComponent {
    @Input() name: string;
    @Input() color: string;
    open = false;
    session = this.store.pipe(select(fromRoot.selectSession));

    isStaging = environment.stage === 'staging';

    constructor(
        public store: Store<fromRoot.State>
    ) { }

    deleteSession() {
        this.store.dispatch(deleteSession({
            microsoftSignout: true
        }));
    }

    showAccountName(): string {
        return this.name ? `Hi, ${this.name}` : '';
    }

}

<div class="wrapper">
    <div class="spinner">
        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div>

    <!-- <div class="session">
        <div class="image" [class.disabled]="isLoading" (click)="navigateToUserList()">
            <img class="icon" src="./assets/img/dashboard/users.svg" />
            <div class="middle">
                <div class="text">Click to Open</div>
            </div>
        </div>
        <span [class.disabled]="isLoading" class="mat-h2">User Events</span>
    </div> -->

    <div class="session main">
        <div class="image" [class.disabled]="isLoading" (click)="browse('all')">
            <img class="icon" [src]="'assets/img/risks/all-risks.svg'" alt="">

            <div class="middle">
                <div class="text">Click to Open</div>
            </div>

            <!-- <app-severity-badges [severity]="allRiskSeverity"></app-severity-badges> -->
        </div>

        <span [class.disabled]="isLoading" class="mat-h2"> User Management</span>
    </div>

    <div *ngFor="let risk of risks" class="session">
        <div class="image" [class.disabled]="isLoading" (click)="browse(risk.type)">
            <img class="icon" [src]="'assets/img/risks/' + IconsSrc[risk.type]?.icon" alt="">

            <div class="middle">
                <div class="text">Click to Open</div>
            </div>

            <app-severity-badges [small]="true" [severity]="risk.severity"></app-severity-badges>
        </div>

        <span [class.disabled]="isLoading" class="mat-h2 title"> {{risk.type}}</span>
    </div>
</div>
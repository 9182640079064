import { createAction, props } from '@ngrx/store';

export const loadMspCwmSeverities = createAction(
    '[Msp/CWM] Load Msp Cwm Severities',
);

export const loadMspCwmSeveritiesSuccess = createAction(
    '[Msp/CWM] Load Msp Cwm Severities Success',
    props<{ data: any }>()
);

export const loadMspCwmSeveritiesFailure = createAction(
    '[Msp/CWM] Load Msp Cwm Severities Failure',
    props<{ error: any }>()
);
<div class="container">
    <h2>
        {{
            data.AdminAuditLogEnabled
                ? "Admin Audit Log is Enabled"
                : "Admin Audit Log is Disabled"
        }}
    </h2>
    <p *ngFor="let e of errors">
        <mat-icon inline="true" color="warn">warning</mat-icon>
        {{ e.message }}
    </p>
</div>

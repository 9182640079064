import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaselineFormComponent } from '../../abstract/baseline-form.component';

@Component({
    selector: 'sway-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class BooleanFormComponent extends BaselineFormComponent implements OnInit {
    public control = new FormControl();

    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        
        if(this.readonly){
            this.control.disable();
        }


        const value = !schema.properties[this.field]? 'any' : schema.properties[this.field]?.const;
        

        this.control.setValue(value);
        

        this.control.valueChanges.subscribe(value => {
            if(value === 'any'){
                delete schema.properties[this.field];
                schema.required = [];
            }else{
                schema.properties[this.field] = {};
                schema.properties[this.field]['const'] = value;
                schema.required = [this.field];
            }

            this.baselineChange.next({
                baseline: {
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema))
                }
            });
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, combineLatest, of, sample } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
import { client } from 'src/app/stores/client';
import { loadBaselines } from 'src/app/stores/client/sway/baseline/actions';
import { Baseline } from 'src/app/stores/client/sway/baseline/model';
import { selectSession, selectSwaySpecAll } from 'src/app/stores/root.store';
import { SwayCategory } from 'src/app/stores/root/sway/category/model';
import { loadSpecs } from 'src/app/stores/root/sway/spec/actions';
import { SwaySpec } from 'src/app/stores/root/sway/spec/model';
import { loadDeviations } from './../../../../../stores/client/sway/deviation/deviation.actions';
import { SwayDeviation } from './../../../../../stores/client/sway/deviation/deviation.model';
import { selectSwaySpecStatus } from './../../../../../stores/root.store';

@Component({
    selector: 'app-baseline-categories',
    templateUrl: './baseline-categories.component.html',
    styleUrls: ['./baseline-categories.component.scss']
})
export class BaselineCategoriesComponent implements OnInit {
    categories$: Observable<any[]>;

    isLoading = true;
    isDevLoaded = false;
    sub: Subscription;

    constructor(
    private router: Router,
    private store: Store<any>
    ) {
    // super()
    }
  
    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }


    ngOnInit(): void {
        const spec_status$ = this.store.pipe(select(selectSwaySpecStatus)).pipe(filter(res => res.loaded));
        const specs$ = this.store.pipe(select(selectSwaySpecAll)).pipe(sample(spec_status$));

        this.dispatcher();

        const deviations = (tenant: string) => this.store.pipe(select(client(tenant).sway.deviations.allActive));
        const deviations_status = (tenant: string) => this.store.pipe(select(client(tenant).sway.deviations.status)).pipe(map(res => res.loaded));

        const baselines = (tenant: string) => this.store.pipe(select(client(tenant).sway.baselines.all));
        const baselines_status = (tenant: string) => this.store.pipe(select(client(tenant).sway.baselines.status)).pipe(map(res => res.loaded));


        this.sub = this.store.pipe(
            select(selectSession),
            filter(sess => !!sess.session.clientId),
            take(1),
            switchMap(sess => combineLatest([
                specs$,
                deviations(sess.session.clientId),
                baselines(sess.session.clientId).pipe(sample(baselines_status(sess.session.clientId))),
                deviations_status(sess.session.clientId),
                baselines_status(sess.session.clientId)
            ])),
            // sample(ready$)
        ).subscribe(([specs, dev, bas, dev_status, base_status]) => {
            const isLoaded = base_status && dev_status;
            this.getCategoriesDetails(specs, bas, dev, isLoaded);
            this.isLoading = false;
            this.isDevLoaded = isLoaded;

    
        });
    }


    dispatcher() {
        this.store.dispatch(loadSpecs());
        this.store.select(selectSession)
            .pipe(
                first(sess => !!sess?.session?.clientId),
                map(sess => sess.session.clientId)
            )
            .subscribe(_tenant => {
                this.store.dispatch(loadBaselines({ _tenant }));
                this.store.dispatch(loadDeviations({ _tenant }));
            });
    }

    getCategoriesDetails(specs: SwaySpec[], bases: Baseline[], devs: SwayDeviation[], isLoaded: boolean) {
        const categories = {};

        const activeSpecs = specs.filter(spec => !!bases.find(baseline => baseline.spec_id === spec.id) && spec.type === 'tenant');


        activeSpecs.forEach(spec => {
            if (spec?.categories[0]?.id) {
                const deviationCounter = this.hasDeviated(devs, spec) ? 1 : 0;
                const infoCounter = deviationCounter === 1 ? 0 : 1;

                if (isLoaded) {
                    const info = categories[spec.categories[0].id]?.info ? categories[spec.categories[0].id].info + infoCounter : infoCounter;

                    categories[spec.categories[0].id] = {
                        info,
                        danger: categories[spec.categories[0].id]?.danger ? categories[spec.categories[0].id]?.danger + deviationCounter : deviationCounter,
                        data: spec.categories[0]
                    };
                } else {
                    categories[spec.categories[0].id] = {
                        data: spec.categories[0]
                    };
                }

            }
        });

        const categoriesArr = [];

        for (const key in categories) {
            categoriesArr.push({
                category: categories[key].data,
                severity$: of({ info: categories[key].info, danger: categories[key].danger })
            });
        }

        this.categories$ = of(categoriesArr);
    }



    goToCategory(cat: SwayCategory) {
        this.store.pipe(
            select(selectSession),
            filter(sess => !!sess.session.clientId),
            take(1)
        )
            .subscribe(sess => this.router.navigate(['client', sess.session.clientId, 'sway', 'baselines', 'tenant'], { queryParams: { categories_id: cat.id, returnUrl: 'dashboard' } }));
    }

    hasDeviated(deviations, spec: SwaySpec) {
        return deviations.some(d => d.spec_id === spec.id);
    }

}

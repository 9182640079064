import { createAction, props } from '@ngrx/store';
import { MalwareFilterRule } from 'src/app/interfaces/powershell/exo/malware-rule.interface';

export const loadMalwareRule = createAction(
    '[Client/Pwsh/Exo] Load Malware Filter Rule',
    props<{ _tenant: string }>()
);

export const loadMalwareRuleSuccess = createAction(
    '[Client/Pwsh/Exo] Load Malware Filter Rule Success',
    props<{ _tenant: string, data: MalwareFilterRule[] }>()
);

export const loadMalwareRuleFailure = createAction(
    '[Client/Pwsh/Exo] Load Malware Filter Rule Failure',
    props<{ _tenant: string, error: any }>()
);

export const createMalwareRule = createAction(
    '[Client/Pwsh/Exo] Create Malware Filter Rule',
    props<{ _tenant: string, Name: string, RecipientDomainIs: string | string[], MalwareFilterPolicy: string }>()
);

export const createMalwareRuleSuccess = createAction(
    '[Client/Pwsh/Exo] Create Malware Filter Rule Success',
    props<{ _tenant: string, Name: string, RecipientDomainIs: string | string[], MalwareFilterPolicy: string }>()
);

export const createMalwareRuleFailure = createAction(
    '[Client/Pwsh/Exo] Create Malware Filter Rule Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateMalwareRule = createAction(
    '[Client/Pwsh/Exo] Update Malware Filter Rule',
    props<{ _tenant: string, Identity: string, RecipientDomainIs: string | string[] }>()
);

export const updateMalwareRuleSuccess = createAction(
    '[Client/Pwsh/Exo] Update Malware Filter Rule Success',
    props<{ _tenant: string, Identity: string, RecipientDomainIs: string | string[] }>()
);

export const updateMalwareRuleFailure = createAction(
    '[Client/Pwsh/Exo] Update Malware Filter Rule Failure',
    props<{ _tenant: string, error: any }>()
);




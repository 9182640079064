import { Windows10CompliancePolicySchema } from "./model";


export const TargetGroups = {
    allUsers: '#microsoft.graph.allLicensedUsersAssignmentTarget',
    allDevices: '#microsoft.graph.allDevicesAssignmentTarget',
    groupInclusion: '#microsoft.graph.groupAssignmentTarget',
    groupExclusion: '#microsoft.graph.exclusionGroupAssignmentTarget'
};


export const updateSchema = (formValue: any, schema: Windows10CompliancePolicySchema): any =>{
    const osRanges = formValue.validOperatingSystemBuildRanges?.filter(res => res.description && res.lowestVersion && res.highestVersion);
    if (osRanges?.length > 0) {
        schema.contains.properties.policy.properties.validOperatingSystemBuildRanges = {
            type: 'array',
            minItems: osRanges.length,
            maxItems: osRanges.length,
            items: {
                type: 'object',

                oneOf: osRanges.map(res => ({
                    properties: {
                        description: { type: 'string', const: res.description },
                        lowestVersion: { type: 'string', const: res.lowestVersion },
                        highestVersion: { type: 'string', const: res.highestVersion }
                    }

                }))

            }
        };

    } else {
        schema.contains.properties.policy.properties.validOperatingSystemBuildRanges = { type: 'array', minItems: 0, maxItems: 0 };
    }



    schema.contains.properties.policy.properties.displayName.const = formValue.displayName;
    schema.contains.properties.policy.properties.description.const = formValue.description;

    schema.contains.properties.policy.properties.bitLockerEnabled.const = formValue.bitLockerEnabled; // boolean
    schema.contains.properties.policy.properties.secureBootEnabled.const = formValue.secureBootEnabled; // boolean
    schema.contains.properties.policy.properties.codeIntegrityEnabled.const = formValue.codeIntegrityEnabled; // boolean


    // device properties
    if (!!formValue.osMinimumVersion) {
        schema.contains.properties.policy.properties.osMinimumVersion.const = formValue.osMinimumVersion;
        schema.contains.properties.policy.properties.osMinimumVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMinimumVersion = { type: 'null' };
    }

    if (!!formValue.osMaximumVersion) {
        schema.contains.properties.policy.properties.osMaximumVersion.const = formValue.osMaximumVersion;
        schema.contains.properties.policy.properties.osMaximumVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMaximumVersion = { type: 'null' };
    }

    if (!!formValue.mobileOsMinimumVersion) {
        schema.contains.properties.policy.properties.mobileOsMinimumVersion.const = formValue.mobileOsMinimumVersion;
        schema.contains.properties.policy.properties.mobileOsMinimumVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.mobileOsMinimumVersion = { type: 'null' };
    }


    if (!!formValue.mobileOsMaximumVersion) {
        schema.contains.properties.policy.properties.mobileOsMaximumVersion.const = formValue.mobileOsMaximumVersion;
        schema.contains.properties.policy.properties.mobileOsMaximumVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.mobileOsMaximumVersion = { type: 'null' };
    }


    schema.contains.properties.policy.properties.configurationManagerComplianceRequired.const = formValue.configurationManagerComplianceRequired; //boolean
    // system security
    schema.contains.properties.policy.properties.passwordRequired.const = formValue.passwordRequired; // boolean // check

    if (!!formValue.passwordRequired) {
        schema.contains.properties.policy.properties.passwordBlockSimple = { type: 'boolean', const: formValue.passwordBlockSimple }; // boolean 
        schema.contains.properties.policy.properties.passwordRequiredType = { type: 'string', const: formValue.passwordRequiredType };// string
        schema.contains.properties.policy.properties.passwordMinimumLength = { type: 'number', const: formValue.passwordMinimumLength }; // number


        schema.contains.properties.policy.properties.passwordExpirationDays = { type: 'number', const: formValue.passwordExpirationDays };
        schema.contains.properties.policy.properties.passwordPreviousPasswordBlockCount = { type: 'number', const: formValue.passwordPreviousPasswordBlockCount }; // number
        schema.contains.properties.policy.properties.passwordRequiredToUnlockFromIdle = { type: 'boolean', const: formValue.passwordRequiredToUnlockFromIdle }; //boolean

        if (formValue.passwordRequiredType === 'alphanumeric') {
            schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount = { type: 'number', const: formValue.passwordMinimumCharacterSetCount }; // TODO 
        } else {
            delete schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount;
        }

        if (formValue.passwordMinutesOfInactivityBeforeLock === 0) {
            schema.contains.properties.policy.properties.passwordMinutesOfInactivityBeforeLock = { type: 'null' }; // number

        } else {
            schema.contains.properties.policy.properties.passwordMinutesOfInactivityBeforeLock = { type: 'number', const: formValue.passwordMinutesOfInactivityBeforeLock }; // number

        }
    } else {
        delete schema.contains.properties.policy.properties.passwordBlockSimple;
        delete schema.contains.properties.policy.properties.passwordRequiredType;
        delete schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount;
        delete schema.contains.properties.policy.properties.passwordMinimumLength;
        delete schema.contains.properties.policy.properties.passwordMinutesOfInactivityBeforeLock;
        delete schema.contains.properties.policy.properties.passwordExpirationDays;
        delete schema.contains.properties.policy.properties.passwordPreviousPasswordBlockCount;
        delete schema.contains.properties.policy.properties.passwordRequiredToUnlockFromIdle;
    }



    schema.contains.properties.policy.properties.storageRequireEncryption.const = formValue.storageRequireEncryption; // boolean
    schema.contains.properties.policy.properties.activeFirewallRequired.const = formValue.activeFirewallRequired; //boolean
    schema.contains.properties.policy.properties.antivirusRequired.const = formValue.antivirusRequired; //boolean
    schema.contains.properties.policy.properties.antiSpywareRequired.const = formValue.antiSpywareRequired; //boolean,
    schema.contains.properties.policy.properties.tpmRequired.const = formValue.tpmRequired;

    // defender 
    schema.contains.properties.policy.properties.defenderEnabled.const = formValue.defenderEnabled; // boolean

    if (!!formValue.defenderVersion) {

        schema.contains.properties.policy.properties.defenderVersion.const = formValue.defenderVersion; //string
        schema.contains.properties.policy.properties.defenderVersion.type = 'string'; //string
    } else {
        schema.contains.properties.policy.properties.defenderVersion = { type: 'null' };
    }

    schema.contains.properties.policy.properties.rtpEnabled.const = formValue.rtpEnabled;
    schema.contains.properties.policy.properties.signatureOutOfDate.const = formValue.signatureOutOfDate;

    // defender for endpoint
    schema.contains.properties.policy.properties.deviceThreatProtectionRequiredSecurityLevel.const = formValue.deviceThreatProtectionRequiredSecurityLevel;


    // assignments (include)
    if (formValue.assignments.allUsers || formValue.assignments.allDevices) {
        // include all devices or all users
        let arr_length = 0;
        const arr_enum = [];

        const assignment_schema = {
            'type': 'array',
            'minItems': arr_length,
            'maxItems': arr_length,
            'items': {
                'type': 'object',
                'properties': {
                    'target': {
                        'type': 'object',

                        oneOf: [

                        ]
                    }
                }
            }
        };


        if (formValue.assignments.allDevices) {
            arr_length += 1;
            arr_enum.push({
                properties: {
                    '@odata.type': { type: 'string', const: TargetGroups.allDevices }
                }
            });

            assignment_schema.minItems = arr_length;
            assignment_schema.maxItems = arr_length;
            assignment_schema.items.properties.target.oneOf = arr_enum;

        } if (formValue.assignments.allUsers) {
            arr_length += 1;
            arr_enum.push(
                {
                    properties: {
                        '@odata.type': { type: 'string', const: TargetGroups.allUsers }
                    }
                }
            );

            assignment_schema.minItems = arr_length;
            assignment_schema.maxItems = arr_length;
            assignment_schema.items.properties.target.oneOf = arr_enum;
        }

        schema.contains.properties.assignments = assignment_schema;
    } else if (formValue.assignments.groupInclusion && formValue.assignments.includedGroups?.length > 0) {
        // include groups
        const includedGroups: string[] = formValue.assignments.includedGroups;
        const arrLength = includedGroups.length;

        const assignment_schema = {
            'type': 'array',
            'minItems': arrLength,
            'maxItems': arrLength,
            'items': {
                'type': 'object',
                'properties': {
                    'target': {
                        'type': 'object',

                        oneOf: includedGroups.map(res => ({
                            properties: {
                                '@odata.type': { type: 'string', const: TargetGroups.groupInclusion },
                                groupId: { type: 'string', const: res },
                            }
                        }))
                    }
                }
            }
        };

        schema.contains.properties.assignments = assignment_schema;

    } else {
        schema.contains.properties.assignments = {
            type: 'array',
            minItems: 0,
            maxItems: 0
        };

    }

    return schema;
};
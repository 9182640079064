<div class="mat-typology">

    <div class="item">
        <sway-cap-include-items 
            [override]="baselineTemplateItem?.override"
            *ngIf="policy" [readOnly]="readOnly" [policy]="policy"
            [title]="'Included Users'" [errors$]="errors$" [form]="form" [usersLookup]="users$ | async" [groupsLookup]="groups$ | async"
            [rolesLookup]="directorRoleTemplates$ | async">
        </sway-cap-include-items>
    </div>

    <div class="item">
        <sway-cap-exclude-items [override]="baselineTemplateItem?.override" *ngIf="policy" [readOnly]="readOnly" [policy]="policy"
            [title]="'Exclude Users'" [errors$]="errors$" [form]="form" [usersLookup]="users$ | async" [groupsLookup]="groups$ | async"
            [rolesLookup]="directorRoleTemplates$ | async">
        </sway-cap-exclude-items>
    </div>
</div>
<sway-cap-baseline *ngIf="baseline && schema$" [readOnly]="readOnly"  [tenant_id]="tenant_id"
    [form]="form" [baseline]="baseline" [schema$]="schema$" [schemaFunc]="schemaFunc" [policy$]="policy$"
    [baselineTemplateItem]="baselineTemplateItem" (baselineErrorChange)="onBaselineErrorChange($event)">


    <ng-container *ngTemplateOutlet="content"></ng-container>

</sway-cap-baseline>


<ng-template #content>
    <ng-container *ngIf="policy$ | async as policy">


        <tr *ngIf="policy.id || form.get('state').value !=='delete'">
            <td>
                Grant
            </td>

            <td>

                <ng-container *ngFor="let item of policy?.grantControls?.builtInControls">
                    {{ mapper(item) }}
                    <br>
                </ng-container>
            </td>




            <td>
                <mat-form-field appearance="fill">
                    <mat-label>Grant</mat-label>
                    <mat-select [disabled]="readOnly" [formControl]="grantController">

                        <mat-option [value]="'block'">Block</mat-option>
                        <mat-option [value]="'mfa'">Required MFA</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>

            <td>
                <sway-baseline-status
                    [hasError]="!(policy?.grantControls?.builtInControls[0] === grantController?.value && policy?.grantControls?.builtInControls?.length == 1)">
                </sway-baseline-status>
            </td>
        </tr>

        <ng-container *ngIf="policy?.id">
            <tr>
                <td>
                    Include Locations
                </td>

                <td>
                    <div *ngFor="let item of policy?.conditions?.locations?.includeLocations">
                        {{ item }}
                    </div>
                </td>



                <td>
                    All
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.conditions?.locations?.includeLocations?.includes('All') &&  policy?.conditions?.locations?.includeLocations?.length == 1)">
                    </sway-baseline-status>
                </td>
            </tr>



            <tr>
                <td>
                    Exclude Locations
                </td>

                <td>
                    <div *ngFor="let item of policy?.conditions?.locations?.excludeLocations">
                        {{ mapper(item) }}
                    </div>
                </td>



                <td>
                    All trusted locations.
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.conditions?.locations?.excludeLocations?.includes('AllTrusted') &&  policy?.conditions?.locations?.excludeLocations?.length == 1)">
                    </sway-baseline-status>
                </td>
            </tr>


            <tr>
                <td>
                    User Actions
                </td>

                <td>
                    <div *ngFor="let item of policy?.conditions?.applications?.includeUserActions">
                        {{ mapper(item) }}
                    </div>
                </td>



                <td>
                    Register Security Information
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.conditions?.applications?.includeUserActions?.includes('urn:user:registersecurityinfo') &&  policy?.conditions?.applications?.includeUserActions?.length == 1)">
                    </sway-baseline-status>
                </td>
            </tr>
        </ng-container>
    </ng-container>
</ng-template>
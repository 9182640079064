import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';
import { AdminAuditLogConfig } from './model';

////////   APPLIES TO ON-PREM ONLY   ////////

@Injectable()
export class ExoAdminAuditLogConfigEffects {

    fetchAdminAuditLogConfigs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchExoAdminAuditLogConfig),
            distinct(action => action._tenant),
            mergeMap((action) => this.get(action._tenant)
                .pipe(
                    map((data: AdminAuditLogConfig[]) => actions.fetchExoAdminAuditLogConfigSuccess({ _tenant: action._tenant, data: data[0] })),
                    catchError((error) => of(actions.fetchExoAdminAuditLogConfigFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    private get(tenant: string): Observable<AdminAuditLogConfig[]> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/admin-auditlog-config');
    }

    updateExoAdminAuditLogConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateExoAdminAuditLogConfig),
            mergeMap((action) => this.put(action._tenant, action.data)
                .pipe(
                    map((data: AdminAuditLogConfig[]) => actions.updateExoAdminAuditLogConfigSuccess({ _tenant: action._tenant, data: data[0] })),
                    catchError((error) => of(actions.updateExoAdminAuditLogConfigFailure({ _tenant: action._tenant, error: {message : error.message, trace: error.trace} })))
                ))
        )
    );

    private put(tenant: string, body: Partial<AdminAuditLogConfig>): Observable<AdminAuditLogConfig[]> {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/admin-auditlog-config', body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }
}

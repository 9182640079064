<form [formGroup]="form">

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Assignments
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="!policy?.id || errorString.includes('/assignments')"></sway-baseline-status>

                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <mat-error
                    *ngIf="(!(assignmentsValue.groupInclusion && assignmentsValue?.includedGroups?.length > 0) && !assignmentsValue.allDevices && !assignmentsValue.allUsers)">
                    The policy must be included in at least one assignment
                </mat-error>

                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                Included groups
                            </td>


                            <td>
                                <div>
                                    <mat-checkbox [checked]="policyAssignments.allDevices" [disabled]="true"> All
                                        Devices</mat-checkbox>
                                </div>

                                <div>
                                    <mat-checkbox [checked]="policyAssignments.allUsers" [disabled]="true"> All
                                        Users</mat-checkbox>
                                </div>

                                <div>
                                    <mat-checkbox [checked]="policyAssignments.groupInclusion" [disabled]="true">
                                        Include
                                        Groups</mat-checkbox>


                                </div>

                                <div *ngIf="includedGroups?.length > 0" class="">
                                    Policy has {{includedGroups.length}} included groups


                                </div>
                            </td>


                            <td formGroupName="assignments">
                                <div>
                                    <mat-checkbox (change)="form.get('assignments')?.get('groupInclusion')?.setValue(false)"
                                        formControlName="allDevices">
                                        All devices</mat-checkbox>
                                </div>

                                <div>
                                    <mat-checkbox (change)="form.get('assignments')?.get('groupInclusion')?.setValue(false)"
                                        formControlName="allUsers">
                                        All users</mat-checkbox>
                                </div>

                                <div>
                                    <mat-checkbox (change)="form.get('assignments')?.get('allDevices')?.setValue(false); form.get('assignments')?.get('allUsers')?.setValue(false)"
                                        formControlName="groupInclusion"> Include Groups</mat-checkbox>

                                    <div class="" *ngIf="assignmentsValue.groupInclusion">
                                        <button (click)="manageGroups('Include')" mat-button>Mange</button>

                                        <div *ngIf="assignmentsValue?.includedGroups?.length" class="">
                                            Baseline has {{assignmentsValue.includedGroups.length}} included groups
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/assignments')"></sway-baseline-status>
                            </td>
                        </tr>



                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>
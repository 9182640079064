import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import { loadReportDeviations, loadReportDeviationsFailure, loadReportDeviationsSuccess } from './actons';
import { CombinedDeviation } from './model';

export const featureKey = 'reportDeviation';

export interface State extends EntityState<any>, Status {
}

export const adapter: EntityAdapter<CombinedDeviation> = createEntityAdapter<any>(
);

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(loadReportDeviations, (state) => ({ ...state, loading: true })),
    on(loadReportDeviationsSuccess,
        (state, action) => adapter.setAll(action.data, { ...state, loading: false, loaded: true })
    ),
    on(loadReportDeviationsFailure,
        (state, { error }) => ({ ...state, loaded: false, error })
    )
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

<div class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                Customer Lock Box
                <mat-icon
                    matTooltip="This helps to protect customer data from unauthorized access. This parameter is available only in the cloud-based service. This parameter is reserved for internal Microsoft use.">info</mat-icon>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>

            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>

<ng-template #validatorRef>
    <div class="validator">
        {{
        data.CustomerLockboxEnabled
        ? "Enabled"
        : "Disabled"
        }}
    </div>
</ng-template>

<ng-template #formRef>
    <div class="form">
        <div class="field boolean">
            <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="control">
                    <mat-option value="any">Ignore</mat-option>
                    <mat-option [value]="true">Enable</mat-option>
                    <mat-option [value]="false">Disable</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>
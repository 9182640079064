import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isValidEmail } from 'src/app/utils/custom-validators';

interface Item {
    name: string;
    id: string
}

@Component({
    selector: 'sway-filtered-list',
    templateUrl: './filtered-list.component.html',
    styleUrls: ['./filtered-list.component.scss']
})
export class FilteredListComponent implements OnInit, OnChanges {
    @Input() options: Array<any>;
    @Input() placeholder: string;
    @Input() readOnly;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    controller = new FormControl();
    @Input() items: string[] = [];

    @ViewChild('listInput') listInput: ElementRef<HTMLInputElement>;
    @Output() update = new EventEmitter();
    invalid: any;
    @Input() addTagText = 'Add Recipient';
    
    ngOnInit(): void {
        this.controller.setValue([...this.items]);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.controller.setValue([...this.items]);
    }

    onChange() {
        const value = this.controller.value.map(res => {
            if (res instanceof Object) {
                console.log(res);
                return res.label;
                // .toLowerCase();
            } else {
                return res;
            }
        });

        this.invalid = value.some(item => !isValidEmail(item));
        this.update.emit(value);
    }
}

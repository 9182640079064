<form *ngIf="form && defaultPolicy" [formGroup]="form" class="mat-typography">
    <table>
        <thead>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </thead>

        <tbody>
            <tr>
                <td>
                    Identity
                </td>

                <td colspan="3" class="bold">
                    {{defaultPolicy.Identity}}
                </td>

            </tr>

            <tr>
                <td>
                    Password history

                    <mat-icon
                        matTooltip="Specifies the number of unique new passwords that need to be created on the mobile device before an old password can be reused.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.PasswordHistory }}
                </td>

                <td>
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label>Password history</mat-label>
                            <input formControlName="PasswordHistory" min="0" max="50" matInput type="number" />
                            <mat-hint>You can enter any number from 0 through 50</mat-hint>
                        </mat-form-field>

                    </div>
                </td>

                <td>
                    <sway-baseline-status [hasError]="defaultPolicy.PasswordHistory !== formValue.PasswordHistory">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Password expiration
                    <mat-icon
                        matTooltip="Specifies how long a password can be used on a mobile device before the user is forced to change the password.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.PasswordExpiration}}
                </td>

                <td>
                    <ng-container *ngIf="!readOnly">

                        <mat-slide-toggle (change)="onTogglePasswordExpiration($event)"
                            [checked]="PasswordExpiration ==='Unlimited'">Unlimited</mat-slide-toggle>

                        <div *ngIf="!readOnly" class="field boolean">
                            <mat-form-field appearance="fill">
                                <mat-label>Number Of Days</mat-label>
                                <input [readonly]="PasswordExpiration ==='Unlimited'"
                                    formControlName="PasswordExpiration" min="1" matInput type="number" max="730" />
                                <mat-hint>The valid input range is 1 to 730 (one day to two years).</mat-hint>
                            </mat-form-field>
                        </div>
                    </ng-container>

                    <div *ngIf="readOnly">
                        {{form.get('PasswordExpiration').value}}
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.PasswordExpiration !== setDaysStamp(formValue.PasswordExpiration) ">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Minimum password complex characters
                    <mat-icon
                        matTooltip="Specifies the character sets that are required in the password of the mobile device. ">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.MinPasswordComplexCharacters }}
                </td>

                <td>
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label>minimum password complex characters</mat-label>
                            <input formControlName="MinPasswordComplexCharacters" min="1" matInput type="number"
                                max="4" />

                            <mat-hint>
                                
                                The MinPasswordComplexCharacters parameter specifies the character sets that are required in the password of the mobile device. The character sets are:
                                <ul>
                                    <li>
                                        Lower case letters.
                                    </li>
                                    <li>
                                        Upper case letters.
                                    </li>
                                    <li>
                                        Digits 0 through 9.
                                    </li>
                                    
                                    <li>
                                        
                                        Special characters (for example, exclamation marks).
                                    </li>
                                </ul>

                                A valid value for this parameter is an integer from 1 through 4. The default value is 1.  the value specifies the number of character sets that are required
                         
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.MinPasswordComplexCharacters !== formValue.MinPasswordComplexCharacters">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    <p>
                        Minimum password length
                        <mat-icon matTooltip="Specifies the minimum number of characters in the mobile device password.">info</mat-icon>
                    </p>

                    <mat-slide-toggle *ngIf="!readOnly" (change)="onToggleMinPasswordLength($event)"
                        [checked]="MinPasswordLengthIgnored">Set to not required
                    </mat-slide-toggle>
                </td>

                <td>
                    {{defaultPolicy.MinPasswordLength}}
                </td>

                <td>
                    <div *ngIf="!readOnly" class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label> Minimum password length</mat-label>

                            <input [readonly]="MinPasswordLengthIgnored" formControlName="MinPasswordLength" min="1"
                                matInput type="number" max="16" />
                            <mat-hint>You can enter any number from 1 through 16</mat-hint>
                        </mat-form-field>
                    </div>

                    <div *ngIf="readOnly" class="">
                        {{form.get('MinPasswordLength').value || 'null'}}

                    </div>
                </td>

                <td>
                    <sway-baseline-status [hasError]="defaultPolicy.MinPasswordLength != formValue.MinPasswordLength">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Maximum Inactivity Time Lock
                    <mat-icon matTooltip="Specifies the length of time that the mobile device can be inactive before the password is required to reactivate it.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.MaxInactivityTimeLock}}
                </td>

                <td>
                    <ng-container *ngIf="!readOnly">

                        <mat-slide-toggle (change)="onToggleMaxInactivityTimeLock($event)"
                            [checked]="MaxInactivityTimeLock ==='Unlimited'">Unlimited</mat-slide-toggle>
                        <div class="field boolean">
                            <mat-form-field appearance="fill">
                                <mat-label>Maximum Inactivity Time Lock</mat-label>
                                <input [readonly]="MaxInactivityTimeLock === 'Unlimited'"
                                    formControlName="MaxInactivityTimeLock" min="1" matInput type="number" max="60" />
                                <mat-hint> The valid input range is 1 to 60 (one minute to one hour).</mat-hint>
                            </mat-form-field>
                        </div>
                    </ng-container>

                    <div *ngIf="readOnly" class="">
                        {{form.get('MaxInactivityTimeLock').value}}
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.MaxInactivityTimeLock !== setTimeStamp(formValue.MaxInactivityTimeLock)">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Require Device Encryption
                    <mat-icon matTooltip="Specifies whether encryption is required on the mobile device.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.RequireDeviceEncryption? 'Required': 'Not Required'}}
                
                    <mat-icon matTooltip="Specifies whether encryption is required on the mobile device.">info</mat-icon>
                </td>

                <td>
                    <div class="field">
                        <mat-form-field appearance="fill">
                            <mat-label>Require Device Encryption</mat-label>
                            <mat-select formControlName="RequireDeviceEncryption">
                                <mat-option [value]="true"> Required</mat-option>
                                <mat-option [value]="false">Not Required</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.RequireDeviceEncryption !== formValue.RequireDeviceEncryption">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Device Encryption Enabled
                    <mat-icon matTooltip="Specifies whether encryption is enabled on the mobile device.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.DeviceEncryptionEnabled? 'Enabled': 'Disabled'}}
                </td>

                <td>
                    <div class="field">
                        <mat-form-field appearance="fill">
                            <mat-label>Device Encryption Enabled</mat-label>
                            <mat-select formControlName="DeviceEncryptionEnabled">
                                <mat-option [value]="true"> Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.DeviceEncryptionEnabled !== formValue.DeviceEncryptionEnabled">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Alphanumeric Password
                    <mat-icon matTooltip="Specifies whether the password for the mobile device must be alphanumeric.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.AlphanumericPasswordRequired? 'Required' : 'Not Required'}}
                </td>

                <td>
                    <div class="field">
                        <mat-form-field appearance="fill">
                            <mat-label> Alphanumeric Password</mat-label>
                            <mat-select formControlName="AlphanumericPasswordRequired">
                                <mat-option [value]="true"> Required</mat-option>
                                <mat-option [value]="false">Not Required</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.AlphanumericPasswordRequired !== formValue.AlphanumericPasswordRequired">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Allow Simple Password
                    <mat-icon matTooltip="Specifies whether a simple password is allowed on the mobile device. A simple password is a password that has a specific pattern, such as 1111 or 1234.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.AllowSimplePassword ? 'Allowed' : 'Disallowed'}}
                </td>

                <td>
                    <div class="field">
                        <mat-form-field appearance="fill">
                            <mat-label> Allow Simple Password</mat-label>
                            <mat-select formControlName="AllowSimplePassword">
                                <mat-option [value]="true"> Allow</mat-option>
                                <mat-option [value]="false">Don't Allow</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.AllowSimplePassword !== formValue.AllowSimplePassword">
                    </sway-baseline-status>

                </td>
            </tr>

            <tr>
                <td>
                    Password Enabled
                    <mat-icon matTooltip="Specifies whether a password is required on the mobile device">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.PasswordEnabled? 'Enabled' : 'Disabled'}}
                </td>

                <td>
                    <div class="field">
                        <mat-form-field appearance="fill">
                            <mat-label> Password Enabled</mat-label>
                            <mat-select formControlName="PasswordEnabled">
                                <mat-option [value]="true"> Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status [hasError]="defaultPolicy.PasswordEnabled !== formValue.PasswordEnabled">
                    </sway-baseline-status>
                </td>
            </tr>
        </tbody>
    </table>
</form>


<div *ngIf="!defaultPolicy" class="bold">
    No default mobile device mailbox policy has been set, please set default mobile device mailbox policy
</div>
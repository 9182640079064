import { Injectable } from '@angular/core';
import { Group, User } from '@microsoft/microsoft-graph-types-beta';
import { Store, select } from '@ngrx/store';
import Ajv from 'ajv';
import { Observable, combineLatest, map } from 'rxjs';
import { filter, sample, throttleTime } from 'rxjs/operators';
import { client } from 'src/app/stores/client';
import { loadGroups } from 'src/app/stores/client/graph/group/actions';
import { loadBaselines } from 'src/app/stores/client/sway/baseline/actions';
import { Baseline } from 'src/app/stores/client/sway/baseline/model';
import { createMultipleDeviations, loadDeviations, updateMultipleDeviations } from 'src/app/stores/client/sway/deviation/deviation.actions';
import { SwayDeviation } from 'src/app/stores/client/sway/deviation/deviation.model';
import { loadSwayGroups } from 'src/app/stores/client/sway/group/actions';
import { loadSwayTenant } from 'src/app/stores/client/sway/tenant/actions';
import { selectSwaySpecAll, selectSwaySpecStatus } from 'src/app/stores/root.store';
import { loadSpecs } from 'src/app/stores/root/sway/spec/actions';
import { SwaySpec } from 'src/app/stores/root/sway/spec/model';
import { GroupSpecRegistryService, Zipper } from '../components/group/group-spec-registry.service';
import { TenantSpecRegistryService } from '../components/tenant/tenant-spec-registry.service';

interface TenantTaggedData {
    data: any
    tag: string
}

interface GroupTaggedData {
    data: any[]
    tag: string
}

@Injectable({
    providedIn: 'root'
})
export class DeviationService {

    tenants = new Set<string>();

    private ajv = new Ajv({
        allErrors: true,
        verbose: true,
        strict: false
    });

    constructor(
        private store: Store<any>,
        private tenantSpecService: TenantSpecRegistryService,
        private groupSpecService: GroupSpecRegistryService,

    ) { }

    /**
     * detect changes from relevant sources and create or dismiss deviation
     * sources include: baselines and resources
     * listens for changes in both sources and creates/dismisses deviations if necessary
     */

    private dispatcher(tenant: string) {
        this.store.dispatch(loadSpecs());
        this.store.dispatch(loadBaselines({ _tenant: tenant }));
        this.store.dispatch(loadGroups({ _tenant: tenant }));
        this.store.dispatch(loadSwayGroups({ _tenant: tenant }));
        this.store.dispatch(loadSwayTenant({ _tenant: tenant }));
        this.store.dispatch(loadDeviations({ _tenant: tenant }));
       
        this.tenantSpecService.init(tenant);
       
        this.groupSpecService.dispatcher(tenant);

        
        const group_data_actions = this.groupSpecService.getAll(tenant).map(item => item.fetch_data);

        for (const actions of group_data_actions) {
            actions.forEach(action => {

                this.store.dispatch(action);
            });
        }
    }

    public init(tenant: string) {
        if (this.tenants.has(tenant)) return;

        this.tenants.add(tenant);
        this.dispatcher(tenant);

        let isProcessing = false; // semaphore

        this.dataPipe(tenant)
            .pipe(
                filter(() => !isProcessing)
            ).subscribe(results => {
                isProcessing = true;
                this.runChecks(tenant, ...results); // the latest emitted value contained all changes;
                isProcessing = false;
            });
    }

    private runChecks(
        tenant: string,
        specs: SwaySpec[],
        baselines: Baseline[],
        deviations: SwayDeviation[],
        users: User[],
        groups: Group[],
        tenant_data: TenantTaggedData[],
        group_data: GroupTaggedData[],
        tenant_data_errors: boolean[],
        group_data_errors: boolean[],
    ): void {
        const dismissals = [], creations = [];
        const group_deviations = deviations.filter(item => item.type === 'group');
        const user_deviations = deviations.filter(item => item.type === 'user');
        const tenant_deviations = deviations.filter(item => item.type === 'tenant');

        console.log('[deviations] group:', group_deviations.length, 'tenant:', tenant_deviations.length);

        const { creations: tenantCreations, dismissals: tenantDismissals } = this.detectResolveTenantDeviations(tenant, specs, baselines, tenant_deviations, tenant_data, tenant_data_errors);
        const { creations: groupCreations, dismissals: groupDismissals } = this.detectResolveGroupDeviations(tenant, specs, baselines, group_deviations, group_data, users, groups, group_data_errors);

        const { creations: userCreations, dismissals: userDismissals } = this.detectResolveUserDeviations(tenant, specs, baselines, user_deviations, group_data, users);


        const secondGroupDismissals = this.resolveDeletedGroupDeviations(tenant, group_deviations, groups);
        const userLeftGroupDismissals = this.resolveDeviationsWhereUserLeftGroup(tenant, group_deviations, groups);
        const userExceptionDismissals = this.resolveUserExceptionDeviations(tenant, group_deviations, baselines);
        const deletedBaselinesDismissals = this.resolveDeviationsForDeletedBaselines(tenant, baselines, deviations);

        creations.push(...tenantCreations, ...groupCreations, ...userCreations);
        dismissals.push(...tenantDismissals, ...groupDismissals, ...secondGroupDismissals, ...userLeftGroupDismissals, ...deletedBaselinesDismissals, ...userExceptionDismissals, ...userDismissals);


        if (dismissals.length > 0) this.store.dispatch(updateMultipleDeviations({ _tenant: tenant, data: dismissals }));
        if (creations.length > 0) this.store.dispatch(createMultipleDeviations({ _tenant: tenant, data: creations }));
    }

    private dataPipe(tenant: string): Observable<[SwaySpec[], Baseline[], SwayDeviation[], User[], Group[], TenantTaggedData[], GroupTaggedData[], boolean[], boolean[]]> {
        const specs$ = this.store.select(selectSwaySpecAll);
        const baselines$ = this.store.pipe(select(client(tenant).sway.baselines.all)); // must filter by type later
        const deviations$ = this.store.pipe(select(client(tenant).sway.deviations.allActive)); // must filter by type later

        const graph_user_status$ = this.store.pipe(select(client(tenant).graph.users.status), map(res => res.loaded), filter(res => !!res));
        const graph_users$ = this.store.pipe(
            select(client(tenant).graph.users.internal),
            sample(graph_user_status$),
            // map(users => users.filter(user => user.accountEnabled)) 
        );

        const graph_groups_status$ =
            combineLatest([
                this.store.pipe(select(client(tenant).graph.groups.status), map(res => res.loaded), filter(res => !!res)),
                this.store.pipe(select(client(tenant).graph.groups.members.status), map(res => res.loaded), filter(res => !!res)),
            ]).pipe(map(([t, g]) => t && g));

        const graph_groups$ = this.store.pipe(select(client(tenant).graph.groups.all), sample(graph_groups_status$));

        const sway_group_status$ =
            combineLatest([
                this.store.pipe(select(client(tenant).sway.tenant.status), map(res => res.loaded), filter(res => !!res)),
                this.store.pipe(select(client(tenant).sway.groups.status), map(res => res.loaded), filter(res => !!res))
            ]).pipe(map(([t, g]) => t && g));


        const sway_groups$ = this.store.pipe(select(client(tenant).sway.groups.all), sample(sway_group_status$));

        const filtered_graph_groups$ = combineLatest([graph_groups$, sway_groups$])
            .pipe(
                map(([graph_groups, sway_groups]) => {
                    const mapped = sway_groups.map(({ id }) => graph_groups.find(group => group.id === id));
                    const filtered = mapped.filter(res => !!res); // groups may have been deleted from tenant
                    return filtered;
                }));

        const groups$ = combineLatest([filtered_graph_groups$, graph_users$])
            .pipe(
                map(([groups, members]) => [...groups, { id: tenant, members }] as Group[]) // add 'default' group
            );

        // data sources for tenant baselines
        const tenant_registry_items = this.tenantSpecService.getAll(tenant);
        const tenant_data_sources = tenant_registry_items.map(item => item.select_data);
        const tenant_data_statuses = tenant_registry_items.map(item => item.select_status);

        const tenant_data$ = combineLatest(tenant_data_sources)
            .pipe(
                map(sources => sources.map((data, i) => ({
                    data,
                    tag: tenant_registry_items[i].tag
                })))
            );

        // data sources for group baselines
        const group_registry_items = this.groupSpecService.getAll(tenant);
        const group_data_sources = group_registry_items.map(item => item.select_data);
        const group_data_statuses = group_registry_items.map(item => item.select_status);

        const group_data$ = combineLatest(group_data_sources)
            .pipe(
                map(sources => sources.map((data, i) => ({
                    data,
                    tag: group_registry_items[i].tag
                })))
            );


        // this.store.pipe(select(selectSwaySpecStatus), filter(status => status.loaded)).subscribe(item => console.log('[dev] 1', item))
        // this.store.pipe(select(client(tenant).sway.baselines.status), filter(status => status.loaded)).subscribe(item => console.log('[dev] 2', item))
        // this.store.pipe(select(client(tenant).sway.deviations.status), filter(status => status.loaded)).subscribe(item => console.log('[dev] 3', item))
        // this.store.pipe(select(client(tenant).graph.users.status), filter(status => status.loaded)).subscribe(item => console.log('[dev] 4', item))
        // this.store.pipe(select(client(tenant).graph.groups.status), filter(status => status.loaded)).subscribe(item => console.log('[dev] 5', item))
        // this.store.pipe(select(client(tenant).graph.groups.members.status), filter(status => status.loaded)).subscribe(item => console.log('[dev] 6', item))
        // this.store.pipe(select(client(tenant).sway.groups.status), filter(status => status.loaded)).subscribe(item => console.log('[dev] 7', item))
        // combineLatest(tenant_data_statuses).subscribe(item => console.log('[dev] tenant data statuses:', item))
        // combineLatest(group_data_statuses).subscribe(item => console.log('[dev] group data statuses:', item))
        // for (let index = 0; index < group_data_statuses.length; index++) {
        //     const element = group_data_statuses[index];
        //     element.subscribe(item => console.log(index, item))
        // }

        // control flow by checking all relevant statuses
        // see sample operator, below
        const ready$ = combineLatest([
            this.store.pipe(select(selectSwaySpecStatus)),
            this.store.pipe(select(client(tenant).sway.baselines.status)),
            this.store.pipe(select(client(tenant).sway.deviations.status)),
            this.store.pipe(select(client(tenant).graph.users.status)),
            this.store.pipe(select(client(tenant).graph.groups.status)),
            this.store.pipe(select(client(tenant).graph.groups.members.status)),
            this.store.pipe(select(client(tenant).sway.groups.status)),

            ...tenant_data_statuses,
            ...group_data_statuses
        ])
            .pipe(
                // tap(item => console.log('ALL', item)),
                filter(items => items.every(s => ((s.loaded || !!s.error) && !s.updating && !s.creating)))
            );

        const tenantDataErrors$ = combineLatest(tenant_data_statuses)
            .pipe(
                map(statuses => statuses.map(status => Boolean(status.error)))
            );

        const groupDataErrors$ = combineLatest(group_data_statuses)
            .pipe(
                map(statuses => statuses.map(status => Boolean(status.error)))
            );

        return combineLatest([
            specs$,
            baselines$,
            deviations$,
            graph_users$,
            groups$,
            tenant_data$,
            group_data$,
            tenantDataErrors$,
            groupDataErrors$,
        ]).pipe(
            sample(ready$), // control flow
            throttleTime(1000, undefined, { leading: true, trailing: true }) // control flow
        );

    }

    // TENANT

    private detectResolveTenantDeviations(tenant: string, specifications: SwaySpec[], baselines: Baseline[], deviations: SwayDeviation[], tagged: TenantTaggedData[], errors: boolean[]) {
        const dismissals = [];
        const creations = [];

        const bases = baselines.filter(item => item.type === 'tenant');

        for (const base of bases) {
            const spec = specifications.find(spec => spec.id === base.spec_id);
            const deviation = deviations.find(devi => devi.baseline_id === base.id);
            const data_index = tagged.findIndex(data => data.tag === spec.tag);
            const data = tagged[data_index]?.data;
            const dataErrored = errors[data_index];

            if (data_index === -1 || dataErrored) {
                console.log('[deviation] data error for', spec.name);
                continue;
            }

            const has_errors = (this.validate(data, base.schema)).length > 0;

            // no problem
            if (!deviation && !has_errors) {
                continue;
            }

            // deviated, but already recorded
            if (!!deviation && has_errors) {
                continue;
            }

            // deviation has been resolved
            if (!!deviation && !has_errors) {
                const update = {
                    deviation_id: deviation.id,
                    data: { // resolution type is going to be detected at the backend
                        resolve_time: (new Date()).toISOString(),
                        resolved_schema: base.schema,
                        resolved_data: data,

                    }
                };
                dismissals.push(update);
                continue;
            }

            // new deviation
            if (!deviation && has_errors) {
                const dev = this.createDeviationObj(tenant, base.spec_id, base.id, null, null, base.schema, data, 'tenant');
                creations.push(dev);
                continue;
            }
        }

        return { creations, dismissals };

    }

    // GROUP
    private detectResolveGroupDeviations(
        tenant: string,
        specs: SwaySpec[],
        all_baselines: Baseline[],
        deviations: SwayDeviation[],
        tagged: TenantTaggedData[],
        users: User[],
        groups: Group[],
        errors: boolean[]
    ) {

        const bases = all_baselines.filter(item => item.type === 'group');
        const registry_items = this.groupSpecService.getAll(tenant);
        const reg_user_data = registry_items.map((reg, idx) => ({
            zipped: Zipper(reg.matcher)(users, tagged[idx].data),
            errored: errors[idx],
            reg
        }));

        const user_base_data_spec: Array<[User, Baseline, any, SwaySpec]> = [];
        const exclusion = new Map<string, Set<string>>(); // don't apply same baseline type to a user twice

        const dismissals = [];
        const creations = [];

        // group users with baselines in priority
        for (const group of groups) {
            const _bases = bases.filter(base => base.group_id === group.id);
            for (const baseline of _bases) {
                const spec = specs.find(spec => spec.id === baseline.spec_id);
                const tag = spec.tag;

                const data_index = reg_user_data.findIndex(item => item.reg.tag === tag);
                const dataErrored = errors[data_index];

                if (data_index === -1 || dataErrored) {
                    console.log('[deviation] data error for', spec.name);
                    continue;
                }

                const user_data = reg_user_data[data_index].zipped;
                const members = user_data.filter(ud => group.members.find(mem => mem.id === ud[0].id));

                for (let j = 0; j < members.length; j++) {

                    const [user, data] = members[j];
                    if (!exclusion.has(user.id)) {
                        exclusion.set(user.id, new Set()); // init user exclusions
                    }

                    if (exclusion.get(user.id).has(baseline.spec_id)) {

                        const deviation = deviations.find(d => d.baseline_id === baseline.id && d.group_id === baseline.group_id && d.user_id === user.id);
                        if (!!deviation) {
                            const update = {
                                deviation_id: deviation.id,
                                data: {
                                    resolve_time: (new Date()).toISOString(),

                                    resolution_type: 'baseline-priority-changed'
                                }
                            };

                            dismissals.push(update);
                        }

                        continue; // baseline from higher priority group already assigned
                    }

                    user_base_data_spec.push([user, baseline, data, spec]);

                    exclusion.get(user.id).add(baseline.spec_id); // add spec tag to exclusions

                }
            }
        }


        for (let i = 0; i < user_base_data_spec.length; i++) {

            const [user, baseline, data, spec] = user_base_data_spec[i];


            const exclude_status = reg_user_data.find(item => item.reg.tag === spec.tag)?.reg?.excluded ? reg_user_data.find(item => item.reg.tag === spec.tag)?.reg?.excluded(data, specs, all_baselines)
                : null;


            if (!data) {
                const deviation = deviations.find(d => d.baseline_id === baseline.id && d.group_id === baseline.group_id && d.user_id === user.id);

                if (!!deviation) { // a deviation entry exists, but this user doesn't have CAS mailbox or the related data
                    const update = {
                        deviation_id: deviation.id,
                        data: {
                            resolve_time: (new Date()).toISOString(),
                            resolution_type: 'data-deleted-resource-external'
                        }
                    };
                    dismissals.push(update);
                }
                continue;
            }

            const has_errors = (this.validate(data, baseline.schema)).length > 0;
            const deviation = deviations.find(d => d.baseline_id === baseline.id && d.group_id === baseline.group_id && d.user_id === user.id);


            const requiredPlans = this.groupSpecService.get(tenant, spec.tag).plans || [];

            const has_required_plans =
                requiredPlans.length > 0 ?
                    user.assignedPlans.some(assignedPlan => requiredPlans.some(requiredPlan => (requiredPlan === assignedPlan.servicePlanId)))
                    : true;




            if (!!deviation && !has_required_plans) {
                const update = {
                    deviation_id: deviation.id,
                    data: {
                        resolve_time: (new Date()).toISOString(),
                        resolution_type: 'data-deleted-resource-external'
                    }
                };
                dismissals.push(update);
                continue;
            }

            if (!!deviation && !!exclude_status) { // a deviation entry exists, but this user should be excluded, could happen rarely
                const update = {
                    deviation_id: deviation.id,
                    data: {
                        resolve_time: (new Date()).toISOString(),
                        resolution_type: 'baseline-overridden'// TODO, karim.
                    }
                };
                dismissals.push(update);
                continue;
            }

            if (!deviation && !has_errors) continue; // no problem
            if (!deviation && !has_required_plans) continue; // no problem
            if (!!deviation && has_errors) continue; // deviated, but already recorded

            if (!!deviation && !has_errors && !exclude_status) { // a deviation entry exists, but problem is now resolved
                const update = {
                    deviation_id: deviation.id,
                    data: { // resolution type is going to be detected at the backend
                        resolve_time: (new Date()).toISOString(),
                        resolved_schema: baseline.schema,
                        resolved_data: data,

                    }
                };
                dismissals.push(update);
                continue;
            }

            const user_baseline = all_baselines.find(b => b.user_id === user.id && b.spec_id === baseline.spec_id);

            if (!deviation && has_errors && !exclude_status && !user_baseline) { // there's a new deviation and not excluded
                const dev = this.createDeviationObj(tenant, baseline.spec_id, baseline.id, baseline.group_id, user.id, baseline.schema, data, 'group');
                creations.push(dev);
                continue;
            }
        }

        return { creations, dismissals };
    }




    private detectResolveUserDeviations(
        tenant: string,
        specs: SwaySpec[],
        all_baselines: Baseline[],
        deviations: SwayDeviation[],
        tagged: TenantTaggedData[],
        users: User[],
    ) {

        const bases = all_baselines.filter(item => item.type === 'user'); // user baselines;
        const registry_items = this.groupSpecService.getAll(tenant);


        const dismissals = [];
        const creations = [];


        for (const baseline of bases) {
            const spec = specs.find(spec => spec.id === baseline.spec_id);
            const tag = spec.tag;
            const user = users.find(user => user.id === baseline.user_id);
            const matcher = registry_items.find(reg => reg.tag === tag).matcher; // matcher
            const data = tagged.find(item => item.tag === tag).data?.find(data => matcher(user, data));





            if (!data) {
                const deviation = deviations.find(d => d.baseline_id === baseline.id && d.group_id === baseline.group_id && d.user_id === user.id);

                if (!!deviation) { // a deviation entry exists, but this user doesn't have CAS mailbox or the related data
                    const update = {
                        deviation_id: deviation.id,
                        data: {
                            resolve_time: (new Date()).toISOString(),
                            resolution_type: 'data-deleted-resource-external'
                        }
                    };
                    dismissals.push(update);
                }
                continue;
            }

            const has_errors = (this.validate(data, baseline.schema)).length > 0;
            const deviation = deviations.find(d => d.baseline_id === baseline.id && d.group_id === baseline.group_id && d.user_id === user.id);


            const requiredPlans = this.groupSpecService.get(tenant, spec.tag).plans || [];

            const has_required_plans =
                requiredPlans.length > 0 ?
                    user.assignedPlans.some(assignedPlan => requiredPlans.some(requiredPlan => (requiredPlan === assignedPlan.servicePlanId)))
                    : true;




            if (!!deviation && !has_required_plans) {
                const update = {
                    deviation_id: deviation.id,
                    data: {
                        resolve_time: (new Date()).toISOString(),
                        resolution_type: 'data-deleted-resource-external'
                    }
                };
                dismissals.push(update);
                continue;
            }


            if (!deviation && !has_errors) continue; // no problem
            if (!deviation && !has_required_plans) continue; // no problem
            if (!!deviation && has_errors) continue; // deviated, but already recorded

            if (!!deviation && !has_errors) { // a deviation entry exists
                const update = {
                    deviation_id: deviation.id,
                    data: { // resolution type is going to be detected at the backend
                        resolve_time: (new Date()).toISOString(),
                        resolved_schema: baseline.schema,
                        resolved_data: data,

                    }
                };
                dismissals.push(update);
                continue;
            }


            if (!deviation && has_errors) { // there's a new deviation
                const dev = this.createDeviationObj(tenant, baseline.spec_id, baseline.id, baseline.group_id, user.id, baseline.schema, data, 'user');
                creations.push(dev);
                continue;
            }
        }





        return { creations, dismissals };
    }


    private resolveDeletedGroupDeviations(tenant: string, deviations: SwayDeviation[], groups: Group[]) {
        const dismissals = [];

        for (const deviation of deviations) {
            const group_exists = groups.find(group => group.id === deviation.group_id);
            if (group_exists) {
                continue;
            } else {
                const update = {
                    deviation_id: deviation.id,
                    data: {
                        resolve_time: (new Date()).toISOString(),
                        resolution_type: 'data-deleted-group-external'
                    }
                };
                dismissals.push(update);
            }
        }

        return dismissals;

    }

    private resolveDeviationsWhereUserLeftGroup(_tenant: string, deviations: SwayDeviation[], groups: Group[]) {

        const dismissals = [];

        for (const group of groups) {
            for (const dev of deviations) {

                if (dev.group_id === group.id && !!dev.user_id) {
                    if (!group.members.some(m => m.id === dev.user_id)) {
                        const update = {
                            deviation_id: dev.id,
                            data: {
                                resolve_time: (new Date()).toISOString(),
                                resolution_type: 'data-removed-group-user-external'
                            }
                        };
                        dismissals.push(update);
                    }
                }
            }
        }
        return dismissals;
    }


    private resolveUserExceptionDeviations(_tenant: string, group_deviations: SwayDeviation[], all_baselines: Baseline[]) {
        const user_baselines = all_baselines.filter(res => res.type === 'user');
        const dismissals_deviations = group_deviations.filter(group_dev => user_baselines.some(user_baseline => user_baseline.user_id === group_dev.user_id && user_baseline.spec_id === group_dev.spec_id));

        const dismissals = dismissals_deviations.map(dev => {
            return {
                deviation_id: dev.id,
                data: {
                    resolve_time: (new Date()).toISOString(),
                    resolution_type: 'baseline-overridden-user' 
                }
            }
        });

        return dismissals;
    }


    private resolveDeviationsForDeletedBaselines(tenant: string, baselines: Baseline[], deviations: SwayDeviation[]) {

        const dismissals = [];

        for (const deviation of deviations) {

            const has_baseline = baselines.some(b => b.id == deviation.baseline_id);

            if (!has_baseline) {

                const update = {
                    deviation_id: deviation.id,
                    data: {
                        resolve_time: (new Date()).toISOString(),
                        resolution_type: 'baseline-deleted'
                    }
                };

                dismissals.push(update);
            }
        }

        return dismissals;

    }

    /**
     * private helper
     */
    private validate(data: any, schema: any) {
        const validate = this.ajv.compile(schema);
        return validate(data) ? [] : validate.errors!;
    }

    /**
     * private helper
     */
    private createDeviationObj(tenant_id: string, spec_id: string, baseline_id: string, group_id: string, user_id: string, schema: any, data: any, type: 'tenant' | 'group' | 'user') {
        const deviation: Partial<SwayDeviation> = {
            tenant_id,
            spec_id,
            baseline_id,
            group_id,
            user_id,
            type,
            schema,
            data
        };

        return deviation;
    }

}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as actions from './actions';

@Injectable()
export class CwmConnectionEffects {
    loadMspCWMConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMspCWMConnection),
            mergeMap(() => this.mspAjax.get('/api/octiga/v2/msp/cwm')
                .pipe(
                    map(data => actions.loadMspCWMConnectionSuccess({ data })),
                    catchError((error) => {
                        // this.store.dispatch(updateMsp({ data: { cwm: false } }));
                        return of(actions.loadMspCWMConnectionFailure({ error }));
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private mspAjax: MspAjaxService,
    ) { }

}

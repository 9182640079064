import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromBilling from '../../store/reducers';
import { SubscriptionPlan } from '../subscription-plan/subscription-plan.component';
import { OkDialogData, OkDialogComponent } from 'src/app/modules/shared/components/ok-dialog/ok-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { client } from 'src/app/stores/client';
import { deleteTenantRequest } from 'src/app/stores/client/octiga/tenant/actions';

@Component({
    selector: 'billing-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('delete_stepper') delete_stepper: MatStepper;
    subscriptions: Subscription[] = [];
    plans: SubscriptionPlan[] = [
        {
            title: 'Trial',
            description: 'Free trial of Octiga Cloud Security',
            color: '#00b3fa80',
            price: '',
            features: ['Incident Response', 'Event History'],
            disabled: true
        },
        {
            title: 'Premium',
            description: 'Unlimited Octiga Cloud Security',
            color: '#00b3fa',
            price: '€2 / Mailbox / Month',
            features: ['Incident Response', 'Event History', 'Email Alerts', 'Auto Response'],
            disabled: false
        },
    ];
    tenant_id = this.route.snapshot.paramMap.get('tenant');
    tenantName: string;

    constructor(
        private store: Store<fromBilling.State>,
        private clientStore: Store<any>,
        private _dialog: MatDialog,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.clientStore.pipe(
            select(client(this.tenant_id).octiga.tenant.data),
            filter(tenant => !!tenant.name),
            map(tenant => tenant.name),
            take(1)).subscribe(name => {
            this.tenantName = name;
        });
    }

  
    ngAfterViewInit() {
        this.subscriptions.push(this.store.pipe(select(fromBilling.selectStep)).subscribe(step => this.stepper.selectedIndex = step));
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }

    }

    sendDeleteTenantRequest() {
        this.store.dispatch(deleteTenantRequest({ _tenant: this.tenant_id }));
        this.delete_stepper.selected.completed = true;
        this.delete_stepper.next();
        // this.delete_stepper.selectedIndex++;
    }

    openConfirmDialog() {
        const data: OkDialogData = {
            title: 'We are sorry to see you go :(',
            description: 'Are you sure you want to delete your tenant from Octiga?',
            boldMessage: 'Click \'Confirm\' to send us a removal request.',
            close_button_text: 'Cancel',
            close_button_class: 'cancel',
            confirm_button_text: 'Confirm',
            confirm_button_class: 'primary'
        };
        const dialogRef = this._dialog.open(OkDialogComponent, { width: '40rem', data: data });

        dialogRef.beforeClosed().pipe(take(1)).subscribe(confirm => {
            if (!!confirm) {

                const data: OkDialogData = {
                    title: 'We are sorry to see you go :(',
                    description: 'Your tenant removal request has been received, we will contact you once it is done.',
                    close_button_text: 'OK'
                };
                this._dialog.open(OkDialogComponent, { width: '600px', data: data });
            }
        });
    }

}

import { Status } from '../../../status.interface';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { UserMailboxSetting } from './model';

export const featureKey = 'mailboxSettings';

export interface State extends EntityState<UserMailboxSetting>, Status {
    // additional entities state properties
}

export const adapter: EntityAdapter<any> = createEntityAdapter<UserMailboxSetting>();

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.fetchMailboxSettingsSuccess,
        (state, action) => adapter.setAll(action.data, { ...state, loaded: true })
    ),
    on(actions.fetchMailboxSettingsSuccessFailure,
        (state, { error }) => ({ ...state, error, loaded: false })
    ),

    on(actions.updateMailboxSettings,
        (state, action) => ({ ...state, updating: true })
    ),

    on(actions.updateMailboxSettingsSuccess,
        (state, action) => adapter.updateOne({ id: action.data.id, changes: { ...action.data }} , {...state , updating: false} )
    ),

    on(actions.updateMailboxSettingsFailure,
        (state, { error }) => ({ ...state, error, updating: false })
    ),
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();


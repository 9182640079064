<div *ngIf="!default_domain">
    <p class="warning">
        You can't remediate this baseline as you don't have a "Default" domain. Set default domain then remediate this
        baseline.

        Check the following doc for help .

        <a href="https://docs.microsoft.com/en-us/microsoft-365/admin/setup/domains-faq" target="_blank">
            <img width="14" class="read-more-img" src="assets/img/sway/readmore.svg" alt="">
        </a>
    </p>
</div>

<div *ngIf="default_domain && !dkimForDefaultDomain">
    <p class="warning">
        You can't remediate this baseline as No DKIM keys saved for this domain.
        Check the following doc for help .

        <a href="https://learn.microsoft.com/en-us/microsoft-365/security/office-365-security/email-authentication-dkim-configure?view=o365-worldwide"
            target="_blank">
            <img width="14" class="read-more-img" src="assets/img/sway/readmore.svg" alt="">
        </a>
    </p>
</div>

<div class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                DKIM Signing for Default Domain

                (<span class="bold">{{default_domain?.id}}</span>)
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>

            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>

<ng-template #validatorRef>
    <div class="validator">
        <div *ngIf="!default_domain; else elseBlock">
            No Default Domain Available
        </div>

        <ng-template #elseBlock>

            <div *ngIf="dkimForDefaultDomain">
                {{dkimForDefaultDomain?.Enabled ? 'Enabled' : 'Disabled'}}
            </div>


            <div *ngIf="!dkimForDefaultDomain">
                No DKIM keys saved for this domain.
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #formRef>
    <div class="form">
        <div class="field boolean">
            <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="control">
                    <mat-option [value]="true">Enable</mat-option>
                    <mat-option [value]="false">Disable</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>
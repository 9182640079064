import { createAction, props } from '@ngrx/store';
import { TargetTenant } from './model';

export const loadTargetTenant = createAction(
    '[EventRuleTargetTenant] Load'
);

export const loadTargetTenantSuccess = createAction(
    '[EventRuleTargetTenant] Load Success',
    props<{ tenants: TargetTenant[] }>()
);

export const loadTargetTenantFailure = createAction(
    '[EventRuleTargetTenant] Load Failure',
    props<{ error: any }>()
);

export const createTargetTenant = createAction(
    '[EventRuleTargetTenant] Create',
    props<{ tenant: TargetTenant }>()
);

export const createTargetTenantSuccess = createAction(
    '[EventRuleTargetTenant] Create Success',
    props<{ tenant: TargetTenant }>()
);

export const createTargetTenantFailure = createAction(
    '[EventRuleTargetTenant] Create Failure',
    props<{ error: any }>()
);

export const deleteTargetTenant = createAction(
    '[EventRuleTargetTenant] Delete',
    props<{ id: string }>()
);

export const deleteTargetTenantSuccess = createAction(
    '[EventRuleTargetTenant] Delete Success',
    props<{ id: string }>()
);

export const deleteTargetTenantFailure = createAction(
    '[EventRuleTargetTenant] Delete Failure',
    props<{ id: string; error: any }>()
);

<div class="wrapper">
    <div class="spinner">
        <!-- <button mat-raised-button color="primary" *ngIf="!isLoading && !startFetching" (click)="fetchData()">
            Fetch InboxRules
        </button> -->
        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="session">
        <div class="image" [class.disabled]="isLoading || !startFetching" (click)="browse('dashboard','mailflow/inbox-rules')">
            <img class="icon" src="./assets/img/dashboard/mailbox-rules.svg" />
            <div class="middle">
                <div class="text">Click to Open</div>
            </div>
            <app-severity-badges *ngIf="!isLoading" [severity]="inboxRules$ | async"></app-severity-badges>
        </div>
        <span class="mat-h2" [class.disabled]="isLoading || !startFetching">Inbox Rules</span>
    </div>
   
    <div class="session" >
        <div class="image" [class.disabled]="isLoading[1]" (click)="browse('dashboard','mailflow/transport-rules')">
            <img class="icon" src="./assets/img/dashboard/transport-rules.svg" />
            <div class="middle">
                <div class="text">Click to Open</div>
            </div>

            <!-- <app-severity-badges [severity]="transportRules$ | async"></app-severity-badges> -->
        </div>
        
        <span class="mat-h2">Transport Rules</span>
    </div>
</div>
import { Group, User } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadMembers = createAction(
    '[Graph/Group Members] Load',
    props<{ _tenant: string, id: string }>()
);

export const loadMembersSuccess = createAction(
    '[Graph/Group Members] Load Success',
    props<{ _tenant: string, id: string, members: User[] }>()
);

export const loadMembersFailure = createAction(
    '[Graph/Group Members] Load Failure',
    props<{ _tenant: string, id: string, error: any }>()
);

export const membersLoaded = createAction(
    '[Graph/Group Members] Loaded',
    props<{ _tenant: string }>()
);


export const addMemberToGroup = createAction(
    '[Graph/Group Members] add Member',
    props<{ _tenant: string, group: Group, user: User }>()
);

export const addMemberToGroupSuccess = createAction(
    '[Graph/Group Members] add member Success',
    props<{ _tenant: string, group: Group, user: User }>()
);

export const addMemberToGroupFailure = createAction(
    '[Graph/Group Members] add member Failure',
    props<{ _tenant: string, group: Group, error: any }>()
);



export const removeMemberFromGroup = createAction(
    '[Graph/Group Members] remove',
    props<{ _tenant: string, group: Group, userId: string }>()
);

export const removeMemberFromGroupSuccess = createAction(
    '[Graph/Group Members] remove member Success',
    props<{ _tenant: string, group: Group, userId: string }>()
);

export const removeMemberFromGroupFailure = createAction(
    '[Graph/Group Members] remove member Failure',
    props<{ _tenant: string, group: Group, error: any }>()
);
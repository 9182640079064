import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { SpinnerService } from './spinner.service';

@Injectable({
    providedIn: 'root'
})
export class SynchronousGuard implements CanActivate, CanActivateChild {

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.run(route, state);
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.run(route, state);
    }

    constructor(private injector: Injector, private spinnerService: SpinnerService) { }

    private async run(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ) {
        this.spinnerService.startSpinner();
        
        const guards = route.data.guards || [];
        

        for (const guard of guards) {
            const instance: CanActivate = this.injector.get(guard);
            let result = instance.canActivate(route, state);
            //Depending on the route result, we may need to await upon it in different ways.
            if (result instanceof Promise) {
                result = await result;
            }
            if (result instanceof Observable) {
                result = await firstValueFrom(result);
            }
            if (result === false || result instanceof UrlTree) {
                this.spinnerService.stopSpinner();
                return result;
            }
        }
        this.spinnerService.stopSpinner();

        return true;
    }

}

import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/interfaces/octiga/user.interface';

export const loadUsers = createAction(
    '[Octiga/User] Load Users',
    props<{ _tenant: string }>()
);

export const loadUsersSuccess = createAction(
    '[Octiga/User] Load Users Success',
    props<{ _tenant: string, data: User[] }>()
);

export const loadUsersFailure = createAction(
    '[Octiga/User] Load Users Failure',
    props<{ _tenant: string, error: any }>()
);

export const addUser = createAction(
    '[Octiga/User] Add User',
    props<{ _tenant: string, user: User }>()
);

export const upsertUser = createAction(
    '[Octiga/User] Upsert User',
    props<{ _tenant: string, user: User }>()
);

export const addUsers = createAction(
    '[Octiga/User] Add Users',
    props<{ _tenant: string, users: User[] }>()
);

export const upsertUsers = createAction(
    '[Octiga/User] Upsert Users',
    props<{ _tenant: string, users: User[] }>()
);

export const updateUser = createAction(
    '[Octiga/User] Update User',
    props<{ _tenant: string, user: Update<User> }>()
);

export const updateUsers = createAction(
    '[Octiga/User] Update Users',
    props<{ _tenant: string, users: Update<User>[] }>()
);

export const deleteUser = createAction(
    '[Octiga/User] Delete User',
    props<{ _tenant: string, user: User }>()
);

export const deleteUsers = createAction(
    '[Octiga/User] Delete Users',
    props<{ _tenant: string, ids: string[] }>()
);

export const clearUsers = createAction(
    '[Octiga/User] Clear Users',
    props<{ _tenant: string }>()
);

export const updateUserNotifications = createAction(
    '[Octiga/User] Update User Notifications',
    props<{ _tenant: string, id: string, notifications: any }>()
);

export const updateUserNotificationsSuccess = createAction(
    '[Octiga/User] Update User Notifications Success',
    props<{ _tenant: string, user: User }>()
);

export const updateUserNotificationsFailure = createAction(
    '[Octiga/User] Update User Notifications Failure',
    props<{ _tenant: string, error: any }>()
);

import { EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { createStatusEntityAdapter, EntityStatus, initialStatus } from '../../../../status.interface';
import * as actions from './actions';
import { CasMailbox } from './model';

export const featureKey = 'exoCASMailboxes';

export interface State extends EntityState<CasMailbox>, EntityStatus { }

export const adapter = createStatusEntityAdapter<CasMailbox>({
    selectId: (mailbox) => mailbox.Identity
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.fetchCasMailboxesSuccess, (state, { data }) => adapter.setAll(data, { ...state, loaded: true })),
    on(actions.fetchCasMailboxesFailure, (state, { error }) => ({ ...state, error, loaded: false })),
    on(actions.updateCasMailbox, (state, { data, field }) => adapter.setEntityStatus(data as CasMailbox, state, { updating: true }, field)),
    // NOTES: same action can't have multiple reducers, so I changed the 1st updateCaseMailboxSuccess -> updateCasMailboxEnitityStatus
    on(actions.updateCasMailboxEntityStatus, (state, { data, field }) => adapter.setEntityStatus(data as CasMailbox, state, { updating: false, error: false }, field)),
    // NOTES: I changed from updateOne to upsertOne as the subscription on CasMailbox.all selector doesn't work for updateOne.
    on(actions.updateCasMailboxSuccess, (state, { data }) => adapter.upsertOne(data, { ...state, updating: false })),
    on(actions.updateCasMailboxFailure, (state, { data, field, error }) => adapter.setEntityStatus(data as CasMailbox, state, { updating: false, error }, field))
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
    selectEntityStatus
} = adapter.getSelectors();

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { AntiPhishPolicy } from './model';


export const featureKey = 'exoAntiPhishPolicy';
export interface State extends EntityState<AntiPhishPolicy>, Status { }

export const adapter: EntityAdapter<AntiPhishPolicy> = createEntityAdapter<AntiPhishPolicy>({
    selectId: (entity) => entity.Id
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.loadAntiPhishPolicySuccess,
        (state, action) => adapter.setAll(action.data, { ...state, loaded: true, error: null })
    ),
    on(actions.loadAntiPhishPolicyFailure,
        (state, { error }) => ({ ...state, error })
    ),

    on(actions.updateExoAntiPhishPolicy,
        (state) => ({ ...state, updating: true, error: null })
    ),
    on(actions.updateExoAntiPhishPolicySuccess,
        (state, action) => adapter.upsertOne(action.data, ({ ...state, updating: false, error: null }))
    ),
    on(actions.updateExoAntiPhishPolicyFailure,
        (state, { error }) => ({ ...state, updating: false, error })
    )
);


export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();



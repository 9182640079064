
<mat-hint color="warn" class="main-hint block" *ngIf="!policy?.id">
    There is no {{displayName}}, when you click remediate you are going to create new compliance policy with the desired
    values of the baseline
</mat-hint>

<mat-error *ngIf="formErrors">
    Please fix the baseline errors
</mat-error>


<form [formGroup]="form" *ngIf="loaded">



    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Basics
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status [hasError]="!policy?.id || errorString.includes('/policy/description')"></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="field">

                                Display Name
                            </td>

                            <td colspan="3">
                                {{displayName}}
                            </td>
                        </tr>


                        <tr>
                            <td class="field">

                                Description
                            </td>

                            <td>
                                {{policy?.description}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline">
                                    <textarea formControlName="description" matInput> </textarea>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/description')"></sway-baseline-status>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Device Health

                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                        [hasError]="
                        !policy?.id ||
                        (errorString.includes('/policy/bitLockerEnabled') ||
                        errorString.includes('/policy/secureBootEnabled') ||
                        errorString.includes('/policy/codeIntegrityEnabled') )

                        "
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td class="field">

                                BitLocker
                            </td>

                            <td>
                                {{policy?.bitLockerEnabled ? 'Required' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="bitLockerEnabled">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/bitLockerEnabled')"></sway-baseline-status>
                            </td>
                        </tr>

                        <tr>
                            <td class="field">

                                Secure Boot
                            </td>

                            <td>
                                {{policy?.secureBootEnabled ? 'Required' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="secureBootEnabled">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/secureBootEnabled')"></sway-baseline-status>

                            </td>
                        </tr>


                        <tr>
                            <td class="field">

                                Code Integrity
                            </td>

                            <td>
                                {{policy?.codeIntegrityEnabled ? 'Required' : 'Not Configured'}}

                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="codeIntegrityEnabled">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/codeIntegrityEnabled')"></sway-baseline-status>

                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Device Properties
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                        [hasError]="
                        !policy?.id ||

                        (errorString.includes('/policy/osMinimumVersion') ||
                        errorString.includes('/policy/osMaximumVersion') ||
                        errorString.includes('/policy/mobileOsMinimumVersion') ||
                        errorString.includes('/policy/mobileOsMaximumVersion') ||
                        errorString.includes('/policy/validOperatingSystemBuildRanges') )
                        "
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Minimum OS version <mat-icon
                                        [matTooltip]="tooltips.osMinimumVersion">info</mat-icon>
                                </div>

                            </td>

                            <td>
                                {{policy?.osMinimumVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline" class="full-width">

                                    <input matInput formControlName="osMinimumVersion" />

                                    <mat-error *ngIf="form.get('osMinimumVersion').hasError('max')">Must be smaller than the maximum
                                        OS version</mat-error>
                                    <mat-error *ngIf="form.get('osMinimumVersion').hasError('pattern')">Must be a valid version
                                        format. Example: 4.3.06, 7.01.4, etc.</mat-error>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMinimumVersion')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Maximum OS version<mat-icon
                                        [matTooltip]="tooltips.osMaximumVersion">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.osMaximumVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline" class="full-width">

                                    <input matInput formControlName="osMaximumVersion" />
                                    <mat-error *ngIf="form.get('osMaximumVersion').hasError('pattern')">Must be a valid version
                                        format. Example: 4.3.06, 7.01.4, etc.</mat-error>
                                    <mat-error *ngIf="form.get('osMaximumVersion').hasError('min')">Must be greater than the minimum
                                        OS version</mat-error>

                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMaximumVersion')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Minimum OS version for mobile devices
                                    <mat-icon
                                        [matTooltip]="tooltips.mobileOsMinimumVersion">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.mobileOsMinimumVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field class="full-width" appearance="outline">

                                    <input matInput formControlName="mobileOsMinimumVersion" />

                                    <mat-error *ngIf="form.get('mobileOsMinimumVersion').hasError('pattern')">Must be a valid
                                        version format. Example: 4.3.06, 7.01.4, etc.</mat-error>
                                    <mat-error *ngIf="form.get('mobileOsMinimumVersion').hasError('max')">Must be smaller than the
                                        maximum OS version for mobile devices</mat-error>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/mobileOsMinimumVersion')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Maximum OS version for mobile devices<mat-icon
                                        [matTooltip]="tooltips.mobileOsMaximumVersion">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.mobileOsMaximumVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field class="full-width" appearance="outline">

                                    <input matInput formControlName="mobileOsMaximumVersion" />

                                    <mat-error *ngIf="form.get('mobileOsMaximumVersion').hasError('pattern')">Must be greater than
                                        the minimum OS version for mobile devices</mat-error>
                                    <mat-error *ngIf="form.get('mobileOsMaximumVersion').hasError('min')">Must be a valid version
                                        format. Example: 4.3.06, 7.01.4, etc.</mat-error>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/mobileOsMaximumVersion')"></sway-baseline-status>
                            </td>
                        </tr>




                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Valid operating system builds
                                    <mat-icon matTooltip="">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                <div *ngIf="policy?.validOperatingSystemBuildRanges?.length >  0" class="os-item-wrapper">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Description </th>
                                                <th>Minimum version </th>
                                                <th>Maximum version </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let item of policy?.validOperatingSystemBuildRanges" class="os-item">
                                                <td>{{item.description}}</td>
                                                <td>{{item.lowestVersion}}</td>
                                                <td>{{item.highestVersion}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>

                            <td formArrayName="validOperatingSystemBuildRanges">

                                <div *ngIf="!readOnly" class="button-wrapper">
                                    <button matTooltip="Add operation system range"
                                        (click)="addOperatingSystemBuildRangesGroupRow()" mat-icon-button>
                                        <mat-icon color="primary">add_circle</mat-icon>
                                    </button>

                                </div>

                                <div *ngFor="let rangeItem of validOperatingSystemBuildRangesControls; index as i"
                                    [formGroupName]="i">

                                    <div class="builds-wrapper">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Description</mat-label>
                                            <input formControlName="description" matInput>
                                        </mat-form-field>

                                        <mat-form-field class="min-version" appearance="outline">
                                            <mat-label>Min Version</mat-label>
                                            <input formControlName="lowestVersion" matInput>
                                        </mat-form-field>

                                        <mat-form-field class="max-version" appearance="outline">
                                            <mat-label>Max Version</mat-label>
                                            <input formControlName="highestVersion" matInput>
                                        </mat-form-field>


                                        <button *ngIf="!readOnly" (click)="removeOperatingSystemBuildRangesGroupRow(i)"
                                            mat-icon-button>
                                            <mat-icon color="warn">delete</mat-icon>
                                        </button>
                                    </div>

                                    <div *ngIf="form.get('validOperatingSystemBuildRanges')?.get([i]).touched" class="errors">
                                        <mat-error
                                            *ngIf="form.get('validOperatingSystemBuildRanges')?.get([i]).get('lowestVersion').errors || form.get('validOperatingSystemBuildRanges').get([i]).get('highestVersion').errors">Must
                                            be a valid version format. Example: 4.3.06, 7.01.4, etc. and minimum version and maximum
                                            version are required</mat-error>
                                    </div>
                                </div>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/validOperatingSystemBuildRanges')"></sway-baseline-status>

                            </td>
                        </tr>



                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>



    <div class="mb-6"></div>




    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Configuration Manager Compliance


                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                        [hasError]="!policy?.id || errorString.includes('/policy/configurationManagerComplianceRequired')"
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>


                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Require device compliance from Configuration Manager <mat-icon
                                        [matTooltip]="tooltips.configurationManagerComplianceRequired">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.configurationManagerComplianceRequired ? 'Required' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="configurationManagerComplianceRequired">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/configurationManagerComplianceRequired')"></sway-baseline-status>

                            </td>

                        </tr>


                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mb-6"></div>

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        System Security (Password)


                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                        [hasError]="
                        !policy?.id ||

                            (errorString.includes('/policy/passwordRequired')
                            || errorString.includes('/policy/passwordRequiredType')
                            || errorString.includes('/policy/passwordBlockSimple')

                            || errorString.includes('/policy/passwordMinimumCharacterSetCount')
                                || errorString.includes('/policy/passwordMinimumLength')
                                || errorString.includes('/policy/passwordMinutesOfInactivityBeforeLock')
                                || errorString.includes('/policy/passwordExpirationDays')
                                || errorString.includes('/policy/passwordPreviousPasswordBlockCount')
                                || errorString.includes('/policy/passwordRequiredToUnlockFromIdle'))

                        "

                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>


                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Require a password to unlock mobile devices <mat-icon
                                        [matTooltip]="tooltips.passwordRequired">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.passwordRequired ? 'Required' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="passwordRequired">
                                    Required
                                </mat-slide-toggle>

                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('!/policy/passwordRequired!')"></sway-baseline-status>

                            </td>

                        </tr>



                        <ng-container *ngIf="passwordRequired">

                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Simple passwords

                                        <mat-icon
                                            [matTooltip]="tooltips.passwordBlockSimple">info</mat-icon>

                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordBlockSimple ? 'Required' : 'Not Configured'}}
                                </td>

                                <td>
                                    Not Configured

                                    <mat-slide-toggle [disabled]="!passwordRequired" color="primary"
                                        formControlName="passwordBlockSimple">
                                        Block
                                    </mat-slide-toggle>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordBlockSimple')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Password type
                                        <mat-icon
                                            matTooltip="This setting determines the type of Password/PIN required. Device Default (Password, Numeric PIN, or Alphanumeric PIN required) Alphanumeric (Password or Alphanumberic PIN required) Numeric (Password or Numeric PIN required) Recommendations: Required password type: Alphanumeric, Password complexity: Require digits and lowercase letters">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordRequiredType || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-select (valueChange)="onPasswordRequiredTypeChange()" [disabled]="!passwordRequired"
                                            formControlName="passwordRequiredType">
                                            <mat-option value="deviceDefault">Device default</mat-option>
                                            <mat-option value="alphanumeric">Alphanumeric</mat-option>
                                            <mat-option value="numeric">Numeric</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordRequiredType')"></sway-baseline-status>

                                </td>

                            </tr>




                            <tr *ngIf="formValue.passwordRequiredType == 'alphanumeric'">

                                <td class="field">
                                    <div class="td-title">

                                        Password complicity
                                        <mat-icon matTooltip="This setting defines the occurrences of complex elements required for a strong PIN or password.
                                    Require digits, lowercase and uppercase letters is not supported in desktop Microsoft accounts and domain accounts
                                    Require digits, lowercase, uppercase, and special characters is not supported in desktop
                                    Default is Require digits and lowercase letters">info</mat-icon>

                                    </div>
                                </td>

                                <td>
                                    {{ policy?.passwordMinimumCharacterSetCount == 2 ?
                                    'Require digits and lowercase letters' :
                                    policy?.passwordMinimumCharacterSetCount == 3 ? 'Require digits, lowercase and uppercase letters' :
                                    policy?.passwordMinimumCharacterSetCount == 4 ? 'Require digits, lowercase, uppercase and special characters' : '' }}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-select [disabled]="!passwordRequired"
                                            formControlName="passwordMinimumCharacterSetCount">
                                            <mat-option [value]="2">Require digits and lowercase letters</mat-option>
                                            <mat-option [value]="3">Require digits, lowercase and uppercase letters</mat-option>
                                            <mat-option [value]="4">Require digits, lowercase, uppercase and special
                                                characters</mat-option>
                                        </mat-select>


                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordMinimumCharacterSetCount')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Minimum password length <mat-icon
                                            matTooltip="This setting specifies the minimum number of digits or characters in the password. Recommended Value: 4 (4-16)">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordMinimumLength || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">

                                        <input [readonly]="!passwordRequired" type="number" matInput
                                            formControlName="passwordMinimumLength" />
                                    </mat-form-field>

                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordMinimumLength')"></sway-baseline-status>

                                </td>

                            </tr>


                            <tr>
                                <td class="field">
                                    <div class="td-title">
                                        maximum minutes of inactivity before password is required
                                        <mat-icon
                                            matTooltip="This setting specifies the length of time without user input after which the mobile device screen is locked. Recommended value: 15 min">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordMinutesOfInactivityBeforeLock || 'Not Configured'}}
                                </td>

                                <td>

                                    <mat-form-field appearance="outline">

                                        <mat-select [disabled]="!passwordRequired"
                                            formControlName="passwordMinutesOfInactivityBeforeLock">
                                            <mat-option [value]="0">No configured</mat-option>
                                            <mat-option [value]="1">1 minute</mat-option>
                                            <mat-option [value]="5">5 minutes</mat-option>
                                            <mat-option [value]="15">15 minutes</mat-option>
                                            <mat-option [value]="60">1 hour</mat-option>
                                            <mat-option [value]="240">4 hours</mat-option>
                                            <mat-option [value]="480">8 hours</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordMinutesOfInactivityBeforeLock')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Password expiration (days) <mat-icon
                                            matTooltip="This setting specifies the number of days after which a device password must be changed. Recommended value: 41 (1-730)">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordExpirationDays || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline" class="full-width">
                                        <input [readonly]="!passwordRequired" type="number" matInput
                                            formControlName="passwordExpirationDays" />
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordExpirationDays')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Number of previous passwords to prevent reuse <mat-icon
                                            matTooltip="This setting specifies the number of recent passwords that cannot be reused. Recommended value: 5 (1-24)">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordPreviousPasswordBlockCount || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline" class="full-width">
                                        <input [readonly]="!passwordRequired" type="number" matInput
                                            formControlName="passwordPreviousPasswordBlockCount" />
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordPreviousPasswordBlockCount')"></sway-baseline-status>
                                </td>

                            </tr>


                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Require password when device returns from idle state (Mobile and Holographic)
                                        <mat-icon
                                            matTooltip="Force user to input password every time the device returns from an idle state">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordRequiredToUnlockFromIdle ? 'Required' : 'Not Configured'}}
                                </td>

                                <td>
                                    Not Configured
                                    <mat-slide-toggle [disabled]="!passwordRequired" color="primary"
                                        formControlName="passwordRequiredToUnlockFromIdle">
                                        Required
                                    </mat-slide-toggle>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordRequiredToUnlockFromIdle')"></sway-baseline-status>

                                </td>
                            </tr>

                        </ng-container>

                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>



    <div class="mb-6"></div>






    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        System Security (Encryption)
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="!policy?.id || errorString.includes('/policy/storageRequireEncryption')"
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Require encryption of data storage on device.

                                    <mat-icon matTooltip="Require encryption of data storage on device.">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.storageRequireEncryption ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="storageRequireEncryption">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/storageRequireEncryption')"></sway-baseline-status>

                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>



    <div class="mb-6"></div>



    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        System Security (Device Security)
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="
                                !policy?.id ||

                                (errorString.includes('/policy/activeFirewallRequired!')
                                || errorString.includes('/policy/tpmRequired!')
                                || errorString.includes('/policy/antivirusRequired!')
                                || errorString.includes('/policy/antiSpywareRequired'))
                            "

                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td class="field">
                                Firewall

                                <mat-icon matTooltip="Require firewall to be on and monitoring.">info</mat-icon>

                            </td>

                            <td>
                                {{policy?.activeFirewallRequired ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="activeFirewallRequired">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/activeFirewallRequired')"></sway-baseline-status>

                            </td>
                        </tr>


                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Trusted Platform Module (TPM)

                                    <mat-icon matTooltip="Require Trusted Platform Module(TPM) to be present.">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.tpmRequired ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="tpmRequired">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/tpmRequired')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Antivirus
                                    <mat-icon
                                        matTooltip="Require any Antivirus solution registered with Windows Security Center to be on and monitoring (e.g. Symantec, Windows Defender).">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.antivirusRequired ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="antivirusRequired">
                                    Required
                                </mat-slide-toggle>

                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/antivirusRequired')"></sway-baseline-status>

                            </td>
                        </tr>

                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Antispyware

                                    <mat-icon
                                        matTooltip="Require any AntiSpyware solution registered with Windows Decurity Center to be on and monitoring (e.g. Symantec, Windows Defender).">info</mat-icon>
                                </div>

                            </td>

                            <td>
                                {{policy?.antiSpywareRequired ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="antiSpywareRequired">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/antiSpywareRequired')"></sway-baseline-status>
                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        System Security (Defender )


                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="!policy?.id || (errorString.includes('/policy/defenderEnabled!')
                            || errorString.includes('/policy/defenderVersion!')
                            || errorString.includes('/policy/signatureOutOfDate!')
                            || errorString.includes('/policy/rtpEnabled!'))
                            "
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Microsoft Defender Antimalware

                                    <mat-icon
                                        matTooltip="Require the Microsoft Defender service to be enabled. (This compliance check is supported for desktop devices running Windows 10 or later).">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.defenderEnabled ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="defenderEnabled">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/defenderEnabled')"></sway-baseline-status>
                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Microsoft Defender Antimalware minimum version

                                    <mat-icon
                                        matTooltip="Require Windows Defender Antimalware minimum version on Windows devices.">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.defenderVersion }}
                            </td>


                            <td>
                                <mat-form-field appearance="outline" class="full-width">

                                    <input matInput formControlName="defenderVersion" />
                                    <mat-error>Must be a valid version format. Example: 4.3.06, 7.01.4, etc.</mat-error>

                                </mat-form-field>
                            </td>


                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/defenderVersion!')"></sway-baseline-status>

                            </td>
                        </tr>




                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Microsoft Defender Antimalware security intelligence up-to-date

                                    <mat-icon
                                        matTooltip="Require Microsoft Defender security intelligence to be up-to-date. (This compliance check is supported for desktop devices running Windows 10 or later).">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.signatureOutOfDate ? 'Required' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="signatureOutOfDate">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/signatureOutOfDate')"></sway-baseline-status>

                            </td>
                        </tr>

                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Real-time protection
                                    <mat-icon
                                        matTooltip="Require real-time protection prompts for known malware detection. (This compliance check is supported for desktop devices running Windows 10 or later)">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.rtpEnabled ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="rtpEnabled">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/rtpEnabled')"></sway-baseline-status>

                            </td>
                        </tr>



                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Microsoft Defender for Endpoint


                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="!policy?.id || errorString.includes('/policy/deviceThreatProtectionRequiredSecurityLevel')"
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>


                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Require the device to be at or under the machine risk source:
                                    <mat-icon
                                        matTooltip="Select the maximum allowed machine risk score for devices evaluated by Microsoft Defender for Endpoint. Devices which exceed this score get marked as noncompliant">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.deviceThreatProtectionRequiredSecurityLevel || 'Not Configured'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-select formControlName="deviceThreatProtectionRequiredSecurityLevel">
                                        <mat-option value="notSet">Not Configured</mat-option>
                                        <mat-option value="unavailable">Clear</mat-option>
                                        <mat-option value="low">Low</mat-option>
                                        <mat-option value="medium">Medium</mat-option>
                                        <mat-option value="high">High</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/deviceThreatProtectionRequiredSecurityLevel')"></sway-baseline-status>

                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mb-6"></div>


    <app-compliance-policy-assignments
        [policy]="policy"
        [errorString]="errorString"
        [assignments]="assignments"
        [form]="form"
        [tenant_id]="tenant_id"
    > </app-compliance-policy-assignments>


    <div class="mb-6"></div>


<!-- <mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <div class="items-center w-full  flex justify-between">

                <mat-panel-title class="font-bold">
                    Actions for noncompliance
                </mat-panel-title>

                <div class="">
                    <sway-baseline-status
                        [hasError]="!policy?.id || errorString.includes('/policy/deviceThreatProtectionRequiredSecurityLevel')"
                    ></sway-baseline-status>
                </div>

            </div>

        </mat-expansion-panel-header>

        <div>
            <table>
                <thead>
                    <tr>
                        <th>
                            Field
                        </th>

                        <th>
                            Current Value
                        </th>

                        <th>
                            Desired Value
                        </th>

                        <th>
                            Status
                        </th>
                    </tr>
                </thead>

                <tbody formGroupName="scheduledActionsForRule">

                    <tr formGroupName="DeviceNonCompliance">
                        <td>
                            Mark device noncompliant ->
                            Schedule (days after noncompliance) <mat-icon
                            matTooltip="Days after noncompliance">info</mat-icon>
                        </td>

                        <td>

                        </td>

                        <td>
                            <mat-form-field appearance="outline">
                                <input type="number" matInput formControlName="gracePeriodHours" />
                                <mat-hint>Zero for immediately </mat-hint>
                            </mat-form-field>
                        </td>

                        <td>
                            <sway-baseline-status
                            [hasError]="!policy?.id || errorString.includes('/policy/gracePeriodHours')"></sway-baseline-status>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </mat-expansion-panel>
</mat-accordion> -->




</form>
            <!-- <tr>
                <td>
                    Exclude groups
                </td>


                <td>
                    <div *ngIf="excludedGroups?.length > 0" class="">
                        Policy has {{excludedGroups.length}} excluded groups
                    </div>
                </td>


                <td formGroupName="assignments">

                    <button (click)="manageGroups('Exclude')" mat-button matTooltip="Manage excluded groups"> Mange</button>
                </td>



                <td>
                    <sway-baseline-status
                    [hasError]="!policy?.id || errorString.includes('assignments/items/properties/target/oneOf')"></sway-baseline-status>
                </td>
            </tr>  -->


<div class="wrapper">
    <!-- <div (click)="goToSway()" class="session">
        <div class="image">
            <img class="icon" src="./assets/img/dashboard/sway.svg" />

            <div class="middle">
                <div class="text">Click to View</div>
            </div>
            <app-severity-badges [severity]="deviations$ | async"></app-severity-badges>
        </div>
        <span  class="mat-h2">Baselines Menu</span>
    </div> -->

    <div class="spinner">
        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="!isLoading" (click)="goToTenant()" class="session main">
        <div class="image">
            <div *ngIf="(tenantDeviations$ | async).danger <= 0; else tenantWarnBlock" class="img-wrapper">
                <img class="icon main-img" src="./assets/img/dashboard/sway/tenant-baseline-ok.svg" />
                <img class="icon" src="./assets/img/dashboard/sway/mark-ok.svg">
            </div>

            <ng-template #tenantWarnBlock>
                <div class="img-wrapper">
                    <img class="icon main-img" src="./assets/img/dashboard/sway/tenant-baseline-warn.svg" />

                    <img class="icon" src="./assets/img/dashboard/sway/mark-warn.svg">
                </div>
            </ng-template>

            <div class="middle">
                <div class="text">Click to View</div>
            </div>

            <app-severity-badges [isLoading]="!isDevLoaded" [small]="true"
                [severity]="tenantDeviations$ | async"></app-severity-badges>
        </div>
        <span class="mat-h2">Tenant Baselines</span>
    </div>

    <div *ngIf="!isLoading" (click)="goToGroup()" class="session main">
        <div [matTooltip]="groupDeviations > 0 ? 'There are ' + groupDeviations + ' group baselines deviations which effecting ' + userDeviations + ' users': 'No group baselines deviations'"
            matBadgeColor="warn" matBadgeSize="medium" [matBadgeHidden]="!groupDeviations" [matBadge]="groupDeviations"
            class="image">

            <div *ngIf="groupDeviations <=0 ; else groupWarnBlock" class="img-wrapper">
                <img class="icon main-img" src="./assets/img/dashboard/sway/group-baselines-ok.svg" />
                <img class="icon" src="./assets/img/dashboard/sway/mark-ok.svg">
            </div>

            <div *ngIf="userDeviations" class="outer-badge">

                <mat-icon>person</mat-icon>

                <span>

                    {{userDeviations}}

                </span>
            </div>

            <ng-template #groupWarnBlock>
                <div class="img-wrapper">
                    <img class="icon main-img" src="./assets/img/dashboard/sway/group-baselines-warn.svg" />

                    <img class="icon" src="./assets/img/dashboard/sway/mark-warn.svg">
                </div>
            </ng-template>

            <div class="middle">
                <div class="text">Click to View</div>
            </div>

            <!-- <app-severity-badges [isLoading]="!isDevLoaded" [severity]="groupDeviations$ | async"></app-severity-badges> -->
        </div>
        <span class="mat-h2">Group Baselines</span>
    </div>

    <div *ngIf="!isLoading" (click)="goToBaselineAudit()" class="session main">
        <div [matTooltip]="baselineAuditCount > 0 ? 'There are ' + baselineAuditCount + ' deviation audit log entries.': 'No deviation audit log entries'"
            matBadgeColor="warn" matBadgeSize="medium" [matBadgeHidden]="!baselineAuditCount" [matBadge]="baselineAuditCount"
            class="image">

            <div *ngIf="0 <=0 ; else groupWarnBlock" class="img-wrapper">
                <img class="icon main-img" src="./assets/img/dashboard/sway/group-baselines-ok.svg" />
                <img class="icon" src="./assets/img/dashboard/sway/mark-ok.svg">
            </div>

            <!-- <div *ngIf="userDeviations" class="outer-badge">

                <mat-icon>person</mat-icon>

                <span>

                    {{userDeviations}}

                </span>
            </div> -->

            <ng-template #groupWarnBlock>
                <div class="img-wrapper">
                    <img class="icon main-img" src="./assets/img/dashboard/sway/group-baselines-warn.svg" />

                    <img class="icon" src="./assets/img/dashboard/sway/mark-warn.svg">
                </div>
            </ng-template>

            <div class="middle">
                <div class="text">Click to View</div>
            </div>

            <!-- <app-severity-badges [isLoading]="!isDevLoaded" [severity]="groupDeviations$ | async"></app-severity-badges> -->
        </div>
        <span class="mat-h2">Deviation audit logs</span>
    </div>
</div>
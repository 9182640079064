import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { EventRule } from './model';

export const featureKey = 'MspEventRule';

export interface State extends EntityState<EventRule>, Status { }

export const adapter: EntityAdapter<EventRule> = createEntityAdapter<EventRule>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.loadEventRules, (state) => ({ ...state, loading: !state.loaded })),
    on(actions.loadEventRulesSuccess, (state, action) => adapter.setAll(action.rules, { ...state, loading: false, loaded: true })),
    on(actions.loadEventRulesFailure, (state, { error }) => ({ ...state, error })),

    on(actions.createEventRule, (state) => ({ ...state, creating: true })),
    on(actions.createEventRuleSuccess, (state, action) => adapter.addOne(action.rule, { ...state, creating: false })),
    on(actions.createEventRuleFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.updateEventRule, (state) => ({ ...state, updating: true })),
    on(actions.updateEventRuleSuccess, (state, action) => adapter.upsertOne(action.rule, { ...state, updating: false })),
    on(actions.updateEventRuleFailure, (state, { error }) => ({ ...state, error, updating: false })),

    on(actions.deleteEventRule, (state) => ({ ...state, deleting: true })),
    on(actions.deleteEventRuleSuccess, (state, { rule }) => adapter.removeOne(rule.id, { ...state, deleting: false })),
    on(actions.deleteEventRuleFailure, (state, { error }) => ({ ...state, error, deleting: false })),
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export const selectEntity = (id: string) => createSelector(
    selectEntities,
    entities => entities[id]
);

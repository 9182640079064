import { createReducer, on } from '@ngrx/store';
import { loadAction_start, loadAction_stop } from './loader.actions';

export const loaderFeatureKey = 'loader';

export interface LoaderState {
    [key: string]: boolean;
}

export const loaderReducer = createReducer(
    {},
    on(loadAction_start, (state: LoaderState, { actionType }) => ({
        ...state, [actionType]: true
    })),
    on(loadAction_stop, (state: LoaderState, { actionType }) => ({
        ...state, [actionType]: false
    }))
);

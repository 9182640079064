import { FormBuilder, Validators } from '@angular/forms';
import { getIncludeUsersConfig, getIncludeGuestsOrExternalUsersValue, getUserItemsFromSchema, getIncludeUsersItemsFromSchema, getExcludeGuestsOrExternalUsersValue, getExcludeUsersItemsFromSchema, getbuiltInControlsFromSchema } from './utlize';

export function InitCapBaselineFormItem(schema, fb: FormBuilder) {
    const isNoPolicySchema = !!schema?.not?.contains;
    if (isNoPolicySchema) {
        return fb.group({
            state: ['delete'],
            displayName: [schema?.not?.contains?.properties?.displayName?.const, Validators.required],
            
            grant: [
                schema?.contains?.properties?.grantControls?.properties?.builtInControls?.items?.const 
                || schema?.contains?.properties?.grantControls?.properties?.builtInControls?.items?.enum[0]
                || 'block'
            ],
            includeRoles: [[]],
            excludeRoles: [[]],
            includeUsers: [[]],
            excludeUsers: [[]],
            includeGroups: [[]],
            excludeGroups: [[]],
            includeUsersConfig: ['All'],
            includeGuestsOrExternalUsers: [[]],
            excludeGuestsOrExternalUsers: [[]],

            includeUserSettings: fb.group({
                includeGuestsOrExternalUsers: [false],
                includeRoles: [false],
                includeUsers: [false],
                includeGroups: [false]

            }),


            excludeUserSettings: fb.group({
                excludeGuestsOrExternalUsers: [false],
                excludeRoles: [false],
                excludeUsers: [false],
                excludeGroups: [false]
            }),
        });
    } else {
        return fb.group({
            state: [schema?.contains?.properties?.state?.const],
            displayName: [schema?.contains?.properties?.displayName?.const, Validators.required],
            grant: [
                schema?.contains?.properties?.grantControls?.properties?.builtInControls?.items?.const 
                || schema?.contains?.properties?.grantControls?.properties?.builtInControls?.items?.enum[0]
                || 'block'],

            includeUsersConfig: [getIncludeUsersConfig(schema)], // all, none, else

            includeUserSettings: fb.group({
                includeGuestsOrExternalUsers: [getIncludeGuestsOrExternalUsersValue(schema)?.length > 0],
                includeRoles: [getUserItemsFromSchema(schema, 'includeRoles')?.length > 0],
                includeUsers: [getIncludeUsersItemsFromSchema(schema)?.length > 0],
                includeGroups: [getUserItemsFromSchema(schema, 'includeGroups')?.length > 0]

            }),
            
            excludeUserSettings: fb.group({
                excludeGuestsOrExternalUsers: [getExcludeGuestsOrExternalUsersValue(schema)?.length > 0],
                excludeRoles: [getUserItemsFromSchema(schema, 'excludeRoles')?.length > 0],
                excludeUsers: [getExcludeUsersItemsFromSchema(schema)?.length > 0],
                excludeGroups: [getUserItemsFromSchema(schema, 'excludeGroups')?.length > 0]

            }),

            // grantControl : fb.group({
            //     builtInControls: [getbuiltInControlsFromSchema(schema)]
            // }),

            includeRoles: [getUserItemsFromSchema(schema, 'includeRoles')],
            excludeRoles: [getUserItemsFromSchema(schema, 'excludeRoles')],

            includeUsers: [getIncludeUsersItemsFromSchema(schema)],
            excludeUsers: [getUserItemsFromSchema(schema, 'excludeUsers')],

            includeGroups: [getUserItemsFromSchema(schema, 'includeGroups')],
            excludeGroups: [getUserItemsFromSchema(schema, 'excludeGroups')],

            includeGuestsOrExternalUsers: [getIncludeGuestsOrExternalUsersValue(schema)],

            excludeGuestsOrExternalUsers: [getExcludeGuestsOrExternalUsersValue(schema)],
        });
    }

}
<div class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                Unified Audit Log Ingestion
                <mat-icon matTooltip="Specifies whether to enable or disable the recording of user and admin activities in the Microsoft 365 audit log.">info</mat-icon>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>

            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>

<ng-template #validatorRef>
    <div class="validator">
            {{
            data.UnifiedAuditLogIngestionEnabled
            ? "Enabled"
            : "Disabled"
            }}
    </div>
</ng-template>

<ng-template #formRef>

    <div class="form">
        <div class="field boolean">
            <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="control">
                    <mat-option value="any">Ignore</mat-option>
                    <mat-option [value]="true">Enable</mat-option>
                    <mat-option [value]="false">Disable</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, expand, EMPTY } from 'rxjs';
import { catchError, distinct, map, mergeMap, reduce } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { loadReportDeviations, loadReportDeviationsFailure, loadReportDeviationsSuccess } from './actons';

@Injectable()
export class ReportDeviationEffects {

    loadDeviations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadReportDeviations),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.getWithQueryPaging(_tenant)
                .pipe(
                    map(data => loadReportDeviationsSuccess({ _tenant, data: data })),
                    catchError(error => of(loadReportDeviationsFailure({ _tenant, error })))
                )
            )
        )
    );

    private getWithQueryPaging(tenant: string) {
        return this.ajax.get(tenant, `/api/report/tenant/${tenant}/deviations`)
            .pipe(
                expand((data) => {
                    if (data.offset) {
                        return this.ajax.get<Array<any>>(tenant, `/api/report/tenant/${tenant}/deviations?offset=${data.offset}`);
                    } else {
                        return EMPTY;
                    }
                }),
                reduce((acc, data: any) => acc.concat(data.records), [])
            );
    }


    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) { }

}

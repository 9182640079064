import { createAction, props } from '@ngrx/store';

export const loadMspCwmSubTypes = createAction(
    '[Msp/CWM] Load Msp Cwm SubTypes',
    props<{ board_id: number, type_id: number }>()
);

export const loadMspCwmSubTypesSuccess = createAction(
    '[Msp/CWM] Load Msp Cwm SubTypes Success',
    props<{ data: any, board_id: number, type_id: number }>()
);

export const loadMspCwmSubTypesFailure = createAction(
    '[Msp/CWM] Load Msp Cwm SubTypes Failure',
    props<{ error: any }>()
);
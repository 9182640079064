import { Action, createReducer, on } from '@ngrx/store';
import { nextStep, previousStep } from '../actions/stepper.actions';

export const featureKey = 'stepper';

export interface State {
    step: number
}

export const initialState: State = {
    step: 0
};

export const reducer = createReducer(
    initialState,
    on(nextStep, (state) => ({ ...state, step: ++state.step })),
    on(previousStep, (state) => ({ ...state, step: state.step > 0 ? --state.step : 0 }))
);

export const getStep = (state: State) => state.step;

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { EOPProtectionPolicy } from './model';


export const featureKey = 'EOPProtectionPolicy';
export interface State extends EntityState<EOPProtectionPolicy>, Status { }

export const adapter: EntityAdapter<EOPProtectionPolicy> = createEntityAdapter<EOPProtectionPolicy>({
    selectId: (entity) => entity.Identity
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});


export const reducer = createReducer(
    initialState,
    on(actions.loadEOPProtectionPolicySuccess,
        (state, action) => adapter.setAll(action.data, { ...state, loaded: true, error: null })
    ),
    on(actions.loadEOPProtectionPolicyFailure,
        (state, { error }) => ({ ...state, error })
    ),

    on(actions.enableExoEOPProtectionPolicy,
        (state) => ({ ...state, updating: true, error: null })
    ),
    on(actions.enableExoEOPProtectionPolicySuccess,
        (state, action) => adapter.upsertOne(action.data, ({ ...state, updating: false , error: null}))
    ),
    on(actions.enableExoEOPProtectionPolicyFailure,
        (state, { error }) => ({ ...state, updating: false, error })
    ),

    on(actions.disableExoEOPProtectionPolicy,
        (state) => ({ ...state, updating: true, error: null })
    ),
    on(actions.disableExoEOPProtectionPolicySuccess,
        (state, action) => adapter.upsertOne(action.data, ({ ...state, updating: false, error: null }))
    ),
    on(actions.disableExoEOPProtectionPolicyFailure,
        (state, { error }) => ({ ...state, updating: false, error })
    )
);


export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();



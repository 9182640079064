<mat-error *ngIf="isSecurityDefaultsEnabled ">
    You can't Add or Enable legacy Auth while your Security Defaults Policy is enabled. Please disable it and try again.
</mat-error>

<div class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                Legacy Auth Conditional Access Policy
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>

            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>

<ng-template #validatorRef>
    <div class="validator">
        <h2 *ngIf="!legacyAuth; else elseBlock">
            Policy not found
        </h2>

        <ng-template #elseBlock>
            <div>
                {{legacyAuth?.state}}
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #formRef>
    <div class="field boolean">
        <mat-form-field appearance="fill">
            <mat-label>State</mat-label>
            <mat-select [formControl]="control">
                <mat-option [value]="'enabled'">Enable</mat-option>
                <mat-option [value]="'disabled'">Disable</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>

import { Component, OnInit, ViewChild, TemplateRef, NgZone, OnDestroy } from '@angular/core';
import { SnackbarService, SnackBarConfig } from 'src/app/services/snackbar.service';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit, OnDestroy {
    success: boolean;
    error: boolean;
    @ViewChild('snackBar') snackBarTemplate: TemplateRef<any>;
    subscriptions: Subscription[] = [];
    snackBarRef: MatSnackBarRef<any>;
    msg: string = '';
    msg2: string = '';
    dismiss_text: string = 'Dismiss';
    constructor(private snackbarService: SnackbarService, public snackBar: MatSnackBar, private zone: NgZone) { }

    ngOnInit() {
        this.subscriptions.push(
            this.snackbarService.snack$.subscribe((msg: SnackBarConfig) => {
                this.msg = '';
                this.msg2 = '';
                this.success = msg.data.success;
                this.error = msg.data.error;
                this.zone.run(() => {
                    this.snackBarRef = this.snackBar.openFromTemplate(this.snackBarTemplate, this.config(msg));
                });
                this.snackBarRef.afterDismissed().subscribe(() => {
                    this.dismiss();
                });
            })
        );

        this.subscriptions.push(
            this.snackbarService.dismiss$.subscribe(() => {
                this.snackBar.dismiss();
                this.snackBar.ngOnDestroy();
            })
        );
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    dismiss() {
        this.snackbarService.dismiss();
    }

    private config(config: SnackBarConfig) {
        this.msg = config.data.message;
        if (config.data.message2) { this.msg2 = config.data.message2; }
        if (config.data.dismiss_text) { this.dismiss_text = config.data.dismiss_text; }
        const conf: SnackBarConfig = {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: [],
            duration: config.data.duration ? config.data.duration : undefined,
            ...config
        };

        const _config = new MatSnackBarConfig();
        _config.verticalPosition = conf.verticalPosition;
        _config.horizontalPosition = conf.horizontalPosition;
        _config.panelClass = conf.panelClass;
        _config.duration = conf.duration;

        return _config;
    }

}

import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';
export { BaselineComponent } from './baseline.component';
export { Remediate } from './remediate';
export const href = 'https://learn.microsoft.com/en-us/azure/active-directory/conditional-access/howto-conditional-access-policy-registration';

function grantControls(grant) {
    return {
        'type': 'object',
        'properties': {
            'builtInControls': {
                'type': 'array',
                'items': {
                    'type': 'string',
                    'const': grant
                }
            }
        }
    };
}


const locations = {
    'type': 'object',
    'properties': {
        'includeLocations': {
            'type': 'array',
            'items': {
                'enum': ['All', 'all'],
                'type': 'string'
            },
            'minItems': 1,
            'maxItems': 1,
            'uniqueItems': true
        },
        'excludeLocations': {
            'type': 'array',
            'items': {
                'enum': ['AllTrusted'],
                'type': 'string'
            },
            'minItems': 1,
            'maxItems': 1,
            'uniqueItems': true
        }

    }
};

const applications = {
    'type': 'object',
    'properties': {
        'includeUserActions': {
            'type': 'array',
            'items': {
                'enum': ['urn:user:registersecurityinfo'],
                'type': 'string'
            },
            'minItems': 1,
            'maxItems': 1,
            'uniqueItems': true
        }

    },
    'required': ['includeUserActions']
};



function policy(form): ConditionalAccessPolicySchema {
    return {
        grantControls: grantControls(form.grant),

        conditions: {
            locations,
            applications
        }
    };
}


export const CAPSecurityInfoRegistrationBaselineSchema =
    (
        form
    ) => {
        return createCapPolicySchema(form, policy(form));
    };


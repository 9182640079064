<form class="form" [formGroup]="formGroup">
    <mat-form-field class="full-width">
        <mat-label>Billing Email</mat-label>
        <input matInput formControlName="email"/>
        <mat-error *ngIf="formGroup.get('email').hasError('email')">
            Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="formGroup.get('email').hasError('required')">
            Email is <strong>required</strong>
        </mat-error>
    </mat-form-field>
</form>

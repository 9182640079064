import { Component, DoCheck, Input, OnChanges, SimpleChanges } from '@angular/core';
import Ajv, { ErrorObject } from 'ajv';
import { JSONSchema7 } from 'json-schema';

@Component({
    template: ''
})
export class BaselineValidatorComponent implements DoCheck, OnChanges {
    ngOnChanges(changes: SimpleChanges): void {
    }
    @Input() schema: JSONSchema7;
    @Input() data: any;
    public errors: ErrorObject<string, Record<string, any>, unknown>[] = [];

    private ajv = new Ajv({
        allErrors: true,
        verbose: true,
        strict: false
    });

    validate() {
        const validate = this.ajv.compile(this.schema);
        this.errors = validate(this.data) ? [] : validate.errors!;
        return this.errors;
    }

    ngDoCheck() {
        this.validate();
    }


}

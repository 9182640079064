import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of, catchError, concatMap, distinct, mergeMap, map, switchMap, filter, take } from 'rxjs';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { client } from '../../..';
import * as CasMailboxActions from './actions';
import { CasMailbox } from './model';

function filterDiscoverySearchMailbox(cas: CasMailbox[]) {
    return cas.filter(c => !c.Name.startsWith('DiscoverySearchMailbox'));
}

@Injectable()
export class CasMailboxEffects {

    loadCasMailboxes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CasMailboxActions.fetchCasMailboxes),
            distinct(action => action._tenant),
            switchMap(action => this.store.pipe(
                select(client(action._tenant).powershell.exoCasMailbox.status),
                filter(status => !status.loaded),
                map(() => action),
                take(1)
            )),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map((casMailboxes: CasMailbox[]) => CasMailboxActions.fetchCasMailboxesSuccess({
                        _tenant,
                        data: filterDiscoverySearchMailbox(casMailboxes)
                    })),
                    catchError((error) => of(CasMailboxActions.fetchCasMailboxesFailure({ _tenant, error }))),
                )
            )
        )
    );

    get(tenant: string): Observable<CasMailbox[]> {
        let url = '/api/microsoft/powershell/exo/cas-mailbox';
        url += '?SelectObject=Identity,Name,ExternalDirectoryObjectId,ActiveSyncEnabled,EwsEnabled,ImapEnabled,MAPIEnabled,OutlookMobileEnabled,OWAEnabled,PopEnabled,SmtpClientAuthenticationDisabled';
        return this.ajax.get(tenant, url);
    }

    updateCasMailbox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CasMailboxActions.updateCasMailbox),
            mergeMap(({ _tenant, data, field }) => this.put(_tenant, data)
                .pipe(
                    concatMap(res => {
                        return [
                            CasMailboxActions.updateCasMailboxSuccess({ _tenant, data: res }),
                            CasMailboxActions.updateCasMailboxEntityStatus({ _tenant, data, field })
                        ];
                    }),
                    catchError((error) => of(CasMailboxActions.updateCasMailboxFailure({ _tenant, data, field, error: { message: error.message, trace: error.trace } })))
                )
            )
        )
    );

    put(tenant: string, data: any): Observable<CasMailbox> {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/cas-mailbox', data).pipe(map(res => res[0]));
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store
    ) { }

}

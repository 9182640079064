import { ConditionalAccessPolicy, User } from '@microsoft/microsoft-graph-types-beta';
import { createConditionalAccessPolicy, deleteConditionalAccessPolicy, updateConditionalAccessPolicy } from 'src/app/stores/client/graph/conditional-access/actions';

export const displayName = 'Enforce MFA for Admins (Octiga Conditional Access)';

// Cloud App Security administrator


const MFAPolicy = (roles: Array<string>, state: 'enabled' | 'disabled'): ConditionalAccessPolicy => ({
    'displayName': displayName,
    'state': state,
    'conditions': {
        'users': {
            'includeRoles': roles
        },
        'applications': {
            'includeApplications': [
                'all'
            ]
        }
    },
    'grantControls': {
        'operator': 'OR',
        'builtInControls': [
            'mfa'
        ]
    }
}
);

export function Remediate(_tenant: string, data: ConditionalAccessPolicy[], schema: any, users?: User[]) { // no exclude users
    const isNoPolicySchema = !!schema?.not?.contains;
   
    const state = schema?.contains?.properties?.state?.const;
    const mfaPolicy = data.find(res => res.displayName === displayName);
    const roles = schema?.contains?.properties?.conditions?.properties?.users?.properties?.includeRoles?.items?.enum;   
    
    
    if (!mfaPolicy) { // create MFA Policy if it is not exists

        if (users?.length) {
            const excludedUsers = users.filter(user => user.userPrincipalName.includes('octiga_service_user_')).map(user => user.id);
            const policy = JSON.parse(JSON.stringify(MFAPolicy(roles, state )));
           
            if (excludedUsers?.length) {
                policy.conditions.users.excludeUsers = excludedUsers;
            }
            return createConditionalAccessPolicy({ _tenant, policy });
        } else {
            return createConditionalAccessPolicy({ _tenant, policy: MFAPolicy(roles, state) });
        }
    }
    else if (mfaPolicy) {
        if (isNoPolicySchema) {
            return deleteConditionalAccessPolicy({ _tenant, id: mfaPolicy.id });
        } 
        else {
            const policy = JSON.parse(JSON.stringify(MFAPolicy(roles, state)));

            return updateConditionalAccessPolicy({ _tenant, policy: { id: mfaPolicy.id, ...policy } });
        }
    }

}

export const NoPolicySchema = {
    'type': 'array',
    'not': {
        'contains': {
            'type': 'object',
            'properties': {
                'displayName': {
                    'type': 'string',
                    'const': 'Enforce MFA for Admins (Octiga Conditional Access)'
                }
            }
        }
    }
};

export const PolicySchema = (status: 'enabled' | 'disabled', roles : string[]) => ({
    'type': 'array',
    'contains': {
        'type': 'object',
        'properties': {
            'state': {
                'type': 'string',
                'const': status
            },
            'conditions': {
                'type': 'object',
                'properties': {
                    'users': {
                        'type': 'object',
                        'properties': {
                            'includeRoles': {
                                'type': 'array',
                                'items': {
                                    'enum': [
                                        ...roles
                                    ],
                                    'type': 'string'
                                },
                                'minItems': roles.length,
                                'maxItems' : roles.length,
                                'uniqueItems': true
                            }
                        }
                    }
                }
            },
            'displayName': {
                'type': 'string',
                'const': 'Enforce MFA for Admins (Octiga Conditional Access)'
            },
            'grantControls': {
                'type': 'object',
                'properties': {
                    'builtInControls': {
                        'type': 'array',
                        'items': {
                            'type': 'string',
                            'const': 'mfa'
                        },
                        'maxItems': 1
                    }
                }
            }
        }
    }
});
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeviceCompliancePolicy, DeviceCompliancePolicyAssignment } from '@microsoft/microsoft-graph-types-beta';
import { select, Store } from '@ngrx/store';
import { map, skipUntil, take } from 'rxjs';
import * as Windows10CompliancePolicyHelper from 'src/app/modules/sway/tools/schemas/Windows10CompliancePolicy/Windows10CompliancePolicy.schema';
import { client } from 'src/app/stores/client';
import { CapItem, RowType } from '../../../conditional-access-policy/shared/model';
import { ManageItemsWrapperDialogComponent } from '../../../conditional-access-policy/shared/manage-items-wrapper-dialog/manage-items-wrapper-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-compliance-policy-assignments',
    templateUrl: './compliance-policy-assignments.component.html',
    styleUrls: ['./compliance-policy-assignments.component.scss', '../../../../styles/baseline-style.scss']
})
export class CompliancePolicyAssignmentsComponent implements OnInit, OnChanges {
    @Input() tenant_id: string;
    @Input() policy: DeviceCompliancePolicy;
    @Input() assignments: DeviceCompliancePolicyAssignment[] = [];
    @Input() errorString: string;
    @Input() form: FormGroup;

    includedGroups: DeviceCompliancePolicyAssignment[] = [];
    excludedGroups: DeviceCompliancePolicyAssignment[] = [];
    targets = Windows10CompliancePolicyHelper.TargetGroups;

    policyAssignments = {
        allUsers: false,
        allDevices: false,
        groupInclusion: false,
        groupExclusion: false
    };

    constructor(
        private store: Store<any>,
        private dialog: MatDialog,

    ) { }

    ngOnChanges(): void {

        if (this.assignments.length > 0) {
            this.policyAssignments = {
                allUsers: this.assignments.some(res => res.target['@odata.type'] === this.targets.allUsers),
                allDevices: this.assignments.some(res => res.target['@odata.type'] === this.targets.allDevices),
                groupInclusion: this.assignments.some(res => res.target['@odata.type'] === this.targets.groupInclusion),
                groupExclusion: this.assignments.some(res => res.target['@odata.type'] === this.targets.groupExclusion)
            };

            this.includedGroups = this.assignments.filter(res => res.target['@odata.type'] === this.targets.groupInclusion);
            this.excludedGroups = this.assignments.filter(res => res.target['@odata.type'] === this.targets.groupExclusion);
        }

    }

    ngOnInit(): void {
        if (this.assignments.length > 0) {
            this.policyAssignments = {
                allUsers: this.assignments.some(res => res.target['@odata.type'] === this.targets.allUsers),
                allDevices: this.assignments.some(res => res.target['@odata.type'] === this.targets.allDevices),
                groupInclusion: this.assignments.some(res => res.target['@odata.type'] === this.targets.groupInclusion),
                groupExclusion: this.assignments.some(res => res.target['@odata.type'] === this.targets.groupExclusion)
            };

            this.includedGroups = this.assignments.filter(res => res.target['@odata.type'] === this.targets.groupInclusion);
            this.excludedGroups = this.assignments.filter(res => res.target['@odata.type'] === this.targets.groupExclusion);
        }

    }


    get assignmentsValue() {
        return this.formValue.assignments;
    }

    get formValue() {
        return this.form.value;
    }



    manageGroups(type: 'Exclude' | 'Include'): void {
        const groups$ = this.store.pipe(select(client(this.tenant_id).graph.groups.all));
        const groups_loaded$ = this.store.pipe(select(client(this.tenant_id).graph.groups.status), map(res => res.loaded));

        const config = {
            title: 'Groups',
            allListTitle: 'Available groups',
            desiredListTitle: type === 'Include' ? 'Included groups in baseline' : 'Excluded groups in baseline',
            allListComparativeTitle: type === 'Include' ? 'Tenant included groups' : 'Tenant excluded groups',
            type: type === 'Include' ? RowType.includeGroups : RowType.excludeGroups
        };

        groups$.pipe(skipUntil(groups_loaded$), take(1))
            .subscribe(lookup => {
                const policyItems = this.includedGroups;

                const allItems: CapItem[] = lookup.map(res => {
                    const inBaselineSchema = !!this.formValue.assignments.includedGroups?.some(b_item => b_item === res.id)

                    return {
                        displayName: res.displayName,
                        id: res.id,
                        inBaselineSchema,
                        inPolicy: !!policyItems?.some(b_item => b_item === res.id),

                    };
                });


                const dialogRef = this.dialog.open(ManageItemsWrapperDialogComponent, {
                    data: {
                        allItems: allItems,
                        ...config
                    },
                    maxHeight: '90vh',
                    width: '70%'
                });


                dialogRef.afterClosed().pipe(take(1)).subscribe((res: CapItem[]) => {
                    if (res instanceof Array) {
                        const schemaItems = res.filter(res => res.inBaselineSchema) || []


                        this.form.get('assignments').get('includedGroups').setValue(schemaItems.map(res => res.id));
                    }
                });
            });
    }


}

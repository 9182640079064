import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@microsoft/microsoft-graph-types-beta';
import { select, Store } from '@ngrx/store';
import { Severities } from 'microsoft-events/dist/interfaces/summary.interface';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map, sample, skipUntil, take } from 'rxjs/operators';
import { InboxRule } from 'src/app/interfaces/powershell/exo/inbox-rule.interface';
import { MailboxRuleService } from 'src/app/services/mailbox-rule.service';
import { client } from 'src/app/stores/client';
import { loadDomains } from 'src/app/stores/client/graph/domains/actions';
import { fetchGraphInboxRules } from 'src/app/stores/client/graph/inbox-rules/actions';
import { loadGraphUsers } from 'src/app/stores/client/graph/user/user.actions';
import { loadDismissItems } from 'src/app/stores/client/octiga/dismiss/actions';
import { fetchMailboxes } from 'src/app/stores/client/powershell/exo/mailbox/actions';
import { CardComponent } from '../card.component';
import { UserHasMailbox } from 'src/app/stores/actions.interface';

@Component({
    selector: 'app-mail-flow',
    templateUrl: './mail-flow.component.html',
    styleUrls: ['./mail-flow.component.scss'],
})
export class MailFlowComponent
    extends CardComponent
    implements OnInit, OnDestroy
{
    public tenant_id = this.route.snapshot.paramMap.get('tenant');

    subscriptions: Subscription[] = [];
    isLoading = false;
    startFetching = false;
    counter = 0;

    inboxRules$: BehaviorSubject<Severities> = new BehaviorSubject({
        critical: -1,
        danger: -1,
        warning: -1,
        info: -1,
        total: 0,
    });

    constructor(
        private store: Store<any>,
        public router: Router,
        private route: ActivatedRoute,
        private mailboxService: MailboxRuleService
    ) {
        super();
    }

    ngOnInit(): void {
        this.startFetching = true;
        this.isLoading = true;
        this.dispatcher();
        this.setInboxRules();
    }

    dispatcher() {
        this.store.dispatch(fetchMailboxes({ _tenant: this.tenant_id }));
        this.store.dispatch(loadDomains({ _tenant: this.tenant_id }));
        this.store.dispatch(loadDismissItems({ _tenant: this.tenant_id }));
        this.store.dispatch(loadGraphUsers({ _tenant: this.tenant_id }));

        const sub = this.store
            .select(client(this.tenant_id).graph.users.all)
            .subscribe((all_users) => {
                if (all_users.length > 0) {
                    const users = all_users.filter(UserHasMailbox);
                    this.store.dispatch(
                        fetchGraphInboxRules({ _tenant: this.tenant_id, users })
                    );
                }
            });

        this.subscriptions.push(sub);
    }

    ngOnDestroy() {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    browse(module: string, component: string) {
        this.router.navigate([
            `client/${this.tenant_id}/${module}/${component}`,
        ]);
    }

    private setInboxRules() {
        const userInboxRuleReady$ = this.store
            .pipe(select(client(this.tenant_id).graph.inboxRule.status))
            .pipe(
                filter((res) => res.loaded && !res.creating && !res.updating)
            );
        const usersInboxRule$ = this.store
            .pipe(select(client(this.tenant_id).graph.inboxRule.mappedToUsers))
            .pipe(sample(userInboxRuleReady$));

        const domainsReady$ = this.store
            .pipe(select(client(this.tenant_id).graph.inboxRule.status))
            .pipe(
                filter((res) => res.loaded && !res.creating && !res.updating)
            );
        const domains$ = this.store
            .pipe(select(client(this.tenant_id).graph.domains.all))
            .pipe(sample(domainsReady$));

        const mailboxesReady$ = this.store
            .pipe(select(client(this.tenant_id).powershell.exoMailbox.status))
            .pipe(
                filter((res) => res.loaded && !res.creating && !res.updating)
            );
        const mailboxes$ = this.store
            .select(client(this.tenant_id).powershell.exoMailbox.all)
            .pipe(sample(mailboxesReady$));

        const dismissReady$ = this.store
            .select(client(this.tenant_id).octiga.dismiss.status)
            .pipe(
                filter((res) => res.loaded && !res.creating && !res.updating)
            );
        const dismiss$ = this.store
            .select(client(this.tenant_id).octiga.dismiss.all)
            .pipe(
                sample(dismissReady$),
                map((res) =>
                    res.filter(
                        (r) => r.type === 'rules' && r.tenant === this.tenant_id
                    )
                )
            );

        this.subscriptions.push(
            combineLatest([
                usersInboxRule$,
                domains$,
                mailboxes$,
                dismiss$,
            ]).subscribe(([user_rules, domains, psMailboxes, dismiss]) => {
                this.counter++;
                this.startFetching = true;
                const dismiss_ids = dismiss.map((d) => d.id);
                this.mailboxService
                    .setSeverity(
                        this.tenant_id,
                        user_rules,
                        domains,
                        dismiss_ids
                    )
                    .then((value) =>
                        value.pipe(take(1)).subscribe((res) => {
                            const set_ib_severity = res;
                            const set_owa_severity = [];
                            for (
                                let index = 0;
                                index < set_ib_severity.length;
                                index++
                            ) {
                                const element: {
                                    user: User;
                                    rules: InboxRule[];
                                } = set_ib_severity[index];
                                set_owa_severity.push(
                                    this.mailboxService.setOWA(
                                        psMailboxes,
                                        element,
                                        domains,
                                        dismiss_ids
                                    )
                                );
                            }
                            const ib_severities = {
                                critical: 0,
                                danger: 0,
                                warning: 0,
                                info: 0,
                                total: 0,
                            };
                            for (let i = 0; i < set_owa_severity.length; i++) {
                                if (
                                    !!set_owa_severity[i]['owa'] &&
                                    set_owa_severity[i]['owa'].Dismiss === false
                                ) {
                                    ib_severities[
                                        set_owa_severity[i]['owa'].Severity
                                    ]++;
                                }
                                if (set_owa_severity[i].rules.length > 0) {
                                    set_owa_severity[i].rules.reduce(
                                        (acc, curr: InboxRule) => {
                                            if (!curr.dismiss) {
                                                switch (curr.severity) {
                                                case 'critical':
                                                    ib_severities.critical++;
                                                    break;
                                                case 'danger':
                                                    ib_severities.danger++;
                                                    break;
                                                case 'warning':
                                                    ib_severities.warning++;
                                                    break;
                                                default:
                                                    break;
                                                }
                                            }
                                            return ib_severities;
                                        },
                                        ib_severities
                                    );
                                }
                            }
                            this.inboxRules$.next(ib_severities);
                            this.isLoading = false;
                        })
                    );
            })
        );
    }
}

<form class="example-form">
    <mat-form-field  class="example-full-width">
        <input [readonly]="readOnly"  type="text" placeholder="{{placeholder}}" aria-label="Number"  matInput [formControl]="myControl"
            [matAutocomplete]="auto">
        <mat-autocomplete  autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>

import { createReducer, on } from '@ngrx/store';
import { Status, initialStatus } from '../../../../status.interface';
import * as actions from './actions';
import { SpoTenant } from './model';

export const featureKey = 'spoTenant';

export interface State extends Status {
    data: SpoTenant
}

export const initialState: State = {
    data: undefined,
    ...initialStatus
};

export const reducer = createReducer(
    initialState,
 
    on(actions.fetchSpoTenantSuccess,
        (state, { data }) => ({ ...state, loaded: true, error: null, data })
    ),
    on(actions.fetchSpoTenantFailure,
        (state, { error }) => ({ ...state, error })
    ),
    on(actions.updateSpoTenant,
        (state) => ({ ...state, updating: true, error: null })
    ),
    on(actions.updateSpoTenantSuccess,
        (state, { data }) => ({ ...state, updating: false, error: null, data: { ...state.data, ...data } })
    ),
    on(actions.updateSpoTenantFailure,
        (state, { error }) => ({ ...state, updating: false, error })
    )
);

export const selectItem = (state: State) => state.data;

import { User } from '@microsoft/microsoft-graph-types-beta';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as MspUserActions from './actions';


export const featureKey = 'mspGraphUser';

export interface State extends EntityState<User>, Status {
}

const selectId = (user: User) => `${user.id}`;

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
    selectId
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,

    on(MspUserActions.loadMspGraphUsersSuccess,
        (state, { msp_users }) => { state = { ...state, loaded: true }; return adapter.setAll(msp_users, state); }
    ),
    on(MspUserActions.loadMspGraphUsersFailure,
        (state, { error }) => ({ ...state, error })
    ),
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();



import { createAction, props } from '@ngrx/store';
import { SpoTenant } from './model';


export const fetchSpoTenant = createAction(
    '[Client/Pwsh/Spo] Fetch SpoTenant',
    props<{ _tenant: string }>()
);

export const fetchSpoTenantSuccess = createAction(
    '[Client/Pwsh/Spo] Fetch SpoTenant Success',
    props<{ _tenant: string, data: SpoTenant }>()
);

export const fetchSpoTenantFailure = createAction(
    '[Client/Pwsh/Spo] Fetch SpoTenant Failure',
    props<{ _tenant: string, error: any }>()
);


export const updateSpoTenant = createAction(
    '[Client/Pwsh/Spo] Update SpoTenant',
    props<{ _tenant: string, data: Partial<SpoTenant> }>()
);

export const updateSpoTenantSuccess = createAction(
    '[Client/Pwsh/Spo] Update SpoTenant Success',
    props<{ _tenant: string, data: Partial<SpoTenant> }>()
);

export const updateSpoTenantFailure = createAction(
    '[Client/Pwsh/Spo] Update SpoTenant Failure',
    props<{ _tenant: string, error: any }>()
);

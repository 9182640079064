<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{title}}
            </mat-panel-title>

            <mat-panel-description>


                <ng-container *ngFor="let error of errors; index as i">
                    <ng-container *ngIf="i < 1">

                        <mat-error *ngIf="error.type == 'includeRoles'">
                            Include roles doesn't match
                        </mat-error>

                        <mat-error *ngIf="error.type == 'includeUsers'">
                            Include users doesn't match
                        </mat-error>


                        <mat-error *ngIf="error.type == 'includeGroups'">
                            Include groups doesn't match
                        </mat-error>


                        <mat-error *ngIf="error.type == 'includeGuestsOrExternalUsers'">
                            Include guests or external users doesn't match
                        </mat-error>

                    </ng-container>
                </ng-container>

                <ng-container *ngIf="errors?.length === 0">
                    <mat-icon class="success">check_circle_outline</mat-icon>


                </ng-container>

            </mat-panel-description>
        </mat-expansion-panel-header>


        <div class="wrapper">
            <ng-container *ngTemplateOutlet="contentRef">

            </ng-container>
        </div>
    </mat-expansion-panel>

</mat-accordion>


<ng-template #contentRef>

    <table>
        <thead>
            <th class="item-header">
                Current Value
            </th>

            <th class="item-header">
                Desired Value
            </th>
        </thead>

        <tbody>
            <tr>

                <ng-container *ngTemplateOutlet="policyValueRef">

                </ng-container>

                <ng-container *ngTemplateOutlet="baselineValueRef">

                </ng-container>
            </tr>
        </tbody>
    </table>


</ng-template>


<ng-template #policyValueRef>
    <td class="item-data">
        <ng-container
            *ngIf="(policy?.conditions?.users?.includeUsers?.[0] === 'None' || policy?.conditions?.users?.includeUsers?.[0] === 'All'); else elseBlock">
            {{policy?.conditions?.users?.includeUsers[0]}}
        </ng-container>

        <ng-template #elseBlock>



            <mat-radio-group [disabled]="true" aria-label="Select an option">
                <div class="radio-wrapper">
                    <mat-radio-button [checked]="policy?.conditions?.users?.includeUsers?.[0] === 'None'"
                        value="All">All Users</mat-radio-button>
                </div>

                <div class="radio-wrapper">
                    <mat-radio-button [checked]="policy?.conditions?.users?.includeUsers?.[0] === 'All'"
                        value="None">None</mat-radio-button>
                </div>

                <div class="radio-wrapper">
                    <!-- (change)="manageItems()" -->
                    <mat-radio-button
                        [checked]="!(policy?.conditions?.users?.includeUsers?.[0] === 'None' || policy?.conditions?.users?.includeUsers?.[0] === 'All')"
                        value="GuestsOrExternalUsers">Select users and groups </mat-radio-button>

                    <ng-container
                        *ngIf="!(policy?.conditions?.users?.includeUsers?.[0] === 'None' || policy?.conditions?.users?.includeUsers?.[0] === 'All')">

                        <ng-container *ngTemplateOutlet="includeExcludeItems">
                        </ng-container>
                    </ng-container>

                </div>
            </mat-radio-group>
        </ng-template>
    </td>
</ng-template>


<ng-template #includeExcludeItems>
    <ul>
        <li>
            <mat-checkbox [disabled]="true"
                [checked]="policy?.conditions?.users?.['includeGuestsOrExternalUsers']?.['guestOrExternalUserTypes']?.split(',')?.length >  0 ">
                Guest or external users
            </mat-checkbox>

            <ul *ngIf="policy?.conditions?.users?.['includeGuestsOrExternalUsers']?.['guestOrExternalUserTypes'].split(',') as items"
                class="number">
                <li *ngFor="let item of items">
                    {{item}}
                </li>
            </ul>
        </li>

        <li>
            <mat-checkbox [disabled]="true" [checked]="policy?.conditions?.users?.includeRoles?.length > 0">
                Directory roles
                <span class="number">({{policy?.conditions?.users?.['includeRoles']?.length ||
                    0}})</span>
            </mat-checkbox>
        </li>

        <li>
            <mat-checkbox [disabled]="true" [checked]="policy?.conditions?.users?.includeUsers?.length > 0">
                Users

                <span class="number">({{policy?.conditions?.users?.includeUsers?.length || 0}})</span>

            </mat-checkbox>
        </li>

        <li>
            <mat-checkbox [disabled]="true" [checked]="policy?.conditions?.users?.includeGroups?.length > 0">
                Groups

                <span class="number">({{policy?.conditions?.users?.includeGroups?.length || 0}})</span>
            </mat-checkbox>
        </li>
    </ul>
</ng-template>

<ng-template #baselineValueRef>
    <td class="item-data">
        <mat-radio-group [disabled]="readOnly" [formControl]="includeUsersConfigController"
            aria-label="Select an option">
            <div class="radio-wrapper">
                <mat-radio-button value="All">All Users</mat-radio-button>
            </div>

            <div class="radio-wrapper">
                <mat-radio-button value="None">None</mat-radio-button>
            </div>

            <form [formGroup]="includeUserSettingsController" class="radio-wrapper">
                <!-- (change)="manageItems()" -->
                <mat-radio-button value="GuestsOrExternalUsers" default>Select users and groups </mat-radio-button>


                <ul *ngIf="includeUsersConfig === 'GuestsOrExternalUsers'">
                    <li>
                        <mat-checkbox [disabled]="!override?.includes('includeGuestsOrExternalUsers')  && readOnly"
                            formControlName="includeGuestsOrExternalUsers">
                            Guest or external users
                        </mat-checkbox>

                        <ul *ngIf="includeUserSettings.includeGuestsOrExternalUsers">
                            <li>
                                <ng-select [searchable]="false" [multiple]="true" [appendTo]="'body'"
                                    [formControl]="includeGuestsOrExternalUsersController"
                                    placeholder="Guest or external users">
                                    <ng-option value="b2bCollaborationGuest">B2B collaboration guest
                                        users</ng-option>
                                    <ng-option value="b2bCollaborationMember">B2B collaboration member
                                        users</ng-option>
                                    <ng-option value="b2bDirectConnectUser">B2B direct connect users</ng-option>
                                    <ng-option value="internalGuest">Local guest users</ng-option>
                                    <ng-option value="serviceProvider">Service provider users</ng-option>
                                    <ng-option value="otherExternalUser">Other external users</ng-option>
                                </ng-select>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <mat-checkbox [disabled]="!override?.includes('includeRoles')  && readOnly"
                            formControlName="includeRoles">
                            Directory roles
                            <span class="number">({{form.get('includeRoles')?.value.length}})</span>
                        </mat-checkbox>

                        <ul
                            *ngIf="includeUserSettings.includeRoles && (override?.includes('includeRoles') || !readOnly)">
                            <li>
                                <div class="btn-wrapper">
                                    <button (click)="manageItems('includeRoles')" mat-button
                                        color="primary">Manage</button>
                                </div>
                            </li>
                        </ul>


                        <ul
                            *ngIf="readOnly && !override?.includes('includeRoles') && form.get('includeRoles')?.value?.length > 0">
                            <li>
                                <div class="btn-wrapper">
                                    <button (click)="viewIncludedRoles()" mat-button color="primary">View Roles</button>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <mat-checkbox [disabled]="!override?.includes('includeUsers')  && readOnly"
                            formControlName="includeUsers">
                            Users

                            <span class="number">({{form.get('includeUsers')?.value.length}})</span>

                        </mat-checkbox>

                        <ul *ngIf="includeUserSettings.includeUsers">
                            <li>

                                <div class="btn-wrapper">
                                    <button (click)="manageItems('includeUsers')" mat-button
                                        color="primary">Manage</button>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <mat-checkbox [disabled]="!override?.includes('includeGroups')  && readOnly"
                            formControlName="includeGroups">
                            Groups

                            <span class="number">({{form.get('includeGroups')?.value.length}})</span>

                        </mat-checkbox>

                        <ul *ngIf="includeUserSettings.includeGroups">
                            <li>

                                <div class="btn-wrapper">
                                    <button (click)="manageItems('includeGroups')" mat-button
                                        color="primary">Manage</button>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </form>
        </mat-radio-group>
    </td>
</ng-template>


<ng-template #includedRolesRef>

    <div class="mat-typography">
        <div>
            <h3 style="font-weight: 600;">
                The Following roles are included in the baseline
            </h3>

        

            <li *ngFor="let role of includedRoles">
                {{role.displayName}}
            </li>

            <div>
                <button mat-raised-button color="primary" mat-dialog-close>Close</button>
            </div>
        </div>
    </div>
</ng-template>
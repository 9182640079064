import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { Baseline } from './model';

export const featureKey = 'reportBaseline';

export interface State extends EntityState<Baseline>, Status {

}

export const adapter = createEntityAdapter<Baseline>({

});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.loadBaselinesSuccess,
        (state, { data }) => adapter.setAll(data, { ...state, loading: false, loaded: true })
    ),
    on(actions.loadBaselinesFailure,
        (state, { error }) => ({ ...state, error, loading: false, loaded: false }))
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

import { updateCasMailbox } from 'src/app/stores/client/powershell/exo/cas-mailbox/actions';
import { CasMailbox } from 'src/app/stores/client/powershell/exo/cas-mailbox/model';
import { BooleanFormComponent, BooleanValidatorComponent } from '../generic/boolean';

export const fieldName = 'OutlookMobileEnabled';

export {
    BooleanFormComponent as FormComponent,
    BooleanValidatorComponent as ValidatorComponent
};

export function Remediate(_tenant: string, current: CasMailbox, schema: any) {

    if (schema.properties[fieldName]?.const === true) {
        const data = {
            Identity: current.ExternalDirectoryObjectId,
            [fieldName]: true
        };
        return updateCasMailbox({ _tenant, data, field: fieldName });
    }

    if (schema.properties[fieldName]?.const === false) {
        const data = {
            Identity: current.ExternalDirectoryObjectId,
            [fieldName]: false
        };
        return updateCasMailbox({ _tenant, data, field: fieldName });
    }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { IP } from './model';

export const featureKey = 'MspIp';

export interface State extends EntityState<IP>, Status { }

export const adapter: EntityAdapter<IP> = createEntityAdapter<IP>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadIps, (state) => ({ ...state, loading: !state.loaded })),
    on(actions.loadIpsSuccess, (state, action) => adapter.setAll(action.ips, { ...state, loading: false, loaded: true })),
    on(actions.loadIpsFailure, (state, { error }) => ({ ...state, error, loading: false })),

    on(actions.createIp, (state) => ({ ...state, creating: true, })),
    on(actions.createIpSuccess, (state, action) => adapter.addOne(action.ip, { ...state, creating: false })),
    on(actions.createIpFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.deleteIp, (state) => ({ ...state, deleting: true })),
    on(actions.deleteIpSuccess, (state, action) => adapter.removeOne(action.id, { ...state, deleting: false })),
    on(actions.deleteIpFailure, (state, { error }) => ({ ...state, error, deleting: false }))
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';


function grantControls(grant) {
    return {
        'type': 'object',
        'properties': {
            'builtInControls': {
                'type': 'array',
                'items': {
                    'type': 'string',
                    'const': grant
                }
            }
        }
    };
}

const platforms = {
    'type': 'object',
    'properties': {
        'includePlatforms': {
            'type': 'array',
            'items': {
                'enum': ['All', 'all'],
                'type': 'string'
            },
            'minItems': 1,
            'maxItems': 1,
            'uniqueItems': true
        }

    }
};

const applications = {
    'type': 'object',
    'properties': {
        'includeApplications': {
            'type': 'array',
            'items': {
                'enum': ['797f4846-ba00-4fd7-ba43-dac1f8f63013'],
                'type': 'string'
            },
            'minItems': 1,
            'maxItems': 1,
            'uniqueItems': true
        }

    },
    'required': ['includeApplications']
};



function policy (form): ConditionalAccessPolicySchema {
    return{
        grantControls: grantControls(form.grant),
        
        conditions: {    
            platforms,
            applications
        }
    };
}

export const CAPRestrictAzureAccessBaselineSchema =
    (
        form
    ) => {
        return createCapPolicySchema(form, policy(form));
    };


<div class="wrapper" (click)="navigate()">
    <div class="spinner">
        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="session">
        <div class="image">
            <img class="icon" src="./assets/img/dashboard/pwned.svg" />
            <div class="middle">
                <div class="text">Click to Open</div>
            </div>
            <app-severity-badges [severity]="pwned$ | async"></app-severity-badges>
        </div>
        <!-- <span class="mat-h2">Pwned Check</span> -->
    </div>
</div>
import { ConditionalAccessApplications, ConditionalAccessClientApp, ConditionalAccessGrantControls, ConditionalAccessPolicy, RiskLevel, User } from '@microsoft/microsoft-graph-types-beta';
import { RemediateCapItem } from '../shared/cap-remediate';
import { getCAPBaselineDisplayName } from '../shared/cap-schema';


// Cloud App Security administrator
const signInRiskLevels: RiskLevel[] = [
    'high',
    'medium'
];

function grantControls(grant): ConditionalAccessGrantControls {
    return {
        'operator': 'OR',
        'builtInControls': [
            grant
        ]
    };
}

const clientAppTypes: ConditionalAccessClientApp[] = [
    'all'
];


const applications: ConditionalAccessApplications = { // check this
    'includeApplications': [
        'all'
    ]

};

export function Remediate(_tenant: string, data: ConditionalAccessPolicy[], schema: any, users: User[], id) {
    let policyItem;
   
    if(id){
        policyItem = data.find(res => res.id === id );
    }else{
        policyItem = data.find(res => res.displayName === getCAPBaselineDisplayName(schema) );
    }
    
  
    const grant = schema?.contains?.properties?.grantControls?.properties?.builtInControls?.items?.const;

    return RemediateCapItem(_tenant, schema, policyItem, { grantControls: grantControls(grant), signInRiskLevels, clientAppTypes, applications });
}

import { AdminAuditLogConfig } from 'src/app/stores/client/powershell/exo/admin-audit-log-config/model';
import { updateExoOrganizationConfig } from 'src/app/stores/client/powershell/exo/organization-config/actions';

export function Remediate(_tenant: string, data: Partial<AdminAuditLogConfig>, schema: any) {

    if (schema.properties.OAuth2ClientProfileEnabled.const === false) {
        return updateExoOrganizationConfig({ _tenant, data: { OAuth2ClientProfileEnabled: false } });
    }

    if (schema.properties.OAuth2ClientProfileEnabled.const === true) {
        return updateExoOrganizationConfig({ _tenant, data: { OAuth2ClientProfileEnabled: true } });
    }
}

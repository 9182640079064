import { MailboxSettings } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';
import { UserMailboxSetting } from './model';

export const fetchMailboxSettings = createAction(
    '[Graph] Fetch Mailbox Settings',
    props<{ _tenant: string }>()
);

export const fetchMailboxSettingsSuccess = createAction(
    '[Graph] Fetch Mailbox Settings Success',
    props<{ _tenant: string, data: Array<UserMailboxSetting> }>()
);

export const fetchMailboxSettingsSuccessFailure = createAction(
    '[Graph] Fetch Mailbox Settings Failure',
    props<{ _tenant: string, error: any }>()
);

// export const fetchUserMailboxSettings = createAction(
//     '[Graph] Fetch User Mailbox Settings',
//     props<{ _tenant: string, user_id: string }>()
// );

// export const fetchUserMailboxSettingsSuccess = createAction(
//     '[Graph] Fetch User Mailbox Settings Success',
//     props<{ _tenant: string, data: Array<UserMailboxSetting> }>()
// );

// export const fetchUserMailboxSettingsSuccessFailure = createAction(
//     '[Graph] Fetch User Mailbox Settings Failure',
//     props<{ _tenant: string, error: any }>()
// );




export const updateMailboxSettings = createAction(
    '[Graph] Update Mailbox Settings',
    props<{ _tenant: string, user_id : string, MailboxSettings: Partial<MailboxSettings> }>()
);

export const updateMailboxSettingsSuccess = createAction(
    '[Graph] Update Mailbox Settings Success',
    props<{ _tenant: string, data: UserMailboxSetting }>()
);

export const updateMailboxSettingsFailure = createAction(
    '[Graph] Update Mailbox Settings Failure',
    props<{ _tenant: string, error: any }>()
);

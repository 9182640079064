import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as MspUserActions from './actions';
import { MSPUser } from './model';


export const featureKey = 'mspUser';

export interface State extends EntityState<MSPUser>, Status {
}

const selectId = (user: MSPUser) => `${user.id}`;

export const adapter: EntityAdapter<MSPUser> = createEntityAdapter<MSPUser>({
    selectId
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,

    on(MspUserActions.loadMspUsersSuccess,
        (state, { msp_users }) => { state = { ...state, loaded: true }; return adapter.setAll(msp_users, state); }
    ),
    on(MspUserActions.loadMspUsersFailure,
        (state, { error }) => ({ ...state, error })
    ),
    on(MspUserActions.upsertMspUser,
        (state, action) => adapter.upsertOne(action.msp_user, state)
    ),
    on(MspUserActions.updateMspUser,
        (state, action) => ({ ...state, updating: true })
    ),
    on(MspUserActions.updateMspUserSuccess,
        (state, action) => adapter.updateOne({ id: action.data.id, changes: { ...action.data } }, state = { ...state, updating: false })
    ),
    on(MspUserActions.updateMspUserFailure,
        (state, { error }) => ({ ...state, error, updating: false })
    ),
    on(MspUserActions.updateMspUser,
        (state, action) => ({ ...state, deleting: true })
    ),
    on(MspUserActions.deleteMspUserSuccess,
        (state, action) => adapter.removeOne(action.id, state = { ...state, deleting: false })),
    on(MspUserActions.deleteMspUserFailure,
        (state, { error }) => ({ ...state, error, deleting: false })
    ),
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();



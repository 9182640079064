<div class="mat-typography">
    <table>
        <tr>
            <th>Field</th>
            <th>Current Value</th>
            <th>Desired Value</th>
            <th>Status</th>
        </tr>

        <tr>
            <td>
                SMTP Access
                <mat-icon matTooltip="Specifies whether to disable authenticated SMTP (SMTP AUTH) for the whole organization.">info</mat-icon>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>
            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>
            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>

<ng-template #validatorRef>
    <div class="validator"> {{ data.SmtpClientAuthenticationDisabled ? "Disabled" : "Enabled" }} </div>
</ng-template>

<ng-template #formRef>
    <div class="form">
        <div class="field boolean">
            <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="control">
                    <mat-option value="any">Ignore</mat-option>
                    <mat-option [value]="false">Enabled</mat-option>
                    <mat-option [value]="true">Disabled</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>

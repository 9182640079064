<div class="wrapper">
    <div class="tenant-info">
        <mat-icon>assignment_ind</mat-icon>
        <h3>Tenant: {{tenantName}}</h3>
    </div>

    <mat-tab-group mat-align-tabs="start" class="tabs">
        <mat-tab label="Subscription / Sign Up">
            <div class="container">
                <mat-horizontal-stepper linear #stepper>
                    <mat-step>
                        <ng-template matStepLabel>Choose Plan</ng-template>
                        <div class="content-wrapper">
                            <billing-subscription-plan *ngFor="let p of plans" [plan]="p"></billing-subscription-plan>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Checkout</ng-template>
                        <div class="content-wrapper">
                            <billing-checkout></billing-checkout>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Complete</ng-template>
                        <div class="content-wrapper">
                            <billing-payment-status></billing-payment-status>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </mat-tab>
        
        <mat-tab label="Delete Tenant">
            <div class="container">
                <mat-horizontal-stepper linear #delete_stepper>
                    <mat-step [completed]="false" [editable]="false">
                        <ng-template matStepLabel>Send Tenant Removal Request</ng-template>
                        <div class="content-col-wrapper">
                            <h3>We are sorry to see you go :( </h3>
                            <p>Are you sure you want to delete your tenant from Octiga?</p>
                            <p>Click 'Confirm' to send us a removal request.</p>
                            <button mat-raised-button class="delete" (click)="sendDeleteTenantRequest()">Confirm</button>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Complete</ng-template>
                        <div class="content-col-wrapper">
                            <h3>We are sorry to see you go :( </h3>
                            <p>Your tenant removal request has been received, we will contact you once it is done.</p>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>   



<div class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                Modern Authentication Protocols

                <mat-icon matTooltip="By default this value is set to $True, which means that authentication using legacy protocols is enabled. Setting this parameter to $False prevents Office clients using non-modern authentication protocols from accessing SharePoint Online resources.">info</mat-icon>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>

            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>

<ng-template #validatorRef>
    <div class="validator">
        {{
        !data.LegacyAuthProtocolsEnabled
        ? "Enforced"
        : "Not Enforced"
        }}
    </div>
</ng-template>

<ng-template #formRef>
    <div class="form">
        <div class="field boolean">
            <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="control">
                    <mat-option value="any">Ignore</mat-option>
                    <mat-option [value]="false">Enforced</mat-option>
                    <mat-option [value]="true">Not Enforced</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>

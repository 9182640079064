<div class="wrapper">
    <div class="spinner">
        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div>
    <!-- <div class="middle">
        <div class="text">Click to Open</div>
    </div> -->
    <app-severity-list [severity]="(severity$ | async)"></app-severity-list>
    
    <app-monitoring-week-summary-chart (loaded)="isLoaded($event)" [tenant_id]="tenant_id"></app-monitoring-week-summary-chart>
    
    <mat-list class="status-list">
        <mat-list-item>
            <button *ngIf="(status$ | async).monitoring.status" class="button on"></button>
            <button *ngIf="!(status$ | async).monitoring.status" class="button off" [matTooltip]="(status$ | async).monitoring.error"></button>
            <span class="title pointer">Monitoring</span>
        </mat-list-item>
        <!-- <mat-list-item>
            <button *ngIf="(status$ | async).alert" class="button on"></button>
            <button *ngIf="!(status$ | async).alert" class="button off"></button>
            <span class="title">Alert</span>
        </mat-list-item> -->
    </mat-list>
</div>
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EntityStatus, initialStatus } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { SwayCategory } from './model';

export const featureKey = 'swayCategory';

export interface State extends EntityState<SwayCategory>, EntityStatus {

}

export const adapter = createEntityAdapter<SwayCategory>({

});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.loadSwayCategories, (state) => ({ ...state, loading: !state.loaded })),
    on(actions.loadSwayCategoriesSuccess,
        (state, { data }) => adapter.upsertMany(data, { ...state, loading: false, loaded: true })
    ),
    on(actions.loadSwayCategoriesFailure,
        (state, { error }) => ({ ...state, error, updating: false })
    )
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

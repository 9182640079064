import { Action, createReducer, on } from '@ngrx/store';
import { MalwareFilterRule } from 'src/app/interfaces/powershell/exo/malware-rule.interface';
import * as ExoActions from './actions';

export const exoFeatureKey = 'malwareFilterRule';

export interface State {
    data: MalwareFilterRule[],
    error: any
    loaded: boolean
}

export const initialState: State = {
    data: [],
    error: false,
    loaded: false
};


export const reducer = createReducer(
    initialState,
    on(ExoActions.loadMalwareRuleSuccess, (state, { data }) => ({ ...state, loaded: true })),
    on(ExoActions.loadMalwareRuleFailure, (state, { error }) => ({ ...state, error, loaded: false}))
);

export const selectMalwareFilterRule = (state: State) => state.data;
export const selectStatus = (state: State) => ({ loaded: state.loaded, error: state.error });


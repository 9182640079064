<div class="overlay" *ngIf="(showOverlay$| async)">
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div [style.grid-template-columns]="'repeat(' + (cardsLayout$ | async)?.columns + ', auto)' " class="grid-wrapper">
    <div class="grid-item"
    [class.justify-start]="card.id === '10'"
        [style.grid-column]="(cardsLayout$ | async)?.columns > 1 ? ('span ' + card.cols): ('span ' + 1)"
        *ngFor="let card of (cardsLayout$ | async)?.items;">

        <app-card-container [item]="card">
            <ng-template cardDirective [item]="card"></ng-template>
        </app-card-container>
    </div>
</div>

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as actions from './actions';
import { DKIMSigningConfig } from './model';

export const featureKey = 'exoDkimSigningConfig';

export interface State extends EntityState<DKIMSigningConfig>, Status { }

export const adapter: EntityAdapter<DKIMSigningConfig> = createEntityAdapter<DKIMSigningConfig>({
    selectId: (item) => item.Identity
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(actions.fetchDkimSigningConfig, (state) => ({ ...state, loading: true, error: null })),
    on(actions.fetchDKIMSigningConfigSuccess,
        (state, action) => adapter.setAll(action.data, { ...state, loading: false, loaded: true, error: null })
    ),
    on(actions.fetchDKIMSigningConfigFailure,
        (state, { error }) => ({ ...state, loading: false, error })
    ),
    on(actions.updateDKIMSigningConfig,
        (state) => ({ ...state, updating: true, error: null })
    ),
    on(actions.updateDKIMSigningConfigSuccess,
        (state, { data }) => adapter.updateOne(data, { ...state, updating: false, error: null })
    ),
    on(actions.updateDKIMSigningConfigFailure,
        (state, { error }) => ({ ...state, updating: false, error })
    )
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();





import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, distinct, last, map, mergeMap } from 'rxjs/operators';
import { MalwareFilterPolicy } from 'src/app/interfaces/powershell/exo/malware-filter-policy.interface';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as ExoActions from './actions';

@Injectable()
export class MalwareFilterPolicyEffects {
    getExoMalwareFilterPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.loadMalwareFilterPolicy),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    retry(3000, 3, 'eop timeout'),
                    map((data: MalwareFilterPolicy[]) => data.map(res => ({ ...res, id: res.Identity }))),

                    last(),
                    map((data: MalwareFilterPolicy[]) => ExoActions.loadMalwareFilterPolicySuccess({ _tenant, data })),
                    catchError((error) => of(ExoActions.loadMalwareFilterPolicyFailure({ _tenant, error: {message : error.message, trace: error.trace} })))
                ))
        )
    );

    updateExoMalwareFilterPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.updateExoMalwareFilterPolicy),
            mergeMap((action) => this.put(action._tenant, action.data)
                .pipe(
                    map((res) => ({ id: res[0].Identity, ...res[0] })),
                    map((data: MalwareFilterPolicy) => ExoActions.updateExoMalwareFilterPolicySuccess({ _tenant: action._tenant, data })),
                    catchError((error) => of(ExoActions.updateExoMalwareFilterPolicyFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    private get(tenant: string) {
        const url = '/api/microsoft/powershell/exo/malware-filter-policy';
        return this.ajax.get(tenant, url);
    }

    private put(tenant: string, body: Partial<MalwareFilterPolicy>): Observable<MalwareFilterPolicy> {
        const url = '/api/microsoft/powershell/exo/malware-filter-policy';
        return this.ajax.put(tenant, url, body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>
    ) { }

}

<div class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                Disallow Infected File Download
                <mat-icon matTooltip="Prevents the Download button from being displayed on the Virus Found warning page.">info</mat-icon>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>

            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>

<ng-template #validatorRef>
    <div class="validator">
            {{
                data.DisallowInfectedFileDownload
                    ? "Disallow download"
                    : "Allow download"
            }}
    </div>
</ng-template>

<ng-template #formRef>
    <div class="form">
        <div class="field boolean">
            <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="control">
                    <mat-option value="any">Ignore</mat-option>
                    <mat-option [value]="true"
                        >Disallow download</mat-option
                    >
                    <mat-option [value]="false"
                        >Allow download</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>


import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveComponentModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule, select } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { distinctUntilChanged, filter, first, map, take } from 'rxjs/operators';
import * as MSPFeatureReducers from 'src/app/modules/msp/store';
import { ChangesEffects } from 'src/app/stores/client/octiga/changes/effects';
import * as fromRoot from 'src/app/stores/root.store';
import { AppRoutingModule } from './app-routing.module';
import { ContainerComponent } from './components/container/container.component';
import { HeaderButtonComponent } from './components/container/header-button/header-button.component';
import { ProfileButtonComponent } from './components/container/profile-button/profile-button.component';
import { SpinnerComponent } from './components/container/spinner/spinner.component';
import { MonitoringNavComponent } from './components/monitoring-nav/monitoring-nav.component';
import { RefreshSnackBarComponent } from './components/refresh-snack-bar/refresh-snack-bar';
import { BillingModule } from './modules/billing/billing.module';
import { MaterialModule } from './modules/material.module';
import { OnboardingService } from './modules/msp/services/onboarding.service';
import { MspAsnEffects } from './modules/msp/store/asn/effects';
import { MspEventRulesEffects } from './modules/msp/store/event-rules/effects';
import { MspGeoEffects } from './modules/msp/store/geo/effects';
import { CwmBoardsEffects } from './modules/msp/store/integrations/cwm/boards/effects';
import { CwmStatusesEffects } from './modules/msp/store/integrations/cwm/boards/statuses/effects';
import { CwmTeamsEffects } from './modules/msp/store/integrations/cwm/boards/teams/effects';
import { CwmTypesEffects } from './modules/msp/store/integrations/cwm/boards/types/effects';
import { CwmSubTypesEffects } from './modules/msp/store/integrations/cwm/boards/types/subTypes/effects';
import { CwmCompaniesEffects } from './modules/msp/store/integrations/cwm/companies/effects';
import { CwmConnectionEffects } from './modules/msp/store/integrations/cwm/connection/effects';
import { CwmPrioritiesEffects } from './modules/msp/store/integrations/cwm/priorities/effects';
import { CwmSeveritiesEffects } from './modules/msp/store/integrations/cwm/severities/effects';
import { DattoConnectionEffects } from './modules/msp/store/integrations/datto/effects';
import { HaloConfigEffects } from './modules/msp/store/integrations/halo/effects';
import { MspIpEffects } from './modules/msp/store/ip/effects';
import { MspAccessGroupsEffects } from './modules/msp/store/msp-access-groups/msp-access-groups.effects';
import { MspUserEffects } from './modules/msp/store/msp-user/effects';
import { loadMsp } from './modules/msp/store/msp/actions';
import { MspEffects } from './modules/msp/store/msp/effects';
import { Msp } from './modules/msp/store/msp/model';
import { SnackBarComponent } from './modules/shared/components/snack-bar/snack-bar.component';
import { LoaderModule } from './modules/shared/libs/loader/loader.module';
import { SharedModule } from './modules/shared/shared.module';
import { SwayTemplateItemsEffects } from './modules/sway/store/templates/items/effects';
import { SwayTemplatesEffects } from './modules/sway/store/templates/templates/effects';
import { PermissionDialogComponent } from './services/guards/permission-dialog/permission-dialog.component';
import { InitClientService } from './services/init-client.service';
// import { SessionCheckService } from './services/session-check.service';
import { VersionCheckService } from './services/version-check.service';
import { WebsocketService } from './services/websocket.service';
import { AsnListEffects } from './stores/root/octiga/asn-list/effects';
import { SwayCategoryEffects } from './stores/root/sway/category/effects';
// SUB CLIENT STORE EFFECTS
import { TokenInterceptor } from './services/interceptors/token.interceptor';
import { AlertEffects as _AlertEffects } from './stores/client/alert/effects';
import { EventEffects as _EventEffects } from './stores/client/etl/event/effects';
import { SummaryEffects } from './stores/client/etl/summary/effects';
import { TasksEffects } from './stores/client/etl/tasks/effects';
import { GraphAppRoleAssignmentsEffects } from './stores/client/graph/appRoleAssignments/effects';
import { AuthenticationMethodsEffects } from './stores/client/graph/authentication/methods/effects';
import { PerUserMfaStateEffects } from './stores/client/graph/authentication/perUserMfaState/effects';
import { Windows10CompliancePolicyEffect } from './stores/client/graph/compliance-policies/policies/effects';
import { ConditionalAccessPolicyEffects } from './stores/client/graph/conditional-access/effects';
import { DirectoryRoleEffects } from './stores/client/graph/directoryRoles/effects';
import { DirectoryRoleMemberEffects } from './stores/client/graph/directoryRoles/members/effects';
import { GraphDomainsEffects } from './stores/client/graph/domains/effects';
import { GroupEffects as _GroupEffects } from './stores/client/graph/group/effects';
import { GroupMembersEffects } from './stores/client/graph/group/members/effects';
import { GraphInboxRulesEffects } from './stores/client/graph/inbox-rules/effects';
import { UserMailboxSettingsEffects } from './stores/client/graph/mailboxsettings/effects';
import { SecurityDefaultsEnforcementPolicyEffects } from './stores/client/graph/security-defaults/effects';
import { GraphServicePrincipalAppRolesEffects } from './stores/client/graph/servicePrincipal/appRoles/effects';
import { GraphServicePrincipalEffects } from './stores/client/graph/servicePrincipal/effects';
import { GraphServicePrincipalOAuth2PermissionGrantsEffects } from './stores/client/graph/servicePrincipal/oauth2PermissionGrants/effects';
import { GraphSPOSharePointSiteDetailsEffects } from './stores/client/graph/spo/sharepoint-site/effects';
import { GraphSubscribedSkusEffects } from './stores/client/graph/subscribedSkus/effects';
import { GraphUserEffects } from './stores/client/graph/user/user.effects';
import { DismissEffects } from './stores/client/octiga/dismiss/effects';
import { RolesStatusEffects } from './stores/client/octiga/status/roles/effects';
import { SubscriptionsStatusEffects } from './stores/client/octiga/status/subscriptions/effects';
import { TenantEffects as _OctigaTenantEffects } from './stores/client/octiga/tenant/effects';
import { UserEffects } from './stores/client/octiga/user/effects';
import { ExoAdminAuditLogConfigEffects as _ExoAdminAuditLogConfigEffects } from './stores/client/powershell/exo/admin-audit-log-config/effects';
import { AntiPhishPolicyEffects } from './stores/client/powershell/exo/anti-phish-policy/effects';
import { CasMailboxEffects as _CasMailboxEffects } from './stores/client/powershell/exo/cas-mailbox/effects';
import { DKIMSigningConfigEffects } from './stores/client/powershell/exo/dkim-signing-config/effects';
import { EOPProtectionPolicyEffects } from './stores/client/powershell/exo/eop-protection-policy-rule/effects';
import { ExoHostedOutboundSpamFilterPolicyEffects } from './stores/client/powershell/exo/hosted-outbound-spam-filter-policy/effects';
import { MailboxDelegateEffects as _ExoMailboxDelegateEffects } from './stores/client/powershell/exo/mailbox-delegate/effects';
import { MailboxEffects as _MailboxEffects } from './stores/client/powershell/exo/mailbox/effects';
import { MalwareFilterPolicyEffects } from './stores/client/powershell/exo/malware-filter-policy/effects';
import { MalwareFilterRuleEffects } from './stores/client/powershell/exo/malware-filter-rule/effects';
import { ExoMobileDeviceMailboxPolicyEffects } from './stores/client/powershell/exo/mobile-device-mailbox-policy/effects';
import { ExoOrganizationConfigEffects as _ExoOrganizationConfigEffects } from './stores/client/powershell/exo/organization-config/effects';
import { ExoQuarantinePolicyEffects } from './stores/client/powershell/exo/quarantine-policy/effects';
import { RecipientEffects } from './stores/client/powershell/exo/recipient/effects';
import { ExoRemoteDomainEffects as _ExoRemoteDomainEffects } from './stores/client/powershell/exo/remote-domain/effects';
import { SharingPolicyEffects, SharingPolicyEffects as _SharingPolicyEffects } from './stores/client/powershell/exo/sharing-policy/effects';
import { ExoTransportConfigEffects as _ExoTransportConfigEffects } from './stores/client/powershell/exo/transport-config/effects';
import { TransportRuleEffects } from './stores/client/powershell/exo/transport-rule/effects';
import { ExoUserEffects as _ExoUserEffects } from './stores/client/powershell/exo/user/effects';
import { SpoTenantEffects as _SpoTenantEffects } from './stores/client/powershell/spo/tenant/effects';
import { CsOAuthConfigurationEffects as _CsOAuthConfigurationEffects } from './stores/client/powershell/teams/cs-oauthconfiguration/effects';
import { ReportBaselineEffects as _ReportBaselineEffects } from './stores/client/report/baseline/effects';
import { ReportCurrentPostureCountEffects as _ReportCurrentPostureCountEffects } from './stores/client/report/current_posture_count/effects';
import { ReportDeviationEffects as _ReportDeviationEffects } from './stores/client/report/deviations/effects';
import { ReportScheduleEffects } from './stores/client/report/schedule/effect';
import { ReportPSATenantTicketCountEffects as _ReportPSATenantTicketCountEffects } from './stores/client/report/tickets/effects';
import { SwayBaselineEffects } from './stores/client/sway/baseline/effects';
import { DeviationEffects } from './stores/client/sway/deviation/deviation.effects';
import { GroupEffects } from './stores/client/sway/group/effects';
import { TenantEffects } from './stores/client/sway/tenant/effects';
import { GeoJsonEffects } from './stores/root/google/geojson/effects';
import { DirectoryRoleTemplatesEffects } from './stores/root/graph/directoryRoleTemplates/effects';
import { loadSession } from './stores/root/octiga/session/actions';
import { SessionEffects } from './stores/root/octiga/session/effects';
import { ReportCategoryEffects } from './stores/root/report/category/effects';
import { SwayMspConfigEffects } from './stores/root/sway/onboardingStatus/effects';
import { SpecEffects } from './stores/root/sway/spec/effects';
import { MspGraphUserEffects } from './modules/msp/store/msp-graph-user/effects';


@NgModule({
    declarations: [
        ContainerComponent,
        HeaderButtonComponent,
        MonitoringNavComponent,
        ProfileButtonComponent,
        SpinnerComponent,
        SnackBarComponent,
        RefreshSnackBarComponent,
        PermissionDialogComponent
    ],
    imports: [
        StoreModule.forRoot(fromRoot.reducers, {
            metaReducers: fromRoot.metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: true,
                strictActionSerializability: false,
            },
        }),

        // StoreModule.forFeature('client', fromClient.reducers),
        EffectsModule.forRoot([SessionEffects]),

        EffectsModule.forFeature([
            TenantEffects,
            SwayBaselineEffects,
            AsnListEffects,
            GeoJsonEffects,
            AuthenticationMethodsEffects,
            ReportScheduleEffects,
            ReportCategoryEffects,
            SwayCategoryEffects,
            SpecEffects,
            DeviationEffects,
            GroupEffects,
            ChangesEffects,
            DismissEffects,
            SwayMspConfigEffects,
            DirectoryRoleEffects,
            DirectoryRoleTemplatesEffects,
            DirectoryRoleMemberEffects,
            UserMailboxSettingsEffects,
            ExoMobileDeviceMailboxPolicyEffects,
            GraphDomainsEffects,
            ConditionalAccessPolicyEffects,
            DKIMSigningConfigEffects,
            SecurityDefaultsEnforcementPolicyEffects,
            GraphInboxRulesEffects,
            GraphAppRoleAssignmentsEffects,
            GraphSubscribedSkusEffects,
            ExoHostedOutboundSpamFilterPolicyEffects,
            MalwareFilterPolicyEffects,
            MalwareFilterRuleEffects,
            SwayTemplateItemsEffects,
            RecipientEffects,
            SessionEffects,
            SharingPolicyEffects,
            SummaryEffects,
            TasksEffects,
            AntiPhishPolicyEffects,
            ExoQuarantinePolicyEffects,
            EOPProtectionPolicyEffects,
            TransportRuleEffects,
            PerUserMfaStateEffects,
            UserEffects,
            GroupMembersEffects,
            Windows10CompliancePolicyEffect,
            RolesStatusEffects,
            SubscriptionsStatusEffects,

            // SUB CLIENT STORE EFFECTS
            _OctigaTenantEffects,
            _AlertEffects,
            _EventEffects,
            GraphUserEffects,
            _GroupEffects,
            GraphServicePrincipalEffects,
            GraphServicePrincipalAppRolesEffects,
            GraphServicePrincipalOAuth2PermissionGrantsEffects,
            _CasMailboxEffects,
            _MailboxEffects,
            _SharingPolicyEffects,
            _ExoUserEffects,
            _ExoMailboxDelegateEffects,
            _ExoTransportConfigEffects,
            _ExoRemoteDomainEffects,
            _ExoOrganizationConfigEffects,
            _ExoAdminAuditLogConfigEffects,
            _SpoTenantEffects,
            _CsOAuthConfigurationEffects,
            _ReportBaselineEffects,
            _ReportDeviationEffects,
            _ReportCurrentPostureCountEffects,
            _ReportPSATenantTicketCountEffects,

            // MSP
            MspAccessGroupsEffects,
            MspEffects,
            MspUserEffects,
            MspGraphUserEffects,
            MspEventRulesEffects,
            MspAsnEffects,
            MspIpEffects,
            MspGeoEffects,
            CwmConnectionEffects,
            CwmCompaniesEffects,
            CwmBoardsEffects,
            CwmPrioritiesEffects,
            CwmSeveritiesEffects,
            CwmStatusesEffects,
            CwmTeamsEffects,
            CwmTypesEffects,
            CwmSubTypesEffects,

            HaloConfigEffects,
            DattoConnectionEffects,

            // MSP Templates
            SwayTemplatesEffects,
            // GRAPH
            GraphSPOSharePointSiteDetailsEffects,
        ]),
        StoreModule.forFeature(
            MSPFeatureReducers.featureKey,
            MSPFeatureReducers.reducers
        ),
        BrowserModule,
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatSelectModule,
        ReactiveFormsModule,
        ReactiveComponentModule,
        SharedModule,
        BillingModule,
        MatIconModule,
        AppRoutingModule,
        LoaderModule,
        StoreDevtoolsModule.instrument({
            maxAge: 5,
        }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        DatePipe,
        // BugSnagErrorHandler,
        OnboardingService,
        { provide: Window, useValue: window },

    ],
    bootstrap: [ContainerComponent],
})
export class AppModule {
    iconList = [
        'no-ual',
        'integrations',
        'view-details',
        'owa-forward',
        'inbox-forward',
        'move-item',
        'delete-item',
        'white-labelling',
        'ip',
    ];

    constructor(
        private store: Store<any>,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        // private bugsnag: BugsnagService,
        private versionCheckService: VersionCheckService,
        private initService: InitClientService,
        // private sessionCheckService: SessionCheckService,
        private onboard: OnboardingService,
        private webSocketService: WebsocketService
    ) {
        this.iconsRegister(this.matIconRegistry, this.domSanitizer);
        // this.sessionCheckService.init();
        this.versionCheckService.init();
        this.init();
    }

    init() {
        console.log('init');

        this.store.dispatch(loadSession());

        // one time init
        this.store
            .pipe(
                select(fromRoot.selectSession),
                first((state) => state.isAuthenticated),
            )
            .subscribe(({ session }) => {
                for (const tid of Object.keys(session?.tenantAccess ?? {})) {
                    this.initService.addClientReducer(tid);
                }
                // this.webSocketService.init();
            });

        // every time client switches init
        this.store
            .pipe(
                select(fromRoot.selectSession),
                filter(
                    (state) => state.isAuthenticated && !!state.session.clientId
                ),
                distinctUntilChanged(
                    (a, b) => a.session.clientId === b.session.clientId
                )
            )
            .subscribe((state) => {
                this.initService.loadClient(state.session.clientId);
                // this.setBugsnagSession(state.session);
            });

        // loadMsp
        this.store
            .pipe(
                select(fromRoot.selectSession),
                filter(
                    (state) => state.isAuthenticated && !!state.session.msp_id
                ),
                take(1)
            )
            .subscribe((msp) => {
                // this.setBugsnagSession(msp.session);
                this.store.dispatch(loadMsp({ msp_id: msp.session.msp_id, userId: msp.session.user_id }));
                this.onboard.init(msp.session.user_id);
            });
    }

    mspIsActive(msp: Msp): boolean {
        return msp.status === 'TRIAL' || msp.status === 'SUBSCRIPTION';
    }

    iconsRegister(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer): void {
        this.iconList.forEach((iconName) => {
            iconRegistry.addSvgIcon(
                iconName,
                domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/img/icons/' + iconName + '.svg'
                )
            );
        });
    }

    // setBugsnagSession(session: Session) {
    //     const { upn, clientId, msp_id } = session;
    //     this.bugsnag.startSession(upn, clientId, msp_id);
    // }
}

import { disableExoEOPProtectionPolicy, enableExoEOPProtectionPolicy } from 'src/app/stores/client/powershell/exo/eop-protection-policy-rule/actions';
import { EOPProtectionPolicy } from 'src/app/stores/client/powershell/exo/eop-protection-policy-rule/model';
export const IdentityName = 'Strict Preset Security Policy';

export function Remediate(_tenant: string, data: EOPProtectionPolicy[], schema: any) {

    const state = schema.contains.properties.State['const'];

    if (state ==='Enabled') {
        return enableExoEOPProtectionPolicy( // not finished
            {
                _tenant,
                data: {
                    Identity: IdentityName
                }
            }
        );
    } else {
        return disableExoEOPProtectionPolicy( // not finished
            {
                _tenant,
                data: {
                    Identity: IdentityName
                }
            }
        );
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, ObservableInput } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CryptoService } from 'src/app/modules/shared/services/crypto.service';
import { client } from 'src/app/stores/client';
import { createDismissItem, deleteDismissItem, loadDismissItems } from 'src/app/stores/client/octiga/dismiss/actions';
import { Dismiss } from 'src/app/stores/client/octiga/dismiss/model';
import { Status } from 'src/app/stores/status.interface';

export interface DismissItem {
    id: string
    severity: string
    title: string
    description: string
    dismiss?: boolean
}

@Component({
    selector: 'app-dismiss-items-table',
    templateUrl: './dismiss-items-table.component.html',
    styleUrls: ['./dismiss-items-table.component.scss']
})
export class DismissItemsTableComponent implements OnInit {
    @Input() tenant_id: string = '';
    @Input() type: string;
    @Input() items: DismissItem[];


    dismiss$: Observable<Dismiss[]>;

    dismissStatus$: Observable<Status>;
    notLoading$: Observable<boolean>;
    dismissed_items: string[] = [];

    dataSource: MatTableDataSource<DismissItem>;
    displayedColumns = ['severity', 'description', 'dismiss'];

    constructor(
        private store: Store<any>,
        private crypto: CryptoService
    ) { }

    ngOnInit(): void {
        this.dispatcher();
        this.dismiss$ = this.store.select(client(this.tenant_id).octiga.dismiss.all).pipe(
            map((res) => {
                if (!!res && res.length > 0) {
                    return res.filter(r => r.type === this.type && r.tenant === this.tenant_id);
                } else {
                    return [];
                }
            })
        );
        this.dismissStatus$ = this.store.select(client(this.tenant_id).octiga.dismiss.status);
        this.notLoading$ = this.dismissStatus$.pipe(map(s => s.loaded && !s.creating && !s.updating));
        this.dismiss$.pipe(distinctUntilChanged())
            .subscribe(dismiss => {
                const ids = dismiss.map(d => d.id);
                this.dismissed_items = ids;
                this.dataSource = new MatTableDataSource(this.setDataSource());
            });
        this.dataSource = new MatTableDataSource(this.setDataSource());
    }


    dispatcher() {
        
        this.store.dispatch(loadDismissItems({_tenant: this.tenant_id}));
    }

    setDataSource() {
        const formattedData: DismissItem[] = [];
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            item.dismiss = this.isDismissed(item.id) ? true : false;
            formattedData.push(item);
        }
        return formattedData.sort((a, b) => {
            return Object.values(a)[0] > Object.values(b)[0] ? 1 : -1;
        });
    }

    isDismissed(key: string) {
        return this.dismissed_items.includes(this.hash(key));
    }

    dismiss(key: string) {
        const hash_id = this.hash(key);
        this.storeDismissItem(hash_id);
    }

    retain(key: string) {
        const hash_id = this.hash(key);
        this.deleteDismissItem(hash_id);
    }

    private hash(key: string) {
        const hash_id = this.crypto.hash(key);
        return hash_id;
    }

    private storeDismissItem(hash_id: string) {
        // this.tenant$
        //     .subscribe(tenant => {
        const data: Dismiss = {
            id: hash_id,
            tenant: this.tenant_id,
            type: this.type
        };
        this.store.dispatch(createDismissItem({ _tenant: this.tenant_id, data }));
        // });
    }

    private deleteDismissItem(hash_id: string) {
        this.store.dispatch(deleteDismissItem({ _tenant: this.tenant_id, id: hash_id }));
    }

}

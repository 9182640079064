<div class="field boolean">
     <mat-form-field>
        <mat-select [formControl]="control">
            <mat-option [value]="false">Enable</mat-option>
            <mat-option [value]="true">Disable</mat-option>
            <mat-option [value]="'null'">Controlled by Global SMTP Policy.</mat-option>
        </mat-select>
    </mat-form-field>


    <p>
        If you set <span class="bold"> Controlled by Global SMTP Policy</span> on a user then that users SMTP settings will be controlled by the global SMTP policy. 
        
        <ng-container *ngIf="tenant_baseline$ | async as baseline; else elseBlock">
            This global policy can be set in Octiga using the <a [routerLink]="'../../../../tenant/baseline/' + baseline.id" class="bold"> SMTP Access</a> tenant baseline.

        </ng-container>

        <ng-template #elseBlock>
            You can create <a routerLink="../../../../tenant" class="bold">SMTP Access </a> policy from Tenant baselines.
        </ng-template>
    </p>
</div>

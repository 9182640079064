import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

export interface SnackBarConfig extends MatSnackBarConfig {
    verticalPosition?: MatSnackBarVerticalPosition
    horizontalPosition?: MatSnackBarHorizontalPosition
    data: {
        message: string
        message2?: string
        dismiss_text?: string
        duration?: number
        success?: boolean
        error?: boolean
    }
}

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    public snack$ = new EventEmitter<any>();
    public dismiss$ = new EventEmitter<any>();

    constructor() { }

    scheduleDismiss(duration = 4000): void {
        setTimeout(() => {
            this.dismiss();
        }, duration);
    }

    open(config: SnackBarConfig): void {
        this.snack$.emit(config);
        this.scheduleDismiss(config.data.duration);
    }

    dismiss() {
        this.dismiss$.emit();
    }

    success(title, desc?) {
        this.snack$.emit(
            {
                data: {
                    message: title,
                    message2: desc,
                    // dismiss_text?: string
                    duration: 4000,
                    success: true,
                    error: false
                }
            }
        );
    }


    error(title, desc?, duration = 3000) {
        this.snack$.emit(
            {
                data: {
                    message: title,
                    message2: desc,
                    // dismiss_text?: string
                    duration,
                    success: false,
                    error: true
                }
            }
        );
    }

    fixedError({ title, desc }) {

        this.open({
            panelClass: ['overlay-large-right', 'error'],
            data: {
                message: title,
                message2: desc,
                success: false,
                error: true
            }
        });

    }
}

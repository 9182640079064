import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, delay, map, mergeMap, of, retryWhen, throwError } from 'rxjs';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as actions from './actions';
import { Session } from './model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SessionEffects {

    loadSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadSession),
            mergeMap(() => this.ajax.get<Session>('/api/microsoft/oauth/session')
                .pipe(
                    retryWhen(errors =>
                        errors.pipe(
                            concatMap((error, count) => {
                                if (count < 3) {
                                    return of(error).pipe(delay(2000)); // Retry 4 times with 1-second delay
                                }
                                return throwError(() => error);
                            })
                        )
                    ),
                    catchError((error: any) => {
                        if (error.status === 405) {
                            // Navigate to access-denied page if status is 405
                            this.router.navigate(['/auth/access-denied'], { queryParams: { upn: error.error?.upn } });
                        } else {
                            // Redirect for reauthentication when session is missing or invalid
                            this.router.navigate(['/auth/microsoft'], { queryParams: { return_url: window.location.href } });
                        }

                        return throwError(() => error);
                    }),
                    map(session => actions.loadSessionSuccess({ session })),
                    catchError((error) => of(actions.loadSessionFailure({ error })))
                ))
        )
    );

    deleteSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteSession),
            mergeMap(({ microsoftSignout }) => this.ajax.delete('/api/microsoft/oauth/session')
                .pipe(
                    map(() => {
                        if (microsoftSignout) {
                            const redirect_uri = `${window.location.origin}/dashboard`;
                            const logout = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${redirect_uri}`;
                            window.location.href = logout;
                        } else {
                            const return_url = window.location.href;
                            this.router.navigate(['/auth/microsoft'], { queryParams: { return_url } });
                        }
                    }),
                    catchError((error) => of(actions.deleteSessionFailure({ error })))
                )
            )
        ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
        private router: Router
    ) { }
}

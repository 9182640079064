import { Group, User } from '@microsoft/microsoft-graph-types-beta';
import { combineReducers, createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { MicrosoftEvent } from 'microsoft-events/dist/interfaces/meta.interface';
import { environment } from 'src/environments/environment';
import { selectStatus } from '../status.interface';
import * as fromAlert from './alert/reducer';
import * as fromEvent from './etl/event/reducer';
import * as fromEventSummary from './etl/summary/reducer';
import { UpdateDateList } from './etl/update-date-list/model';
import * as fromUpdateDateLists from './etl/update-date-list/reducer';
import * as fromGraphAppRoleAssignments from './graph/appRoleAssignments/reducer';
import * as fromGraphAuthenticationMethods from './graph/authentication/methods/reducer';
import * as fromGraphPerUserMfa from './graph/authentication/perUserMfaState/reducer';
import * as fromGraphConditionalAccessPolicy from './graph/conditional-access/reducer';
import * as fromGraphDirectoryRoleMembers from './graph/directoryRoles/members/reducer';
import * as fromGraphDirectoryRoles from './graph/directoryRoles/reducer';
import * as fromGraphDomains from './graph/domains/reducer';
import * as fromSharePointSiteDetails from './graph/spo/sharepoint-site/reducer';
import * as fromGraphGroupMembers from './graph/group/members/reducer';
import * as FromGraphCompliancePolicyWindows10 from './graph/compliance-policies/policies/reducer';
import * as fromGraphGroup from './graph/group/reducer';
import * as fromGraphInboxRule from './graph/inbox-rules/reducer';
import * as fromGraphMailboxSettings from './graph/mailboxsettings/reducer';

import * as fromGraphOrganization from './graph/organization/reducer';
import * as fromSecurityDefaultsPolicy from './graph/security-defaults/reducer';
import * as fromGraphAppRole from './graph/servicePrincipal/appRoles/reducer';
import * as fromOAuth2PermissionGrants from './graph/servicePrincipal/oauth2PermissionGrants/reducer';
import * as fromGraphServicePrincipal from './graph/servicePrincipal/reducer';
import * as fromGraphSubscribedSku from './graph/subscribedSkus/reducer';
import * as fromGraphUser from './graph/user/user.reducer';
import { ChangesLog } from './octiga/changes/model';
import * as fromOctigaChanges from './octiga/changes/reducer';
import * as fromOctigaDismiss from './octiga/dismiss/reducer';
import * as fromOctigaTenantStatus from './octiga/status';
import * as fromOctigaTenant from './octiga/tenant/reducer';
import * as fromOctigaUser from './octiga/user/reducer';
import * as fromExoAdminAuditLogConfig from './powershell/exo/admin-audit-log-config/reducer';
import * as fromExoAntiPhishPolicy from './powershell/exo/anti-phish-policy/reducer';
import * as fromExoCasMailbox from './powershell/exo/cas-mailbox/reducer';
import * as fromExoDkimSigningConfig from './powershell/exo/dkim-signing-config/reducer';
import * as fromEOPProtectionPolicy from './powershell/exo/eop-protection-policy-rule/reducer';
import * as fromHostedOutboundSpamFilterPolicy from './powershell/exo/hosted-outbound-spam-filter-policy/reducer';
import * as fromExoMailboxDelegate from './powershell/exo/mailbox-delegate/reducer';
import * as fromExoMailbox from './powershell/exo/mailbox/reducer';
import * as fromExoMalwareFilterPolicy from './powershell/exo/malware-filter-policy/reducer';
import * as fromExoMalwareFilterRule from './powershell/exo/malware-filter-rule/reducer';
import * as fromMobileDeviceMailboxPolicy from './powershell/exo/mobile-device-mailbox-policy/reducer';
import * as fromExoOrganizationConfig from './powershell/exo/organization-config/reducer';
import * as fromExoQuarantinePolicy from './powershell/exo/quarantine-policy/reducer';
import * as fromExoRecipient from './powershell/exo/recipient/reducer';
import * as fromExoRemoteDomain from './powershell/exo/remote-domain/reducer';
import * as fromExoSharingPolicy from './powershell/exo/sharing-policy/reducer';
import * as fromExoTransportConfig from './powershell/exo/transport-config/reducer';
import * as fromExoTransportRules from './powershell/exo/transport-rule/reducer';
import * as fromExoUser from './powershell/exo/user/reducer';
import * as fromSpoTenant from './powershell/spo/tenant/reducer';
import * as fromTeamsCsOAuthConfiguration from './powershell/teams/cs-oauthconfiguration/reducer';
import * as fromSwayBaseline from './sway/baseline/reducer';
import * as fromSwayDeviation from './sway/deviation/deviation.reducer';
import { SwayGroup } from './sway/group/model';
import * as fromSwayGroup from './sway/group/reducer';
import * as fromReportBaseline from './report/baseline/reducer';
import * as fromReportSchedule from './report/schedule/reducer';
import * as fromReportDeviation from './report/deviations/reducer';
import * as fromSwayTenant from './sway/tenant/reducer';
import * as fromReportCurrentPostureCount from './report/current_posture_count/reducer';
import * as fromReportTenantTicketCount from './report/tickets/reducer';

export interface State {
    [fromAlert.featureKey]: fromAlert.State;
    [fromEvent.featureKey]: fromEvent.State;
    [fromEventSummary.featureKey]: fromEventSummary.State;
    [fromUpdateDateLists.featureKey]: fromUpdateDateLists.State;
    [fromExoAdminAuditLogConfig.featureKey]: fromExoAdminAuditLogConfig.State;
    [fromExoDkimSigningConfig.featureKey]: fromExoDkimSigningConfig.State;
    [fromExoCasMailbox.featureKey]: fromExoCasMailbox.State;
    [fromExoMailbox.featureKey]: fromExoMailbox.State;
    [fromExoMailboxDelegate.exoFeatureKey]: fromExoMailboxDelegate.State;
    [fromExoMalwareFilterPolicy.featureKey]: fromExoMalwareFilterPolicy.State;
    [fromEOPProtectionPolicy.featureKey]: fromEOPProtectionPolicy.State;
    [fromExoAntiPhishPolicy.featureKey]: fromExoAntiPhishPolicy.State;
    [fromExoMalwareFilterRule.exoFeatureKey]: fromExoMalwareFilterRule.State;
    [fromExoOrganizationConfig.featureKey]: fromExoOrganizationConfig.State;
    [fromHostedOutboundSpamFilterPolicy.featureKey]: fromHostedOutboundSpamFilterPolicy.State;
    [fromMobileDeviceMailboxPolicy.featureKey]: fromMobileDeviceMailboxPolicy.State;
    [fromExoRemoteDomain.featureKey]: fromExoRemoteDomain.State;
    [fromExoQuarantinePolicy.featureKey]: fromExoQuarantinePolicy.State;
    [fromExoRecipient.exoFeatureKey]: fromExoRecipient.State;
    [fromExoSharingPolicy.featureKey]: fromExoSharingPolicy.State;
    [fromExoTransportConfig.featureKey]: fromExoTransportConfig.State;
    [fromExoTransportRules.exoFeatureKey]: fromExoTransportRules.State;
    [fromExoUser.featureKey]: fromExoUser.State;
    [fromSecurityDefaultsPolicy.featureKey]: fromSecurityDefaultsPolicy.State;
    [fromGraphAppRoleAssignments.featureKey]: fromGraphAppRoleAssignments.State;
    [fromGraphDomains.featureKey]: fromGraphDomains.State;
    [fromSharePointSiteDetails.featureKey]: fromSharePointSiteDetails.State;
    [fromGraphConditionalAccessPolicy.featureKey]: fromGraphConditionalAccessPolicy.State;
    [fromGraphGroup.featureKey]: fromGraphGroup.State;
    [fromGraphGroupMembers.featureKey]: fromGraphGroupMembers.State;
    [FromGraphCompliancePolicyWindows10.featureKey]: FromGraphCompliancePolicyWindows10.State;
    [fromGraphInboxRule.featureKey]: fromGraphInboxRule.State;
    [fromGraphMailboxSettings.featureKey]: fromGraphMailboxSettings.State;
    [fromGraphDirectoryRoles.featureKey]: fromGraphDirectoryRoles.State;
    [fromGraphServicePrincipal.featureKey]: fromGraphServicePrincipal.State;
    [fromGraphSubscribedSku.featureKey]: fromGraphSubscribedSku.State;
    [fromGraphDirectoryRoleMembers.featureKey]: fromGraphDirectoryRoleMembers.State;
    [fromOAuth2PermissionGrants.featureKey]: fromOAuth2PermissionGrants.State;
    [fromGraphAppRole.featureKey]: fromGraphAppRole.State;
    [fromGraphUser.featureKey]: fromGraphUser.State;
    [fromGraphAuthenticationMethods.featureKey]: fromGraphAuthenticationMethods.State;
    [fromGraphPerUserMfa.featureKey]: fromGraphPerUserMfa.State;
    [fromGraphOrganization.featureKey]: fromGraphOrganization.State;
    [fromOctigaChanges.featureKey]: fromOctigaChanges.State;
    [fromOctigaDismiss.featureKey]: fromOctigaDismiss.State;
    [fromOctigaTenant.featureKey]: fromOctigaTenant.State;
    [fromOctigaUser.featureKey]: fromOctigaUser.State;
    [fromSpoTenant.featureKey]: fromSpoTenant.State;
    [fromSwayDeviation.featureKey]: fromSwayDeviation.State;
    [fromSwayGroup.featureKey]: fromSwayGroup.State;
    [fromSwayTenant.featureKey]: fromSwayTenant.State;
    [fromSwayBaseline.featureKey]: fromSwayBaseline.State;
    [fromReportBaseline.featureKey]: fromReportBaseline.State;
    [fromReportSchedule.featureKey]: fromReportSchedule.State;
    [fromReportDeviation.featureKey]: fromReportDeviation.State;
    [fromReportCurrentPostureCount.featureKey]: fromReportCurrentPostureCount.State;
    [fromReportTenantTicketCount.featureKey]: fromReportTenantTicketCount.State;
    [fromTeamsCsOAuthConfiguration.featureKey]: fromTeamsCsOAuthConfiguration.State;
    [fromOctigaTenantStatus.featureKey]: fromOctigaTenantStatus.State;
}

const _reducer = combineReducers({
    [fromAlert.featureKey]: fromAlert.reducer,
    [fromEvent.featureKey]: fromEvent.reducer,
    [fromEventSummary.featureKey]: fromEventSummary.reducer,
    [fromExoAdminAuditLogConfig.featureKey]: fromExoAdminAuditLogConfig.reducer,
    [fromExoDkimSigningConfig.featureKey]: fromExoDkimSigningConfig.reducer,
    [fromExoCasMailbox.featureKey]: fromExoCasMailbox.reducer,
    [fromExoMailbox.featureKey]: fromExoMailbox.reducer,
    [fromExoMailboxDelegate.exoFeatureKey]: fromExoMailboxDelegate.reducer,
    [fromExoMalwareFilterPolicy.featureKey]: fromExoMalwareFilterPolicy.reducer,
    [fromEOPProtectionPolicy.featureKey]: fromEOPProtectionPolicy.reducer,
    [fromExoAntiPhishPolicy.featureKey]: fromExoAntiPhishPolicy.reducer,
    [fromExoMalwareFilterRule.exoFeatureKey]: fromExoMalwareFilterRule.reducer,
    [fromExoOrganizationConfig.featureKey]: fromExoOrganizationConfig.reducer,
    [fromHostedOutboundSpamFilterPolicy.featureKey]: fromHostedOutboundSpamFilterPolicy.reducer,
    [fromMobileDeviceMailboxPolicy.featureKey]: fromMobileDeviceMailboxPolicy.reducer,
    [fromExoRemoteDomain.featureKey]: fromExoRemoteDomain.reducer,
    [fromExoQuarantinePolicy.featureKey]: fromExoQuarantinePolicy.reducer,
    [fromExoRecipient.exoFeatureKey]: fromExoRecipient.reducer,
    [fromExoSharingPolicy.featureKey]: fromExoSharingPolicy.reducer,
    [fromExoTransportConfig.featureKey]: fromExoTransportConfig.reducer,
    [fromExoTransportRules.exoFeatureKey]: fromExoTransportRules.reducer,
    [fromExoUser.featureKey]: fromExoUser.reducer,
    [fromSecurityDefaultsPolicy.featureKey]: fromSecurityDefaultsPolicy.reducer,
    [fromGraphAppRoleAssignments.featureKey]: fromGraphAppRoleAssignments.reducer,
    [fromGraphDomains.featureKey]: fromGraphDomains.reducer,
    [fromSharePointSiteDetails.featureKey]: fromSharePointSiteDetails.reducer,
    [fromGraphConditionalAccessPolicy.featureKey]: fromGraphConditionalAccessPolicy.reducer,
    [fromGraphGroup.featureKey]: fromGraphGroup.reducer,
    [fromGraphGroupMembers.featureKey]: fromGraphGroupMembers.reducer,
    [FromGraphCompliancePolicyWindows10.featureKey]: FromGraphCompliancePolicyWindows10.reducer,
    [fromGraphInboxRule.featureKey]: fromGraphInboxRule.reducer,
    [fromGraphMailboxSettings.featureKey]: fromGraphMailboxSettings.reducer,
    [fromGraphDirectoryRoles.featureKey]: fromGraphDirectoryRoles.reducer,
    [fromGraphServicePrincipal.featureKey]: fromGraphServicePrincipal.reducer,
    [fromGraphSubscribedSku.featureKey]: fromGraphSubscribedSku.reducer,
    [fromGraphDirectoryRoleMembers.featureKey]: fromGraphDirectoryRoleMembers.reducer,
    [fromOAuth2PermissionGrants.featureKey]: fromOAuth2PermissionGrants.reducer,
    [fromGraphAppRole.featureKey]: fromGraphAppRole.reducer,
    [fromGraphUser.featureKey]: fromGraphUser.reducer,
    [fromGraphAuthenticationMethods.featureKey]: fromGraphAuthenticationMethods.reducer,
    [fromGraphPerUserMfa.featureKey]: fromGraphPerUserMfa.reducer,
    [fromGraphOrganization.featureKey]: fromGraphOrganization.reducer,
    [fromOctigaChanges.featureKey]: fromOctigaChanges.reducer,
    [fromOctigaDismiss.featureKey]: fromOctigaDismiss.reducer,
    [fromOctigaTenant.featureKey]: fromOctigaTenant.reducer,
    [fromOctigaUser.featureKey]: fromOctigaUser.reducer,
    [fromSpoTenant.featureKey]: fromSpoTenant.reducer,
    [fromSwayDeviation.featureKey]: fromSwayDeviation.reducer,
    [fromSwayGroup.featureKey]: fromSwayGroup.reducer,
    [fromSwayTenant.featureKey]: fromSwayTenant.reducer,
    [fromSwayBaseline.featureKey]: fromSwayBaseline.reducer,
    [fromReportBaseline.featureKey]: fromReportBaseline.reducer,
    [fromReportSchedule.featureKey]: fromReportSchedule.reducer,
    [fromReportDeviation.featureKey]: fromReportDeviation.reducer,
    [fromReportCurrentPostureCount.featureKey]: fromReportCurrentPostureCount.reducer,
    [fromReportTenantTicketCount.featureKey]: fromReportTenantTicketCount.reducer,
    [fromTeamsCsOAuthConfiguration.featureKey]: fromTeamsCsOAuthConfiguration.reducer,
    [fromUpdateDateLists.featureKey]: fromUpdateDateLists.reducer,
    [fromOctigaTenantStatus.featureKey]: fromOctigaTenantStatus.reducer
});

export const reducer = (tenant: string) => (state, action) => {
    // filter out component actions that are not this component
    if (action._tenant && action._tenant !== tenant) {
        return state;
    }
    // local action, invoke the component reducer
    return _reducer(state, action);
};

const cache = new Map<string, ReturnType<typeof selectedFeature>>();

export function client(tenant: string) {
    if (!cache.has(tenant)) {
        const selectFeature = createFeatureSelector<State>(tenant);
        cache.set(tenant, selectedFeature(selectFeature));
    }
    return cache.get(tenant);
}

function selectedFeature(selectFeature: MemoizedSelector<object, State, DefaultProjectorFn<State>>) {

    const selectAlerts = createSelector(selectFeature, (state) => !!state ? state[fromAlert.featureKey] : fromAlert.initialState);
    const selectEvents = createSelector(selectFeature, (state) => !!state ? state[fromEvent.featureKey] : fromEvent.initialState);
    const selectExoAdminAuditLogConfig = createSelector(selectFeature, (state) => !!state ? state[fromExoAdminAuditLogConfig.featureKey] : fromExoAdminAuditLogConfig.initialState);
    const selectExoDkimSigningConfig = createSelector(selectFeature, (state) => !!state ? state[fromExoDkimSigningConfig.featureKey] : fromExoDkimSigningConfig.initialState);
    const selectExoCasMailbox = createSelector(selectFeature, (state) => !!state ? state[fromExoCasMailbox.featureKey] : fromExoCasMailbox.initialState);
    const selectExoMailbox = createSelector(selectFeature, (state) => !!state ? state[fromExoMailbox.featureKey] : fromExoMailbox.initialState);
    const selectExoMailboxDelegates = createSelector(selectFeature, (state) => !!state ? state[fromExoMailboxDelegate.exoFeatureKey] : fromExoMailboxDelegate.initialState);
    const selectExoMalwareFilterPolicy = createSelector(selectFeature, (state) => !!state ? state[fromExoMalwareFilterPolicy.featureKey] : fromExoMalwareFilterPolicy.initialState);
    const selectExoEOPProtectionPolicy = createSelector(selectFeature, (state) => !!state ? state[fromEOPProtectionPolicy.featureKey] : fromEOPProtectionPolicy.initialState);
    const selectExoAntiPhishPolicy = createSelector(selectFeature, (state) => !!state ? state[fromExoAntiPhishPolicy.featureKey] : fromExoAntiPhishPolicy.initialState);
    const selectExoMalwareFilterRule = createSelector(selectFeature, (state) => !!state ? state[fromExoMalwareFilterRule.exoFeatureKey] : fromExoMalwareFilterRule.initialState);
    const selectExoOrganizationConfig = createSelector(selectFeature, (state) => !!state ? state[fromExoOrganizationConfig.featureKey] : fromExoOrganizationConfig.initialState);
    const selectExoHostedOutboundSpamFilterPolicy = createSelector(selectFeature, (state) => !!state ? state[fromHostedOutboundSpamFilterPolicy.featureKey] : fromHostedOutboundSpamFilterPolicy.initialState);
    const selectExoMobileDeviceMailboxPolicy = createSelector(selectFeature, (state) => !!state ? state[fromMobileDeviceMailboxPolicy.featureKey] : fromMobileDeviceMailboxPolicy.initialState);
    const selectExoRemoteDomain = createSelector(selectFeature, (state) => !!state ? state[fromExoRemoteDomain.featureKey] : fromExoRemoteDomain.initialState);
    const selectQuarantinePolicy = createSelector(selectFeature, (state) => !!state ? state[fromExoQuarantinePolicy.featureKey] : fromExoQuarantinePolicy.initialState);
    const selectExoRecipient = createSelector(selectFeature, (state) => !!state ? state[fromExoRecipient.exoFeatureKey] : fromExoRecipient.initialState);
    const selectExoSharingPolicy = createSelector(selectFeature, (state) => !!state ? state[fromExoSharingPolicy.featureKey] : fromExoSharingPolicy.initialState);
    const selectExoTransportConfig = createSelector(selectFeature, (state) => !!state ? state[fromExoTransportConfig.featureKey] : fromExoTransportConfig.initialState);
    const selectExoTransportRule = createSelector(selectFeature, (state) => !!state ? state[fromExoTransportRules.exoFeatureKey] : fromExoTransportRules.initialState);
    const selectExoUser = createSelector(selectFeature, (state) => !!state ? state[fromExoUser.featureKey] : fromExoUser.initialState);
    const selectEventSummary = createSelector(selectFeature, (state) => !!state ? state[fromEventSummary.featureKey] : fromEventSummary.initialState);
    const selectGraphSecurityDefaultsPolicy = createSelector(selectFeature, (state) => !!state ? state[fromSecurityDefaultsPolicy.featureKey] : fromSecurityDefaultsPolicy.initialState);
    const selectGraphAppRoleAssignments = createSelector(selectFeature, (state) => !!state ? state[fromGraphAppRoleAssignments.featureKey] : fromGraphAppRoleAssignments.initialState);
    const selectGraphDomains = createSelector(selectFeature, (state) => !!state ? state[fromGraphDomains.featureKey] : fromGraphDomains.initialState);
    const selectSharePointSiteDetails = createSelector(selectFeature, (state) => !!state ? state[fromSharePointSiteDetails.featureKey] : fromSharePointSiteDetails.initialState);
    const selectGraphConditionalAccessPolicy = createSelector(selectFeature, (state) => !!state ? state[fromGraphConditionalAccessPolicy.featureKey] : fromGraphConditionalAccessPolicy.initialState);
    const selectGraphGroup = createSelector(selectFeature, (state) => !!state ? state[fromGraphGroup.featureKey] : fromGraphGroup.initialState);
    const selectGraphGroupMembers = createSelector(selectFeature, (state) => !!state ? state[fromGraphGroupMembers.featureKey] : fromGraphGroupMembers.initialState);
    const selectGraphCompliancePolicy = createSelector(selectFeature, (state) => !!state ? state[FromGraphCompliancePolicyWindows10.featureKey] : FromGraphCompliancePolicyWindows10.initialState);
    const selectGraphGroupMembersStatus = createSelector(selectGraphGroupMembers, selectStatus);
    const selectGraphGroupWithMembers = createSelector(
        selectGraphGroup,
        selectGraphGroupMembers,
        selectGraphGroupMembersStatus,
        (groups, members, status) => {
            if (!status.loaded) return [];
            const merged = groups.ids.map(id => ({
                ...groups.entities[id],
                members: members.entities[id]?.members as User[]
            }));
            return merged as Group[];
        });

    const selectGraphInboxRule = createSelector(selectFeature, (state) => !!state ? state[fromGraphInboxRule.featureKey] : fromGraphInboxRule.fetchState);
    const selectGraphMailboxSettings = createSelector(selectFeature, (state) => !!state ? state[fromGraphMailboxSettings.featureKey] : fromGraphMailboxSettings.initialState);
    const selectGraphDirectoryRoles = createSelector(selectFeature, (state) => !!state ? state[fromGraphDirectoryRoles.featureKey] : fromGraphDirectoryRoles.initialState);
    const selectGraphDirectoryRoleMembers = createSelector(selectFeature, (state) => !!state ? state[fromGraphDirectoryRoleMembers.featureKey] : fromGraphDirectoryRoleMembers.initialState);
    const selectGraphServicePrincipal = createSelector(selectFeature, (state) => !!state ? state[fromGraphServicePrincipal.featureKey] : fromGraphServicePrincipal.initialState);
    const selectOAuth2PermissionGrants = createSelector(selectFeature, (state) => !!state ? state[fromOAuth2PermissionGrants.featureKey] : fromOAuth2PermissionGrants.initialState);
    const selectAppRole = createSelector(selectFeature, (state) => !!state ? state[fromGraphAppRole.featureKey] : fromGraphAppRole.initialState);
    const selectGraphUser = createSelector(selectFeature, (state) => !!state ? state[fromGraphUser.featureKey] : fromGraphUser.initialState);
    const selectGraphAuthenticationMethod = createSelector(selectFeature, (state) => !!state ? state[fromGraphAuthenticationMethods.featureKey] : fromGraphAuthenticationMethods.initialState);
    const selectGraphPerUserMfaState = createSelector(selectFeature, (state) => !!state ? state[fromGraphPerUserMfa.featureKey] : fromGraphPerUserMfa.initialState);
    const loadGraphUsers = createSelector(selectGraphUser, fromGraphUser.selectAll);
    const getGraphUsersFiltered = createSelector(loadGraphUsers, users => users.filter(fromGraphUser.SystemUserFilter));
    const selectGraphSubscribedSku = createSelector(selectFeature, (state) => !!state ? state[fromGraphSubscribedSku.featureKey] : fromGraphSubscribedSku.initialState);
    const selectGraphOrganization = createSelector(selectFeature, (state) => !!state ? state[fromGraphOrganization.featureKey] : fromGraphOrganization.initialState);
    const selectSpoTenant = createSelector(selectFeature, (state) => !!state ? state[fromSpoTenant.featureKey] : fromSpoTenant.initialState);
    const selectUpdateDateList = createSelector(selectFeature, (state) => !!state ? state[fromUpdateDateLists.featureKey] : fromUpdateDateLists.initialState);
    const selectSwayDeviation = createSelector(selectFeature, (state) => !!state ? state[fromSwayDeviation.featureKey] : fromSwayDeviation.initialState);
    const selectSwayGroup = createSelector(selectFeature, (state) => !!state ? state[fromSwayGroup.featureKey] : fromSwayGroup.initialState);
    const selectSwayTenant = createSelector(selectFeature, (state) => !!state ? state[fromSwayTenant.featureKey] : fromSwayTenant.initialState);
    const selectReportBaseline = createSelector(selectFeature, (state) => !!state ? state[fromReportBaseline.featureKey] : fromReportBaseline.initialState);
    const selectReportSchedule = createSelector(selectFeature, (state) => !!state ? state[fromReportSchedule.featureKey] : fromReportSchedule.initialState);
    const selectReportDeviation = createSelector(selectFeature, (state) => !!state ? state[fromReportDeviation.featureKey] : fromReportDeviation.initialState);
    const selectReportCurrentPostureCount = createSelector(selectFeature, (state) => !!state ? state[fromReportCurrentPostureCount.featureKey] : fromReportCurrentPostureCount.initialState);
    const selectReportTenantPSATicketCount = createSelector(selectFeature, (state) => !!state ? state[fromReportTenantTicketCount.featureKey] : fromReportTenantTicketCount.initialState);
    const selectTenant = createSelector(selectFeature, (state) => !!state ? state[fromOctigaTenant.featureKey] : fromOctigaTenant.initialState);
    const selectChanges = createSelector(selectFeature, (state) => !!state ? state[fromOctigaChanges.featureKey] : fromOctigaChanges.initialState);
    const getChanges = createSelector(selectChanges, fromOctigaChanges.selectAll);

    const getChangesLoading = createSelector(selectChanges, fromOctigaChanges.selectLoading);

    const selectDismiss = createSelector(selectFeature, (state) => !!state ? state[fromOctigaDismiss.featureKey] : fromOctigaDismiss.initialState);
    const selectOctigaUser = createSelector(selectFeature, (state) => !!state ? state[fromOctigaUser.featureKey] : fromOctigaUser.initialState);
    const selectSwayBaseline = createSelector(selectFeature, (state) => !!state ? state[fromSwayBaseline.featureKey] : fromSwayBaseline.initialState);

    const selectTeamsCsOAuthConfiguration = createSelector(selectFeature, (state) => !!state ? state[fromTeamsCsOAuthConfiguration.featureKey] : fromTeamsCsOAuthConfiguration.initialState);
    const swayTenant = createSelector(selectSwayTenant, state => fromSwayTenant.selectTenant(state));
    const swayGroupAll = createSelector(selectSwayGroup, state => fromSwayGroup.selectAll(state));

    const selectTenantStatus = createSelector(selectFeature, (state) => !!state ? state[fromOctigaTenantStatus.featureKey] : fromOctigaTenantStatus.initialState);

    return {
        alert: {
            all: createSelector(selectAlerts, fromAlert.selectAll),
            status: createSelector(selectAlerts, selectStatus),
            fetchedDates: createSelector(selectAlerts, fromAlert.selectFetchedDates)
        },
        events: {
            all: createSelector(selectEvents, fromEvent.selectAll),
            cursor: createSelector(selectEvents, fromEvent.selectEventsCursor),
            byDate: (date: string) => createSelector(createSelector(selectEvents, fromEvent.selectAll), (events: MicrosoftEvent[]) => (events && date) ? events.filter(e => e.timestamp.startsWith(date)) : []),
            byUserDate: (user_id: string, date: string) => createSelector(createSelector(selectEvents, fromEvent.selectAll), (events: MicrosoftEvent[]) => (events && user_id && date) ? events.filter(e => e.timestamp.startsWith(date) && e.user_id === user_id) : []),
            byId: (id: string) => createSelector(createSelector(selectEvents, fromEvent.selectAll), (events: MicrosoftEvent[]) => (events && id) ? events.find(e => e.id === id) : null),
            byIds: (ids: string[]) => createSelector(createSelector(selectEvents, fromEvent.selectEntities), (entities) => ids.map(id => entities[id])),
            lastEvaluatedKeys: (date: string) => createSelector(
                createSelector(selectEvents, fromEvent.selectLastEvaluatedKeys),
                (keys) => keys[date]
            ),
            userLastEvaluatedKeys: (user_id: string, date: string) => createSelector(
                createSelector(selectEvents, fromEvent.selectUserLastEvaluatedKeys),
                (users) => !!users[user_id] ? users[user_id][date.split('T')[0]] : undefined
            ),
            dateLoaded: (date: string) => createSelector(
                createSelector(selectEvents, fromEvent.selectDateLoaded),
                (loadedDates) => loadedDates.has(date)
            ),
            status: createSelector(selectEvents, selectStatus)
        },
        graph: {
            domains: {
                all: createSelector(selectGraphDomains, fromGraphDomains.selectAll),
                verified: createSelector(selectGraphDomains, state => fromGraphDomains.selectAll(state).filter(res => res.isVerified)),
                status: createSelector(selectGraphDomains, selectStatus)
            },
            conditionalAccessPolicy: {
                all: createSelector(selectGraphConditionalAccessPolicy, fromGraphConditionalAccessPolicy.selectAll),
                status: createSelector(selectGraphConditionalAccessPolicy, selectStatus)
            },

            compliancePolicies: {
                windows10: {
                    all: createSelector(selectGraphCompliancePolicy, FromGraphCompliancePolicyWindows10.selectAll),
                    status: createSelector(selectGraphCompliancePolicy, selectStatus)
                }
            },

            securityDefaults: {
                item: createSelector(selectGraphSecurityDefaultsPolicy, fromSecurityDefaultsPolicy.selectPolicy),
                status: createSelector(selectGraphSecurityDefaultsPolicy, selectStatus)
            },
            sharepointSiteDetails: {
                all: createSelector(selectSharePointSiteDetails, fromSharePointSiteDetails.selectAll),
                status: createSelector(selectSharePointSiteDetails, selectStatus)
            },
            groups: {
                all: createSelector(selectGraphGroupWithMembers, (groups) => groups),
                group: (group_id: string) => createSelector(selectGraphGroupWithMembers, groups => groups.find(g => g.id === group_id)),
                groupWithInternalMembers: (group_id: string) => createSelector(selectGraphGroupWithMembers, groups => {
                    const group = groups.find(g => g.id === group_id);
                    return {
                        ...group,
                        members: group?.members.filter((member: User) => member.userType === 'Member')
                    };
                }),
                status: createSelector(selectGraphGroup, selectStatus),
                members: {
                    all: createSelector(selectGraphGroupMembers, fromGraphGroupMembers.selectAll),
                    internal: createSelector(selectGraphGroupMembers, fromGraphGroupMembers.selectInternal),
                    status: createSelector(selectGraphGroupMembers, selectStatus),
                }
            },
            inboxRule: {
                all: createSelector(selectGraphInboxRule, fromGraphInboxRule.selectAll),
                status: createSelector(selectGraphInboxRule, selectStatus),
                byUserId: (id: string) => createSelector(selectGraphInboxRule, fromGraphInboxRule.selectByUserId(id)),
                mappedToUsers: createSelector(getGraphUsersFiltered, selectGraphInboxRule, fromGraphInboxRule.mappedToUser)
            },
            servicePrincipal: {
                all: createSelector(selectGraphServicePrincipal, fromGraphServicePrincipal.selectAll),
                byOctiga: createSelector(createSelector(selectGraphServicePrincipal, fromGraphServicePrincipal.selectAll), (sps) => {
                    const sp = sps.find(item => item.appDisplayName === environment.principalDisplayName);
                    if (!!sp) { return sp; }
                    else { return null; }
                }),
                status: createSelector(selectGraphServicePrincipal, selectStatus),
                oauth2PermissionGrants: {
                    byPrincipalId: (id: string) => createSelector(selectOAuth2PermissionGrants, fromOAuth2PermissionGrants.selectByPrincipalId(id))
                },
                appRole: {
                    all: createSelector(selectAppRole, fromGraphAppRole.selectAll),
                    byId: (id: string) => createSelector(selectAppRole, fromGraphAppRole.selectById(id))
                },
                appRoleAssignments: {
                    all: createSelector(selectGraphAppRoleAssignments, fromGraphAppRoleAssignments.selectAll),
                    status: createSelector(selectGraphAppRoleAssignments, selectStatus)
                },
            },
            subscribedSkus: {
                all: createSelector(selectGraphSubscribedSku, fromGraphSubscribedSku.selectAll),
                byAppliesTo: (type: 'User' | 'Company') => ({
                    all: createSelector(selectGraphSubscribedSku, state => fromGraphSubscribedSku.selectAll(state).filter(res => res.appliesTo === type)),
                    enabled: createSelector(selectGraphSubscribedSku, state => fromGraphSubscribedSku.selectAll(state).filter(res => res.appliesTo === type && res.capabilityStatus === 'Enabled')),
                }),
                status: createSelector(selectGraphSubscribedSku, selectStatus)
            },
            users: {
                all: loadGraphUsers,
                status: createSelector(selectGraphUser, fromGraphUser.selectGraphUserStatus),
                filtered: createSelector(loadGraphUsers, users => users.filter(fromGraphUser.SystemUserFilter)),
                internal: createSelector(loadGraphUsers, users => users.filter(fromGraphUser.InternalUsersFilter)),
                withMailbox: createSelector(getGraphUsersFiltered, users => users.filter(fromGraphUser.MailboxUserFilter)),
                byUPN: (upn: string) => createSelector(getGraphUsersFiltered, (users) => users.find(user => user.userPrincipalName === upn || user.mail === upn)),
                byId: (id: string) => createSelector(loadGraphUsers, (users) => users.find(user => user.id === id))
            },
            authenticationMethods: {
                all: createSelector(selectGraphAuthenticationMethod, fromGraphAuthenticationMethods.selectAll),
                status: createSelector(selectGraphAuthenticationMethod, selectStatus),
            },
            perUserMfaState: {
                all: createSelector(selectGraphPerUserMfaState, fromGraphPerUserMfa.selectAll),
                status: createSelector(selectGraphPerUserMfaState, selectStatus),
            },
            directoryRole: {
                all: createSelector(selectGraphDirectoryRoles, state => fromGraphDirectoryRoles.selectAll(state)),
                users: createSelector(
                    selectGraphDirectoryRoles,
                    state => fromGraphDirectoryRoles.selectAll(state).filter(member => member['@odata.type'] === '#microsoft.graph.user')
                ),
                byDisplayName: (displayName: string) => createSelector(selectGraphDirectoryRoles, state => fromGraphDirectoryRoles.byDisplayName(state, displayName)),
                status: createSelector(selectGraphDirectoryRoles, selectStatus),
                members: {
                    all: createSelector(selectGraphDirectoryRoleMembers, state => fromGraphDirectoryRoleMembers.selectAll(state)),
                    byDirectoryRoleId: (id: string) => createSelector(selectGraphDirectoryRoleMembers, state => fromGraphDirectoryRoleMembers.byDirectoryRoleId(state, id)),
                    status: createSelector(selectGraphDirectoryRoleMembers, selectStatus),
                }
            },
            organization: {
                organization: createSelector(selectGraphOrganization, fromGraphOrganization.selectOrganization),
                status: createSelector(selectGraphOrganization, selectStatus),
            },

            mailboxSettings: {
                all: createSelector(selectGraphMailboxSettings, fromGraphMailboxSettings.selectAll),
                status: createSelector(selectGraphMailboxSettings, selectStatus),
                byUserId: (id: string) => createSelector(selectGraphMailboxSettings, state => fromGraphMailboxSettings.selectAll(state).filter(b => b.user_id === id)),

            }
        },
        sway: {
            baselines: {
                all: createSelector(selectSwayBaseline, fromSwayBaseline.selectAll),
                byId: (id: string) => createSelector(selectSwayBaseline, state => fromSwayBaseline.selectAll(state).find(b => b.id === id)),
                bySpecId: (spec_id: string) => createSelector(selectSwayBaseline, state => fromSwayBaseline.selectAll(state).find(b => b.spec_id === spec_id)),
                byType: (type: string) => createSelector(selectSwayBaseline, state => fromSwayBaseline.selectAll(state).filter(b => b.type === type)),
                byUserBaselines: () => createSelector(selectSwayBaseline, state => fromSwayBaseline.selectAll(state).filter(b => b.type === 'user')),
                byGroupId: (id: string) => createSelector(selectSwayBaseline, state => fromSwayBaseline.selectAll(state).filter(b => b.group_id === id)),
                byUserId: (id: string) => createSelector(selectSwayBaseline, state => fromSwayBaseline.selectAll(state).filter(b => b.user_id === id)),
                byTemplateId: (id: string) => createSelector(selectSwayBaseline, state => fromSwayBaseline.selectAll(state).filter(b => b.template_id === id)),
                byTemplateItemId: (id: string) => createSelector(selectSwayBaseline, state => fromSwayBaseline.selectAll(state).find(b => b.template_item_id === id)),
                status: createSelector(selectSwayBaseline, selectStatus),
                count: createSelector(selectSwayBaseline, state => fromSwayBaseline.selectAll(state).length)
                // entityStatus: (entity: any) => createSelector(selectSwayBaseline, state => fromSwayBaseline.selectEntityStatus(entity, state))
            },
            tenant: {
                item: swayTenant,
                status: createSelector(selectSwayTenant, selectStatus),
            },
            groups: {
                all: createSelector(swayTenant, swayGroupAll, (tenant, groups) => {
                    const sorted: SwayGroup[] = [];
                    if (!!tenant && groups.length > 0) {
                        for (const id of tenant.group_order) {
                            sorted.push(groups.find(g => g.id === id));
                        }
                    }
                    return sorted;
                }),
                byId: (id: string) => createSelector(swayGroupAll, groups => groups.find(g => g.id === id)),
                status: createSelector(selectSwayGroup, selectStatus),
                count: createSelector(selectSwayGroup, state => fromSwayGroup.selectAll(state).length)
            },
            deviations: {
                all: createSelector(selectSwayDeviation, fromSwayDeviation.selectAll),
                allActive: createSelector(selectSwayDeviation, state => fromSwayDeviation.selectAll(state).filter(d => d.resolve_time === null)),
                byId: (id: string) => createSelector(selectSwayDeviation, state => fromSwayDeviation.selectAll(state).find(d => d.id === id)),
                byBaselineId: (id: string) => createSelector(selectSwayDeviation, state => fromSwayDeviation.selectAll(state).find(d => d.baseline_id === id)),
                status: createSelector(selectSwayDeviation, selectStatus)
            },
            report: {
                deviations: {
                    all: createSelector(selectReportDeviation, fromReportDeviation.selectAll),
                    status: createSelector(selectReportDeviation, selectStatus),
                    byDateRange: (start: string, end: string) => createSelector(selectReportDeviation, state => fromReportDeviation.selectAll(state).
                        filter(d => start <= d.deviation_detect_time && d.deviation_detect_time <= end))
                },
                current_posture_count: {
                    data: createSelector(selectReportCurrentPostureCount, fromReportCurrentPostureCount.selectData),
                    status: createSelector(selectReportCurrentPostureCount, selectStatus)
                },
                ticket_count: {
                    data: createSelector(selectReportTenantPSATicketCount, fromReportTenantTicketCount.selectData),
                    status: createSelector(selectReportTenantPSATicketCount, selectStatus)
                }
            }
        },
        report: {
            baseline: {
                all: createSelector(selectReportBaseline, fromReportBaseline.selectAll),
                status: createSelector(selectReportBaseline, selectStatus)
            },
            schedules: {
                all: createSelector(selectReportSchedule, fromReportSchedule.selectAll),
                status: createSelector(selectReportSchedule, selectStatus)
            }
        },
        octiga: {
            changes: {
                all: createSelector(selectChanges, fromOctigaChanges.selectAll),
                loading: createSelector(selectChanges, fromOctigaChanges.selectLoading),
                byDateRange: (start: string, end: string) => createSelector(getChanges, getChangesLoading, (changes: ChangesLog[], loading: boolean) => {
                    if (!loading) {
                        return changes.filter(change => start <= change.timestamp.split('T')[0] && change.timestamp.split('T')[0] <= end);
                    }
                }),
                total: {
                    byType: (type: string) => createSelector(getChanges, (changes: ChangesLog[]) => changes.filter(change => change.type === type).length)
                },
                fetchedDates: createSelector(selectChanges, fromOctigaChanges.selectFetchedDates)
            },
            dismiss: {
                all: createSelector(selectDismiss, fromOctigaDismiss.selectAll),
                status: createSelector(selectDismiss, selectStatus)
            },
            user: {
                all: createSelector(selectOctigaUser, fromOctigaUser.selectAll),
                status: createSelector(selectOctigaUser, selectStatus)
            },
            tenant: {
                data: createSelector(selectTenant, state => fromOctigaTenant.selectTenantData(state)),
                status: createSelector(selectTenant, selectStatus)
            },
            status: {
                roles: {
                    all: createSelector(createSelector(selectTenantStatus, state => state.roles), state => state.data),
                    status: createSelector(createSelector(selectTenantStatus, state => state.roles), selectStatus),
                    hasScopes: (scopes: string[] = []) => createSelector(createSelector(selectTenantStatus, state => state.roles), state => {
                        return scopes.every(scope => state.data.some(role => fromOctigaTenantStatus.CompareRoles(role, scope)));
                    })
                },
                subscriptions: {
                    all: createSelector(createSelector(selectTenantStatus, state => state.subscriptions), state => state.data),
                    status: createSelector(createSelector(selectTenantStatus, state => state.subscriptions), selectStatus)
                }
            }
        },
        powershell: {
            exoCasMailbox: {
                all: createSelector(selectExoCasMailbox, fromExoCasMailbox.selectAll),
                status: createSelector(selectExoCasMailbox, selectStatus),
                entityStatus: (entity: any, field: string) => createSelector(selectExoCasMailbox, state => fromExoCasMailbox.selectEntityStatus(entity, state, field))
            },
            exoMailbox: {
                all: createSelector(selectExoMailbox, fromExoMailbox.selectAll),
                status: createSelector(selectExoMailbox, selectStatus)
            },
            exoMailboxDelegates: {
                all: createSelector(selectExoMailboxDelegates, fromExoMailboxDelegate.selectAll),
                identity_status: (Identity) => createSelector(selectExoMailboxDelegates, fromExoMailboxDelegate.selectIdentityStatus(Identity)),
            },
            exoMalwareFilterPolicy: {
                all: createSelector(selectExoMalwareFilterPolicy, fromExoMalwareFilterPolicy.selectAll),
                status: createSelector(selectExoMalwareFilterPolicy, selectStatus)
            },
            exoEOPProtectionPolicyRule: {
                all: createSelector(selectExoEOPProtectionPolicy, fromEOPProtectionPolicy.selectAll),
                status: createSelector(selectExoEOPProtectionPolicy, selectStatus)
            },
            exoAntiPhishPolicy: {
                all: createSelector(selectExoAntiPhishPolicy, fromExoAntiPhishPolicy.selectAll),
                status: createSelector(selectExoAntiPhishPolicy, selectStatus)
            },
            fromExoMalwareFilterRule: {
                all: createSelector(selectExoMalwareFilterRule, fromExoMalwareFilterRule.selectMalwareFilterRule),
                status: createSelector(selectExoMalwareFilterRule, fromExoMalwareFilterRule.selectStatus)
            },
            exoSharingPolicy: {
                all: createSelector(selectExoSharingPolicy, fromExoSharingPolicy.selectAll),
                status: createSelector(selectExoSharingPolicy, selectStatus)
            },
            exoUser: {
                all: createSelector(selectExoUser, fromExoUser.selectAll),
                status: createSelector(selectExoUser, selectStatus)
            },
            exoTransportConfig: {
                item: createSelector(selectExoTransportConfig, fromExoTransportConfig.selectItem),
                status: createSelector(selectExoTransportConfig, selectStatus)
            },
            exoTransportRules: {
                all: createSelector(selectExoTransportRule, fromExoTransportRules.selectAll),
                status: createSelector(selectExoTransportRule, selectStatus)
            },
            exoRemoteDomain: {
                item: createSelector(selectExoRemoteDomain, fromExoRemoteDomain.selectItem),
                status: createSelector(selectExoRemoteDomain, selectStatus)
            },
            exoQuarantinePolicy: {
                all: createSelector(selectQuarantinePolicy, fromExoQuarantinePolicy.selectAll),
                status: createSelector(selectQuarantinePolicy, selectStatus)
            },
            exoRecipient: {
                all: createSelector(selectExoRecipient, fromExoRecipient.selectRecipients)
            },
            exoOrganizationConfig: {
                item: createSelector(selectExoOrganizationConfig, fromExoOrganizationConfig.selectItem),
                status: createSelector(selectExoOrganizationConfig, selectStatus)
            },
            hostedOutboundSpamFilterPolicy: {
                all: createSelector(selectExoHostedOutboundSpamFilterPolicy, fromHostedOutboundSpamFilterPolicy.selectAll),
                status: createSelector(selectExoHostedOutboundSpamFilterPolicy, selectStatus)
            },
            mobileDeviceMailboxPolicy: {
                all: createSelector(selectExoMobileDeviceMailboxPolicy, fromMobileDeviceMailboxPolicy.selectAll),
                status: createSelector(selectExoMobileDeviceMailboxPolicy, selectStatus)
            },
            exoAdminAuditLogConfig: {
                item: createSelector(selectExoAdminAuditLogConfig, fromExoAdminAuditLogConfig.selectItem),
                status: createSelector(selectExoAdminAuditLogConfig, selectStatus)
            },
            exoDkimSigningConfig: {
                item: createSelector(selectExoDkimSigningConfig, fromExoDkimSigningConfig.selectAll),
                status: createSelector(selectExoDkimSigningConfig, selectStatus)
            },
            spoTenant: {
                item: createSelector(selectSpoTenant, fromSpoTenant.selectItem),
                status: createSelector(selectSpoTenant, selectStatus)
            },
            teamsCsOAuthConfiguration: {
                item: createSelector(selectTeamsCsOAuthConfiguration, fromTeamsCsOAuthConfiguration.selectItem),
                status: createSelector(selectTeamsCsOAuthConfiguration, selectStatus)
            }
        },
        scan: {
            status: createSelector(selectEventSummary, state => {
                let pending, complete = 0;
                for (const key in state.entities) {
                    const summary = state.entities[key];
                    if (summary.status === 'PENDING') pending++;
                    else if (summary.status === 'COMPLETE') complete++;
                }
                return { pending, complete };
            }),
        },
        summary: {
            all: createSelector(selectEventSummary, fromEventSummary.selectAll),
            total: createSelector(createSelector(selectEventSummary, fromEventSummary.selectAll), (summaries) => summaries.length),
            status: createSelector(selectEventSummary, selectStatus),
            num_of_days: createSelector(selectEventSummary, fromEventSummary.SelectNumDays),
            last: createSelector(createSelector(selectEventSummary, fromEventSummary.selectAll), (summaries) => summaries.length > 0 ? summaries[summaries.length - 1] : null),
            updateDateList: {
                all: createSelector(selectUpdateDateList, fromUpdateDateLists.selectAll),
                updatedCount: createSelector(createSelector(selectUpdateDateList, fromUpdateDateLists.selectAll), (datelists: UpdateDateList[]) => datelists.filter(d => !!d.updated).length),
                fetched: createSelector(selectUpdateDateList, fromUpdateDateLists.getFetched),
                loading: createSelector(createSelector(selectUpdateDateList, fromUpdateDateLists.selectTotal), (total: number) => total > 0 ? true : false)
            }
        },
        severity: {
            country: {
                all: createSelector(selectEventSummary, (state) => fromEventSummary.SelectTotalSeveritiesForType(state, 'country')),
                byCountryGroupedByDate: (country: string) => createSelector(selectEventSummary, (state) => fromEventSummary.SelectSeveritiesByTypeDateFiltered(state, 'country', country))
            },
            date: {
                all: createSelector(selectEventSummary, (state) => fromEventSummary.SelectTotalSeveritiesByDate(state)),
                byDateRange: (start: string, end: string) => createSelector(selectEventSummary, (state) => {
                    const ids = (state.ids as string[]).filter(id => (id >= start && id <= end));
                    const filtered = { ...state, ids };
                    const result = fromEventSummary.SelectTotalSeveritiesByDate(filtered);
                    return result;
                })
            },
            ip: {
                all: createSelector(selectEventSummary, (state) => fromEventSummary.SelectTotalSeveritiesForType(state, 'ip'))
            },
            user: {
                all: createSelector(selectEventSummary, (state) => fromEventSummary.SelectTotalSeveritiesForType(state, 'user')),
                byUserGroupedByDate: (user: string) => createSelector(selectEventSummary, (state) => fromEventSummary.SelectSeveritiesByTypeDateFiltered(state, 'user', user)),
            }
        },
        reputation: {
            all: createSelector(selectEventSummary, (state) => fromEventSummary.SelectAllReputation(state))
        }
    };
}

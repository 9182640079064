import { createAction, props } from '@ngrx/store';
import { DattoConnection } from './model';

export const loadMspDattoConnection = createAction(
    '[Msp/API] Load Msp Datto Connection',
);

export const loadMspDattoConnectionSuccess = createAction(
    '[Msp/API] Load Msp Datto Connection Success',
    props<{ data: DattoConnection }>()
);

export const loadMspDattoConnectionFailure = createAction(
    '[Msp/API] Load Msp Datto Connection Failure',
    props<{ error: string }>()
);

export const loadMspDattoConfig = createAction(
    '[Msp/Datto] Load Msp Datto Config',
);

export const loadMspDattoConfigSuccess = createAction(
    '[Msp/Datto] Load Msp Datto Config Success',
    props<{ data: any }>()
);

export const loadMspDattoConfigFailure = createAction(
    '[Msp/Datto] Load Msp Datto Config Failure',
    props<{ error: any }>()
);

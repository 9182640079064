import { createAction, props } from '@ngrx/store';
import { Tenant } from 'src/app/interfaces/tenant.interface';

export const loadTenant = createAction(
    '[MSP/Tenant] Load Tenant',
    props<{ _tenant: string }>()
);

export const loadTenantsSuccess = createAction(
    '[MSP/Tenant] Load Tenant Success',
    props<{ _tenant: string, data: Tenant }>()
);

export const loadTenantFailure = createAction(
    '[MSP/Tenant] Load Tenant Failure',
    props<{ _tenant: string, error: any }>()
);

export const deleteTenantRequest = createAction(
    '[Root/Tenant] Delete Tenant Request',
    props<{ _tenant: string }>()
);

export const deleteTenantRequestSuccess = createAction(
    '[Root/Tenant] Delete Tenant Request Success',
    props<{ _tenant: string, data: any }>()
);

export const deleteTenantRequestFailure = createAction(
    '[Root/Tenant] Delete Tenant Request Failure',
    props<{ _tenant: string, error: any }>()
);

import { Domain } from '@microsoft/microsoft-graph-types-beta';
import { Update } from '@ngrx/entity';
import { TypedAction } from '@ngrx/store/src/models';
import { updateDomain } from 'src/app/stores/client/graph/domains/actions';

export function Remediate(_tenant: string, domains: Update<Domain>[], schema: any) {
    const actions: Array<TypedAction<any>> = [];

    domains.map(domain => {
        const action = updateDomain( // not finished
            {
                _tenant,
                domain: {
                    id: domain.id as string,
                    passwordNotificationWindowInDays: schema.items.properties.passwordNotificationWindowInDays.const,
                    passwordValidityPeriodInDays: schema.items.properties.passwordValidityPeriodInDays.const,
                }
            }
        );

        actions.push(action);
    });


    return actions;
}

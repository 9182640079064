<mat-card class="mat-elevation-z8">

    <mat-card-header>
        <!-- <div mat-card-avatar class="header-image-container mat-elevation-z4" [style.background-color]="plan.color">
            <div class="header-image"></div>
        </div> -->
        <mat-card-title>{{status}}</mat-card-title>
        <mat-card-subtitle>{{description}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

        <mat-spinner></mat-spinner>

    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="primary">Continue</button>
    </mat-card-actions>

</mat-card>

import { ConditionalAccessApplications, ConditionalAccessPolicy, ConditionalAccessSessionControls } from '@microsoft/microsoft-graph-types-beta';
import { RemediateCapItem } from '../shared/cap-remediate';
import { getCAPBaselineDisplayName } from '../shared/cap-schema';

const applications: ConditionalAccessApplications = { // check this
    'includeApplications': [
        'Office365'
    ]

};


const sessionControls : ConditionalAccessSessionControls = {
    'disableResilienceDefaults': null,
    'cloudAppSecurity': null,
    'signInFrequency': null,
    'persistentBrowser': null,
    'continuousAccessEvaluation': null,
    'applicationEnforcedRestrictions': {
        'isEnabled': true
    }
};

export function Remediate(_tenant: string, data: ConditionalAccessPolicy[], schema: any, metadata, key) {
    const id = key;
    let policyItem;
 
    if(id){
        policyItem = data.find(res => res.id === id );
    }else{
        policyItem = data.find(res => res.displayName === getCAPBaselineDisplayName(schema) );
    }
  
    return RemediateCapItem(_tenant, schema, policyItem, { sessionControls, applications});
}

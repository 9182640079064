import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardComponent } from '../card.component';

@Component({
    selector: 'app-changes-history',
    templateUrl: './changes-history.component.html',
    styleUrls: ['./changes-history.component.scss']
})
export class ChangesHistoryComponent extends CardComponent implements OnInit {

    public tenant_id = this.route.snapshot.paramMap.get('tenant');

    constructor(
    public router: Router,
    private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
    }

    navigate() {
        this.router.navigate([`client/${this.tenant_id}/dashboard/changes-history`]);
    }

}

<form *ngIf="form && defaultPolicy; else notFound" [formGroup]="form" class="mat-typography">
    <table>
        <thead>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </thead>

        <tbody>
            <tr>
                <td>
                    Identity
                </td>

                <td>
                    {{defaultPolicy.Identity}}
                </td>

                <td>
                    {{form.get('Identity').value}}

                    <mat-hint class="d-block">can't change this value</mat-hint>
                </td>

                <td>
                    <sway-baseline-status></sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Bcc Suspicious Outbound Mail
                </td>

                <td>
                    {{defaultPolicy.BccSuspiciousOutboundMail ? 'Enabled' : 'Disabled'}}
                    <mat-icon matTooltip="specifies whether to add recipients to the Bcc field of outgoing spam messages.">info</mat-icon>
                </td>

                <td>

                    <div class="field">
                        <mat-form-field
                            [class.mat-form-field-invalid]="form.get('BccSuspiciousOutboundAdditionalRecipients').value?.length === 0 && form.get('BccSuspiciousOutboundMail').value == true"
                            appearance="fill">
                            <mat-label>Bcc Suspicious Outbound Mail</mat-label>
                            <mat-select formControlName="BccSuspiciousOutboundMail">
                                <mat-option [value]="true">Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error
                            *ngIf="form.get('BccSuspiciousOutboundAdditionalRecipients').value?.length === 0 && form.get('BccSuspiciousOutboundMail').value == true">
                            To enable this Desired Value, you must add valid Desired Value(s) to the field Bcc
                            Suspicious Outbound Additional Recipients.
                        </mat-error>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.BccSuspiciousOutboundMail !== formValue.BccSuspiciousOutboundMail">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr [class.require-item]="form.get('BccSuspiciousOutboundAdditionalRecipients').value?.length === 0 && form.get('BccSuspiciousOutboundMail').value == true">
                <td>
                    Bcc Suspicious Outbound Additional Recipients

                    <mat-icon matTooltip="specifies an email address to add to the Bcc field of outgoing spam messages. You can specify multiple email addresses">info</mat-icon>
                </td>

                <td>
                    <ng-container
                        *ngIf="defaultPolicy?.BccSuspiciousOutboundAdditionalRecipients?.length; else noOutboundRecipients">
                        <button (click)="addNotFountOutboundRecipients()" color="primary" mat-button
                            *ngIf="isAllBccSuspiciousOutboundAdditionalRecipientsFound ">
                            Add All
                        </button>

                        <p class="d-flex align-items-center"
                            *ngFor="let recipient of BccSuspiciousOutboundAdditionalRecipients">
                            {{recipient.email}}

                            <button mat-button (click)="addBccSuspiciousOutboundRecipient(recipient.email)">
                                <mat-icon *ngIf="!recipient.isExist">add_circle_outline</mat-icon>

                            </button>
                        </p>
                    </ng-container>

                    <ng-template #noOutboundRecipients>
                        <p>
                            -
                        </p>
                    </ng-template>
                </td>

                <td>
                    <sway-filtered-list 
                        [items]="form.get('BccSuspiciousOutboundAdditionalRecipients').value"
                        (update)="updateBccSuspiciousOutboundAdditionalRecipients($event)" *ngIf="emailsLookups"
                        [options]="emailsLookups" [placeholder]="'Bcc Suspicious Outbound Additional Recipients'">
                    </sway-filtered-list>
                </td>

                <td>
                    <sway-baseline-status [hasError]="isOutboundAdditionalRecipientsHasError"></sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                
                    Notify Outbound Spam
                
                    <mat-icon matTooltip="specify whether to notify admins when outgoing spam is detected.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.NotifyOutboundSpam ? 'Enabled' : 'Disabled'}}
                </td>

                <td>
                    <div class="field boolean">
                        <mat-form-field
                            [class.mat-form-field-invalid]="form.get('NotifyOutboundSpamRecipients').value?.length ===0 && form.get('NotifyOutboundSpam').value == true"
                            appearance="fill">
                            <mat-label>Notify Outbound Spam</mat-label>
                            <mat-select formControlName="NotifyOutboundSpam">
                                <mat-option value="any">Ignore</mat-option>
                                <mat-option [value]="true">Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-error
                            *ngIf="form.get('NotifyOutboundSpamRecipients').value?.length ===0 && form.get('NotifyOutboundSpam').value == true">
                            To enable this Desired Value, you must add valid Desired Value(s) to the field Notify
                            Outbound Spam Recipients.
                        </mat-error>
                    </div>

                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="(defaultPolicy.NotifyOutboundSpam !== formValue.NotifyOutboundSpam) && formValue?.NotifyOutboundSpam !== 'any' ">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr [class.require-item] = "form.get('NotifyOutboundSpamRecipients').value?.length ===0 && form.get('NotifyOutboundSpam').value == true">
                <td>
                    Notify Outbound Spam Recipients
                    <mat-icon matTooltip="Specifies the email addresses of admins to notify when an outgoing spam is detected. You can specify multiple email addresses.">info</mat-icon>
                </td>

                <td>
                    <ng-container *ngIf="defaultPolicy?.NotifyOutboundSpamRecipients?.length; else noNotifyRecipients">
                        <button (click)="addNotFountNotifyOutboundSpamRecipients()" color="primary" mat-button
                            *ngIf="isAllNotifyRecipientsFound ">
                            Add All
                        </button>

                        <p class="d-flex align-items-center" *ngFor="let recipient of NotifyOutboundSpamRecipients">
                            {{recipient.email}}

                            <button mat-button (click)="addNotifyOutboundSapmRecipient(recipient.email)">
                                <mat-icon *ngIf="!recipient.isExist">add_circle_outline</mat-icon>

                            </button>
                        </p>
                    </ng-container>

                    <ng-template #noNotifyRecipients>
                        <p>
                            -
                        </p>
                    </ng-template>
                </td>

                <td>
                    <sway-filtered-list  [items]="form.get('NotifyOutboundSpamRecipients').value"
                        (update)="updateNotifyOutboundSpamRecipients($event)" *ngIf="emailsLookups"
                        [options]="emailsLookups" [placeholder]="'Notify Outbound Spam Recipients'">
                    </sway-filtered-list>
                </td>
                <td>
                    <sway-baseline-status [hasError]="isNotifyOutboundSpamRecipientsHasError"></sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Recipient Limit External Per Hour

                    <mat-icon matTooltip="Specifies the maximum number of external recipients that a user can send to within an hour. A valid value is 0 to 10000. The default value is 0, which means the service defaults are used.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.RecipientLimitExternalPerHour}}
                </td>

                <td>
                    
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label>Recipient Limit External Per Hour</mat-label>
                            <input min="0" [max]="formValue.RecipientLimitPerDay" matInput type="number"
                                formControlName="RecipientLimitExternalPerHour" />
                            <mat-hint>Zero means the default value</mat-hint>
                            <mat-error> Recipient limit external per hour can't exceed recipient limit per day.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.RecipientLimitExternalPerHour !== formValue.RecipientLimitExternalPerHour">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Recipient Limit Internal Per Hour
                
                    <mat-icon matTooltip="Specifies the maximum number of internal recipients that a user can send to within an hour. A valid value is 0 to 10000. The default value is 0, which means the service defaults are used.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.RecipientLimitInternalPerHour}}
                </td>

                <td>
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label>Recipient Limit Internal Per Hour</mat-label>
                            <input min="0" [max]="formValue.RecipientLimitPerDay" matInput type="number"
                                formControlName="RecipientLimitInternalPerHour" />
                            <mat-hint>Zero means the default value</mat-hint>
                            <mat-error> Recipient limit internal per hour can't exceed recipient limit per day.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.RecipientLimitInternalPerHour !== formValue.RecipientLimitInternalPerHour">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Recipient Limit Per Day
                    <mat-icon matTooltip="Specifies the maximum number of recipients that a user can send to within a day. A valid value is 0 to 10000. The default value is 0, which means the service defaults are used.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.RecipientLimitPerDay}}
                </td>

                <td>
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label>Recipient Limit Per Day</mat-label>
                            <input min="0" matInput type="number" formControlName="RecipientLimitPerDay" />
                            <mat-hint>Zero means the default value</mat-hint>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.RecipientLimitPerDay !== formValue.RecipientLimitPerDay">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr>
                <td>
                    Action When Threshold Reached
                    <mat-icon matTooltip="Specifies the action to take when any of the limits specified in the policy are reached.">info</mat-icon>
                </td>

                <td>
                    {{defaultPolicy.ActionWhenThresholdReached}}
                </td>

                <td>

                    <div class="field">
                        <mat-form-field appearance="fill">
                            <mat-label>Bcc Suspicious Outbound Mail</mat-label>
                            <mat-select formControlName="ActionWhenThresholdReached">
                                <mat-option value="Alert">Alert</mat-option>
                                <mat-option value="BlockUser">Block user</mat-option>
                                <mat-option value="BlockUserForToday">Block user for today</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.ActionWhenThresholdReached !== formValue.ActionWhenThresholdReached">
                    </sway-baseline-status>
                </td>
            </tr>
        </tbody>
    </table>
</form>

<ng-template #notFound>
    <p>
        Policy not found
    </p>
</ng-template>
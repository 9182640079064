<h1 class="font-bold" mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.description}}</p>
  <p><b *ngIf="data.boldMessage">{{data.boldMessage}}</b></p>
</div>
<div mat-dialog-actions class="buttons">
  <button mat-raised-button (click)="closeClick()" [ngClass]="data.close_button_class"
    cdkFocusInitial>{{data.close_button_text}}</button>

  <button mat-raised-button color="primary" class="primary"*ngIf="data.confirm_button_text != null" [ngClass]="data.confirm_button_class"
    [mat-dialog-close]="data">{{data.confirm_button_text}}</button>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, sample } from 'rxjs/operators';
import { HaveIBeenPwnedBreach } from 'src/app/interfaces/hibp/hibp.interface';
import { client } from 'src/app/stores/client';
import { loadGraphUsers } from 'src/app/stores/client/graph/user/user.actions';
import { loadUsers } from 'src/app/stores/client/octiga/user/actions';
import { CardComponent } from '../card.component';

@Component({
    selector: 'app-pwned-check',
    templateUrl: './pwned-check.component.html',
    styleUrls: ['./pwned-check.component.scss']
})

export class PwnedCheckComponent extends CardComponent implements OnInit, OnDestroy {

    private tenant_id = this.route.snapshot.paramMap.get('tenant');
    private subscriptions: Subscription[] = [];
    public isLoading: boolean;
    public pwned$: BehaviorSubject<{ critical: number, danger: number, warning: number, info: number }> = new BehaviorSubject({ critical: -1, danger: -1, warning: -1, info: -1 });

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<any>
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.tenant_id !== '77700dab-0afc-4bd8-acbe-e82b7cd6285b') {
            this.dispatcher();
            this.pwnedCheck();
        }
    }

    dispatcher() {
        this.store.dispatch(loadGraphUsers({ _tenant: this.tenant_id }));
        this.store.dispatch(loadUsers({ _tenant: this.tenant_id }));
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    pwnedCheck() {
        this.isLoading = true;
        const pwned_severity = { critical: 0, danger: 0, warning: 0, info: 0 };

        const graphUsersReady$ = this.store.pipe(
            select(client(this.tenant_id).graph.users.status),
            filter(res => res.loaded && !res.creating && !res.updating)
        );
        const graphUsers$ = this.store.pipe(
            select(client(this.tenant_id).graph.users.all),
            sample(graphUsersReady$)
        );

        const octigaUsersReady$ = this.store.pipe(
            select(client(this.tenant_id).octiga.user.status),
            filter(res => res.loaded && !res.creating && !res.updating)
        );
        const octigaUsers$ = this.store.pipe(
            select(client(this.tenant_id).octiga.user.all),
            sample(octigaUsersReady$)
        );

        this.subscriptions.push(
            combineLatest([
                graphUsers$,
                octigaUsers$
            ]).subscribe(([graphUsers, users]) => {

                if (users.length > 0 && graphUsers.length > 0) {
                    for (const user of users) {
                        if (!!user.hibp) {
                            const found_user = graphUsers.find(u => u.userPrincipalName === user.upn);
                            const lastPassChange = found_user !== undefined ? found_user.lastPasswordChangeDateTime : undefined;
                            const is_pwned = this.isPWNED(user.hibp, lastPassChange);
                            if (is_pwned) {
                                console.log(`${user.id} is PWNED!`);
                                pwned_severity[is_pwned as string]++;
                                this.pwned$.next(pwned_severity);
                            }
                        }

                    }
                    console.log('Complete pwned check scanning');
                }
                this.pwned$.next(pwned_severity);
                this.isLoading = false;
            })
        );

    }

    isPWNED(hibp: HaveIBeenPwnedBreach[], lastPassTimeStamp: string): string | boolean {
        if (lastPassTimeStamp === undefined) return false;
        // only compromised breaches with dataClass 'Password' will be considered as PWNED user
        if (hibp.some(breach => breach.DataClasses.includes('Passwords') && breach.IsVerified &&
            !breach.IsFabricated && !breach.IsRetired)) {
            if (hibp.some(breach => breach.ModifiedDate > lastPassTimeStamp) && lastPassTimeStamp !== null) {
                return 'critical';
            }
            return 'warning';
        } else {
            return false;
        }
    }

    navigate() {
        this.router.navigate([`client/${this.tenant_id}/dashboard/compromised-users`]);
    }

}

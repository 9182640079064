import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { selectSession } from 'src/app/stores/root.store';
import { changeClient } from 'src/app/stores/root/octiga/session/actions';
import { Session } from 'src/app/stores/root/octiga/session/model';
import { InitClientService } from '../init-client.service';

@Injectable({
    providedIn: 'root'
})
export class ClientGuard implements CanActivate, CanActivateChild {

    constructor(
        private store: Store<any>,
        private initService: InitClientService,
        private router: Router
    ) { }

    checkRoute(route: ActivatedRouteSnapshot) {
        const clientId = route.params.tenant;
        return this.store.pipe(
            select(selectSession),
            filter(sess => {

                if (sess.loading) {
                    return false; // no session yet
                }

                if (sess.session?.clientId === clientId) { // already viewing this client
                    return true;
                }

                if (sess.isAuthenticated) { // change clientId and block request (will cause this function to get invoked again with a clientId set...)
                    this.initService.addClientReducer(clientId);
                    this.store.dispatch(changeClient({ clientId }));
                    return false;

                } else { // move on to the auth checks
                    return true;
                }

            }),
            map(state => this.checkAuth(state.isAuthenticated, state.session))
        );
    }

    checkAuth(isAuthenticated: boolean, session: Session) {
        if (!isAuthenticated) {
            return this.router.createUrlTree(['/auth/microsoft'], { queryParams: { return_url: window.location.href } });
        } else if (session.clientId === undefined && session.msp_id) {
            return this.router.createUrlTree(['/msp/dashboard']);
        }
        return true;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkRoute(route);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkRoute(childRoute);
    }

}

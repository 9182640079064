import { Injectable } from '@angular/core';
import { DirectoryRole } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, distinct, expand, map, reduce } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';

interface GraphResponse {
    '@odata.nextLink'?: string
}

interface GraphDirectoryRoleResponse extends GraphResponse {
    value: DirectoryRole[]
}

function parseToken(response: GraphResponse): string | false {
    let skiptoken: string | false = false;
    if (response['@odata.nextLink']) {
        skiptoken = response['@odata.nextLink'].split('skiptoken=')[1];
    }
    return skiptoken;
}

@Injectable()
export class DirectoryRoleTemplatesEffects {

    loadDirectoryRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadDirectoryRoleTemplates),
            distinct(action => action.msp),
            concatMap(({ msp: _tenant }) => this.get(_tenant)
                .pipe(
                    map(roles => actions.loadDirectoryRoleTemplatesSuccess({ msp: _tenant, roles })),
                    catchError((error: any) => of(actions.loadDirectoryRoleTemplatesFailure({ msp: _tenant, error })))
                )
            )
        )
    );

    private get(tenant: string) {
        const url = '/api/microsoft/graph/directoryRoleTemplates';
        return this.ajax.get<GraphDirectoryRoleResponse>(tenant, url)
            .pipe(
                expand(data => {
                    const token = parseToken(data);
                    if (token) {
                        return this.ajax.get<GraphDirectoryRoleResponse>(tenant, `${url}?$skiptoken=${token}`);
                    } else {
                        return EMPTY;
                    }
                }),
                reduce((acc, data) => acc.concat(data.value), [] as DirectoryRole[])
            );
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }
}

import { createAction, props } from '@ngrx/store';
import { SwayDeviation } from './deviation.model';

export const loadDeviations = createAction(
    '[Sway Deviation] Load',
    props<{ _tenant: string }>()
);

export const loadDeviationsSuccess = createAction(
    '[Sway Deviation] Load Success',
    props<{ _tenant: string, data: SwayDeviation[] }>()
);

export const loadDeviationsFailure = createAction(
    '[Sway Deviation] Load Failure',
    props<{ _tenant: string, error: any }>()
);

export const createDeviation = createAction(
    '[Sway Deviation] Create',
    props<{ _tenant: string, data: Partial<SwayDeviation> }>()
);

export const createDeviationSuccess = createAction(
    '[Sway Deviation] Create Success',
    props<{ _tenant: string, data: SwayDeviation }>()
);

export const createDeviationFailure = createAction(
    '[Sway Deviation] Create Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateDeviation = createAction(
    '[Sway Deviation] Update',
    props<{ _tenant: string, deviation_id: string, data: Partial<SwayDeviation> }>()
);

export const updateDeviationSuccess = createAction(
    '[Sway Deviation] Update Success',
    props<{ _tenant: string, data: SwayDeviation }>()
);

export const updateDeviationFailure = createAction(
    '[Sway Deviation] Update Failure',
    props<{ _tenant: string, error: any }>()
);

export const createMultipleDeviations = createAction(
    '[Sway Deviation] Create Multiple',
    props<{ _tenant: string, data: Array<{ data: Partial<SwayDeviation> }> }>()
);

export const createMultipleDeviationsSuccess = createAction(
    '[Sway Deviation] Create Multiple Success',
    props<{ _tenant: string, data: SwayDeviation[] }>()
);

export const createMultipleDeviationsFailure = createAction(
    '[Sway Deviation] Create Multiple Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateMultipleDeviations = createAction(
    '[Sway Deviation] Update Multiple',
    props<{ _tenant: string, data: Array<{ deviation_id: string, data: Partial<SwayDeviation> }> }>()
);

export const updateMultipleDeviationsSuccess = createAction(
    '[Sway Deviation] Update Multiple Success',
    props<{ _tenant: string, data: SwayDeviation[] }>()
);

export const updateMultipleDeviationsFailure = createAction(
    '[Sway Deviation] Update Multiple Failure',
    props<{ _tenant: string, error: any }>()
);

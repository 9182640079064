import { ActionCreatorProps, props } from '@ngrx/store';

export type ActionBaseProps<
    Props extends object = never,
    SuccessFncParams extends object = never,
    FailFncParams extends object = never
> = {
    props?: Props;
    successFnc?: (args?: SuccessFncParams) => void;
    failFnc?: (error: unknown, args?: FailFncParams) => void;
};

/**
 * @typeParams
 * - Props: The properties packaged in the action when dispatched
 * - SuccessFncParams: Parameter types in a callback function: ```({ SuccessFncParams }) => void```
 * - FailFncParams: Parameter types in a callback function: ```({ err, FailFncParams }) => void```
 *      - Note: the 'err' param is static, and it's value will be injected during EffectsBase catchError
 * @returns Typed ngrx props() for use in an actionCreator call in the shape
 *      ```typescript
 *      {
 *          props: Props,
 *          successFnc: ({SuccessFncParams }) => void,
 *          failFnc: ({ err, FailFncParams }) => void
 *      }
 *      ```
 * ### Usage examples:
 * #### Creating Action
    In action, without successFnc/failFnc parameters

    i.e., success/failFnc exist, but do not have any input params ( () => console.log('success!'))
    ```typescript
    export const deleteMspAccessGroup = createAction(
        '[ort/msp-access-groups] deleteMspAccessGroup', baseProps<{groupId: string }>()
    ```
    In action, with successFnc/failFnc parameters
    ```typescript
    export const updateMspAccessGroup = createAction(
        '[ort/msp-access-groups] updateMspAccessGroup',
        baseProps<{ updatedGroup: MspAccessGroupsDTO_Post;}, { groupId: string }, { groupId: string } >()
    );
    ```

    ### Usage
    In dispatcher code
        ```typescript
        this.store.dispatch(updateMspAccessGroup(
            {
                props: { groupId, updatedGroup },
                successFnc: (effectValue) => console.log('hi'),
                failFnc: (err, effectValue) => { console.log('hi', err); }
            },
        ));
        ```

    In effect definition
        ```typescript
            updateMspAccessGroup$ = this.createEffect(
                updateMspAccessGroup,
                exhaustMap(({ props: { groupId, updatedGroup }, successFnc, failFnc }) =>
                this.mspAccessGroupsService
                    .updateAccessGroup(groupId, updatedGroup)
                    .pipe(
                    switchMap(() => [ updateMspAccessGroup_success() ]),
                    this.success('Access group update successful', () => successFnc({ value: 'effect value' })),
                    this.catchError('Access group update failed', updateMspAccessGroup, (err) => failFnc(err, { value: 'effect value' })),
                    )
                )
            );
        ```
*/
export function baseProps<
    Props extends object = never,
    SuccessFncParams extends object = never,
    FailFncParams extends object = never
>():
    ActionCreatorProps<
        ActionBaseProps<Props, SuccessFncParams, FailFncParams>
        > {
    return props<ActionBaseProps<Props, SuccessFncParams, FailFncParams>>();
}

import { createAction, props } from '@ngrx/store';
import { SwayTenant } from '../tenant/model';
import { SwayGroup } from './model';

export const loadSwayGroups = createAction(
    '[Sway/Group] Load',
    props<{ _tenant: string }>()
);


export const reloadSwayGroups = createAction(
    '[Sway/Group] Reload Sway groups',
    props<{ _tenant: string }>()
);

export const loadSwayGroupsSuccess = createAction(
    '[Sway/Group] Load Success',
    props<{ _tenant: string, data: SwayGroup[] }>()
);

export const loadSwayGroupsFailure = createAction(
    '[Sway/Group] Load Failure',
    props<{ _tenant: string, error: any }>()
);

export const createGroup = createAction(
    '[Sway/Group] Create',
    props<{ _tenant: string, data: SwayGroup }>()
);

export const createGroupSuccess = createAction(
    '[Sway/Group] Create Success',
    props<{ _tenant: string, data: SwayGroup }>()
);

export const createGroupFailure = createAction(
    '[Sway/Group] Create Failure',
    props<{ _tenant: string, group_id: string, error: any }>()
);

export const updateSwayGroup = createAction(
    '[Sway/Group] Update',
    props<{ _tenant: string, id: string, data: Partial< SwayGroup >}>()
);

export const updateSwayGroupSuccess = createAction(
    '[Sway/Group] Update Success',
    props<{ _tenant: string, data: Partial< SwayGroup> }>()
);

export const updateGroupFailure = createAction(
    '[Sway/Group] Update Failure',
    props<{ _tenant: string, group_id: string, error: any }>()
);

export const deleteGroup = createAction(
    '[Sway/Group] Delete',
    props<{ _tenant: string, group_id: string }>()
);

export const deleteGroupSuccess = createAction(
    '[Sway/Group] Delete Success',
    props<{ _tenant: string, group_id: string }>()
);

export const deleteGroupFailure = createAction(
    '[Sway/Group] Delete Failure',
    props<{ _tenant: string, group_id: string, error: any }>()
);

export const updateGroupOrder = createAction(
    '[Sway/Group] Update Group Order',
    props<{ _tenant: string, group_order: string[] }>()
);

export const updateGroupOrderSuccess = createAction(
    '[Sway/Group] Update Group Order Success',
    props<{ _tenant: string, data: SwayTenant }>()
);

export const updateGroupOrderFailure = createAction(
    '[Sway/Group] Update Group Order Failure',
    props<{ _tenant: string, error: any }>()
);

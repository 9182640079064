import { Injectable } from '@angular/core';
import { AuthenticationMethod } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, zip, catchError, delay, filter, map, mergeMap, switchMap, take, distinct } from 'rxjs';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { client } from '../../..';
import * as actions from './actions';
import { AuthenticationMethodModel } from './model';

interface Response {
    id: string,
    status: number
    headers: {
        [key: string]: string
    }
    body: {
        value: AuthenticationMethod[]
    }
}

interface BatchResponse {
    responses: Response[]
}

@Injectable()
export class AuthenticationMethodsEffects {

    fetchAuthMethods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchGraphAuthenticationMethods),
            distinct(action => action._tenant),
            switchMap(action => this.store.pipe(
                select(client(action._tenant).graph.authenticationMethods.status),
                filter(status => !status.loaded),
                map(() => action),
                take(1)
            )),
            mergeMap(({ _tenant, ids }) => this.fetchAuthenticationMethods(_tenant, ids)
                .pipe(
                    map((data) => actions.fetchGraphAuthenticationMethodsSuccess({ _tenant, data })),
                    catchError((error) => of(actions.fetchGraphAuthenticationMethodsFailure({ _tenant, error })))
                ))
        )
    );

    private fetchAuthenticationMethods(tenant: string, ids: string[]): Observable<AuthenticationMethodModel[]> {
        const requests = ids.map(id => ({
            method: 'GET',
            id,
            url: `/users/${id}/authentication/methods`
        }));

        const request_batches = []; // batching batches! $batch is limited to 20 requests per batch

        let count = 0;
        do {
            const start = count * 20;
            const end = start + 20;
            request_batches.push(requests.slice(start, end));
        } while ((++count * 20) < requests.length);

        return combineLatest(request_batches.map(requests => this.batch(tenant, { requests })))
            .pipe(
                take(1),
                map(items => items.flat(1)),
                switchMap(batches => {

                    const results: AuthenticationMethodModel[] = [];
                    const retry_ids: string[] = [];
                    const retry_delays: number[] = [];

                    for (const batch of batches) {

                        for (const response of batch.responses) {

                            if (response.status === 200) {
                                results.push({
                                    id: response.id,
                                    value: response.body.value || [] // should be an array, probs not necessary
                                });
                                continue;
                            }

                            if (response.status === 429) { // too many requests
                                retry_ids.push(response.id);
                                retry_delays.push(parseInt(response.headers['Retry-After']) || 0);
                                continue;
                            }

                            console.error(response);
                        }
                    }

                    if (retry_ids.length === 0) {
                        // no throttled requests
                        return of(results);
                    } else {
                        // recur this function to retry throttled requests
                        const delay_ms = Math.max(...retry_delays) * 1000;
                        return of(null)
                            .pipe(
                                delay(delay_ms),
                                switchMap(() => zip( // zip results from this invocation and next
                                    of(results),
                                    this.fetchAuthenticationMethods(tenant, retry_ids)
                                )
                                    .pipe(
                                        map(([a, b]) => a.concat(b))
                                    )
                                )
                            );

                    }

                })
            );
    }

    private batch(tenant: string, body: any): Observable<BatchResponse> {
        return this.ajax.post<BatchResponse>(tenant, '/api/microsoft/graph/$batch', body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>
    ) { }

}


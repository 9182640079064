import { createReducer, on } from '@ngrx/store';
import * as TenantActions from './actions';
import * as SwayGroupActions from '../group/actions';
import { SwayTenant } from './model';
import { Status, initialStatus } from 'src/app/stores/status.interface';

export const featureKey = 'swayTenant';

export interface State extends Status {
    data: SwayTenant | null;
}

export const initialState: State = {
    data: null,
    ...initialStatus
};

function insertNewGroupOrder(group_order: string[], new_group_id: string): string[] {
    const new_group_order = [].concat(group_order);
    const add_position = new_group_order.length === 1 ? 0 : new_group_order.length - 1;
    new_group_order.splice(add_position, 0, new_group_id);
    return new_group_order;
}

function removeNewGroupOrder(group_order: string[], remove_group_id: string): string[] {
    const new_group_order = [].concat(group_order);
    new_group_order.splice(new_group_order.indexOf(remove_group_id), 1);
    return new_group_order;
}

export const reducer = createReducer(
    initialState,

    on(TenantActions.loadSwayTenantSuccess, (state, { data }) => ({ ...state, data, loaded: true })),
  
    on(TenantActions.updateSwayTenantSuccess, (state, { tenant }) => {
        state.data = {...state.data, ...tenant};
        return { ...state, updating: false}; 
    }),
    // on(TenantActions.updateSwayTenantFailure, (state, { data }) => ({ ...state, data, loaded: true })),

    on(SwayGroupActions.updateGroupOrder, (state, { group_order }) => ({ ...state, data: { ...state.data, group_order } })),
    on(SwayGroupActions.updateGroupOrderSuccess, (state, { data }) => ({ ...state, data })),
    on(SwayGroupActions.createGroupSuccess, (state, { data }) => {
        const newData: SwayTenant = { ...state.data, group_order: insertNewGroupOrder(state.data.group_order, data.id) };
        const newState: State = Object.assign({}, { ...state, data: newData });
        return newState;
    }),

    on(SwayGroupActions.deleteGroupSuccess, (state, { group_id }) => {
        const newData: SwayTenant = { ...state.data, group_order: removeNewGroupOrder(state.data.group_order, group_id) };
        const newState: State = Object.assign({}, { ...state, data: newData });
        return newState;
    })
);

export const selectTenant = (state: State) => state.data;

import { ConditionalAccessApplications, ConditionalAccessGrantControls, ConditionalAccessPolicy, User } from '@microsoft/microsoft-graph-types-beta';
import { RemediateCapItem } from '../shared/cap-remediate';
import { getCAPBaselineDisplayName } from '../shared/cap-schema';


// Cloud App Security administrator
const applications: ConditionalAccessApplications = { // check this
    'includeApplications': [
        'All'
    ]

};
const grantControls : ConditionalAccessGrantControls = {
    'operator': 'OR',
    'builtInControls': [
        'mfa'
    ]
};


export function Remediate(_tenant: string, data: ConditionalAccessPolicy[], schema: any, users: User[], key) { 
    const id = key;
    let policyItem;
    
    if(id){
        policyItem = data.find(res => res.id === id );
    }else{
        policyItem = data.find(res => res.displayName === getCAPBaselineDisplayName(schema) );
    }
    

    return RemediateCapItem(_tenant, schema, policyItem, { grantControls, applications});
}

import { createAction, props } from '@ngrx/store';
import { AntiPhishPolicy } from './model';

export const loadAntiPhishPolicy = createAction(
    '[Client/Pwsh/Exo] Load AntiPhishPolicy',
    props<{ _tenant: string }>()
);

export const loadAntiPhishPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Load AntiPhishPolicy Success',
    props<{ _tenant: string, data: AntiPhishPolicy[] }>()
);

export const loadAntiPhishPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Load AntiPhishPolicy Failure',
    props<{ _tenant: string, error: any }>()
);


export const updateExoAntiPhishPolicy = createAction(
    '[Client/Pwsh/Exo] Update AntiPhishing Policy',
    props<{ _tenant: string, data: Partial<AntiPhishPolicy> }>()
);

export const updateExoAntiPhishPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Update AntiPhishing Policy Success',
    props<{ _tenant: string, data: any }>()
);

export const updateExoAntiPhishPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Update AntiPhishing Policy Failure',
    props<{ _tenant: string, error: any }>()
);

import { selectStatus } from 'src/app/stores/status.interface';
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ExoMailboxDelegate } from './model';
import * as ExoMailboxDelegateActions from './actions';

export const exoFeatureKey = 'exoMailboxDelegates';

export type State = EntityState<ExoMailboxDelegate>

export const adapter: EntityAdapter<ExoMailboxDelegate> = createEntityAdapter<ExoMailboxDelegate>({
    selectId: (d) => d.Identity
});

export const initialState = adapter.getInitialState({
});


export const reducer = createReducer(
    initialState,
 
    on(ExoMailboxDelegateActions.getMailboxDelegatesSuccess,
        (state, action) => adapter.upsertOne({...action.exoMailboxDelegates,loaded: true}, state) 
    ),

    on(ExoMailboxDelegateActions.getMailboxDelegatesFailure,
        (state, action) => ({ ...state, loaded: false, error: action.error })
    ),



    on(ExoMailboxDelegateActions.updateMailboxDelegatesSuccess,
        (state) => (state)
    ),
    on(ExoMailboxDelegateActions.updateMailboxDelegatesFailure,
        (state, action) => ({ ...state, error: action.error })
    ),
    on(ExoMailboxDelegateActions.addExoMailboxDelegate,
        (state, action) => adapter.addOne(action.exoMailboxDelegate, state)
    ),
    on(ExoMailboxDelegateActions.upsertExoMailboxDelegate,
        (state, action) => adapter.upsertOne(action.exoMailboxDelegate, state)
    ),
    on(ExoMailboxDelegateActions.addExoMailboxDelegates,
        (state, action) => adapter.addMany(action.exoMailboxDelegates, state)
    ),
    on(ExoMailboxDelegateActions.upsertExoMailboxDelegates,
        (state, action) => adapter.upsertMany(action.exoMailboxDelegates, state)
    ),
    on(ExoMailboxDelegateActions.updateExoMailboxDelegate,
        (state, action) => adapter.updateOne(action.exoMailboxDelegate, state)
    ),
    on(ExoMailboxDelegateActions.updateExoMailboxDelegates,
        (state, action) => adapter.updateMany(action.exoMailboxDelegates, state)
    ),
    on(ExoMailboxDelegateActions.deleteExoMailboxDelegate,
        (state, action) => adapter.removeOne(action.id, state)
    ),
    on(ExoMailboxDelegateActions.deleteExoMailboxDelegates,
        (state, action) => adapter.removeMany(action.ids, state)
    ),
    on(ExoMailboxDelegateActions.clearExoMailboxDelegates,
        state => adapter.removeAll(state)
    ),
);


export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectIdentityStatus = (Identity) => (state: State) => state?.entities[Identity]?.loaded;

import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-severity-list',
    templateUrl: './severity-list.component.html',
    styleUrls: ['./severity-list.component.scss']
})
export class SeverityListComponent implements OnInit {
  @Input() severity: { critical: number | string, danger: number | string, warning: number | string };

  constructor() { }

  ngOnInit(): void {
  }

}

<table mat-table [dataSource]="dataSource" class="mat-elevation-z0 table">
    <ng-container matColumnDef="severity">
        <mat-header-cell *matHeaderCellDef>Severity</mat-header-cell>
        <mat-cell *matCellDef="let item">
            <div *ngIf="isDismissed(item.id); then dismiss else severity"></div>
            <ng-template #dismiss>
                <mat-icon mat-list-icon class="severity dismiss" matTooltip="dismissed">
                    info_outlined</mat-icon>
            </ng-template>
            <ng-template #severity>
                <mat-icon *ngIf="item.severity=== 'critical'" mat-list-icon class="severity critical"
                    matTooltip="critical">
                    error_outlined</mat-icon>
                <mat-icon *ngIf="item.severity === 'danger'" mat-list-icon class="severity danger" matTooltip="danger">
                    error_outlined</mat-icon>
                <mat-icon *ngIf="item.severity === 'warning'" mat-list-icon class="severity warning"
                    matTooltip="warning">
                    report_problem_outlined</mat-icon>
                <mat-icon *ngIf="item.severity === 'info'" mat-list-icon class="severity info" matTooltip="info">
                    info_outlined</mat-icon>
            </ng-template>

        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
        <mat-cell *matCellDef="let item">
            <div class="description" [class.dismissed]="item.severity !== 'info' && isDismissed(item.id)">
                <span><b>{{item.title}}</b></span>
                <span>{{item.description}}</span>
            </div>
        </mat-cell>
    </ng-container>


    <ng-container matColumnDef="dismiss">
        <mat-header-cell *matHeaderCellDef>Alert</mat-header-cell>
        <mat-cell *matCellDef="let item;" class="cell-dismiss">
            <div *ngIf="(notLoading$ | async); then ready else loading"></div>
            <ng-template #ready>
                <mat-icon *ngIf="item.severity !== 'info' && !isDismissed(item.id)" class="button dismiss"
                    color="accent" aria-label="dismiss" matTooltipPosition="after" (click)="dismiss(item.id)">close
                </mat-icon>
                <mat-icon *ngIf="isDismissed(item.id)" aria-label="retain" matTooltip="retain/show"
                    matTooltipPosition="after" (click)="retain(item.id)" class="button undo">visibility</mat-icon>
            </ng-template>
            <ng-template #loading>
                <mat-spinner diameter="20" class="button disabled" color="primiary" aria-label="loading"
                    matTooltipPosition="after"></mat-spinner>
            </ng-template>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="table-header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" style="width: inherit;"></mat-row>
</table>
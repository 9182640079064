<div class="mat-typography">
    <div class="form">
        <div class="field boolean">
            <mat-form-field appearance="outline">
                <mat-label>Default Alert Threshold </mat-label>
                <input [formControl]="control" max="100" min="0" matInput type="number">
              
                <div matSuffix class="suffix-info">
                    <span >%</span>

                    <mat-icon
                    matTooltip="The percentage of space used over which a deviation (and hence an alert) will be triggered."
                    class="suffix-icon" matSuffix>info</mat-icon>
                </div>
            </mat-form-field>
          
        </div>
    </div>


    <div class="form">
        <div class="field boolean">
            <mat-form-field class="search-site" appearance="outline">
                <mat-label>Search Site </mat-label>

                <input #text (keyup)="filter(text.value)" matInput type="search">
            </mat-form-field>
        </div>
    </div>

    <table  mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="siteUrl">
            <th mat-sort-header class="header-details" mat-header-cell *matHeaderCellDef> Site


            </th>

            <td class="cell-details" mat-cell *matCellDef="let item">
                {{item.siteUrl}}

                <mat-slide-toggle class="d-block " [disabled]="readOnly"
                    (change)="item.hasThreshold = false; removeSpecificChecker(item.siteUrl); setIgnoredSites()"
                    [(ngModel)]="item.isIgnored"> Ignore

                </mat-slide-toggle>

            </td>
        </ng-container>


        <ng-container matColumnDef="storageAllocatedInMGBytes">
            <th mat-sort-header start="desc"  class="header-details" mat-header-cell *matHeaderCellDef> Maximum Size
                (MB) </th>

            <td class="cell-details" mat-cell *matCellDef="let item">
                {{item.storageAllocatedInMGBytes }}
            </td>
        </ng-container>

        <ng-container matColumnDef="storageUsedInMGBytes">
            <th mat-sort-header class="header-details" des mat-header-cell *matHeaderCellDef start="desc" > Current Used (MB) </th>

            <td class="cell-details" mat-cell *matCellDef="let item">
                {{item.storageUsedInMGBytes }}
            </td>
        </ng-container>




        <ng-container matColumnDef="storageUsedPercentage">
            <th mat-sort-header class="header-details" mat-header-cell *matHeaderCellDef start="desc" > Percentage Used </th>

            <td class="cell-details" mat-cell *matCellDef="let item">
                {{ item.storageUsedPercentage | number:'.2' }}
            </td>
        </ng-container>



        <ng-container matColumnDef="threshold">
            <th mat-sort-header class="header-details" mat-header-cell *matHeaderCellDef> Threshold </th>

            <td class="cell-details threshold-cell" mat-cell *matCellDef="let item">
                <div class="item">
                    <ng-select [disabled]="readOnly" (change)="changeThresholdType(item, $event)" [clearable]="false"
                        [(ngModel)]="item.hasThreshold" class="auto-grow">
                        <ng-option [value]="false"> Default Alert Threshold ({{control.value}} %) </ng-option>
                        <ng-option [value]="true"> Set Specific Threshold </ng-option>
                    </ng-select>
                </div>

                <mat-form-field class="specific-threshold" *ngIf="item.hasThreshold" appearance="outline">
                    <mat-label>Specific Threshold </mat-label>

                    <input [formControl]="item.control" max="100" min="0" matInput type="number">
                    <span matSuffix>%</span>
                </mat-form-field>
            </td>
        </ng-container>




        <ng-container matColumnDef="status">
            <th mat-sort-header class="header-details" mat-header-cell *matHeaderCellDef> Status</th>

            <td class="cell-details" mat-cell *matCellDef="let item">
                <sway-baseline-status
                    [hasError]="item.hasThreshold ? item.control.value < item.storageUsedPercentage : control.value < item.storageUsedPercentage">
                </sway-baseline-status>
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr [class.ignored]="row.isIgnored" mat-row *matRowDef="let row; columns: columns;"></tr>

    </table>
</div>
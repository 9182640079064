import { createAction, props } from '@ngrx/store';
import { Dismiss } from './model';

export const loadDismissItems = createAction(
    '[Octiga/Dismiss] Load',
    props<{ _tenant: string }>()
);

export const loadDismissItemsSuccess = createAction(
    '[Octiga/Dismiss] Load Success',
    props<{ _tenant: string, data: Dismiss[] }>()
);

export const loadDismissItemsFailure = createAction(
    '[Octiga/Dismiss] Load Failure',
    props<{ _tenant: string, error: any }>()
);

export const createDismissItem = createAction(
    '[Octiga/Dismiss] Create',
    props<{ _tenant: string, data: Dismiss }>()
);

export const createDismissItemSuccess = createAction(
    '[Octiga/Dismiss] Create Success',
    props<{ _tenant: string, data: Dismiss }>()
);

export const createDismissItemFailure = createAction(
    '[Octiga/Dismiss] Create Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateDismissItem = createAction(
    '[Octiga/Dismiss] Update',
    props<{ _tenant: string, data: Dismiss }>()
);

export const updateDismissItemSuccess = createAction(
    '[Octiga/Dismiss] Update Success',
    props<{ _tenant: string, data: Dismiss }>()
);

export const updateDismissItemFailure = createAction(
    '[Octiga/Dismiss] Update Failure',
    props<{ _tenant: string, error: any }>()
);

export const deleteDismissItem = createAction(
    '[Octiga/Dismiss] Delete',
    props<{ _tenant: string, id: string }>()
);

export const deleteDismissItemSuccess = createAction(
    '[Octiga/Dismiss] Delete Success',
    props<{ _tenant: string, id: string }>()
);

export const deleteDismissItemFailure = createAction(
    '[Octiga/Dismiss] Delete Failure',
    props<{ _tenant: string, error: any }>()
);

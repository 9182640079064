import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeviceCompliancePolicyAssignment, OperatingSystemVersionRange, Windows10CompliancePolicy } from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import { initForm, windows10CompliancePolicyDisplayName } from 'src/app/modules/sway/tools/schemas/Windows10CompliancePolicy';
import { Windows10CompliancePolicySchema } from 'src/app/modules/sway/tools/schemas/Windows10CompliancePolicy/model';
import * as Windows10CompliancePolicyHelper from 'src/app/modules/sway/tools/schemas/Windows10CompliancePolicy/Windows10CompliancePolicy.schema';
import { PolicyAssignment } from 'src/app/stores/client/graph/compliance-policies/policies/modet';
import { BaseComponent } from '../../../base/base.component';
import { Tooltips } from './model';



// TODO, Validate data correctly.
// TODO, filter inclusion.

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss']
})


export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    displayName = windows10CompliancePolicyDisplayName;
    errorString = ''; // string contains all errors
    tooltips = Tooltips;
    form: FormGroup;
    formErrors;

    @Input() data: PolicyAssignment[];

    policy: Windows10CompliancePolicy;
    assignments: DeviceCompliancePolicyAssignment[] = [];

    loaded: boolean;

    constructor(private fb: FormBuilder, private store: Store<any>, private dialog: MatDialog) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.loadData();
    }

    ngOnInit(): void {
        this.loadData();

        this.form = initForm(this.baseline.schema, this.fb);
        this.loaded = true;


        this.observePolicy();
    }


    setErrorString(): void {
        this.errorString = '';
        this.errors.forEach(res => {
            this.errorString += '!' + res.instancePath + '!';
        });
    }

    loadData(): void {

        const policy_assignments = this.data.find(res => res?.policy?.displayName === windows10CompliancePolicyDisplayName);

        this.policy = policy_assignments?.policy;

        this.assignments = policy_assignments?.assignments || [];

        this.setErrorString();


    }


    observePolicy() {

        const schema: Windows10CompliancePolicySchema = JSON.parse(JSON.stringify(this.baseline.schema));

        this.form.valueChanges.subscribe(res => {
            const updatedSchema = Windows10CompliancePolicyHelper.updateSchema(this.formValue, schema);


            const formHasError = Object.keys(this.form.controls).some(control => this.form.get(control).invalid);

            const osRangesHaveErrors = Object.keys(this.form.get('validOperatingSystemBuildRanges').value)
                .some(index => this.form.get('validOperatingSystemBuildRanges').get(index).get('description').invalid || this.form.get('validOperatingSystemBuildRanges').get(index).get('lowestVersion').invalid || this.form.get('validOperatingSystemBuildRanges').get(index).get('highestVersion').invalid);


            const nowGroupAssignments = updatedSchema.contains.properties.assignments?.maxItems === 0;


            this.formErrors = formHasError || osRangesHaveErrors || nowGroupAssignments;

            if (this.formErrors) {
                this.baselineErrorChange.next({ remediate: true, save: true });
            } else {
                this.baselineErrorChange.next({ remediate: false, save: false });
            }


            this.setErrorString();

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(updatedSchema))
            });

        });
    }



    onPasswordRequiredTypeChange(): void {
        this.form.get('passwordMinimumCharacterSetCount').setValue(2);
    }

    addOperatingSystemBuildRangesGroup(item?: OperatingSystemVersionRange): FormGroup {
        return this.fb.group({
            description: [item?.description],
            lowestVersion: [item?.lowestVersion, [Validators.required, Validators.pattern(/^\d+(?:\.\d+){3}$/)]],
            highestVersion: [item?.highestVersion, [Validators.required, Validators.pattern(/^\d+(?:\.\d+){3}$/)]]
        });
    }

    addOperatingSystemBuildRangesGroupRow(): void {
        const newItem = this.addOperatingSystemBuildRangesGroup();
        (this.form.get('validOperatingSystemBuildRanges') as FormArray).push(newItem);
    }

    removeOperatingSystemBuildRangesGroupRow(index: number): void {
        (this.form.get('validOperatingSystemBuildRanges') as FormArray).removeAt(index);
    }


    get formValue() {
        return this.form.getRawValue();
    }

    get validOperatingSystemBuildRangesControls() {
        return (this.form.get('validOperatingSystemBuildRanges') as FormArray).controls;
    }

    get passwordRequired() {
        return this.formValue.passwordRequired;
    }

    get osRanges(): OperatingSystemVersionRange[] {
        return this.formValue.validOperatingSystemBuildRanges;
    }

    get assignmentsValue() {
        return this.formValue.assignments;
    }
}



import { updateExoAdminAuditLogConfig } from 'src/app/stores/client/powershell/exo/admin-audit-log-config/actions';
import { AdminAuditLogConfig } from 'src/app/stores/client/powershell/exo/admin-audit-log-config/model';

export function Remediate(_tenant: string, data: Partial<AdminAuditLogConfig>, schema: any) {

    if (schema.properties.UnifiedAuditLogIngestionEnabled.const === false) {
        return updateExoAdminAuditLogConfig({ _tenant, data: { UnifiedAuditLogIngestionEnabled: false } });
    }

    if (schema.properties.UnifiedAuditLogIngestionEnabled.const === true) {
        return updateExoAdminAuditLogConfig({ _tenant, data: { UnifiedAuditLogIngestionEnabled: true } });
    }
}

<mat-card class="mat-elevation-z8">

    <mat-card-header>
        <div mat-card-avatar class="header-image-container mat-elevation-z4" [style.background-color]="plan.color">
            <div class="header-image"></div>
        </div>
        <mat-card-title>{{plan.title}}</mat-card-title>
        <mat-card-subtitle>{{plan.description}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

        <mat-list>

            <mat-list-item *ngFor="let item of plan.features">
                <mat-icon mat-list-icon>check</mat-icon>
                <div>{{item}}</div>
                <!-- <div mat-line> {{folder.updated | date}} </div> -->
            </mat-list-item>

        </mat-list>

        <mat-card-subtitle class="price">{{plan.price}}</mat-card-subtitle>

    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="primary" [disabled]="plan.disabled" (click)="next()">Select Plan</button>
    </mat-card-actions>

</mat-card>

import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';
export { BaselineComponent } from './baseline.component';
export { Remediate } from './remediate';
export const href ='https://learn.microsoft.com/en-us/azure/active-directory/conditional-access/howto-policy-app-enforced-restriction';



const sessionControls = {
    'type': 'object',
    'properties': {
        'applicationEnforcedRestrictions': {
            'type': 'object',
            'properties': {
                'isEnabled': {
                    'type': 'boolean',
                    'const': true
                }
            }
        }
    }
};

const applications = {
    'type': 'object',
    'properties': {
        'includeApplications': {
            'type': 'array',
            'items': {
                'enum': ['Office365'],
                'type': 'string'
            },
            'minItems': 1,
            'maxItems': 1,
            'uniqueItems': true
        }

    },
    'required': ['includeApplications']
};



const policy : ConditionalAccessPolicySchema = {
    sessionControls,
    conditions: {    
        applications
    }
};


export const CAPAppEnforcedRestrictionUmangedDevicesSchemaBaselineSchema =
    (
        form
    ) => {
        return createCapPolicySchema(form, policy);
    };


import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { DirectoryRoleMembersModel } from './model';

export const featureKey = 'directoryRoleMembers';

export interface State extends EntityState<DirectoryRoleMembersModel>, Status {
    loaded_entities: {
        [key: string]: boolean
    }
}

export const adapter: EntityAdapter<DirectoryRoleMembersModel> = createEntityAdapter({
    selectId: item => item.roleTemplateId
});

export const initialState: State = adapter.getInitialState({
    loaded_entities: {},
    ...initialStatus
});

// data is loaded across multiple ajax calls, so we need to track multiple entities until they're all loaded
function setEntityLoaded(state: State, roleTemplateId: string, loaded: boolean): State {
    const new_state: State = {
        ...state,
        loaded_entities: {
            ...state.loaded_entities,
            [roleTemplateId]: loaded
        }
    };
    new_state.loaded = Object.values(new_state.loaded_entities).every(loaded => loaded);
    return new_state;
}

export const reducer = createReducer(
    initialState,
    on(actions.loadDirectoryRoleMembers,
        (state, { roleTemplateId }) => setEntityLoaded(state, roleTemplateId, false)
    ),
    on(actions.loadDirectoryRoleMembersSuccess,
        (state, { item }) => adapter.setOne(item, setEntityLoaded(state, item.roleTemplateId, true))
    ),
    on(actions.loadDirectoryRoleMembersFailure,
        (state, action) => ({ ...state, loaded: false, error: action.error })
    ),

    on(actions.assignRoles, (state) => ({ ...state, updating: true })),

    on(actions.assignRolesSuccess,
        (state, {_tenant, user: user, addRoles, removeRoles}) => {
            
            const updates : Update<DirectoryRoleMembersModel>[] = [];

            addRoles.forEach(addRole=>{
                updates.push( { id: addRole, changes: { members: [...state.entities[addRole].members, user] }});
                
            });
            
            removeRoles.forEach(removeRole=>{
                
                updates.push({ id: removeRole, changes: { members: [...state.entities[removeRole].members.filter(member => member.id !== user.id)] } });
            });
            
            return adapter.updateMany(updates, {...state, updating: false});
        }
    ),
    on(actions.assignRolesFailure,
        (state, action) => ({ ...state, deleting: false, error: action.error })
    ),


    on(actions.removeDirectoryRoleMember,
        (state, { roleTemplateId }) => ({ ...state, deleting: true })
    ),
    on(actions.removeDirectoryRoleMemberSuccess,
        (state, action) => adapter.updateOne({
            id: action.roleTemplateId, changes: {
                members: state.entities[action.roleTemplateId].members.filter(member => member.id !== action.memberId)
            }
        }, state = { ...state, deleting: false })
    ),
    on(actions.removeDirectoryRoleMemberFailure,
        (state, action) => ({ ...state, deleting: false, error: action.error })
    ),
    on(actions.directoryRoleMembersLoaded,
        (state, action) => ({ ...state, loaded: true })
    ),
);

export function byDirectoryRoleId(state: State, id: string) {
    return state.entities[id]?.members;
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

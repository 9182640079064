import { ConditionalAccessApplications, ConditionalAccessClientApp, ConditionalAccessGrantControls, ConditionalAccessGuestOrExternalUserTypes, ConditionalAccessLocations, ConditionalAccessPlatforms, ConditionalAccessPolicy, ConditionalAccessSessionControls, NullableOption, RiskLevel } from '@microsoft/microsoft-graph-types-beta';
import { createConditionalAccessPolicy, deleteConditionalAccessPolicy, updateConditionalAccessPolicy } from 'src/app/stores/client/graph/conditional-access/actions';
import { getCAPBaselineDisplayName } from './cap-schema';



const CAP_Policy = (
    items: {
        displayName,
        sessionControls: ConditionalAccessSessionControls,
        grantControls: NullableOption<ConditionalAccessGrantControls>,
        clientAppTypes: ConditionalAccessClientApp[],
        platforms: NullableOption<ConditionalAccessPlatforms>,
        applications: NullableOption<ConditionalAccessApplications>,
        locations: NullableOption<ConditionalAccessLocations>,
        signInRiskLevels: NullableOption<RiskLevel[]>
        state: 'enabled' | 'disabled',
        includeRoles: string[],
        excludeRoles,
        includeUsers,
        excludeUsers,
        includeGroups,
        excludeGroups
    }

): ConditionalAccessPolicy => {
    const {
        displayName,
        sessionControls,
        grantControls,
        clientAppTypes,
        signInRiskLevels,
        platforms,
        applications,
        locations,
        state,
        includeRoles,
        excludeRoles,
        includeUsers,
        excludeUsers,
        includeGroups,
        excludeGroups
    } = items;

    return {
        displayName: displayName,
        state: state,
        conditions: {
            users: {
                includeRoles: includeRoles,
                excludeRoles: excludeRoles,
                includeUsers: includeUsers,
                excludeUsers: excludeUsers,
                includeGroups: includeGroups,
                excludeGroups: excludeGroups,
            },
            locations: locations,
            clientAppTypes: clientAppTypes,
            platforms: platforms,
            applications: applications,
            signInRiskLevels: signInRiskLevels
        },

        sessionControls: sessionControls,
        grantControls: grantControls,

    };
};

export function RemediateCapItem(
    _tenant: string,
    schema: any,
    policy: ConditionalAccessPolicy,

    policy_change: {
        sessionControls?: NullableOption<ConditionalAccessSessionControls>,
        grantControls?: NullableOption<ConditionalAccessGrantControls>,
        clientAppTypes?: ConditionalAccessClientApp[],
        platforms?: NullableOption<ConditionalAccessPlatforms>,
        applications?: NullableOption<ConditionalAccessApplications>
        locations?: NullableOption<ConditionalAccessLocations>
        signInRiskLevels?: NullableOption<RiskLevel[]>
    }
) {
    const displayName = getCAPBaselineDisplayName(schema);
    
    const isNoPolicySchema = !!schema?.not?.contains;
    const state = schema?.contains?.properties?.state?.const;

    const includeRoles = schema?.contains?.properties?.conditions?.properties?.users?.properties?.includeRoles?.items?.enum || [];

    const includeGuestsOrExternalUsersPattern: string =
        schema?.contains?.properties?.conditions?.properties?.users?.properties?.includeGuestsOrExternalUsers?.properties?.guestOrExternalUserTypes?.pattern || null;
    const includeGuestsOrExternalUsers: string = includeGuestsOrExternalUsersPattern?.split(')')?.join('')?.replace('(?=.*', '').split('(?=.*')?.join(',') || null;



    const excludeGuestsOrExternalUsersPattern: string =
        schema?.contains?.properties?.conditions?.properties?.users?.properties?.excludeGuestsOrExternalUsers?.properties?.guestOrExternalUserTypes?.pattern || null;
    const excludeGuestsOrExternalUsers: string = excludeGuestsOrExternalUsersPattern?.split(')')?.join('')?.replace('(?=.*', '').split('(?=.*')?.join(',') || null;



    const excludeRoles = schema?.contains?.properties?.conditions?.properties?.users?.properties?.excludeRoles?.items?.enum || [];
    const includeUsers = schema?.contains?.properties?.conditions?.properties?.users?.properties?.includeUsers?.items?.enum || [];
    const excludeUsers = schema?.contains?.properties?.conditions?.properties?.users?.properties?.excludeUsers?.items?.enum || [];
    const includeGroups = schema?.contains?.properties?.conditions?.properties?.users?.properties?.includeGroups?.items?.enum || [];
    const excludeGroups = schema?.contains?.properties?.conditions?.properties?.users?.properties?.excludeGroups?.items?.enum || [];


    if (!policy) { // create MFA Policy if it is not exists
        const new_policy: ConditionalAccessPolicy =
            JSON.parse(JSON.stringify(
                CAP_Policy(
                    {
                        displayName: displayName,
                        sessionControls: policy_change.sessionControls,
                        grantControls: policy_change.grantControls,
                        clientAppTypes: policy_change.clientAppTypes,
                        platforms: policy_change.platforms,
                        applications: policy_change.applications,
                        signInRiskLevels: policy_change.signInRiskLevels,
                        locations: policy_change.locations,
                        state,
                        includeRoles,
                        excludeRoles,
                        includeUsers,
                        excludeUsers,
                        includeGroups,
                        excludeGroups
                    }
                )
            ));

        if (includeGuestsOrExternalUsers) {

            new_policy.conditions.users['includeGuestsOrExternalUsers'] = {};
            new_policy.conditions.users['includeGuestsOrExternalUsers'].guestOrExternalUserTypes = includeGuestsOrExternalUsers as ConditionalAccessGuestOrExternalUserTypes;

        } else {
            new_policy.conditions.users['includeGuestsOrExternalUsers'] = null;

        }


        if (excludeGuestsOrExternalUsers) {
            new_policy.conditions.users['excludeGuestsOrExternalUsers'] = {};
            new_policy.conditions.users['excludeGuestsOrExternalUsers'].guestOrExternalUserTypes = excludeGuestsOrExternalUsers as ConditionalAccessGuestOrExternalUserTypes;

        } else {
            new_policy.conditions.users['excludeGuestsOrExternalUsers'] = null;

        }


        return createConditionalAccessPolicy({ _tenant, policy: new_policy });

    }
    else if (policy) {
        if (isNoPolicySchema) {
            return deleteConditionalAccessPolicy({ _tenant, id: policy.id });
        }
        else {
            const updated_policy = JSON.parse(
                JSON.stringify(
                    CAP_Policy(
                        {
                            displayName: displayName,
                            sessionControls: policy_change.sessionControls,
                            grantControls: policy_change.grantControls,
                            clientAppTypes: policy_change.clientAppTypes,
                            platforms: policy_change.platforms,
                            applications: policy_change.applications,
                            signInRiskLevels: policy_change.signInRiskLevels,
                            locations: policy_change.locations,
                            state,
                            includeRoles,
                            excludeRoles,
                            includeUsers,
                            excludeUsers,
                            includeGroups,
                            excludeGroups
                        }
                    )
                ));


            if (includeGuestsOrExternalUsers) {

                updated_policy.conditions.users['includeGuestsOrExternalUsers'] = {};
                updated_policy.conditions.users['includeGuestsOrExternalUsers'].guestOrExternalUserTypes = includeGuestsOrExternalUsers;

            } else {
                updated_policy.conditions.users['includeGuestsOrExternalUsers'] = null;

            }

            if (excludeGuestsOrExternalUsers) {
                updated_policy.conditions.users['excludeGuestsOrExternalUsers'] = {};
                updated_policy.conditions.users['excludeGuestsOrExternalUsers'].guestOrExternalUserTypes = excludeGuestsOrExternalUsers;
            } else {
                updated_policy.conditions.users['excludeGuestsOrExternalUsers'] = null;
            }


            return updateConditionalAccessPolicy({ _tenant, policy: { id: policy.id, ...updated_policy } });
        }
    }

}

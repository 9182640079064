import { createAction, props } from '@ngrx/store';
import { Baseline } from './model';

export const loadBaselines = createAction(
    '[Report Baseline] Load',
    props<{ _tenant: string }>()
);

export const loadBaselinesSuccess = createAction(
    '[Report Baseline] Load Success',
    props<{ _tenant: string, data: Baseline[] }>()
);

export const loadBaselinesFailure = createAction(
    '[Report Baseline] Load Failure',
    props<{ _tenant: string, error: any }>()
);





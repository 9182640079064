import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AlertItem } from './model';
import * as AlertActions from './actions';
import { initialStatus, Status } from '../../status.interface';
import { DateTime } from 'luxon';

export const featureKey = 'AlertItems';

export function getDates(start: string, end: string): string[] {

    let startDate = DateTime.fromISO(start);
    const endDate = DateTime.fromISO(end);
    const dateArray = [];

    while (startDate <= endDate) {
        const utc = startDate.toUTC().toISODate();
        dateArray.push(utc);
        startDate = startDate.plus({ days: 1 });
    }

    return dateArray;
}


export interface State extends EntityState<AlertItem>, Status {
    fetchedDates: string[]
}

const selectId = (alert: AlertItem) => `${alert.tenant_id}-${alert.timestamp_source}`;

export const adapter: EntityAdapter<AlertItem> = createEntityAdapter<AlertItem>({
    selectId,
    sortComparer: (a, b) => b.timestamp.localeCompare(a.timestamp)
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    ...initialStatus,
    fetchedDates: []
});

function updateFetchedDates(state: State, action: { _tenant: string, start: string, end: string, alerts: AlertItem[] }) {
    const dates = getDates(action.start, action.end);
    let fetchedDates = state.fetchedDates;
    for (const date of dates) {
        if (!fetchedDates.includes(date)) {
            fetchedDates.push(date);
        }
    }
    fetchedDates = fetchedDates.sort();
    return { ...state, fetchedDates, loaded: true };
}

export const reducer = createReducer(
    initialState,
    on(AlertActions.fetchAlerts,
        (state, action) => ({ ...state, loading: true })),
    on(AlertActions.fetchAlertsSuccess,
        (state, action) => updateFetchedDates(state, action)
    ),
    on(AlertActions.fetchAlertsFailure,
        (state, action) => ({ ...state, loaded: false, loading: false, error: action.error })
    ),
    on(AlertActions.updateAlertsCursor,
        (state, action) => ({ ...state, cursor: action.date.includes('T') ? action.date.split('T')[0] : action.date })
    ),
    on(AlertActions.addAlert,
        (state, action) => adapter.addOne(action.alert, state)
    ),
    on(AlertActions.upsertAlert,
        (state, action) => adapter.upsertOne(action.alert, state = { ...state, loaded: true })
    ),
    on(AlertActions.addAlerts,
        (state, action) => adapter.addMany(action.alerts, state)
    ),
    on(AlertActions.upsertAlerts,
        (state, action) => adapter.upsertMany(action.alerts, state = { ...state, loaded: true, loading: false })
    ),
    on(AlertActions.updateAlert,
        (state, action) => adapter.updateOne(action.alert, state)
    ),
    on(AlertActions.updateAlerts,
        (state, action) => adapter.updateMany(action.alerts, state)
    ),
    on(AlertActions.deleteAlert,
        (state, action) => adapter.removeOne(selectId(action.alert), state)
    ),
    on(AlertActions.deleteAlerts,
        (state, action) => adapter.removeMany(action.ids, state)
    ),
    on(AlertActions.clearAlerts,
        state => adapter.removeAll(state)
    ),
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectFetchedDates = (state: State) => state.fetchedDates;

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { initialStatus, Status } from 'src/app/stores/status.interface';
import { CwmConfig } from '../integrations/cwm/model';
import { DattoConfig } from '../integrations/datto/model';
import { HaloConfig } from './../integrations/halo/model';
import * as MspActions from './actions';
import { Msp } from './model';

export const featureKey = 'msp';

export interface State extends Status {
    data: Msp | null,
    showStonlyTrial: boolean,
}

export const adapter: EntityAdapter<Msp> = createEntityAdapter<Msp>({});

export const initialState: State = adapter.getInitialState({
    data: null,
    showStonlyTrial: false,
    ...initialStatus
});

function updateHaloConfig(state: State, data: HaloConfig): State {
    const updated: State = Object.assign({}, state, {
        data: { ...state.data, halo_config: JSON.parse(JSON.stringify(data)) },
        updating: false,
        error: false
    });
    return updated;
}

function updateDattoConfig(state: State, data: DattoConfig): State {
    const updated: State = Object.assign({}, state, {
        data: { ...state.data, datto_config: JSON.parse(JSON.stringify(data)) },
        updating: false,
        error: false
    });
    return updated;
}

function updateCwmConfig(state: State, data: CwmConfig): State {
    const updated: State = Object.assign({}, state, {
        data: { ...state.data, cwm_config: JSON.parse(JSON.stringify(data)) },
        updating: false,
        error: false
    });
    return updated;
}

function update(state: State, data: Partial<Msp>): State {
    const updated: State = Object.assign({}, state, {
        data: { ...state.data, ...data },
        updating: false,
        error: false
    });
    return updated;
}

function updateCwmConnection(state: State): State {
    const updated: State = Object.assign({}, state, {
        data: { ...state.data, cwm: true },
        updating: false,
        error: false
    });
    return updated;
}

function updateDattoConnection(state: State): State {
    const updated: State = Object.assign({}, state, {
        data: { ...state.data, datto: true },
        updating: false,
        error: false
    });
    return updated;
}

function updateHaloConnection(state: State): State {
    const updated: State = Object.assign({}, state, {
        data: { ...state.data, halo: true },
        updating: false,
        error: false
    });
    return updated;
}

function deleteMspHaloConnection(state: State): State {
    const deleted: State = Object.assign({}, state, {
        data: { ...state.data, halo: false },
        updating: false,
        error: false
    });
    return deleted;
}

function deleteMspDattoConnection(state: State): State {
    const deleted: State = Object.assign({}, state, {
        data: { ...state.data, datto: false },
        updating: false,
        error: false
    });
    return deleted;
}

function deleteMspCwmConnection(state: State): State {
    const deleted: State = Object.assign({}, state, {
        data: { ...state.data, cwm: false },
        updating: false,
        error: false
    });
    return deleted;
}

function deleteTenant(state: State, id: string): State {
    const updated: State = Object.assign({}, state, {
        data: { ...state.data }
    });
    updated.data.tenants = updated.data.tenants.filter(t => t.id != id);
    return updated;
}

export const reducer = createReducer(
    initialState,
    on(MspActions.loadMsp, (state) => ({ ...state, loading: true })),
    on(MspActions.loadMspSuccess, (state, { data }) => ({ ...state, data, loading: false, loaded: true })),
    on(MspActions.loadMspFailure, (state, { error }) => ({ ...state, loading: false, error })),

    on(MspActions.updateMspTenants, (state, { tenants }) => {
        return Object.assign({}, state, {
            data: { ...state.data, tenants },
            updating: true,
            error: false
        });
    }),
    on(MspActions.updateMspTenantsSuccess, (state, { tenants }) => {
        return Object.assign({}, state, {
            updating: false,
            error: false
        });
    }),
    // on(MspActions.updateMspTenants, (state, { tenants }) => ({ ...state, updating: true, error: false })), // change it
    // on(MspActions.updateMspTenantsSuccess, (state, { tenants }) => ({ ...state, updating: false, error: false })), // change it

    on(MspActions.updateMsp, (state, { data }) => update(state, data)),

    on(MspActions.deleteMspTenantSuccess, (state, { tenant }) => deleteTenant(state, tenant)),

    on(MspActions.showStonlyTrial, (state) => ({ ...state, showStonlyTrial: true })),

    // connectWise connection
    on(MspActions.updateMspCwmConnection, (state, { data }) => ({ ...state, updating: true, error: false })),
    on(MspActions.updateMspCwmConnectionSuccess, (state) => updateCwmConnection(state)),
    on(MspActions.updateMspCwmConnectionFailure, (state, { error }) => ({ ...state, error, updating: false })),
    on(MspActions.deleteMspCwmConnection, (state) => ({ ...state, updating: true, error: false })),
    on(MspActions.deleteMspCwmConnectionSuccess, (state) => deleteMspCwmConnection(state)),
    on(MspActions.deleteMspCwmConnectionFailure, (state, { error }) => ({ ...state, error, updating: false })),

    // connectWise config
    on(MspActions.updateMspCwmConfig, (state, { data }) => ({ ...state, updating: true, error: false })),
    on(MspActions.updateMspCwmConfigSuccess, (state, { data }) => updateCwmConfig(state, data)),
    on(MspActions.updateMspCwmConfigFailure, (state, { error }) => ({ ...state, error, updating: false })),

    // datto connection
    on(MspActions.updateMspDattoConnection, (state, { data }) => ({ ...state, updating: true, error: false })),
    on(MspActions.updateMspDattoConnectionSuccess, (state) => updateDattoConnection(state)),
    on(MspActions.updateMspDattoConnectionFailure, (state, { error }) => ({ ...state, error, updating: false })),
    on(MspActions.deleteMspDattoConnection, (state) => ({ ...state, updating: true, error: false })),
    on(MspActions.deleteMspDattoConnectionSuccess, (state) => deleteMspDattoConnection(state)),
    on(MspActions.deleteMspDattoConnectionFailure, (state, { error }) => ({ ...state, error, updating: false })),

    // datto config
    on(MspActions.updateMspDattoConfig, (state, { data }) => ({ ...state, updating: true, error: false })),
    on(MspActions.updateMspDattoConfigSuccess, (state, { data }) => updateDattoConfig(state, data)),
    on(MspActions.updateMspDattoConfigFailure, (state, { error }) => ({ ...state, error, updating: false })),

    // halo Connection
    on(MspActions.updateMspHaloConnection, (state, { data }) => ({ ...state, updating: true, error: false })),
    on(MspActions.updateMspHaloConnectionSuccess, (state) => updateHaloConnection(state)),
    on(MspActions.updateMspHaloConnectionFailure, (state, { error }) => ({ ...state, error, updating: false })),
    on(MspActions.deleteMspHaloConnection, (state) => ({ ...state, updating: true, error: false })),
    on(MspActions.deleteMspHaloConnectionSuccess, (state) => deleteMspHaloConnection(state)),
    on(MspActions.deleteMspHaloConnectionFailure, (state, { error }) => ({ ...state, error, updating: false })),

    // halo Config
    on(MspActions.updateMspHaloConfig, (state) => ({ ...state, updating: true, error: false })),
    on(MspActions.updateMspHaloConfigSuccess, (state, { data }) => updateHaloConfig(state, data)),
    on(MspActions.updateMspHaloConfigFailure, (state, { error }) => ({ ...state, error, updating: false }))

);

export const selectData = (state: State) => state.data;
export const selectLoaded = (state: State) => state.loaded;
export const selectLoading = (state: State) => !state.loaded;
export const selectUpdating = (state: State) => state.updating;
export const selectError = (state: State) => state.error;

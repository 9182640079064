import { Templates_CRUD } from "src/app/routes/msp-routes";
import { BASELINES_CRUD } from "src/app/routes/tenant-routes";
import { RoleFeature } from "src/app/stores/root/octiga/session/model";

type TenantFeaturesResourceMapperI = {
    [key in RoleFeature] : ((tenant: string)=> string) []
}

export const FeaturesResourceMapper : Partial< TenantFeaturesResourceMapperI >= {
    "tenant-baselines": [
        BASELINES_CRUD
    ],

    "msp-baseline-templates": [
        Templates_CRUD
    ],



}
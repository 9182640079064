import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { client } from 'src/app/stores/client';
import { ExoTransportRule } from 'src/app/stores/client/powershell/exo/transport-rule/model';
import { isValidEmail } from 'src/app/utils/custom-validators';
import { BlockAutoForwardingIdentityName, NoTransportRuleSchema, setTransportRulePolicySchema } from '.';
import { BaseComponent } from '../../base/base.component';

const scopes = [
    { text: 'In Organization', value: 'InOrganization', default: true },
    { text: 'Not In Organization', value: 'NotInOrganization' },
    // { text: 'External Partner', value: 'ExternalPartner' },
    // { text: 'External Non Partner', value: 'ExternalNonPartner' },
];

// const messageTypeMatchesLookup = [
//     { text: 'Automatic Reply', value: 'OOF' },
//     { text: 'Auto-Forward', value: 'AutoForward' },
//     { text: 'Encrypted', value: 'Encrypted' },
//     { text: 'Calendaring', value: 'Calendaring' },
//     { text: 'Permission Controlled', value: 'PermissionControlled' },
//     { text: 'Voice Mail', value: 'Voicemail' },
//     { text: 'Signed', value: 'Signed' },
//     { text: 'Approval Request', value: 'ApprovalRequest' },
//     { text: 'Read Receipt', value: 'ReadReceipt' },
// ]

// const messageTypeMatchesLookupMap = {
//     "OOF" :  "Automatic Reply",
//     "AutoForward" : "Auto-Forward"  ,
//     "Encrypted" :  "Encrypted" ,
//     "Calendaring" :  "Calendaring" ,
//     "PermissionControlled" : "Permission Controlled" ,
//     "Voicemail" : "Voice Mail" ,
//     "Signed" :  "Signed" ,
//     "ApprovalRequest" : "Approval Request" ,
//     "ReadReceipt": "Read Receipt"  ,

// }


function RejectMessageEnhancedStatusCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if ((+control.value >= 900 && +control.value <= 999) || +control.value === 1) {

            return null;
        } else {
            return {
                invalid: true
            };
        }
    };
}

function getRejectMessageEnhancedStatusCodeNumber(value: string) {
    if (!value) return null;
    return value.split('.')[2];
}

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss']
})

// TODO add state

export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    scopes = scopes;
    policy: ExoTransportRule;
    @Input() data: ExoTransportRule[];
    isLoaded: boolean;

    users$: Observable<Array<any>>;
    ExceptIfSentToError: string[] = [];
    RejectMessageEnhancedStatusCodeMismatch: boolean;
    ExceptIfRecipientDomainIsMismatch: boolean;
    ExceptIfSentToMismatch: boolean;

    constructor(private fb: FormBuilder, private store: Store<any>) {
        super();
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.policy = this.data.find(res => res.Identity === BlockAutoForwardingIdentityName);
        this.RejectMessageEnhancedStatusCodeMismatch = !!this.errors.find(res => res.instancePath.includes('RejectMessageEnhancedStatusCode') || res.schemaPath.includes('properties/RejectMessageEnhancedStatusCode'));
        this.ExceptIfRecipientDomainIsMismatch = !!this.errors.find(res => res.instancePath.includes('ExceptIfRecipientDomainIs') || res.schemaPath.includes('properties/ExceptIfRecipientDomainIs'));
        this.ExceptIfSentToMismatch = !!this.errors.find(res => res.instancePath.includes('ExceptIfSentTo') || res.schemaPath.includes('ExceptIfSentTo'));

    }


    ngOnInit(): void {
        this.users$ = this.store.select(client(this.tenant_id).graph.users.withMailbox).pipe(
            map((users) => users.map(re => ({ id: re['id'], name: re['mail'] })))
        );

        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        this.policy = this.data[0];

        this.form = this.initForm(schema);


        this.errorChecker();

        this.RejectMessageEnhancedStatusCodeMismatch = !!this.errors.find(res => res.instancePath.includes('RejectMessageEnhancedStatusCode') || res.schemaPath.includes('properties/RejectMessageEnhancedStatusCode'));
        this.ExceptIfRecipientDomainIsMismatch = !!this.errors.find(res => res.instancePath.includes('ExceptIfRecipientDomainIs') || res.schemaPath.includes('properties/ExceptIfRecipientDomainIs'));
        this.ExceptIfSentToMismatch = !!this.errors.find(res => res.instancePath.includes('ExceptIfSentTo') || res.schemaPath.includes('ExceptIfSentTo'));

        this.observeFormValueChanges(schema);
        this.isLoaded = true;
    }


    observeFormValueChanges(schema): void {

        this.form .valueChanges.subscribe(res => {
            const value = this.form.getRawValue(); // including disabled values

            if (value.State === 'delete') {
                schema = JSON.parse(JSON.stringify(NoTransportRuleSchema()));

                this.baselineErrorChange.next({
                    remediate: false,
                    save: false
                });

            } else {
                schema = JSON.parse(JSON.stringify(setTransportRulePolicySchema(value)));
                this.errorChecker();
            }


            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema))
            }
            );
        });
    }



    errorChecker() {
        const ExceptIfSentTo = this.form.get('ExceptIfSentTo').value || [];
        this.ExceptIfSentToError = ExceptIfSentTo.filter(item => !isValidEmail(item)); // TODO add this to hosted outbound spam filter policy

        if(this.form.value.State ==='delete'){
            this.baselineErrorChange.next({
                remediate: false,
                save: false
            });

        }else if (
            this.form.hasError('max', 'Priority') ||
            this.form.hasError('required', 'RejectMessageReasonText') ||
            this.form.hasError('email', 'GenerateIncidentReport') ||
            this.ExceptIfSentToError?.length > 0 ||
            this.form.get('RejectMessageEnhancedStatusCode').invalid
        ) {
            this.baselineErrorChange.next({
                remediate: true,
                save: true
            });
        } else {
            this.baselineErrorChange.next({
                remediate: false,
                save: false
            });
        }
    }

    initForm(schema): FormGroup {
        return this.fb.group({
            State: [schema?.contains?.properties?.State?.const || 'delete'],
            Identity: [BlockAutoForwardingIdentityName],
            FromScope: [schema?.contains?.properties?.FromScope?.const || 'InOrganization'],
            SentToScope: [schema?.contains?.properties?.SentToScope?.const || 'InOrganization'],

            RejectMessageEnhancedStatusCode: [getRejectMessageEnhancedStatusCodeNumber(schema?.contains?.properties?.RejectMessageEnhancedStatusCode?.const), [RejectMessageEnhancedStatusCodeValidator()]],
            RejectMessageReasonText: [schema?.contains?.properties?.RejectMessageReasonText?.const, Validators.required],
            ExceptIfSentTo: [schema?.contains?.properties?.ExceptIfSentTo.items?.enum || []],
            ExceptIfRecipientDomainIs: [schema?.contains?.properties?.ExceptIfRecipientDomainIs.items?.enum || []],
            GenerateIncidentReport: [schema?.contains?.properties?.GenerateIncidentReport?.const, Validators.email],

            Priority: [schema?.contains?.properties?.Priority?.const || 0, Validators.max(this.meta_data?.length)]
        });
    }




    updateField(field: string, value: any) {
        this.form.get(field).patchValue(value);

    }

    get formValue() {
        return this.form.getRawValue();
    }


}

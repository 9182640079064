import { Organization } from '@microsoft/microsoft-graph-types-beta';
import { createReducer, createSelector, on } from '@ngrx/store';
import * as GraphOrganizationActions from './actions';

export const featureKey = 'graphOrganization';

export interface State {
    data: Organization
    error: any
    loaded: boolean
}


export const initialState: State = {
    data: null,
    loaded: false,
    error: false,
};


export const reducer = createReducer(
    initialState,
    on(GraphOrganizationActions.loadOrganizationSuccess, (state, { data }) => ({ ...state, data, loaded: true })),
    on(GraphOrganizationActions.loadOrganizationFailure, (state, { error }) => ({ ...state, error }))
);

export const selectOrganization = (state: State) => state.data;
export const selectError = (state: State) => state.error;

export const getOrganization = createSelector(selectOrganization, (data) => data);
export const getError = createSelector(selectError, (error) => error);

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { StripeService } from '../../services/stripe.service';
import { previousStep, nextStep } from '../../store/actions/stepper.actions';
import * as fromBilling from '../../store/reducers';
import { SubscriptionPlan } from '../subscription-plan/subscription-plan.component';
import { setPaymentMethod, updateCustomer } from '../../store/actions/billing.actions';

@Component({
    selector: 'billing-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

    plan: SubscriptionPlan = {
        title: 'Premium',
        description: 'Unlimited Octiga Cloud Security',
        color: '#00b3fa',
        price: '€2 / Mailbox / Month',
        features: ['Incident Response', 'Event History', 'Email Alerts', 'Auto Response'],
        disabled: false
    };

    card: stripe.elements.Element;
    info: { email: string };

    constructor(
        private store: Store<fromBilling.State>,
        public stripe: StripeService
    ) { }

    ngOnInit(): void {

    }

    public back() {
        this.store.dispatch(previousStep());
    }

    cardChanges(card: stripe.elements.Element | false) {
        if (card) this.card = card;
    }

    infoChanges(info: { email: string }) {
        this.info = info;
    }

    async submit() {
        this.store.dispatch(nextStep());
        const card = Object.assign({}, this.card);
        const result = await this.stripe.createPaymentMethod(card);
        const paymentMethodId = result.paymentMethod.id;
        this.store.dispatch(setPaymentMethod({ data: { paymentMethodId } }));
        this.store.dispatch(updateCustomer({ data: this.info }));
    }
}

import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';

declare let Stripe: stripe.StripeStatic;

@Injectable({
    providedIn: 'root',
})
export class StripeService {

    public stripe = Stripe(environment.stripe.pk);

    constructor() { }

    public createPaymentMethod(card: stripe.elements.Element) {
        return this.stripe.createPaymentMethod({ type: 'card', card });
    }

}

<div class="wrapper" (click)="navigate()">
    <div class="session">
        <div class="image">
            <img class="icon" src="./assets/img/dashboard/change-history.png" />
            <div class="middle">
                <div class="text">Click to View</div>
            </div>
            <!-- <app-severity-badges [severity]="pwned$ | async"></app-severity-badges> -->
        </div>
        <!-- <span class="mat-h2">Changes Log</span> -->
    </div>
</div>
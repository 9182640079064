import { IdentitySecurityDefaultsEnforcementPolicy } from '@microsoft/microsoft-graph-types-beta';
import { updateSecurityDefaults } from 'src/app/stores/client/graph/security-defaults/actions';
export interface ISecurityDefaultsSchema {
    properties: {
        isEnabled: {
            const: boolean;
        };
    };
}

export function Remediate(
    _tenant: string,
    policy: IdentitySecurityDefaultsEnforcementPolicy,
    schema: ISecurityDefaultsSchema
) {
    return updateSecurityDefaults({
        _tenant,
        isEnabled: schema.properties.isEnabled.const,
    });
}

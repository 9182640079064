import { startWith } from 'rxjs';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MobileDeviceMailboxPolicy } from 'src/app/stores/client/powershell/exo/mobile-device-mailbox-policy/model';
import { BaseComponent } from '../../base/base.component';

function getMinutes(oldString: string): string {
    if (oldString === 'Unlimited') {
        return oldString;
    } else if (oldString === '01:00:00') {
        return '60';
    }
    else {
        return oldString.substring(3, 5);
    }
}


function getDays(oldString: string): string {
    if (oldString === 'Unlimited') {
        return oldString;
    }
    else {
        return oldString.substring(0, oldString.indexOf('.'));
    }
}
@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],

})

export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    defaultPolicy: MobileDeviceMailboxPolicy;
    @Input() data: MobileDeviceMailboxPolicy[];
    MinPasswordLengthIgnored: boolean;

    constructor(private fb: FormBuilder, private ref: ChangeDetectorRef) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.defaultPolicy = this.data.find(res => res.IsDefault);
    }

    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));

        this.defaultPolicy = this.data.find(res => res.IsDefault);
        this.form = this.initForm(schema);
        this.MinPasswordLengthIgnored = !this.MinPasswordLength;

        this.observeFormValueChanges(schema);
    }

    observeFormValueChanges(schema): void {
        
        this.form.valueChanges.pipe(startWith(this.form.value)).subscribe(res => {
            schema.contains.properties.PasswordHistory.const = +res.PasswordHistory;
            schema.contains.properties.PasswordExpiration.const = this.setDaysStamp(res.PasswordExpiration);
            schema.contains.properties.MinPasswordComplexCharacters.const = +res.MinPasswordComplexCharacters;
            
            if(!res.MinPasswordLength){
                 
                schema.contains.properties.MinPasswordLength = {};
                schema.contains.properties.MinPasswordLength.type = 'null';
                // schema.contains.properties.MinPasswordLength.const = +res.MinPasswordLength

                // if(index > -1){
                //     schema.contains.required.splice(index, 1)
                // }
                
                
            }else{
                schema.contains.properties.MinPasswordLength = {};
                schema.contains.properties.MinPasswordLength.type = 'number';
                schema.contains.properties.MinPasswordLength.const = +res.MinPasswordLength;
            }

            schema.contains.properties.MaxInactivityTimeLock.const = this.setTimeStamp(res.MaxInactivityTimeLock);
            schema.contains.properties.RequireDeviceEncryption.const = res.RequireDeviceEncryption;
            schema.contains.properties.DeviceEncryptionEnabled.const = res.DeviceEncryptionEnabled;
           
            
            // for fixing issue purpose
            schema.contains.properties.AlphanumericPasswordRequired.const = res.AlphanumericPasswordRequired;
           

            schema.contains.properties.AllowSimplePassword.const = res.AllowSimplePassword;
            schema.contains.properties.PasswordEnabled.const = res.PasswordEnabled;
           
            if( (+res.MaxInactivityTimeLock > 60 && res.MaxInactivityTimeLock !== 'Unlimited') || (+res.PasswordExpiration > 730 && res.PasswordExpiration !== 'Unlimited')){
                this.baselineErrorChange.next({remediate: true, save: true});

            }else{
                this.baselineErrorChange.next({remediate: false, save: false});
            }
            
            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema))
            });
        });
    }

    initForm(schema): FormGroup {
        return this.fb.group({
            Identity: [schema?.contains?.properties?.Identity?.const],
            PasswordHistory: [schema?.contains?.properties?.PasswordHistory?.const],
            PasswordExpiration: [getDays(schema?.contains?.properties?.PasswordExpiration?.const)],
            MinPasswordComplexCharacters: [schema?.contains?.properties?.MinPasswordComplexCharacters?.const],
            MinPasswordLength: [schema?.contains?.properties?.MinPasswordLength?.const],
            MaxInactivityTimeLock: [getMinutes(schema?.contains?.properties?.MaxInactivityTimeLock?.const)],
            RequireDeviceEncryption: [schema?.contains?.properties?.RequireDeviceEncryption?.const],
            DeviceEncryptionEnabled: [schema?.contains?.properties?.DeviceEncryptionEnabled?.const],
            AlphanumericPasswordRequired: [schema?.contains?.properties?.AlphanumericPasswordRequired?.const],
            AllowSimplePassword: [schema?.contains?.properties?.AllowSimplePassword?.const],
            PasswordEnabled: [schema?.contains?.properties?.PasswordEnabled?.const]
        });
    }


    setTimeStamp(minutes): string {
        if (minutes === 'Unlimited') {
            return minutes;
        } else if (minutes === '60') {
            return '01:00:00';
        } else {
            if( +minutes <10){
                return `00:0${minutes}:00`;
            }else{
                return `00:${minutes}:00`;
            }
        }
    }

    setDaysStamp(days): string {
        if (days === 'Unlimited') {
            return days;
        }
        else {
            return `${days}.00:00:00`;
        }
    }

    get PasswordExpiration(): string {
        return this.form.get('PasswordExpiration').value;
    }
    
    set PasswordExpiration(value: string) {
        this.form.get('PasswordExpiration').patchValue(value);
    }

    get MaxInactivityTimeLock(): string {
        return this.form.get('MaxInactivityTimeLock').value;
    }

    set MaxInactivityTimeLock(value: string) {
        this.form.get('MaxInactivityTimeLock').patchValue(value);
    }

    get MinPasswordLength(): string {
        return this.form.get('MinPasswordLength').value;
    }
    set MinPasswordLength(value: string) {
        this.form.get('MinPasswordLength').patchValue(value);
    }

    get formValue() {
        return this.form.value;
    }

    onToggleMaxInactivityTimeLock(e: MatSlideToggleChange) {
        this.MaxInactivityTimeLock = e.checked ? 'Unlimited' : '10';
    }

    onTogglePasswordExpiration(e: MatSlideToggleChange) {
        this.PasswordExpiration = e.checked ? 'Unlimited' : '10';
    }

    onToggleMinPasswordLength(e: MatSlideToggleChange) {
        this.MinPasswordLength = e.checked ? null : '8';
        this.MinPasswordLengthIgnored = !this.MinPasswordLength;
    
    }
}
import { createAction, props } from '@ngrx/store';
import { CsOAuthConfiguration } from './model';

export const fetchCsOAuthConfiguration = createAction(
    '[Client/Pwsh/Teams] Fetch CsOAuthConfiguration',
    props<{ _tenant: string }>()
);

export const fetchCsOAuthConfigurationSuccess = createAction(
    '[Client/Pwsh/Teams] Fetch CsOAuthConfiguration Success',
    props<{ _tenant: string, data: CsOAuthConfiguration }>()
);

export const fetchCsOAuthConfigurationFailure = createAction(
    '[Client/Pwsh/Teams] Fetch CsOAuthConfiguration Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateCsOAuthConfiguration = createAction(
    '[Client/Pwsh/Teams] Update CsOAuthConfiguration',
    props<{ _tenant: string, data: Partial<CsOAuthConfiguration> }>()
);

export const updateCsOAuthConfigurationSuccess = createAction(
    '[Client/Pwsh/Teams] Update CsOAuthConfiguration Success',
    props<{ _tenant: string, data: Partial<CsOAuthConfiguration> }>()
);

export const updateCsOAuthConfigurationFailure = createAction(
    '[Client/Pwsh/Teams] Update CsOAuthConfiguration Failure',
    props<{ _tenant: string, error: any }>()
);

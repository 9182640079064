import { DirectoryRole } from '@microsoft/microsoft-graph-types-beta';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Status, initialStatus } from 'src/app/stores/status.interface';
import * as RoleActions from './actions';

export const featureKey = 'directoryRoles';

export interface State extends EntityState<DirectoryRole>, Status { }

export const adapter: EntityAdapter<DirectoryRole> = createEntityAdapter();

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    on(RoleActions.loadDirectoryRolesSuccess,
        (state, action) => adapter.setAll(action.roles, { ...state, loaded: true })
    ),
    on(RoleActions.loadDirectoryRolesFailure,
        (state, action) => ({ ...state, loaded: false, error: action.error }))
);

export function byDisplayName(state: State, displayName: string) {
    for (const id of state.ids) {
        if (state.entities[id].displayName === displayName) {
            return state.entities[id];
        }
    }
    return undefined;
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

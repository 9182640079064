<sway-cap-baseline [baselineTemplateItem]="baselineTemplateItem" *ngIf="baseline && schema$" [readOnly]="readOnly"  [tenant_id]="tenant_id"
    [form]="form" [baseline]="baseline" [schema$]="schema$" [schemaFunc]="schemaFunc" [policy$]="policy$"
    (baselineErrorChange)="onBaselineErrorChange($event)">


    <ng-container *ngTemplateOutlet="content"></ng-container>

</sway-cap-baseline>


<ng-template #content>
    <ng-container *ngIf="policy$ | async as policy">
        <ng-container *ngIf="policy?.id">
            <tr>
                <td>
                    Grant
                </td>

                <td>

                    <ng-container *ngFor="let item of policy?.grantControls?.builtInControls">
                        {{ item }}
                    </ng-container>
                </td>



                <td>
                    block
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.grantControls?.builtInControls[0] === 'block' && policy?.grantControls?.builtInControls?.length == 1)">
                    </sway-baseline-status>
                </td>
            </tr>


            <tr>
                <td>
                    Client Apps
                </td>

                <td>
                    <div *ngFor="let item of policy?.conditions?.clientAppTypes">
                        {{ item }}
                    </div>
                </td>



                <td>
                    <div>
                        Exchange ActiveSync clients
                    </div>

                    <div>
                        Other clients
                    </div>
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.conditions?.clientAppTypes?.includes('exchangeActiveSync') && policy?.conditions?.clientAppTypes?.includes('other')  && policy?.conditions?.clientAppTypes?.length == 2)">
                    </sway-baseline-status>
                </td>
            </tr>

            <tr >
                <td>
                    Include Applications
                </td>
        
                <td >
                    <div *ngFor=" let item of policy.conditions?.applications?.includeApplications">
                        {{item}}
                    </div>
                </td>
        
        
        
                <td>
                    All
                </td>
        
                <td>
                    <sway-baseline-status [hasError]="!(policy?.conditions?.applications?.includeApplications?.[0] === 'All' && policy?.conditions?.applications?.includeApplications?.length === 1)" > </sway-baseline-status>
                </td>
            </tr>

        </ng-container>
    </ng-container>
</ng-template>
<mat-error *ngIf="isSecurityDefaultsEnabled ">
    You can't Add or Enable MFA while your Security Defaults Policy is enabled. Please disable it and try again.
</mat-error>

<form *ngIf="isLoaded" [formGroup]="form" class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>


        <tr>
            <td>
                Policy Name
            </td>

            <td>
                <ng-container *ngIf=" MFA?.displayName">

                    {{ MFA?.displayName }}
                </ng-container>

                <ng-container *ngIf="!MFA?.displayName">
                    Policy Not Found
                </ng-container>
            </td>

            <td *ngIf="form.get('state').value =='delete'">
                No Policy
            </td>

            
            <td *ngIf="form.get('state').value !=='delete'">
                Enforce MFA for Admins (Octiga Conditional Access)
            </td>

            <td>
                <mat-icon>remove</mat-icon>
            </td>
        </tr>

        <tr>
            <td>
                Multi Factor Authentication
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <div class="field boolean">
                    <mat-form-field appearance="fill">
                        <mat-label>State</mat-label>
                        <mat-select formControlName="state">
                            <mat-option [value]="'enabled'">Enable</mat-option>
                            <mat-option [value]="'disabled'">Disable</mat-option>
                            <mat-option [value]="'delete'">No Policy (Delete)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </td>

            <td>
                <sway-baseline-status *ngIf="form.get('state').value =='delete'" [hasError]="!!MFA"></sway-baseline-status>

                <sway-baseline-status *ngIf="form.get('state').value !=='delete'" [hasError]="form.get('state').value !== MFA?.state"></sway-baseline-status>
            </td>
        </tr>

    </table>
</form>




<ng-template #validatorRef>
    <div class="validator">
        <div *ngIf="!MFA; else elseBlock">
            Policy Not Found
        </div>

        <ng-template #elseBlock>
            <div class="capitalize">

                {{MFA?.state }}

            </div>
        </ng-template>
    </div>
</ng-template>
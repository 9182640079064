import { createAction, props } from '@ngrx/store';
import { DirectoryRoleMembersModel } from './model';
import { User } from '@microsoft/microsoft-graph-types-beta';

export const loadDirectoryRoleMembers = createAction(
    '[Graph/Directory Role Members] Load',
    props<{ _tenant: string, roleTemplateId: string }>()
);

export const loadDirectoryRoleMembersSuccess = createAction(
    '[Graph/Directory Role Members] Load Success',
    props<{ _tenant: string, item: DirectoryRoleMembersModel }>()
);

export const loadDirectoryRoleMembersFailure = createAction(
    '[Graph/Directory Role Members] Load Failure',
    props<{ _tenant: string, error: any }>()
);

export const removeDirectoryRoleMember = createAction(
    '[Graph/Directory Role Members] Remove Member',
    props<{ _tenant: string, roleTemplateId: string, memberId: string }>()
);

export const removeDirectoryRoleMemberSuccess = createAction(
    '[Graph/Directory Role Members] Remove Member Success',
    props<{ _tenant: string, roleTemplateId: string, memberId: string }>()
);

export const removeDirectoryRoleMemberFailure = createAction(
    '[Graph/Directory Role Members] Remove Member Failure',
    props<{ _tenant: string, error: any }>()
);

export const addDirectoryRoleMember = createAction(
    '[Graph/Directory Role Members] Add Member',
    props<{ _tenant: string, roleTemplateId: string, memberId: string }>()
);

export const addDirectoryRoleMemberSuccess = createAction(
    '[Graph/Directory Role Members] Add Member Success',
    props<{ _tenant: string, roleTemplateId: string, memberId: string }>()
);

export const addDirectoryRoleMemberFailure = createAction(
    '[Graph/Directory Role Members] Add Member Failure',
    props<{ _tenant: string, error: any }>()
);

export const directoryRoleMembersLoaded = createAction(
    '[Graph/Directory Role Members] Loaded',
    props<{ _tenant: string }>()
);



export const assignRoles = createAction(
    '[Graph/Directory Role Members] assign Roles',
    props<{ _tenant: string, user: User, addRoles: Array<string>, removeRoles : Array<string> }>()
);

export const assignRolesSuccess = createAction(
    '[Graph/Directory Role Members] assign Roles Success',
    props<{ _tenant: string, user: User, addRoles: Array<string>, removeRoles : Array<string> }>()
);

export const assignRolesFailure = createAction(
    '[Graph/Directory Role Members] assign Roles Failure',
    props<{ _tenant: string, error: any }>()
);


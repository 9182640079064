<div class="container">
    <div class="profile-button" matRipple [matRippleCentered]="true" [style.background-color]="color"
        (menuOpened)="open = true" (menuClosed)="open = false" [matMenuTriggerFor]="appMenu"
        [ngClass]="{'active': open}" *ngIf="(session | async).isAuthenticated">
        <div class="icon">
            <mat-icon inline>account_circle</mat-icon>
        </div>
        <div class="text">
            <div class="name">{{showAccountName()}}</div>
        </div>
        <mat-icon class="dropDown">arrow_drop_down</mat-icon>
    </div>

    <mat-menu #appMenu="matMenu" class="menu" xPosition="before">
        <button mat-menu-item (click)="deleteSession()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</div>
import { createAction, props } from '@ngrx/store';

export const loadMspHaloConnection = createAction(
    '[Msp/API] Load Msp Halo Connection'
);
export const loadMspHaloConnectionSuccess = createAction(
    '[Msp/API] Load Msp Halo Connection Success',
    props<{ data: any }>()
);
export const loadMspHaloConnectionFailure = createAction(
    '[Msp/API] Load Msp Halo Connection Failure',
    props<{ error: any }>()
);

export const loadMspHaloConfig = createAction(
    '[Msp/API] Load Msp Halo Config'
);

export const loadMspHaloConfigSuccess = createAction(
    '[Msp/API] Load Msp Halo Config Success Config',
    props<{ data: any }>()
);

export const loadMspHaloConfigFailure = createAction(
    '[Msp/API] Load Msp Halo Config Failure Config',
    props<{ error: any }>()
);


import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, last, map } from 'rxjs/operators';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as ExoActions from './actions';
import { AntiPhishPolicy } from './model';

const EndPoint = '/api/microsoft/powershell/exo/anti-phish-policy';

@Injectable()
export class AntiPhishPolicyEffects {

    fetchExoAntiPhishPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.loadAntiPhishPolicy),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    retry(3000, 3, 'anti phishing  timeout'),
                    last(),
                    map((data: AntiPhishPolicy[]) => ExoActions.loadAntiPhishPolicySuccess({ _tenant, data })),
                    catchError((error) => of(ExoActions.loadAntiPhishPolicyFailure({ _tenant, error })))
                ))
        )
    );

    updateAntiMalwarePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.updateExoAntiPhishPolicy),
            mergeMap((action) => this.put(action._tenant, action.data)
                .pipe(
                    map((res) => ({ ...res[0] })),
                    map((data: AntiPhishPolicy) => ExoActions.updateExoAntiPhishPolicySuccess({ _tenant: action._tenant, data })),
                    catchError((error) => of(ExoActions.updateExoAntiPhishPolicyFailure({ _tenant: action._tenant, error: {message : error.message, trace: error.trace} })))
                ))
        )
    );

    private get(tenant: string) {
        return this.ajax.get(tenant, EndPoint);
    }

    private put(tenant: string, body: Partial<AntiPhishPolicy>): Observable<AntiPhishPolicy> {
        return this.ajax.put(tenant, EndPoint, body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { selectSession } from 'src/app/stores/root.store';

@Injectable({
    providedIn: 'root'
})
export class MspGuard implements CanActivate, CanActivateChild {

    constructor(private store: Store<any>, private router: Router) { }

    private isAuthenticatedOrRedirect(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.pipe(select(selectSession))
            .pipe(
                filter(state => !state.loading),
                map(data => {
                    if (!data.isAuthenticated || !data.session?.msp_id) {
                        return this.router.createUrlTree(['/auth/microsoft'], { queryParams: { return_url: window.location.href } });
                    } else {
                        return true;
                    }
                })
            );
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.isAuthenticatedOrRedirect(next, state);
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.isAuthenticatedOrRedirect(next, state);
    }

}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Classifier } from 'microsoft-events';
import {
    Classification,
    MicrosoftEvent,
} from 'microsoft-events/dist/interfaces/meta.interface';
import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as fromMsp from '../modules/msp/store';
import { loadASNs } from '../modules/msp/store/asn/actions';
import { loadEventRules } from '../modules/msp/store/event-rules/actions';
import { loadGeos } from '../modules/msp/store/geo/actions';
import { loadIps } from '../modules/msp/store/ip/actions';
import { client } from '../stores/client';
import { loadDomains } from '../stores/client/graph/domains/actions';
import { loadGraphUsers } from '../stores/client/graph/user/user.actions';

@Injectable()
export class ClassificationService {

    classifier: Classifier;

    private ready$: ReplaySubject<any> = new ReplaySubject(1);

    constructor(private store: Store) { }

    private dispatcher(_tenant: string) {
        this.store.dispatch(loadGeos());
        this.store.dispatch(loadASNs());
        this.store.dispatch(loadIps());
        this.store.dispatch(loadDomains({ _tenant }));
        this.store.dispatch(loadGraphUsers({ _tenant }));
        this.store.dispatch(loadEventRules());
    }

    public classify(event: MicrosoftEvent): Observable<Classification> {
        return this.ready$.pipe(map(() => this.classifier.run(event)));
    }

    init(tenant_id: string) {

        this.dispatcher(tenant_id);

        return combineLatest([
            this.store.select(fromMsp.selectEventRulesAll),
            this.store.select(fromMsp.selectMspGeos),
            this.store.pipe(select(client(tenant_id).graph.users.all)),
            this.store.pipe(select(client(tenant_id).graph.domains.all))
        ])
            .subscribe(([event_rules, geos, users, domains]) => {

                const rules = [];


                for (const rule of event_rules) {
                    const copy = { ...rule };
                   
                    copy.geo = copy.input_geo.map(id => {
                        const geo = geos.find(geo => geo.id === id);
                       
                        if(geo){ // TODO, karim
                            return { center: { lat: geo.lat, lng: geo.lng }, radius: geo.radius };
                        }
                    });

                    rules.push(copy);
                }

                this.classifier = new Classifier(
                    tenant_id,
                    environment.appId,
                    users,
                    domains,
                    rules
                );

                this.ready$.next(null);
            });
    }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, last, map, mergeMap } from 'rxjs/operators';
import { Recipient } from 'src/app/interfaces/powershell/exo/recipient.interface';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as ExoActions from './actions';


@Injectable()
export class RecipientEffects {

    loadRecipients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.loadRecipients),
            mergeMap(({ _tenant }) => this.ajax.get(_tenant, '/api/microsoft/powershell/exo/recipient')
                .pipe(
                    retry(3000, 3, 'exo timeout'),
                    last(),
                    map((data: Recipient[]) => ExoActions.loadRecipientsSuccess({ _tenant, data })),
                    catchError((error) => of(ExoActions.loadRecipientsFailure({ _tenant, error })))
                ))
        )
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}

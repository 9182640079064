import { createAction, props } from '@ngrx/store';
import { MalwareFilterPolicy } from 'src/app/interfaces/powershell/exo/malware-filter-policy.interface';

export const loadMalwareFilterPolicy = createAction(
    '[Client/Pwsh/Exo] Load MalwareFilterPolicy',
    props<{ _tenant: string }>()
);

export const loadMalwareFilterPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Load MalwareFilterPolicy Success',
    props<{ _tenant: string, data: MalwareFilterPolicy[] }>()
);

export const loadMalwareFilterPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Load MalwareFilterPolicy Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateExoMalwareFilterPolicy = createAction(
    '[Client/Pwsh/Exo] Update Malware Filter Policy Policy',
    props<{ _tenant: string, data: Partial<MalwareFilterPolicy> }>()
);

export const updateExoMalwareFilterPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Update Malware Filter Policy Policy Success',
    props<{ _tenant: string, data: MalwareFilterPolicy }>()
);

export const updateExoMalwareFilterPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Update Malware Filter Policy Policy Failure',
    props<{ _tenant: string, error: any }>()
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';
import { QuarantinePolicy } from './model';

@Injectable()
export class ExoQuarantinePolicyEffects {

    fetchQuarantinePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchExoQuarantinePolicy),
            distinct(action => action._tenant),
            mergeMap((action) => this.get(action._tenant)
                .pipe(
                    map((data: QuarantinePolicy[]) => actions.fetchExoQuarantinePolicySuccess({ _tenant: action._tenant, data })),
                    catchError((error) => of(actions.fetchExoQuarantinePolicyFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    private get(tenant: string): Observable<QuarantinePolicy[]> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/quarantine-policy');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }
}

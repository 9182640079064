import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { DKIMSigningConfig } from './model';

export const fetchDkimSigningConfig = createAction(
    '[Client/Pwsh/Exo] Fetch DKIMSigningConfigEffects',
    props<{ _tenant: string }>()
);

export const fetchDKIMSigningConfigSuccess = createAction(
    '[Client/Pwsh/Exo] Fetch DKIMSigningConfigEffects Success',
    props<{ _tenant: string, data: any }>()
);

export const fetchDKIMSigningConfigFailure = createAction(
    '[Client/Pwsh/Exo] Fetch DKIMSigningConfigEffects Failure',
    props<{ _tenant: string, error: any }>()
);


export const updateDKIMSigningConfig = createAction(
    '[Client/Pwsh/Exo] Update DKIMSignINConfigEffects',
    props<{ _tenant: string, data: Partial<DKIMSigningConfig> }>()
);

export const updateDKIMSigningConfigSuccess = createAction(
    '[Client/Pwsh/Exo] Update DKIMSignINConfigEffects Success',
    props<{ _tenant: string, data: Update<DKIMSigningConfig> }>()
);

export const updateDKIMSigningConfigFailure = createAction(
    '[Client/Pwsh/Exo] Update DKIMSignINConfigEffects Failure',
    props<{ _tenant: string, error: any }>()
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, delay, distinct, map, mergeMap, retryWhen, take, tap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';

@Injectable()
export class RolesStatusEffects {
    reload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.reloadGraphRoles),
            mergeMap(({ _tenant, oldRoles }) =>
                this.get(_tenant).pipe(
                    map(roles => {
                        if (!roles || !Array.isArray(roles) || roles.length === 0) {
                            throw new Error('roles invalid');
                        }

                        if (roles.length <= oldRoles.length) {
                            throw new Error('New roles length should be higher than old roles');
                        }

                        return roles;
                    }),
                    retryWhen(errors =>
                        errors.pipe(
                            tap(error => console.warn('Retrying due to:', error.message)), // log retries
                            delay(3000), // wait 1 second between retries
                            take(5) // retry up to 5 times
                        )
                    ),
                    map(data => actions.loadGraphRolesSuccess({ _tenant, data })),

                    // keep old roles if reload fails
                )
            )
        )
    );

    loadGraphRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadGraphRoles),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map(roles => {
                        if (!roles || !Array.isArray(roles) || roles.length === 0) {
                            throw 'roles invalid';
                        } else {
                            return roles;
                        }
                    }),
                    map(data => actions.loadGraphRolesSuccess({ _tenant, data })),
                    catchError((error) => of(actions.loadGraphRolesFailure({ _tenant, error })))
                )
            )
        )
    );

    private get(tenant: string): Observable<string[]> {
        return this.ajax.get(tenant, '/api/fabric/status/client/roles');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}

import { Domain } from '@microsoft/microsoft-graph-types-beta';
import { updateDKIMSigningConfig } from 'src/app/stores/client/powershell/exo/dkim-signing-config/actions';
import { DKIMSigningConfig } from 'src/app/stores/client/powershell/exo/dkim-signing-config/model';

export function Remediate(_tenant: string, data: DKIMSigningConfig[], schema: any, default_domain: Domain) {
    if(!default_domain){
        return;
    }

    const changes: Partial<DKIMSigningConfig> = {
        Enabled: schema.contains.properties.Enabled.const,
        Identity: default_domain.id
    };

    return updateDKIMSigningConfig({ _tenant, data: changes });
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as TenantActions from './actions';
import { SwayTenant } from './model';

@Injectable()
export class TenantEffects {

    loadSwayTenant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.loadSwayTenant),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.getTenant(_tenant)
                .pipe(
                    map(data => TenantActions.loadSwayTenantSuccess({ _tenant, data })),
                    catchError(error => of(TenantActions.loadSwayTenantFailure({ _tenant, error })))
                )
            )
        )
    );


    
    updateSwayTenant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.updateSwayTenant),
            mergeMap(({ _tenant, update_auto_remediation, data }) => this.updateTenant(_tenant, update_auto_remediation, data)
                .pipe(
                    map(tenant => TenantActions.updateSwayTenantSuccess({ _tenant, tenant })),
                    catchError(error => of(TenantActions.updateSwayTenantFailure({ _tenant, error })))
                )
            )
        )
    );


    /**
     * @description
     * update groups order array to a specific tenant (SWAY database)
     * @param {string} tenant - tenant ID (string)
     * @param {string[]} group_order - array of group IDs in order
     */
    private getTenant(tenant: string): Observable<SwayTenant> {
        return this.ajax.get(tenant, `/api/sway/tenant/${tenant}`);

    }

    private updateTenant(tenant_id: string, update_auto_remediation: boolean, tenant: Partial<SwayTenant>): Observable<SwayTenant> {
        return this.ajax.patch(tenant_id, `/api/sway/tenant/${tenant_id}?update_auto_remediation=${update_auto_remediation}`, tenant);

    }


    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}

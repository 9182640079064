<mat-error *ngIf="isSecurityDefaultsEnabled ">
    You can't Add or Enable any conditional access policy while your Security Defaults Policy is enabled. Please disable
    it and try again.
</mat-error>

<mat-error *ngIf="noIncludeUsers">
    At least one rule in 'users' condition is required. Try to include any roles/users/groups or even set include users to 'none' to start with.
</mat-error>

<mat-error *ngIf="isBlockingEveryOne && preventBlockingEveryOne">
    Don't lock yourself out! We recommend applying a policy to a small set of users first to verify it behaves as
    expected. We also recommend excluding at least one administrator from this policy. This ensures that you still have
    access and can update a policy if a change is required. Please review the affected users.
</mat-error>


<form *ngIf="policy$ | async as policy" [formGroup]="form" class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>


        <tr>
            <td>
                Policy Name
            </td>

            <td>
                <ng-container *ngIf=" policy?.displayName">
                    {{ policy?.displayName }}
                </ng-container>

                <ng-container *ngIf="!policy?.displayName">
                    Policy Not Found
                </ng-container>
            </td>



            <td>
                <div class="field boolean">
                    <mat-form-field appearance="fill">
                        <mat-label>Display Name</mat-label>

                        <input matInput [disabled]="readOnly" formControlName="displayName">

                        <mat-error>Display name is required</mat-error>
                    </mat-form-field>
                </div>
            </td>

            <td>
                <sway-baseline-status *ngIf="form.get('state').value !=='delete'"
                    [hasError]="form.get('displayName').value !== policy?.displayName"></sway-baseline-status>

                <mat-icon *ngIf="form.get('state').value =='delete'">remove</mat-icon>
            </td>

        </tr>

        <tr>
            <td>
                State
            </td>

            <td>
                <div class="validator">
                    <div *ngIf="!policy?.id; else elseBlock">
                        Policy Not Found
                    </div>

                    <ng-template #elseBlock>
                        <div class="capitalize">

                            {{policy?.state }}

                        </div>
                    </ng-template>
                </div>
            </td>

            <td>
                <div class="field boolean">
                    <mat-form-field appearance="fill">
                        <mat-label>State</mat-label>
                        <mat-select [disabled]="readOnly" formControlName="state">
                            <mat-option [value]="'enabled'">Enable</mat-option>
                            <mat-option [value]="'disabled'">Disable</mat-option>
                            <mat-option [value]="'delete'">No Policy (Delete)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </td>

            <td>
                <sway-baseline-status *ngIf="form.get('state').value =='delete'"
                    [hasError]="!!policy?.id"></sway-baseline-status>

                <sway-baseline-status *ngIf="form.get('state').value !=='delete'"
                    [hasError]="form.get('state').value !== policy?.state"></sway-baseline-status>
            </td>
        </tr>


        <ng-content></ng-content>

    </table>



    <ng-container *ngIf="policy?.id || form.get('state').value !=='delete'">
        <sway-baseline-cap-config [schema$]="schema$" [baselineTemplateItem]="baselineTemplateItem"
            [tenant_id]="tenant_id" [readOnly]="readOnly" [form]="form" [policy$]="policy$">
        </sway-baseline-cap-config>
    </ng-container>
</form>

<div *ngIf="!readOnly" class="sway-filter-list">
  <mat-label>{{placeholder}}</mat-label>
 
  <ng-select 
    [class.ng-invalid]="invalid"
    [addTagText]="addTagText" [appendTo]="'body'" (change)="onChange()" [addTag]="true"  [formControl]="controller" [multiple]="true" >
    <ng-option *ngFor ="let item of options" [value]="item.name" >{{item.name}}</ng-option>
  </ng-select>
  
</div>

<mat-divider></mat-divider>



<!-- <mat-form-field *ngIf="!readOnly" class="sway-filter-list" appearance="fill">
  <mat-label>{{placeholder}}</mat-label>
 
  <mat-chip-list #chipList aria-label="">
    <mat-chip *ngFor="let item of items" (removed)="remove(item)">
      {{item}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>

    <input [placeholder]="placeholder" #listInput [formControl]="controller" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" (keyup.enter)="selected(listInput.value)"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
  </mat-chip-list>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected(controller.value)">
    <mat-option *ngFor="let item of filteredOptions | async" [value]="item['name']">
      {{item['name']}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field> -->


<div *ngIf="readOnly">
  <mat-form-field appearance="fill">
    <mat-label>{{placeholder}}</mat-label>
    <input [readonly]="true" matInput [formControl]="controller" />
  </mat-form-field>
</div>
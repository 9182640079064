import { createAction, props } from '@ngrx/store';
import { Geo } from './model';

export const loadGeos = createAction(
    '[MspGeo] Load'
);

export const loadGeosSuccess = createAction(
    '[MspGeo] Load Success',
    props<{ geos: Geo[] }>()
);

export const loadGeosFailure = createAction(
    '[MspGeo] Load Failure',
    props<{ error: any }>()
);

export const createGeo = createAction(
    '[MspGeo] Create',
    props<{ geo: Partial<Geo> }>()
);

export const createGeoSuccess = createAction(
    '[MspGeo] Create Success',
    props<{ geo: Geo }>()
);

export const createGeoFailure = createAction(
    '[MspGeo] Create Failure',
    props<{ error: any }>()
);

export const updateGeo = createAction(
    '[MspGeo] Update',
    props<{ id: string; geo: Partial<Geo> }>()
);

export const updateGeoSuccess = createAction(
    '[MspGeo] Update Success',
    props<{ geo: Geo }>()
);

export const updateGeoFailure = createAction(
    '[MspGeo] Update Failure',
    props<{ error: any }>()
);

export const deleteGeo = createAction(
    '[MspGeo] Delete',
    props<{ id: string }>()
);

export const deleteGeoSuccess = createAction(
    '[MspGeo] Delete Success',
    props<{ id: string }>()
);

export const deleteGeoFailure = createAction(
    '[MspGeo] Delete Failure',
    props<{ id: string; error: any }>()
);

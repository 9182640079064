<div *ngIf="standardPolicy; else notPossible" class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                State
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>

            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>

<ng-template #validatorRef>
    <div class="validator">
            {{
                standardPolicy.State

            }}
    </div>
</ng-template>

<ng-template #formRef>
    <div class="form">
        <div class="field boolean">
            <mat-form-field appearance="fill">
                <mat-label>State</mat-label>
                <mat-select [formControl]="control">
                    <mat-option [value]="'Enabled'">Enable</mat-option>
                    <mat-option [value]="'Disabled'">Disable</mat-option>
                    <!-- <mat-option [value]="'Not possible'">Not possible</mat-option> -->
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>


<ng-template #notPossible
 >
    <p class="warning">
        This baseline can only be applied if the Preset EOP Policy (Standard) has been created on the tenant in the Admin Console
        <a href="https://docs.microsoft.com/en-us/microsoft-365/security/office-365-security/preset-security-policies" target="_blank" > Read more</a>
    </p>
</ng-template>

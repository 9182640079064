<div class="mat-typography">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                Anonymous Links Expiry
                <mat-icon matTooltip="Specifies all anonymous links that have been created (or will be created) will expire after the set number of days. The value can be from 0 to 730 days. To remove the expiration requirement, set the value to zero (0).">info</mat-icon>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="validatorRef"></ng-container>
            </td>

            <td>
                <ng-container *ngTemplateOutlet="formRef"></ng-container>
            </td>

            <td>
                <sway-baseline-status [errors]="errors"></sway-baseline-status>
            </td>
        </tr>
    </table>
</div>


<ng-template #validatorRef>
    <div class="validator">
        <div class="container">
            <h2>
                {{
                    data.RequireAnonymousLinksExpireInDays > 0
                        ? data.RequireAnonymousLinksExpireInDays
                        : " 0 (No Expiry)."
                }}
            </h2>
        </div>
    </div>
</ng-template>

<ng-template #formRef>
    <div class="form">
        <div class="field boolean">
            <mat-form-field appearance="fill">
                <mat-label>Number of Days</mat-label>
                <input  matInput type="number" [formControl]="control">
                <mat-hint align="end">Zero for no expiry</mat-hint>
                <mat-error *ngIf="control?.errors">You have to set value between 0 and 730</mat-error>
            </mat-form-field>
        </div>
    </div>
</ng-template>





<sway-cap-baseline *ngIf="baseline && schema$" [readOnly]="readOnly"  [tenant_id]="tenant_id"
    [form]="form" [baseline]="baseline" [schema$]="schema$" [preventBlockingEveryOne]="grantController.value === 'block'" [schemaFunc]="schemaFunc" [policy$]="policy$"
    [baselineTemplateItem]="baselineTemplateItem" (baselineErrorChange)="onBaselineErrorChange($event)">


    <ng-container *ngTemplateOutlet="content"></ng-container>

</sway-cap-baseline>


<ng-template #content>
    <ng-container *ngIf="policy$ | async as policy">

        <tr *ngIf="policy.id || form.get('state').value !=='delete'">

            <td>
                Grant
            </td>

            <td>

                <ng-container *ngFor="let item of policy?.grantControls?.builtInControls">
                    {{ item }}
                </ng-container>
            </td>

            <td>
                <mat-form-field appearance="fill">
                    <mat-label>Grant</mat-label>
                    <mat-select [disabled]="readOnly" [formControl]="grantController">

                        <mat-option [value]="'block'">Block</mat-option>
                        <mat-option [value]="'mfa'">Required MFA</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>

            <td>
                <sway-baseline-status
                    [hasError]="!(policy?.grantControls?.builtInControls[0] === grantController?.value && policy?.grantControls?.builtInControls?.length == 1)">
                </sway-baseline-status>
            </td>
        </tr>

        <ng-container *ngIf="policy?.id">
            <tr>
                <td>
                    Include platforms
                </td>

                <td>
                    <div *ngFor="let item of policy?.conditions?.platforms?.includePlatforms">
                        {{ item }}
                    </div>
                </td>



                <td>
                    All
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!(policy?.conditions?.platforms?.includePlatforms?.includes('all') && policy?.conditions?.platforms?.includePlatforms?.length == 1)">
                    </sway-baseline-status>
                </td>
            </tr>


            <tr>
                <td>
                    Exclude platforms
                </td>

                <td>
                    <div *ngFor="let item of policy?.conditions?.platforms?.excludePlatforms">
                        {{ item }}
                    </div>
                </td>



                <td>
                    android<br>
                    iOS<br>
                    macOS<br>
                    windows
                </td>

                <td>
                    <sway-baseline-status
                        [hasError]="!( policy?.conditions?.platforms?.excludePlatforms?.includes('android')  && policy?.conditions?.platforms?.excludePlatforms?.includes('iOS') && policy?.conditions?.platforms?.excludePlatforms?.includes('windows') && policy?.conditions?.platforms?.excludePlatforms?.includes('macOS') && policy?.conditions?.platforms?.excludePlatforms?.length == 4)">
                    </sway-baseline-status>
                </td>
            </tr>
        </ng-container>
    </ng-container>





</ng-template>
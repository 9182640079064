import { ConditionalAccessApplications, ConditionalAccessGrantControls, ConditionalAccessPlatforms, ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { RemediateCapItem } from '../shared/cap-remediate';
import { getCAPBaselineDisplayName } from '../shared/cap-schema';

function grantControls(grant): ConditionalAccessGrantControls {
    return {
        'operator': 'OR',
        'builtInControls': [
            grant
        ]
    };
}

const applications: ConditionalAccessApplications = { // check this
    'includeApplications': [
        'All'
    ]

};

const platforms: ConditionalAccessPlatforms = { // check this
    'includePlatforms': [
        'all'
    ],

    'excludePlatforms': [
        'android',
        'iOS',

        'macOS',
        'windows'
    ]
};

export function Remediate(_tenant: string, data: ConditionalAccessPolicy[], schema: any, users, id) {
    let policyItem;
   
    if(id){
        policyItem = data.find(res => res.id === id );
    }else{
        policyItem = data.find(res => res.displayName === getCAPBaselineDisplayName(schema) );
    }
    
    const grant = schema?.contains?.properties?.grantControls?.properties?.builtInControls?.items?.const;
    return RemediateCapItem(_tenant, schema, policyItem, { grantControls: grantControls(grant), platforms, applications });
}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromBilling from '../../store/reducers';

@Component({
    selector: 'billing-payment-status',
    templateUrl: './payment-status.component.html',
    styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {

    public status = 'Processing';
    public description = 'We\'re processing your subscription';

    constructor(
        private store: Store<fromBilling.State>,
    ) { }

    ngOnInit(): void {
    }

}

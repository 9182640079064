import { createAction, props } from '@ngrx/store';
import { AuthenticationMethodModel } from './model';

export const fetchGraphAuthenticationMethods = createAction(
    '[Graph/AuthMethods] Fetch Graph AuthenticationMethods',
    props<{ _tenant: string, ids: string[] }>()
);

export const fetchGraphAuthenticationMethodsSuccess = createAction(
    '[Graph/AuthMethods] Fetch Graph AuthenticationMethods Success',
    props<{ _tenant: string, data: AuthenticationMethodModel[] }>()
);

export const fetchGraphAuthenticationMethodsFailure = createAction(
    '[Graph/AuthMethods] Fetch Graph AuthenticationMethods Failure',
    props<{ _tenant: string, error: any }>()
);

<div class="mat-typology">
    <h2>
        Manage {{data.title}} in Baseline
    </h2>

    <p class="w-70">
        The purpose of this screen is to manage {{data.title}} that you desire to be on the baseline. Choose
        {{data.title}} from the
        left that you want to add to the baseline and use the right arrow button to add them to the
        baseline. Conversely you can choose {{data.title}} from the right that you want to remove from the baseline, and
        then
        use the left arrow button  to move out of the baseline.

    </p>

    <p class="w-70">
        <b>
            NB. Editing here will not change anything on the tenant. That will only happen when you remediate the
            baseline.
        </b>
    </p>

    <mat-tab-group *ngIf="data && !data.template">
        <mat-tab  [label]="'Manage ' + data.title">
          
            <div class="manage-wrapper">
                <sway-manage-items [allItems$]="allItems$" [data]="data"></sway-manage-items>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <label matTooltip="Compare current tenant items with baseline desired value">Compare tenant to baseline
                </label>
            </ng-template>

            <div class="manage-wrapper">

                <sway-compare-cap-items [allItems$]="allItems$" [data]="data"></sway-compare-cap-items>
            </div>
        </mat-tab>
    </mat-tab-group>


    <ng-container *ngIf="data?.template">
        <sway-manage-items [allItems$]="allItems$" [data]="data"></sway-manage-items>
    </ng-container>

    <div class="buttons">
        <button (click)="saveChanges()" mat-raised-button color="primary" class="save-btn">
            Continue
        </button>


        <button mat-dialog-close="" mat-raised-button color="danger" class="save-btn">
            Discard
        </button>
    </div>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaselineFormComponent } from '../../abstract/baseline-form.component';

@Component({
    selector: 'sway-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})

export class FormComponent extends BaselineFormComponent implements OnInit, OnDestroy {
    ngOnDestroy(): void {
        this.subs.forEach(res => {
            res.unsubscribe();
        });
    }

    subs: Subscription[] = [];
    public control = new FormControl();

    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));

        if (this.readonly) {
            this.control.disable();
        }

        const value = schema.properties[this.field]?.maximum;

        this.control.setValue(value);

        this.control.valueChanges.subscribe(value => {
            if (!value) return;

            if (value === 'any') {
                delete schema.properties[this.field];
                schema.required = [];
            } else {
                schema.properties[this.field] = {};
                schema.properties[this.field]['maximum'] = value;
                schema.required = [this.field];
            }

            this.baselineChange.next({
                baseline: {
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema))
                }
            });
        });
    }

}

import { createAction, props } from '@ngrx/store';
import { TransportConfig } from './model';

export const fetchExoTransportConfig = createAction(
    '[Client/Pwsh/Exo] Fetch TransportConfig',
    props<{ _tenant: string }>()
);

export const fetchExoTransportConfigSuccess = createAction(
    '[Client/Pwsh/Exo] Fetch TransportConfig Success',
    props<{ _tenant: string, data: TransportConfig }>()
);

export const fetchExoTransportConfigFailure = createAction(
    '[Client/Pwsh/Exo] Fetch TransportConfig Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateExoTransportConfig = createAction(
    '[Client/Pwsh/Exo] Update TransportConfig',
    props<{ _tenant: string, data: Partial<TransportConfig> }>()
);

export const updateExoTransportConfigSuccess = createAction(
    '[Client/Pwsh/Exo] Update TransportConfig Success',
    props<{ _tenant: string, data: Partial<TransportConfig> }>()
);

export const updateExoTransportConfigFailure = createAction(
    '[Client/Pwsh/Exo] Update TransportConfig Failure',
    props<{ _tenant: string, error: any }>()
);

import { ConditionalAccessApplications, ConditionalAccessGrantControls, ConditionalAccessLocations, ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { RemediateCapItem } from '../shared/cap-remediate';
import { getCAPBaselineDisplayName } from '../shared/cap-schema';


function grantControls(grant): ConditionalAccessGrantControls {
    return {
        'operator': 'OR',
        'builtInControls': [
            grant
        ]
    };
}
const locations: ConditionalAccessLocations = {
    excludeLocations: ['AllTrusted'],
    includeLocations: ['All']
};


const applications: ConditionalAccessApplications = { // check this
    'includeUserActions': [
        'urn:user:registersecurityinfo'
    ]

};


export function Remediate(_tenant: string, data: ConditionalAccessPolicy[], schema: any, users, id) {
    let policyItem;

    if (id) {
        policyItem = data.find(res => res.id === id);
    } else {
        policyItem = data.find(res => res.displayName === getCAPBaselineDisplayName(schema));
    }


    const grant = schema?.contains?.properties?.grantControls?.properties?.builtInControls?.items?.const
        || schema?.contains?.properties?.grantControls?.properties?.builtInControls?.items?.enum[0];

    return RemediateCapItem(_tenant, schema, policyItem, { grantControls: grantControls(grant), locations, applications });
}

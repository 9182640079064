import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { filter, first, Observable, switchMap } from 'rxjs';
import { client } from '../stores/client';
import { getSessionMspId } from '../stores/root.store';
import { PermissionDialogComponent } from './guards/permission-dialog/permission-dialog.component';
import { BuildAuthorisationLink } from './guards/permission.guard';

@Injectable({
    providedIn: 'root'
})
export class PermissionCheckService {
    constructor(
        private store: Store<any>,
        private dialog: MatDialog,
    ) { }


    public hasPermissions(tenant: string, scopes: string[]): Observable<boolean> {
        return this.store.select(client(tenant).octiga.status.roles.status)
            .pipe(
                filter(status => status.loaded),
                switchMap(_ => this.store.select(client(tenant).octiga.status.roles.hasScopes(scopes)))
            );
    }

    consentMissingPermissions(tenant_id: string): void {

        this.store.pipe(select(getSessionMspId), first())
            .subscribe(msp_id => {

                this.dialog.open(PermissionDialogComponent, {
                    width: '40rem',
                    data: {
                        link: BuildAuthorisationLink(tenant_id, msp_id),
                        tenant: tenant_id
                    }
                });
            }
            );
    }
}

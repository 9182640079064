import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import { loadPSATenantTicketCount, loadPSATenantTicketCountSuccess, loadPSATenantTicketCountFailure } from './actions';
import { TicketCount } from 'oct-report-lib';

export const featureKey = 'reportPSATenantTicketCount';

export interface State extends Status {
    data: TicketCount[]
}

export const initialState: State = {
    data: null,
    ...initialStatus
};

export const reducer = createReducer(
    initialState,

    // LOAD
    on(loadPSATenantTicketCount,
        (state) => ({ ...state, loading: true })
    ),
    on(loadPSATenantTicketCountSuccess,
        (state, { _msp, _tenant, data }) => ({ ...state, _msp, _tenant, data, loading: false, loaded: true })
    ),
    on(loadPSATenantTicketCountFailure,
        (state, { _msp, _tenant, error }) => ({ ...state, _msp, _tenant, error, loading: false, loaded: false }))
);

export const selectData = (state: State) => state.data;
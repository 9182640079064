import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { loadReportCurrentPostureCount, loadReportCurrentPostureCountFailure, loadReportCurrentPostureCountSuccess } from './actons';

@Injectable()
export class ReportCurrentPostureCountEffects {

    loadCurrentPostureCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadReportCurrentPostureCount),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.getCountWithPaging(_tenant)
                .pipe(
                    map(data => {
                        console.log('data', data);
                        return loadReportCurrentPostureCountSuccess({ _tenant, data });
                    }),
                    catchError(error => of(loadReportCurrentPostureCountFailure({ _tenant, error })))
                )
            )
        )
    );

    private getCountWithPaging(tenant: string) {
        return this.ajax.get(tenant, `/api/report/tenant/${tenant}/baselines/posture/count`);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) { }

}

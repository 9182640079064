<h2 mat-dialog-title>New Feature</h2>

<mat-dialog-content class="mat-typography">
    <h3>Additional Permissions Required</h3>
    <button mat-button (click)="addInfo = addInfo === 'close'? 'open' : 'close'" color="warn">
        {{missing_roles.length}} Missing Permission(s) <mat-icon>{{addInfo === 'close'?'arrow_drop_down' :
            'expand_less'}}</mat-icon>
    </button>
    <mat-list [@showHide]="addInfo">
        <ng-container *ngFor="let role of missing_roles">
            <mat-list-item style="color:red; font-size:12px">{{role}}</mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
    <p>New feature has been added or updated since consent was last granted for this client. For this new feature to
        work, some
        additional permissions need to be granted.</p>
    <p>Please note that since this is an <i>Application</i> consent, even though you may be missing just one permission,
        you are required to re-consent all permissions.</p>
    <p>Please authorize using the admin credentials for the
        <b>client tenant</b>.
    </p>
    <p>You can view the permissions on the next screen.</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="flex gap-4">

        <button mat-button mat-dialog-close>Cancel</button>


        <a (click)="copyAuthorizeLink()">

            <button mat-raised-button color="primary" cdkFocusInitial>
                <mat-icon>file_copy</mat-icon>

                Copy Link</button>
        </a>

        
        <a (click)="authenticatePermissions()">

            <button matTooltip="Share this link with your tenant admin to grant Octiga the required permissions." mat-raised-button color="primary" cdkFocusInitial>Authorize</button>
        </a>
    </div>


</mat-dialog-actions>
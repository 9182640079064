<mat-nav-list>

    <div class="wrap">
        <a id="monitoring-nav-alerts" mat-list-item routerLink="{{'/client/' + tenant_id + '/monitoring/alerts'}}"
            routerLinkActive="active" class="info">
            Alerts
        </a>
    </div>

    <div class="wrap disabled">
        <a id="monitoring-nav-events" class="info disabled" mat-list-item disableRipple> Events</a>
    </div>

    <div class="wrap indent">
        <a id="monitoring-nav-events" mat-list-item
            routerLink="{{'/client/' + tenant_id + '/monitoring/events-temporal'}}" routerLinkActive="active"
            class="info">
            All
        </a>
    </div>

    <div class="wrap indent">
        <a id="monitoring-nav-events" mat-list-item
            routerLink="{{'/client/' + tenant_id + '/monitoring/events-country'}}" routerLinkActive="active"
            class="info">
            Country
        </a>
    </div>

    <div class="wrap indent">
        <a id="monitoring-nav-events" mat-list-item
            routerLink="{{'/client/' + tenant_id + '/monitoring/events-region'}}" routerLinkActive="active"
            class="info">
            Region
        </a>
    </div>

    <div class="wrap indent">
        <a id="monitoring-nav-events" mat-list-item routerLink="{{'/client/' + tenant_id + '/monitoring/events-user'}}"
            routerLinkActive="active" class="info">
            User
        </a>
    </div>

</mat-nav-list>
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { InputCountry } from './model';

export const featureKey = 'InputCountry';

export interface State extends EntityState<InputCountry>, Status { }

export const adapter: EntityAdapter<InputCountry> = createEntityAdapter<InputCountry>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadInputCountry, (state) => ({ ...state, loading: true })),
    on(actions.loadInputCountrySuccess, (state, action) => adapter.setAll(action.data, { ...state, loading: false, loaded: true })),
    on(actions.loadInputCountryFailure, (state, { error }) => ({ ...state, error })),

    on(actions.createInputCountry, (state) => ({ ...state, creating: true })),
    on(actions.createInputCountrySuccess, (state, action) => adapter.addOne(action.data, { ...state, creating: false })),
    on(actions.createInputCountryFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.deleteInputCountry, (state) => ({ ...state, deleting: true })),
    on(actions.deleteInputCountrySuccess, (state, action) => adapter.removeOne(action.id, { ...state, deleting: false })),
    on(actions.deleteInputCountryFailure, (state, { error }) => ({ ...state, error, deleting: false }))
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export const selectEntity = (id: string) => createSelector(
    selectEntities,
    entities => entities[id]
);

import { Component, Input, OnInit } from '@angular/core';
import { ConditionalAccessPolicy, IdentitySecurityDefaultsEnforcementPolicy } from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import { combineLatest, first, map, switchMap, take } from 'rxjs';
import { client } from 'src/app/stores/client';
import { selectSession } from 'src/app/stores/root.store';
import { BaseComponent } from '../../../base/base.component';
import { displayName } from './remediate';

const DisabledSchema = {
    'type': 'array',
    'items': {
        'type': 'object',
        'properties': {
            'displayName': {
                'type': 'string',
                'not': {
                    'const': 'Octiga Generated Disable Legacy Auth'
                }
            }
        }
    }
};

const EnabledSchem = {
    'type': 'array',
    'contains': {
        'type': 'object',
        'required': [
            'grantControls',
            'displayName',
            'conditions',
            'state'
        ],
        'properties': {
            'state': {
                'type': 'string',
                'const': 'enabled'
            },
            'conditions': {
                'type': 'object',
                'required': [
                    'clientAppTypes',
                    'users'
                ],
                'properties': {
                    'users': {
                        'type': 'object',
                        'properties': {
                            'IncludeUsers': {
                                'type': 'string',
                                'const': 'all'
                            }
                        }
                    },
                    'clientAppTypes': {
                        'type': 'array',
                        'items': {
                            'enum': [
                                'exchangeActiveSync',
                                'other'
                            ]
                        }
                    }
                }
            },
            'displayName': {
                'type': 'string',
                'const': 'Octiga Generated Disable Legacy Auth'
            },
            'grantControls': {
                'type': 'object',
                'properties': {
                    'operator': {
                        'type': 'string',
                        'const': 'OR'
                    },
                    'builtInControls': {
                        'type': 'array',
                        'items': {
                            'type': 'string',
                            'const': 'block'
                        },
                        'maxItems': 1
                    }
                }
            }
        }
    }
};

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss']
})

export class BaselineComponent extends BaseComponent implements OnInit {
    isSecurityDefaultsEnabled: boolean;
    legacyAuth: ConditionalAccessPolicy;
    @Input() data: ConditionalAccessPolicy[];

    constructor(private store: Store<any>) {
        super();
    }

    ngOnInit(): void {
        const securityDefatuls$ = this.store.select(selectSession).pipe(
            map((sess) => sess.session.clientId),

            switchMap((tid) =>
                combineLatest([
                    this.store.select(client(tid).graph.securityDefaults.item),
                    this.store.select(client(tid).graph.securityDefaults.status),
                ])
            ),
            first(([data, status]) => status.loaded),
            map(([data]) => data)
        );


        securityDefatuls$.subscribe((res: IdentitySecurityDefaultsEnforcementPolicy) => {
            if (res) {
                this.isSecurityDefaultsEnabled = res.isEnabled;
                this.baselineErrorChange.next({
                    remediate: true,
                    save: false
                });
            } else {
                this.baselineErrorChange.next({
                    remediate: false,
                    save: false
                });
            }

        });

        this.legacyAuth = this.data.find(res => res.displayName === displayName);


        const selectPolicies$ = this.store.select(selectSession).pipe(
            map(sess => sess.session.clientId),
            switchMap(tid => combineLatest([
                this.store.select(client(tid).graph.users.all),
                this.store.select(client(tid).graph.users.status)
            ])),
            first(([data, status]) => status.loaded),
            map(([data]) => data),
            take(1)
        );

        selectPolicies$.subscribe(users => {

            const service_users = users.filter(u => (u.userPrincipalName && u.userPrincipalName.startsWith('octiga_service_user')));

            let schema = JSON.parse(JSON.stringify(this.baseline.schema));

            const value = schema?.contains?.properties?.state?.const;
            this.control.setValue(value || 'disabled');

            this.control.valueChanges.subscribe(value => {
                if (value === 'enabled') {
                    schema = JSON.parse(JSON.stringify(EnabledSchem));

                    const excludeUsers = {
                        'type': 'array',
                        'items': {
                            'type': 'string',
                            'enum': service_users.map(u => u.id)
                        },

                    };


                    if (service_users.length > 0) {
                        schema.contains.properties.conditions.properties.users.properties.excludeUsers = excludeUsers;

                    } else {
                        delete schema.contains.properties.conditions.properties.users.properties.excludeUsers;
                    }


                } else {
                    schema = JSON.parse(JSON.stringify(DisabledSchema));

                }


                this.baselineChange.next({
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema))
                });
            });

        });

    }


    ngOnChanges(): void {
        if (this.data instanceof Array) {
            this.legacyAuth = this.data.find(res => res.displayName === displayName);
        }
    }
}

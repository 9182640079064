<div class="wrapper">
    <!-- <div (click)="goToSway()" class="session">
        <div class="image">
            <img class="icon" src="./assets/img/dashboard/sway.svg" />

            <div class="middle">
                <div class="text">Click to View</div>
            </div>
            <app-severity-badges [severity]="deviations$ | async"></app-severity-badges>
        </div>
        <span  class="mat-h2">Baselines Menu</span>
    </div> -->

    <div class="spinner">
        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div>


    
        <div *ngIf="(categories$ | async)?.length > 0; else elseBlock" class="wrapper">

            <div (click)="goToCategory(cat.category)" class="session" *ngFor="let cat of (categories$ | async)">
                <div  class="image">
                    <div *ngIf="(cat.severity$ | async)['danger'] <=0; else categoryWarnBlock" class="img-wrapper">
                        <img class="icon main-img"
                            [src]="'./assets/img/dashboard/sway/' + cat?.category?.name + '-ok.svg'" />
                        <img class="icon" src="./assets/img/dashboard/sway/mark-ok.svg">

                    </div>

                    <ng-template #categoryWarnBlock>
                        <div class="img-wrapper">
                            <img class="icon main-img"
                                [src]="'./assets/img/dashboard/sway/' + cat?.category?.name + '-warn.svg'" />
                            <img class="icon" src="./assets/img/dashboard/sway/mark-warn.svg">
                        </div>
                    </ng-template>


                    <div class="middle">
                        <div class="text">Click to View</div>
                    </div>
                    <app-severity-badges [small]="true" [isLoading]="!isDevLoaded"
                        [severity]="cat.severity$ | async"></app-severity-badges>
                </div>
                <span class="mat-h2" *ngIf="cat?.category?.name !== 'Authentication & Authorisation'">{{cat?.category?.name}}</span>
                <span class="mat-h2" *ngIf="cat?.category?.name === 'Authentication & Authorisation'">Authn & Authz</span>
            </div>
        </div>

        <ng-template #elseBlock>
            <p *ngIf="!isLoading">
                You don't have any tenant baseline yet, Please go to tenant baselines and add your desired baselines
            </p>
        </ng-template>

</div>